import AmpecoTheme from '@ampeco/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text, ThemeProps, withTheme} from 'react-native-elements';
// @ts-ignore
import HTML from 'react-native-render-html';
import {Common} from '@ampeco/components';

interface Props {
    text: string;
}

class HTMLRendererComponent extends React.Component<Props & ThemeProps<AmpecoTheme>, {}> {
    render(): React.ReactElement {
        if (!this.props.text) {
            return <React.Fragment/>;
        }

        const baseFontStyle = {
            color: this.props.theme.HTMLRenderer.color,
            textAlign: 'left',
        };
        const tagsStyles= {
            a: {
                color: this.props.theme.HTMLRenderer.color,
            },
        };

        const fontSize = this.props.theme.HTMLRenderer.fontSize || 14;
        const listsPrefixesRenderers = {
            ul: (htmlAttribs: any, children: any, convertedCSSStyles: any, passProps: any) => {
                return <View style={{
                    marginRight: 5,
                    width: fontSize / 2.8,
                    height: fontSize / 2.8,
                    marginTop: fontSize / 2,
                    borderRadius: fontSize / 2.8,
                    backgroundColor: this.props.theme.HTMLRenderer.color,
                }}/>;
            },
            ol: (htmlAttribs: any, children: any, convertedCSSStyles: any, passProps: any) => {
                return (
                    <Text style={{ color: this.props.theme.HTMLRenderer.color, fontSize, marginRight: 5 }}>{passProps.index + 1})</Text>
                );
            },
        };

        const renderers = {
            del: (htmlAttribs: any, children: any, convertedCSSStyles: any, passProps: any) => {
                return <Text style={{color: this.props.theme.HTMLRenderer.color, textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>
                    {passProps.rawChildren?.[0].data}
                </Text>;
            },
        };

        return <HTML
            html={this.props.text}
            baseFontStyle={baseFontStyle}
            tagsStyles={tagsStyles}
            listsPrefixesRenderers={listsPrefixesRenderers}
            onLinkPress={(event: any, href: any) => {
                Common.openURL(href);
            }}
            renderers={renderers}
            containerStyle={{alignItems: 'flex-start'}}
        />;
    }
}

const HTMLRenderer = withTheme<Props, AmpecoTheme>(HTMLRendererComponent);

export {HTMLRenderer};

const styles = StyleSheet.create({
    container: {
        width: 28,
        height: 28,
        borderRadius: 14,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
