import React from 'react';
import {Image} from 'react-native';
import ImageFailLoaderComponent from './ImageFailLoaderComponent';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import ImageLoader from './ImageLoader';

interface Props {
    source: Object | number;
    failColor?: string;
    defaultImage?: number;
    style?: Object;
    imageStyle?: Object;
    resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center';
}
interface State {}

export default class LoadServerImage extends React.PureComponent<Props, State> {
    render() {
        return <ImageLoader {...this.props} renderLoadingIndicator={this.renderLoadingIndicator} renderError={this.renderError} />;
    }

    renderLoadingIndicator = () => {

        return <ConfigurableActivityIndicator />;
    }

    renderError = () => {
        const {failColor, defaultImage} = this.props;
        if (defaultImage) {
            return <Image source={defaultImage} resizeMode={'contain'} style={this.props.style} />;
        }
        return <ImageFailLoaderComponent color={failColor} />;
    }
}
