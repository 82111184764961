import {SCAService, PaymentTransaction as Transaction, PaymentTransactionDefaultScaMeta as DefaultScaMeta} from '@ampeco/models';
import {Logger} from '@ampeco/logging';
import BroadcastService from '@ampeco/broadcast';
import { Platform } from 'react-native';

export default abstract class BaseSCAService implements SCAService {
    protected transaction: Transaction | null;

    constructor(transaction: Transaction | null) {
        this.transaction = transaction;
    }

    setTransaction(transaction: Transaction | null) {
        this.transaction = transaction;

        return this;
    }

    requiresSCA() {
        return this.transaction?.scaMeta !== undefined && Object.keys(this.transaction.scaMeta).length > 0;
    }

    protected canRedirect() {
        if (!this.transaction || !this.transaction.scaMeta) {
            return false;
        }

        const scaMeta = this.transaction.scaMeta as DefaultScaMeta;

        return scaMeta.redirectUrl && scaMeta.returnUrl && scaMeta.failureUrl;
    }

    openChallenge() {
        if (!this.transaction) {
            return Promise.resolve();
        }

        const scaMeta = this.transaction.scaMeta as DefaultScaMeta;
        if (!scaMeta) {
            Logger.error(`Tried to open SCA challenge without SCA meta for transactionID: ${this.transaction.id}`);
            return Promise.reject();
        }

        if (!this.requiresSCA()) {
            return Promise.resolve();
        }

        if(Platform.OS === 'web'){
            return this.handleOpenChallenge();
        } else {
            // Setting the BroadcastService.shouldDisconnect is necessary in order to preserve
            // the ECHO connection open while SCA challenge is being performed.
            return new Promise((resolve, reject) => {
                BroadcastService.shouldDisconnect = false;
                resolve(true);
            }).then(() => this.handleOpenChallenge().finally(() => {
                BroadcastService.shouldDisconnect = true;
            }));
        }
    }

    protected abstract handleOpenChallenge(): Promise<any>;
}
