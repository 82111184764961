import React from 'react';
import {observer} from 'mobx-react';
import ContactDetails from "./ContactDetails";
import GlobalStore from "../../stores/GlobalStore";
import {useLocation} from "react-router-dom";
import {BasePage} from "../BasePage";
import NavigationService from "@ampeco/navigation";

type LocationState = {
    title: string;
    url: string;
};

const ContactDetailsWrapper: React.FC = observer(() => {
    const location = useLocation<LocationState>();
    const navigation: any = {
        title: location.state?.title,
        url: location.state?.url,
        getParam: (query: string) => {
            if (query === 'steps') {
                return [GlobalStore.sharedInstance().flow.activeStep, GlobalStore.sharedInstance().flow.totalSteps];
            }

            if (query === 'flow') {
                return GlobalStore.sharedInstance().flow
            }

            return navigation[query];
        },
        setOptions: () => {/**/},
    };

    return (
        <BasePage title={'popups.title.ContactDetails'} onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home')
        }}>
            <ContactDetails navigation={navigation}/>
        </BasePage>
    );
});

export default ContactDetailsWrapper;
