import React from 'react';
import PropTypes from 'prop-types';
import {FormStore} from './FormStore';

export interface ProviderContext {
    store: FormStore;
}

export const FormStoreContext = React.createContext<ProviderContext|undefined>(undefined);

interface Props extends React.PropsWithChildren {
    store: FormStore
}

export class FormStoreProvider extends React.Component<Props> {
    // you must specify what you’re adding to the context
    static childContextTypes = {
        store: PropTypes.object.isRequired,
    };

    getChildContext() {
        return {store: this.props.store};
    }

    render() {
        // `Children.only` enables us not to add a <div /> for nothing
        return <>{this.props.children}</>;
    }
}

