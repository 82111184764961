declare global {
    interface Window {
        initialConfig: { app: App, additional?: Additional };
    }
}

interface App {
    imgUrlPins: string,
    name: string,
    appVersion: string,
    baseUrlSchema: string,
    baseUrl: string,
    language: string,
    supportedLanguages: string[],
    supportsPayments: boolean,
    iosUrl: string,
    androidUrl: string,
    ga: string,
    images: string,
    logo: string,
    appleStoreId: string,
    googleStoreId: string,
    appBackendSchema: string,
    appBackendDomain: string,
    broadcastEndpoint: string,
    broadcastChannelPrefix: string,
    spinnerType: string,
    spinnerSize: number,
    googleMapsApiKey: string,
    bugsnagApiKey: string,
    mapOptions: {
        defaultCoordinates: {
            latitude: string,
            longitude: string,
        }
    },
    appEnv?: string,
    theme: Theme
}

interface Additional {
    displays?: {
        connectorOrdering?: 'asc' | 'desc'
    }
}

interface Theme {
    type?: string;
    accentColor?: string
    backgroundColor?: string,
    headerBackgroundColor?: string,
    menuBarsColor?: string,
    connectorTintColor?: string,
    chargingProgressGenericBorderColor?: string
    chargingProgressGenericSegmentColor?: string
    dividerColor?: string,
    locationInfoBoxColor?: string,
    pinTextColor?: string,
    spinnerSize?: string,
    spinnerType?: string,
    noticeTextColor?: string,
    noticeBackgroundColor?: string,
    mapNoticeTextColor?: string,
    mapNoticeBackgroundColor?: string,
}

const settings = {
    env: process.env.APP_ENV,
    appName: window.initialConfig.app.name,
    appVersion: window.initialConfig.app.appVersion,
    serverUrl: (window.initialConfig.app.baseUrlSchema || 'https') + '://' + window.initialConfig.app.baseUrl + '/api/v1',
    api: {
        endpoint: (window.initialConfig.app.appBackendSchema || 'https') + '://' + window.initialConfig.app.appBackendDomain + '/api/v1',
        client_id: '',
        client_secret: '',
        oauth_token_url: '/app/oauth/token',
        pass_client_id_and_secret_from_register_call: false,
    },
    bugsnag: {
        apiKey: window.initialConfig.app.bugsnagApiKey,
        releaseStage: window.initialConfig.app.appEnv ?? 'production',
    },
    analytics: {
        trackingId: window.initialConfig.app.ga
    },
    maps: {
        apiKey: window.initialConfig.app.googleMapsApiKey,
        defaults: {
            coordinates: {
                latitude: window.initialConfig.app?.mapOptions?.defaultCoordinates?.latitude,
                longitude: window.initialConfig.app?.mapOptions?.defaultCoordinates?.longitude
            }
        }
    },
    appStores: {
        appleStoreId: window.initialConfig.app.appleStoreId,
        googleStoreId: window.initialConfig.app.googleStoreId,
    },
    images: window.initialConfig.app.images,
    locale: {
        // ADD NEW LANGUAGE MARKER
        default: window.initialConfig.app.language ?? 'en',
        enabled: {
            en: 'English',
            bg: 'Български',
            mk: 'Македонски',
            el: 'Ελληνικά',
            'zh-TW': '繁體中文',
            'zh-CN': '简体中文',
            'zh-HK': '繁體中文',
            zh: '繁體中文',
            pt: 'Português',
            sl: 'Slovenski',
            hr: 'Hrvatski',
            it: 'Italiano',
            de: 'Deutsch',
            hu: 'Magyar',
            fi: 'Suomi',
            is: 'Islenska',
            'sv-SE': 'Svenska',
            'da-DK': 'Dansk',
            'tr-TR': 'Türkçe',
            'nb-NO': 'Norsk',
            'es-ES': 'Español',
            ar: 'اَلْعَرَبِيَّةُ',
            he: 'עִבְרִית',
            ta: 'தமிழ்',
            ms: 'Malay',
            cy: 'Cymraeg',
            sr: 'Српски',
            fr: 'Français',
            ro: 'Română',
            lt: 'Lietuvių',
            uk: 'Українська',
            ru: 'Русский',
            nl: 'Nederlands',
            'ca-ES': 'Catalan',
            'he-x-evedge': 'עִבְרִית',
        },
    },
    broadcast: {
        endpoint: window.initialConfig.app.broadcastEndpoint,
        channelPrefix: window.initialConfig.app.broadcastChannelPrefix,
    },
    spinnerSettings: {
        spinner: window.initialConfig.app?.theme?.spinnerType || 'three-bounce',
        spinnerSize: window.initialConfig.app?.theme?.spinnerSize || 40,
    },
    supportsPayments: window.initialConfig.app.supportsPayments,
};

export default settings;
