import React from 'react';
import {useLocation} from "react-router-dom";
import TermsScreen from "./TermsScreen";
import {BasePage} from "../BasePage";

type LocationState = {
    title: string;
    url: string;
}

function Terms() {
    const location = useLocation<LocationState>();
    const navigation: any = {
        title: location?.state?.title,
        url: location?.state?.url,
        getParam: (query: string) => navigation[query]
    };

    return (
        <BasePage>
            <TermsScreen navigation={navigation} />
        </BasePage>
    );
}

export default Terms;
