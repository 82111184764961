import React, {FunctionComponent} from 'react';
import {Text} from 'react-native-elements';
import {icons} from '../../res/images';
import NavigationService from '@ampeco/navigation';
import {__} from '@ampeco/i18n';
import {Image, StyleSheet, TouchableOpacity, View} from "react-native";
import colors from "../../res/colors";
import settings from '../../settings';
import RTLImage from "../../../frontend_modules/rtl-image/RTLImage";

type Props = {
    title: string;
    componentTitle: string;
    onGoBackClick: () => void;
    goBackDisabled: boolean;
}

const TitleComponent: FunctionComponent<Props> = ({title, componentTitle, onGoBackClick, goBackDisabled = false}: Props) => {
    const getTitle = () => {
        if (title && title.includes('.')) {
            return __(`${title}`);
        } else if (componentTitle && componentTitle.includes('.')) {
            return __(`${componentTitle}`);
        } else if (componentTitle) {
            return componentTitle;
        } else {
            return settings.appName;
        }
    };

    let onGoBackClickCallback = onGoBackClick;

    if (onGoBackClickCallback == null ) {
        onGoBackClickCallback = () => NavigationService.sharedInstance().goBack();
    }

    return (
        <View style={styles.container}>
            {goBackDisabled !== true && <TouchableOpacity style={styles.menuItem} onPress={onGoBackClickCallback}>
                <RTLImage source={{uri: icons.back}} style={styles.imageStyle} resizeMode={'contain'}/>
            </TouchableOpacity>}
            <View style={styles.titleStyles}>
                <Text style={{marginTop: (goBackDisabled ? 20 : 0)}}>{getTitle()}</Text>
            </View>
        </View>
    );
};

export {TitleComponent};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        lineHeight: '20px',
        textAlignVertical: 'middle',
        paddingBottom: 10,
        flex: 1,
        flexDirection: "row",
        justifyContent: "center"
    },
    menuItem: {
        color: "white",
        padding: 0,
    },
    titleStyles: {
        textAlignVertical: "middle",
        width: "90%",
        textAlign: "center",
    },
    imageStyle: {
        tintColor: colors.textEmphasisColor,
        height: 20,
        width: 20,
    },
});
