import {observable} from 'mobx';
import {TopUpPackage} from '../TopUpPackage';

export default class TopUpPackagesStore {
    private static instance: TopUpPackagesStore;
    @observable topUpPackages: TopUpPackage[] = [];

    static sharedInstance(): TopUpPackagesStore {
        if (TopUpPackagesStore.instance === undefined) {
            TopUpPackagesStore.instance = new TopUpPackagesStore();
        }
        return TopUpPackagesStore.instance;
    }
}
