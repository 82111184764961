import {observer} from 'mobx-react';
import React from 'react';
import palette from "../../res/palette";
import {View, StyleSheet, Text, TouchableHighlight, TouchableOpacity, Image, I18nManager} from "react-native";
import {TextSubTitle} from "@ampeco/charge-screens";
import {__} from "@ampeco/i18n/helpers";
import {useHistory} from "react-router";
import {LocaleStore} from '@ampeco/i18n';
import {PaymentsStore} from '@ampeco/payment-methods';
import {AddButton} from '@ampeco/components';
import {icons, paymentMethodImages} from '../../res/images';
import GlobalStore from "../../stores/GlobalStore";
import Alert from "@ampeco/alert";
import NavigationService from '@ampeco/navigation';
import {RTLImage} from '@ampeco/rtl-image';

const Header = observer((props: {profile: any}) => {
    const history = useHistory();
    LocaleStore.observeLanguage();
    const paymentsStore = PaymentsStore.sharedInstance();
    const globalStore = GlobalStore.sharedInstance();
    const defaultPaymentMethod = paymentsStore.defaultPaymentMethod;

    const paymentMethodAdd = () => {
        if (globalStore.canAddPaymentMethod) {
            NavigationService.sharedInstance().navigate('PaymentMethods');
        } else {
            Alert.sharedInstance().show(
                __('verify.cannot-add-payment-method-title'),
                __('verify.cannot-add-payment-method-message'),
                [
                    {text: __('verify.ok')},
                ],
                {cancelable: false},

            );
        }
    };

    const showPaymentMethods = () => {
        NavigationService.sharedInstance().navigate('PaymentMethods');
    };

    return <View>
        <TouchableHighlight style={styles.row} onPress={() => {
            history.push('/view-profile');
        }}>
            <View style={styles.container}>
                <View style={[styles.right, styles.rowWrapper]}>
                    <View style={styles.textWrapper}>
                        <Text style={[palette.emphasis, styles.title]}>{props.profile.firstName && props.profile.lastName && __('user.names').replace('{firstName}', props.profile.firstName || '').replace('{lastName}', props.profile.lastName || '') || __('menu.profile_name_placeholder')}</Text>
                        <TextSubTitle>{props.profile.email}</TextSubTitle>
                    </View>
                    <View style={styles.disclosure}>
                        <RTLImage source={icons.disclosure} style={styles.disclosureIcon} />
                    </View>
                </View>
            </View>
        </TouchableHighlight>
        {Boolean(paymentsStore.paymentsEnabled) && defaultPaymentMethod !== undefined &&
        <TouchableOpacity activeOpacity={0.6}
                          onPress={defaultPaymentMethod === null ? paymentMethodAdd : showPaymentMethods}>
            <View style={[styles.container, styles.row]}>
                <View style={[styles.right, styles.height50]}>
                    <TextSubTitle>{__('menu.titles.payment_method_select')}</TextSubTitle>
                    {defaultPaymentMethod === null &&
                    <View style={styles.creditCard}>
                        <Text
                            style={[palette.emphasis, palette.light, styles.text]}>{__('menu.no_payment_method')}
                        </Text>
                    </View>}
                    {defaultPaymentMethod !== null &&
                        <View style={styles.creditCard}>
                        {defaultPaymentMethod.type !== 'corporate' &&
                        <Image
                            source={paymentMethodImages[defaultPaymentMethod.type]}
                            style={styles.creditCardImage}/>
                        }
                        <Text
                            style={[palette.emphasis, palette.light, styles.text]}
                            numberOfLines={1}>{defaultPaymentMethod.name}</Text>
                    </View>
                    }
                </View>
                <View style={styles.disclosure}>
                    {defaultPaymentMethod === null && <AddButton iconStyle={styles.iconStyle} onPress={paymentMethodAdd}/>}
                    {defaultPaymentMethod !== null && <RTLImage source={icons.disclosure} style={styles.disclosureIcon}/>}
                </View>
            </View>
        </TouchableOpacity>}
    </View>
});

export default Header;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 10,
        paddingLeft: 15,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 9,
        paddingBottom: 9,
        textAlign: 'left',
    },
    rowWrapper: {
        height: 50,
        flexDirection: 'row',
        textAlign: 'left',
    },
    height50: {
        height: 50
    },
    right: {
        flex: 50,
        justifyContent: 'center',
    },
    disclosure: {
        flex: 5,
        justifyContent: 'center',
        alignItems: 'center',
        width: 10,
        marginRight: 14,
        textAlign: "left",
    },
    disclosureIcon: {
        tintColor: palette.text.color,
        width: 5,
        height: 12,
    },
    title: {
        fontSize: 18,
        fontWeight: '500',
        textAlign: 'left',
    },
    textWrapper: {
        flex: 50,
        textAlign: 'left'
    },
    text: {
        flex: 1,
        fontSize: 16,
    },
    iconStyle: {
        width: 10,
        height: 10,
    },
    creditCard: {
        flexDirection: 'row',
        marginTop: 5,
    },
    creditCardImage: {
        width: 38,
        height: 24,
        marginRight: 13,
        borderRadius: 3,
    },
});
