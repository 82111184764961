import React from 'react';
import {InputProps} from 'react-native-elements';
import {FieldInput} from '@ampeco/forms';
import Select from './Select';
import {SelectProps} from '@ampeco/select';

interface SelectAdapterProps {
    value: any,
    onChange: (value: any) => void,
    afterChangeCallback: (value: any) => void,
    errorMessage: string,
    editable: boolean,
    options: [{ key: string, label: string }],
}

export type Props = SelectAdapterProps & Partial<InputProps> & Omit<SelectProps, 'options'>;

export class SelectAdapter extends React.Component<Props> implements FieldInput {
    render() {

        const {
            onChange,
            afterChangeCallback,
            value,
        } = this.props;

        const selected = this.props.options.find(el => el.key == value);

        return <Select
            {...this.props}
            options={this.props.options.map(el => el.label)}
            selected={selected ? selected.label : ''}
            onChange={(text: string, index: number) => {
                const changedTo = this.props.options.find(el => el.label == text);
                const value = changedTo ? changedTo.key : ''
                onChange(value);
                if (this.props?.afterChangeCallback !== undefined) {
                    afterChangeCallback(value)
                }
            }}
        />;
    }
}
