import React from 'react';
import {EVSE, PricePeriodFeesAvailability, PriceTypes, Tariff, TimeFormatTypes} from '@ampeco/charge-models';
import Currency, {format} from '@ampeco/currency';
import {Text, ThemeConsumer} from 'react-native-elements';
import {Dimensions, Image, StyleSheet, TouchableOpacity, View} from 'react-native';
import {__, LocaleStore} from '@ampeco/i18n';
import Alert from '@ampeco/alert';
import TimeOfDayPrices from './TimeOfDayPrices';
import PriceRow from './PriceRow';
import Navigation from '@ampeco/navigation';
import {LocationStore, PriceFieldsFormatterStore} from '@ampeco/charge-stores';
import {Common} from '@ampeco/components';
import {AveragePowerLevelsPrices, PeakPowerLevelsPrices} from "./index";
import moment from "moment";
import {HTMLRenderer} from "@ampeco/html-renderer";

const PricingHeader: React.FC<{padTop?: boolean, padBottom?: boolean}> = ({padTop, padBottom, children}) => (<ThemeConsumer>{({theme}) => {
    return <Text style={[
        theme.Pricing.titleStyle,
        {marginBottom: padBottom ? 16 : 0, marginTop: padTop ? 16 : 0}]}>
        {children}
    </Text>;
}}</ThemeConsumer>);

const Pricing: React.FC<{evse: EVSE, tariff: Tariff|null, currency: Currency|null, canUseChargingAllowance?: boolean}> = ({evse, tariff, currency, canUseChargingAllowance = false}) => (<ThemeConsumer>{({theme}) => {
    const priceFormatterStore = PriceFieldsFormatterStore.sharedInstance();
    const durationOnlyFor = tariff?.pricingPeriodInMinutes && tariff?.standardTariffDurationLastUnit ? (tariff.pricingPeriodInMinutes * tariff.standardTariffDurationLastUnit).toString() : undefined;
    const width = Dimensions.get('screen').width;
    const locationStore = LocationStore.sharedInstance();
    const pricePeriods = tariff?.pricePeriods ? tariff.pricePeriods.filter(period => period.connectionFee || period.idle?.fee || period.duration?.fee || period.energyPerKwh) : null;
    const pricePeriodFeesAvailability: PricePeriodFeesAvailability = {
        connectionFee: false,
        energyPerKwh: false,
        idleFee: false,
        durationFee: false,
    }

    pricePeriods?.filter(
        period => {
            if (period.connectionFee && !pricePeriodFeesAvailability.connectionFee) {
                pricePeriodFeesAvailability.connectionFee = true;
            }
            if (period.idle?.fee && !pricePeriodFeesAvailability.idleFee) {
                pricePeriodFeesAvailability.idleFee = true;
            }
            if (period.duration?.fee && !pricePeriodFeesAvailability.durationFee) {
                pricePeriodFeesAvailability.durationFee = true;
            }
            if (period.energyPerKwh && !pricePeriodFeesAvailability.energyPerKwh) {
                pricePeriodFeesAvailability.energyPerKwh = true;
            }
        },
    );
    const pricePeriodFeesAvailabilityLength = Object.values(pricePeriodFeesAvailability).filter(item => item === true).length;

    if (locationStore.selectedPricePeriod === undefined && tariff && tariff.priceType === 'standard_tod' && pricePeriods) {
        locationStore.updateSelectedPricePeriod(pricePeriods[0])
    }

    return <View>

        <View style={[theme.Pricing.containerStyle, styles.pricingContainer]}>
            {tariff?.priceType !== 'charging not allowed' ?
                <PricingHeader padBottom>
                    {__('session.start.pricing.title')}
                    {(evse.tariffId === null || (tariff && tariff.priceType === 'free'))
                        &&
                        ' ' + __('session.start.pricing.free')

                    }
                </PricingHeader> : null}

            {tariff && tariff.additionalInformation !== '' && tariff.additionalInformation && <TouchableOpacity activeOpacity={0.6} onPress={
                async () => {
                    const buttons: any = [
                        {text: __('message.ok')},
                    ];

                    if (tariff.learnMoreUrl !== null) {
                        buttons.push(
                            {
                                text: __('message.learn-more'),
                                onPress: async () => {
                                    await Common.openURL(tariff.learnMoreUrl as string);
                                    await Navigation.sharedInstance().goBack();
                                },
                                style: 'cancel',
                            },
                        );
                    }
                    Alert.sharedInstance().show(
                        __('session.start.pricing.additional-information'),
                        tariff.additionalInformation || '',
                        buttons,
                    );
                }
            }><Image source={theme.Pricing.infoIcon} style={[theme.Pricing.iconStyle, styles.icon]} resizeMode={'contain'} /></TouchableOpacity>}
        </View>
        {tariff && tariff.description ?  <HTMLRenderer text={tariff.description} style={[
            theme.Pricing.descriptionStyle,
            {marginBottom: 10}]} /> : null}
        {tariff && currency !== null && <View>
            {
                tariff.connectionFee !== null
                && tariff.connectionFee > 0
                && <PriceRow
                    label={__('session.start.pricing.connection-fee')}
                    value={format(tariff.connectionFee, currency)}
                />}
            {
                tariff.priceForEnergyWhenOptimized !== null && tariff.priceType === 'agile streets' || tariff.priceType === PriceTypes.OPTIMISED_DYNAMIC
                && tariff.priceForEnergyWhenOptimized && tariff.priceForEnergyWhenOptimized > 0
                && <PriceRow
                    label={tariff.priceType !== PriceTypes.OPTIMISED_DYNAMIC ? __('session.start.pricing.per-kwh-optimized-1') : __('session.start.pricing.per-kwh-optimized-1').toLocaleLowerCase()}
                    labelBold={tariff.priceType !== PriceTypes.OPTIMISED_DYNAMIC ? __('session.start.pricing.per-kwh-optimized-2-in-bold') : tariff?.optimisedLabel ?? __('dynamic-optimised.session-start.default_dynamic_optimised_label')}
                    value={format(Number(tariff.priceForEnergyWhenOptimized), currency)}
                    valueIsBold={true}
                    hasAsterisk={canUseChargingAllowance}
                    reverse
                />
            }
            {
                tariff.priceForEnergy !== null && !['agile streets', 'optimised', 'energy tou'].includes(tariff.priceType) && tariff.priceForEnergy > 0 && (
                    <PriceRow
                        label={tariff.priceType === PriceTypes.OPTIMISED_DYNAMIC ? __('session.start.pricing.per-kwh-boost') : __('session.start.pricing.per-kwh')}
                        value={format(Number(tariff.priceForEnergy), currency)}
                        hasAsterisk={canUseChargingAllowance}
                    />)
            }
            {
                tariff.priceForIdle !== null
                && tariff.priceForIdle > 0
                && <PriceRow
                    label={__('session.start.pricing.idle-fee-per-minute')}
                    value={format(tariff.priceForIdle, currency)}
                />}
            {
                tariff.idleFeeGracePeriodMinutes !== null
                && tariff.idleFeeGracePeriodMinutes > 0
                && <PriceRow
                    label={__('session.start.pricing.idle-fee-grace-period')}
                    value={__('session.start.pricing.duration-fee-grace-period-value').replace(':period', tariff?.idleFeeGracePeriodMinutes?.toString() ?? '0')}
                />}

            {
                tariff.priceType === 'duration+energy time of day'
                && <TimeOfDayPrices tariff={tariff} currency={currency}
                                    canUseChargingAllowance={canUseChargingAllowance}/>}

            {
                tariff.priceForDuration !== null
                && tariff.priceForDuration > 0
                && <PriceRow
                    label={__('session.start.pricing.per-period-minutes').replace(':period', tariff?.pricingPeriodInMinutes?.toString() ?? '1')}
                    value={format(tariff.priceForDuration, currency)}
                />}
            {
                tariff.priceType === PriceTypes.OPTIMISED_DYNAMIC && tariff.durationFeeFrom && tariff.durationFeeTo ? (
                    <View style={styles.row}>
                        <Text style={[theme.Pricing?.labelStyle, {fontSize: 12}]}>{__('session.start.pricing.dynamic-optimised.period-from')}</Text>
                        <Text style={[theme.Text.style, styles.boldText]}>{moment(tariff.durationFeeFrom, 'HH:mm:ss').format(LocaleStore.sharedInstance().displayTimeFormat as TimeFormatTypes)}</Text>
                        <Text style={[theme.Pricing?.labelStyle, {fontSize: 12}]}>{__('session.start.pricing.dynamic-optimised.period-to')}</Text>
                        <Text style={[theme.Text.style, styles.boldText]}>{moment(tariff.durationFeeTo, 'HH:mm:ss').format(LocaleStore.sharedInstance().displayTimeFormat as TimeFormatTypes)}</Text>
                        <Text style={[theme.Pricing?.labelStyle, {fontSize: 12}]}>{__('session.start.pricing.dynamic-optimised.period-only')}</Text>
                    </View>
                ) : null
            }
            {
                tariff.durationFeeGracePeriod !== null
                && tariff.durationFeeGracePeriod > 0
                && <PriceRow
                    label={__('session.start.pricing.duration-fee-grace-period-label')}
                    value={__('session.start.pricing.duration-fee-grace-period-value').replace(':period', tariff?.durationFeeGracePeriod?.toString() ?? '0')}
                />}
            {
                // Handles case where tou tariff's priceType is a text saying to contact the provider
                tariff.priceForEnergy !== null && tariff.priceType === 'energy tou' && isNaN(Number(tariff.priceForEnergy)) && (
                    <PriceRow
                        label={__('session.start.pricing.per-kwh')}
                        value={tariff.priceForEnergy as string}
                        hasAsterisk={canUseChargingAllowance}
                        hasLongValue
                    />)
            }
            {
                tariff.priceForEnergy !== null && tariff.priceType === 'agile streets' && tariff.priceForEnergy > 0 && (
                    <PriceRow
                        label={__('session.start.pricing.per-kwh-non-optimized')}
                        value={format(Number(tariff.priceForEnergy), currency)}
                        hasAsterisk={canUseChargingAllowance}
                    />)
            }
            {
                tariff.priceForEnergyAtDay !== null && ['optimised'].includes(tariff.priceType) && tariff.priceForEnergyAtDay as number > 0 && (
                    <View style={{marginTop: 10}}>
                        <Text style={styles.rowLabel}>{__('session.start.pricing.day')} ({tariff.optimisedTariffEnd?.substring(0, 5)} - {tariff.optimisedTariffStart?.substring(0, 5)})</Text>
                        <PriceRow
                            label={__('session.start.pricing.fee-per-kwh')}
                            value={format(Number(tariff.priceForEnergyAtDay), currency)}
                            hasAsterisk={canUseChargingAllowance}
                        />
                    </View>
                )}
            {
                tariff.priceForEnergyAtNight !== null && ['optimised'].includes(tariff.priceType)
                && tariff.priceForEnergyAtNight as number > 0
                && <View style={{marginTop: 10}}>
                    <Text  style={styles.rowLabel}>{__('session.start.pricing.night')} ({tariff.optimisedTariffStart?.substring(0, 5)} - {tariff.optimisedTariffEnd?.substring(0, 5)})</Text>
                    <PriceRow
                        label={__('session.start.pricing.fee-per-kwh')}
                        value={format(tariff.priceForEnergyAtNight as number, currency)}
                        hasAsterisk={canUseChargingAllowance}
                    />

                </View>
            }
            {
                tariff.priceForSession !== null
                && tariff.priceForSession > 0
                && <PriceRow
                    label={__('session.start.pricing.per-session')}
                    value={format(tariff.priceForSession, currency)}
                />}

            {
                tariff.priceType === PriceTypes.POWER_LEVELS && currency !== null
                    ? <AveragePowerLevelsPrices
                        powerLevels={tariff.powerLevels.map((level) => {
                            return {
                                averagePowerUpToKw: level.max_power_kw,
                                pricePerMinute: level.price_per_minute,
                            };
                        })}
                        currency={currency} />
                    : null
            }
            {  tariff.priceType === PriceTypes.PEAK_POWER_LEVELS
            && currency
                ? <PeakPowerLevelsPrices
                    powerLevels={tariff.powerLevels.map((level) => {
                        return {
                            peakPowerUpToKw: level.max_power_kw,
                            regularPricePerMinute: level.price_per_minute,
                            excessUsePricePerMin: level.excess_use_price_per_minute,
                        };
                    })}
                    currency={currency}
                    regularUseMinutes={tariff.regularUseMinutes} /> : null
            }
            {
                tariff.minPrice !== null &&
                tariff.minPrice > 0 &&
                <PriceRow
                    label={__('session.start.pricing.min_price')}
                    value={format(tariff.minPrice, currency)}
                />}
            {
                tariff.standardTariffIdleFee !== null &&
                tariff.standardTariffIdleFee > 0 &&
                <PriceRow
                    label={tariff.pricingPeriodInMinutes ? __('session.start.pricing.idle-fee-per-minutes').replace('{idleMinutes}', tariff.pricingPeriodInMinutes.toString()) : __('session.start.pricing.idle-fee')}
                    value={format(tariff.standardTariffIdleFee, currency)}
                />}
            {tariff.priceType === 'standard' && tariff.standardTariffDurationFee !== null &&
                <>
                    <PriceRow
                        label={tariff.pricingPeriodInMinutes ? __('session.start.pricing.duration-fee-per').replace('{duration}', tariff.pricingPeriodInMinutes.toString()) : __('session.start.pricing.duration-fee')}
                        value={format(tariff.standardTariffDurationFee, currency)}
                    />
                    {durationOnlyFor ? <Text style={StyleSheet.flatten([theme.Pricing.labelStyle, styles.labelDescription])}>
                        {__('session.start.pricing.duration-fee-only-for').replace('{duration}', durationOnlyFor)}
                    </Text> : null}
                </>
            }
            {
                tariff.standardTariffFeePerKwh !== null && tariff.priceType === 'standard' && tariff.standardTariffFeePerKwh > 0 && (
                    <PriceRow
                        label={__('session.start.pricing.per-kwh')}
                        value={format(Number(tariff.standardTariffFeePerKwh), currency)}
                        hasAsterisk={canUseChargingAllowance}
                    />)
            }
            {/*@ TODO add new swiper for web...*/}
            {/*{tariff.priceType === 'standard_tod' && pricePeriods?.length ? (<SafeAreaView style={{marginHorizontal: -20}}>*/}
            {/*        <Swiper*/}
            {/*            automaticallyAdjustContentInsets*/}
            {/*            style={[{*/}
            {/*                flexDirection: I18nManager.isRTL && Platform.OS === 'android' ? 'row-reverse' : 'row',*/}
            {/*            }, theme.Pricing?.swiper.border]}*/}
            {/*            removeClippedSubviews={false}*/}
            {/*            showsPagination={false}*/}
            {/*            height={pricePeriodFeesAvailabilityLength * 20 + 100}*/}
            {/*            index={pricePeriods.findIndex((period, key) => {*/}
            {/*                return period === locationStore.selectedPricePeriod ? key : 0;*/}
            {/*            })}*/}
            {/*            loop={false}*/}
            {/*            onMomentumScrollEnd={e => {*/}
            {/*                const index = Math.round(e.nativeEvent.contentOffset.x / width);*/}
            {/*                pricePeriods[index] && locationStore.updateSelectedPricePeriod(pricePeriods[index]);*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {pricePeriods.map((period, key) => {*/}
            {/*                return <StandardTimeOfDayPrices*/}
            {/*                    key={key}*/}
            {/*                    tariff={tariff}*/}
            {/*                    currency={currency}*/}
            {/*                    pricePeriod={period}*/}
            {/*                    pricePeriodFeesAvailability={pricePeriodFeesAvailability}*/}
            {/*                />;*/}
            {/*            })}*/}
            {/*        </Swiper>*/}
            {/*        <View style={{paddingHorizontal: 20}}><Text style={theme.Pricing?.swiper.notice}>{__('session.start.pricing.swipe-to-next-time-period')}</Text></View>*/}
            {/*    </SafeAreaView>*/}
            {/*) : null}*/}
        </View>}
    </View>
}}</ThemeConsumer>);

export default Pricing;

const styles = StyleSheet.create({
    pricingContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        height: 20,
        width: 26,
    },
    rowLabel: {
        fontWeight: '300',
        fontSize: 10,
        textTransform: 'uppercase',
    },
    labelDescription: {
        fontSize: 13,
        lineHeight: 15,
        marginBottom: 4,
    },
    row: {
        flexDirection: 'row',
    },
    boldText: {
        fontWeight: '500',
        marginHorizontal: 5,
        fontSize: 10,
        lineHeight: 18,
    },
});
