import React, {useCallback, useRef} from 'react';
import {InfoBox} from "@react-google-maps/api";
import {observer} from "mobx-react";
import {View, StyleSheet, Image, I18nManager} from "react-native";
import InfoBoxAddressComponent from "./InfoBoxAddressComponent";
import colors from "../../res/colors";
import NavigationService from '@ampeco/navigation';
import {ConnectorTypesList} from "@ampeco/charge-screens";
import {FavoritesStore, LocationStore} from '@ampeco/charge-stores';
import GlobalStore from "../../stores/GlobalStore";
import {__} from "@ampeco/i18n";
import './mapPopUpStyles.scss';
import {icons} from "../../res/images";
import {splitGeoToLatLng} from "../../utils/Map";
import {useHistory} from 'react-router';
import {matchRoutePath} from "../../services/NavigationService";
import useOpenMenu from '../../hooks/useOpenMenu';
import {theme} from "../../Theme";

const MapPopupComponent = observer((): JSX.Element => {
    const history = useHistory();
    const detailsRef = useRef(null);
    const favouriteRef = useRef(null);
    const closeRef = useRef(null);
    const locationStore = LocationStore.sharedInstance();
    const favoritesStore = FavoritesStore.sharedInstance();
    const globalStore = GlobalStore.sharedInstance();

    useOpenMenu('openMenu');

    const popupOptions = {
        alignBottom: false,
        boxStyle: {
            backgroundColor: colors.locationInfoBoxColor,
            color: 'white',
            borderRadius: '8px 8px',
            overflow: 'visible'
        },
        pixelOffset: new google.maps.Size(-60, 5),
    };

    const onCloseClick = useCallback(() => {
        locationStore.openedPinLocation = false;
    }, []);

    const onDetailsClick = async () => {
        const currentUrl = history.location.pathname;
        const matchedRoute = matchRoutePath('Location', {
            locationId: locationStore.focusedLocationId
        });

        if (currentUrl === matchedRoute.path && globalStore.isMenuOpen) {
            globalStore.closeMenu();
        } else {
            globalStore.openMenu();
            await NavigationService.sharedInstance().navigate(matchedRoute.path);
        }
    };

    const onFavoriteClick = async () => {
        await favoritesStore.toggleFavorite(locationStore.focusedLocation);
    };

    const getLatLng = (coords: string) => {
        const {latitude: lat, longitude: lng} = splitGeoToLatLng(coords);

        return {lat, lng};
    };

    const location = locationStore.focusedLocation;
    const fav = location && favoritesStore.favorites.find(fav => location.id === fav.id) !== undefined;
    const margins = {marginLeft: I18nManager.isRTL ? 0 : 10, marginRight: I18nManager.isRTL ? 10 : 0};
    const buttonStyle = StyleSheet.flatten([
        (globalStore.isLoggedIn ?
            reactNativeStyles.favoriteButton :
            reactNativeStyles.disabledFavButton), margins
    ]);
    return locationStore.openedPinLocation && locationStore.focusedLocation
        ? (<InfoBox
            options={popupOptions}
            position={getLatLng(locationStore.focusedLocation.location)}
            onCloseClick={onCloseClick}
            onLoad={() => {
                if (detailsRef?.current) {
                    detailsRef.current.addEventListener('click', onDetailsClick);
                }
                if (closeRef?.current) {
                    closeRef.current.addEventListener('click', onCloseClick);
                }
                if (favouriteRef?.current) {
                    favouriteRef.current.addEventListener('click', onFavoriteClick);
                }
            }}
            onUnmount={() => {
                if (detailsRef?.current) {
                    detailsRef.current.removeEventListener('click', onDetailsClick);
                }
                if (closeRef?.current) {
                    closeRef.current.removeEventListener('click', onCloseClick);
                }
                if (favouriteRef?.current) {
                    favouriteRef.current.removeEventListener('click', onFavoriteClick);
                }
            }}
        >
            <div className="popup">
                <View style={reactNativeStyles.viewStyle}/>
                <View>
                    <InfoBoxAddressComponent location={locationStore.focusedLocation} showDetails />
                </View>
                <View style={reactNativeStyles.container}>
                    <ConnectorTypesList location={locationStore.focusedLocation} limit={3} />
                </View>
                <View style={{flexDirection: 'row'}}>
                    <button
                        className="show-details-button" ref={detailsRef}
                        style={StyleSheet.flatten(reactNativeStyles.detailsButton)}
                    >{__('message.show-details')}</button>
                    <button
                        ref={favouriteRef}
                        disabled={!globalStore.isLoggedIn}
                        style={buttonStyle}
                        className={globalStore.isLoggedIn ? "show-details-button" : "add-to-fav--disabled"}
                    ><img
                        alt="favorite icon"
                        src={fav && !!globalStore.profile ? icons.favoriteOn : icons.favoriteOff}
                        width={25}
                        height={25}
                    /></button>
                </View>
                <div ref={closeRef}
                    style={StyleSheet.flatten([reactNativeStyles.closeButton,  I18nManager.isRTL ? {left: 10, right: 'auto'} :  {right: 10, left: 'auto'}])}
                >
                    <Image source={{uri: theme.Popup.closeImageSource}} style={StyleSheet.flatten([reactNativeStyles.closeButton])} resizeMode={'contain'}/>
                </div>
            </div>
            </InfoBox>
        ) : null;
});

export default MapPopupComponent;

const reactNativeStyles = StyleSheet.create({
    viewStyle: {
        width:0,
        height:0,
        borderLeftColor: 'transparent',
        borderLeftWidth: 15,
        borderRightColor: 'transparent',
        borderRightWidth: 15,
        borderBottomColor: colors.locationInfoBoxColor,
        borderBottomWidth: 15,
        zIndex: 5,
        position: 'absolute',
        left: 45,
        top: -10,
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    detailsButton: {
        backgroundColor: colors.buttonPrimaryColor,
    },
    favoriteButton: {
        flex: 2,
        flexDirection: 'row',
        backgroundColor: colors.buttonPrimaryColor,
    },
    disabledFavButton: {
        flex: 2,
        flexDirection: 'row',
        backgroundColor: colors.buttonPrimaryDisabledColor,
        border: 0,
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        width: 12,
        height: 12,
        tintColor: colors.textEmphasisColor,
    }
});
