import {StandaloneIcon} from '@ampeco/icons';
import AmpecoTheme from '@ampeco/theme';
import {observer} from 'mobx-react';
import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import InfoCenterStore from '../../stores/InfoCenterStore';
import {Common} from '@ampeco/components';

@observer
class HelpDesk extends React.Component {

    static navigationOptions = {
        headerMode: 'none',
    };

    render() {
        const store = InfoCenterStore.sharedInstance();
        if (store.help !== null) {
            return (
                <ThemeConsumer<AmpecoTheme>>{({theme}) => (
                    <>
                        <View style={styles.helpCenterSection}>
                            <View style={styles.icon}><StandaloneIcon style={theme.InfoCenter.iconStyles} source={theme.InfoCenter.iconHeadset} /></View>
                            <TouchableOpacity style={theme.InfoCenter.contactStyles} activeOpacity={0.6} onPress={
                                () => {
                                    if (store.help !== null && store.help.phone !== null) {
                                        Common.openURL('tel://' + store.help.phone);
                                    }
                                }
                            }><Text>
                                    {store.help && store.help.phone}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.helpCenterSection}>
                            <View style={styles.icon}><StandaloneIcon style={theme.InfoCenter.iconStyles} source={theme.InfoCenter.iconEnvelope} /></View>
                            <TouchableOpacity style={theme.InfoCenter.contactStyles} onPress={
                                () => {
                                    if (store.help !== null && store.help.email !== null) {
                                        Common.openURL('mailto:' + store.help.email);
                                    }
                                }
                            }><Text>{store.help && store.help.email}</Text></TouchableOpacity>
                        </View>
                    </>
                )}</ThemeConsumer>
            );
        } else {
            return <></>;
        }
    }
}

const styles = StyleSheet.create({
    helpCenterSection: {
        flexDirection: 'row',
        marginTop: 25,
    },
    icon: {
        width: 40,
        justifyContent: 'center',
    },
});

export default HelpDesk;
