import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {__} from '@ampeco/i18n';
import {Text, ThemeConsumer, Button} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

interface Props {
    enteredId?: string;
    message?: string | null;
    onPress: () => Promise<any>;
    propStyles?: any;
}
export default class UnknownAsset extends React.Component<Props> {

    render() {
        const {enteredId, propStyles} = this.props;
        const errorMsg = this.props.message ? this.props.message : !!enteredId ?  __('personalAssets.unknown-id.description').replace('{enteredId}', enteredId || '') : __('session.start.unknown-id.description');

        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => {
                return (
                    <SafeAreaView  style={[propStyles, styles.container, theme.PersonalAsset.UnknownAsset.container]}>
                        <Image style={styles.image} source={theme.PersonalAsset.UnknownAsset.unknownIcon} />
                        <Text style={styles.descriptionText}>{errorMsg}</Text>
                        <Button
                            title={__('message.back')}
                            onPress={this.onPress} />
                    </SafeAreaView>
                );
            }}
            </ThemeConsumer>
        );
    }

    onPress = async () => {
        const {onPress} = this.props;
        return onPress();
    };
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 60,
    },
    image: {
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titleText: {
        textAlign: 'center',
        marginBottom: 10,
    },
    descriptionText: {
        textAlign: 'center',
        marginBottom: 20,
    },
});
