import { Platform } from 'react-native';
import { __ } from '@ampeco/i18n';
import Geolocation, { GeolocationResponse } from '@react-native-community/geolocation';
import { calculateDistance } from '@ampeco/utils';
import Alert from '@ampeco/alert'
import {action} from 'mobx';

let userLocationInstance: UserLocationStore;
export default class UserLocationStore {
    userPosition?: GeolocationResponse = undefined;
    showAlert?: boolean = true;

    static sharedInstance() {
        if (userLocationInstance === undefined) {
            userLocationInstance = new UserLocationStore();
            Geolocation.setRNConfiguration({ skipPermissionRequests: false, authorizationLevel: 'whenInUse' });
        }
        return userLocationInstance;
    }

    @action.bound
    setShowAlert(shouldShow: boolean) {
        this.showAlert = shouldShow;
    }

    getCurrentPosition(): Promise<GeolocationResponse> {
        return new Promise((resolve, reject) => {
            if (this.userPosition !== undefined) {
                resolve(this.userPosition);
            } else {
                Geolocation.getCurrentPosition(
                    (position) => {
                        this.userPosition = position;
                        resolve(this.userPosition);
                    },
                    (error) => {
                        if (this.showAlert) {
                            switch (error.code) {
                                case 1:
                                    // FIXME: Error handling should be improved or @react-native-community/geolocation package should be changed. [ch20476]
                                    Alert.sharedInstance().show('', __('message.info.enable-location'));
                                    break;
                                default:
                                    Alert.sharedInstance().show('', __('message.error.no-location'))
                            }
                            this.setShowAlert(false);
                        }
                        reject(error);
                    },
                    (Platform.OS === 'android' || Platform.OS === 'web') ? {
                        enableHighAccuracy: true,
                        timeout: 20000,
                        maximumAge: 10000,
                    } : {},
                );
            }
        });
    }

    updatePosition(coordinates: any) {
        if (this.userPosition) {
            this.userPosition.coords = coordinates;
        }
    }

    static distanceFromUser(position: any): number {
        const [latitude, longitude] = position.split(',').map(parseFloat);
        if (isNaN(latitude) || isNaN(longitude)) {
            return Infinity;
        }

        const userCoordinates = this.sharedInstance().userPosition;
        if (!userCoordinates || !userCoordinates?.coords) {
            return Infinity;
        }

        return calculateDistance(latitude, longitude, userCoordinates.coords.latitude, userCoordinates.coords.longitude, 'K');
    }
}
