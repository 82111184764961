import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import NavigationService from '@ampeco/navigation';
import TopUpPackages from "@ampeco/balance/screens/TopUpPackages";
import {useLocation} from "react-router";
import {BalanceHistoryStore} from "@ampeco/balance";
import GlobalStore from "../../stores/GlobalStore";
import {ModulePage} from "../ModulePage";

function BalanceScreen(): JSX.Element {
    const navigationService = NavigationService.sharedInstance();

    const navigation: any = {
        setOptions: () => {/**/},
        navigate: navigationService.navigate.bind(navigationService),
    };
    const locationState = useLocation<{autoTopUp?:string}>();
    const title = locationState.state?.autoTopUp ? 'top-up-package.title_auto' : 'top-up-package.title';

    return (
        <ModulePage
            title={title}
            onGoBackClick={() => {
                NavigationService.sharedInstance().navigate('Balance')
            }}
            moduleName='balance'
        >
            <SafeAreaProvider>
                <TopUpPackages
                    navigation={navigation}
                    route={{
                        params: {
                            autoTopUp: locationState.state?.autoTopUp,
                            afterPurchaseCallback: async () => {
                                await GlobalStore.sharedInstance().statusUpdate();
                                await BalanceHistoryStore.sharedInstance().load();
                                NavigationService.sharedInstance().navigate('Balance');
                            }
                        },
                    }}
                />
            </SafeAreaProvider>
        </ModulePage>
    );
}

export default BalanceScreen;
