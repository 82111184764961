import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {EVSE, Session} from '@ampeco/charge-models';
import {PCEVSE} from '@ampeco/charge-models';
import {Session as SessionAPI} from '@ampeco/charge-api';

export class SessionPollingService {
    session: Session;
    callback: (session: Session) => void;
    interval: number;
    private handler: any;

    constructor(session: Session, callback: (session: Session) => void, interval: number = 1000) {
        this.session = session;
        this.callback = callback;
        this.interval = interval;
    }

    static poll(evse: EVSE|PCEVSE, session: Session, status: string, callback: (session: Session) => void): SessionPollingService | undefined {
        if (GlobalStoreProxy.sharedInstance().mockSession) {
            if (status === 'starting') {
                // Mock session start in 3 seconds
                setTimeout(() => {
                    session.status = 'active';
                    callback(session);
                }, 3000);
            } else if (status === 'stopping') {
                // Mock session start in half a second
                setTimeout(() => {
                    session.status = 'finished';
                    callback(session);
                }, 1000);
            } else {
                callback(session);
            }

            return;
        }

        if (session.status === status) {
            // Start polling untill status changes
            const sessionPoll = new SessionPollingService(session, session => {
                if (session.status !== status) {
                    callback(session);
                    sessionPoll.stop();
                    // globalStore.activeSession = session;
                    // if (status === 'stopping') {
                    //     SessionChangesMonitor.sessionHasEnded(session);
                    // }
                }
            });
            sessionPoll.start();
            return sessionPoll;
        } else {
            callback(session);
        }
    }

    start() {
        this.handler = setInterval(() => {
            SessionAPI.info(this.session.id).then((session: Session) => {
                this.callback(session);
                // tslint:disable-next-line:no-empty
            }).catch(() => {
            });
        }, this.interval);
    }

    stop() {
        if (this.handler) {
            clearInterval(this.handler);
            this.handler = undefined;
        }
    }
}
