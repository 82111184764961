// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
    Object.keys = (function () {
        'use strict'
        var hasOwnProperty = Object.prototype.hasOwnProperty,
            // eslint-disable-next-line no-prototype-builtins
            hasDontEnumBug = !({toString: null}).propertyIsEnumerable(
                'toString'),
            dontEnums = [
                'toString',
                'toLocaleString',
                'valueOf',
                'hasOwnProperty',
                'isPrototypeOf',
                'propertyIsEnumerable',
                'constructor'
            ],
            dontEnumsLength = dontEnums.length

        return function (obj) {
            if (typeof obj !== 'function' &&
                (typeof obj !== 'object' || obj === null)) {
                throw new TypeError('Object.keys called on non-object')
            }

            var result = [], prop, i

            for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(prop)
                }
            }

            if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                    if (hasOwnProperty.call(obj, dontEnums[i])) {
                        result.push(dontEnums[i])
                    }
                }
            }
            return result
        }
    }())
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i)
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]]

        return resArray
    }
}
