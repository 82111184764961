import {StackScreenProps} from '@react-navigation/stack';
import {HomeModalParamList} from '@ampeco/navigation';
import React from 'react';
import WebView from 'react-native-webview';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react';
import {ThemeProps, withTheme} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

type ExternalBalanceTopUpProps = StackScreenProps<
    HomeModalParamList,
    'ExternalBalanceTopUp'
    > & ThemeProps<AmpecoTheme>;

@observer
class ExternalBalanceTopUp extends React.Component<ExternalBalanceTopUpProps ,
        {
            url: string,
            onClose?: (success: boolean, shouldNavigateBack?: boolean) => void,
            loaded: boolean,
        }
    > {

    private closed = false;

    constructor(props: ExternalBalanceTopUpProps) {
        super(props);
        this.state = {
            url: props.route.params.url,
            onClose: props.route.params.onClose,
            loaded: false,
        }
    }

    _onNavigationStateChange = (webViewState: any) => {
        if (this.state.url && this.state.url.includes(encodeURIComponent(webViewState.url))
            && !this.closed
        ) {
            this.closed = true;

            if (this.state.onClose) {
                this.state.onClose(true, true);
            }
        }
    }

    render(): React.ReactElement<any> | string | React.ReactPortal | boolean | undefined {
        return <WebView
            source={ {uri: this.state.url} }
            onNavigationStateChange={this._onNavigationStateChange}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            onLoad={() => this.setState({loaded: true})}
            renderError={(domain, code, desc) =>  (
                <View style={{flex: 1, backgroundColor: this.props.theme.NetworkError.containerStyle.backgroundColor}} >
                    <Text style={[{padding: 16}, this.props.theme.NetworkError.titleStyle]}>{desc}</Text>
                </View>
            )}
            onError={() => <View style={{flex: 1, backgroundColor: this.props.theme.NetworkError.containerStyle.backgroundColor}} />}

            />;
    }
}

export default withTheme(ExternalBalanceTopUp);
