import React from 'react';
import { observer } from 'mobx-react';
import {Text, ThemeConsumer} from 'react-native-elements';
import {StyleSheet, View} from 'react-native';
import { WorkingHours } from '@ampeco/charge-models';
import {__} from '@ampeco/i18n';

type WorkingHoursProps = {
    workingHours: WorkingHours;
}

export default observer((prop: WorkingHoursProps) => {
    const workingHours = prop.workingHours;

    return (
        <ThemeConsumer>{({theme}) => {
            return <View style={styles.container}>
                {
                    Object.entries(workingHours).map(([weekDay, timeRange]) => (
                        <View key={weekDay} style={styles.hoursContainer}>
                            <Text style={[theme.Text?.style, styles.label]}>{__('location.working-hours.' + weekDay)}:</Text>
                            <View style={styles.hours}>
                                {timeRange.map((item, index) => <Text style={theme.Text?.style} key={index}>{item.start} - {item.end}</Text>)}
                                {!timeRange.length && <Text style={theme.Text?.style}>{__('location.working-hours.closed')}</Text>}
                            </View>
                        </View>
                    ))
                }
            </View>;
        }}</ThemeConsumer>
    )

});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
        marginTop: 20,
    },
    hoursContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        flex: 1,
    },
    hours: {
        display: 'flex',
        alignItems: 'flex-start',
        flex: 2,
    },
});
