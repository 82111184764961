import React from 'react';
import {
    ActivityIndicator,
    FlatList,
    Platform,
    StyleSheet,
    TouchableOpacity,
    View
} from 'react-native';
import {__, DEFAULT_DATE_FORMAT, LocaleStore} from '@ampeco/i18n';
import {observer} from 'mobx-react';
import {Divider, Text, ThemeProps, Button} from 'react-native-elements';
import {SessionHistoryStore, SessionStatsStore} from '@ampeco/charge-stores';
import {Action, CompositeInput, Field, FormStore, FormStoreProvider} from '@ampeco/forms';
import Select from '@ampeco/select';
import {HistoryLine} from '@ampeco/balance';
import {IReactionDisposer, reaction} from 'mobx';
import Navigation, {HomeStackParamList} from '@ampeco/navigation';
import {Notices} from '@ampeco/components';
import {StandaloneIcon} from '@ampeco/icons';
import {ThemeConsumer} from 'react-native-elements';
import {StackScreenProps} from '@react-navigation/stack';
import moment from 'moment';

const store = new FormStore();
store.rules.from_date = 'required';
store.rules.to_date = 'required';

type HistoryProps = StackScreenProps<
    HomeStackParamList,
    'History'
    >;
@observer
export default class History extends React.Component<HistoryProps> {
    static reaction: IReactionDisposer | null = null;

    componentDidMount(): void {
        const statsStore =  SessionStatsStore.sharedInstance();
        this.props.navigation.setOptions({title: __('sessions-history.title')});

        History.reaction = reaction(
            () => LocaleStore.sharedInstance().language,
            () => {
                this.props.navigation.setOptions({title: __('sessions-history.title')});
            },
        );

        const historyStore = SessionHistoryStore.sharedInstance();
        const filter = this.props.route.params.filter || historyStore.filter;
        if (filter) {
            historyStore.setFilter(filter)
        }

        historyStore.selectedPeriodIndex = SessionHistoryStore.THIS_MONTH_INDEX;
        historyStore.setCustomPeriodStart(getStartOfThisMonth());
        historyStore.setCustomPeriodEnd(getToday());
        historyStore.load();
        statsStore.load();
    }
    componentWillUnmount(): void {
        History.reaction && History.reaction();
        History.reaction = null;
        const historyStore = SessionHistoryStore.sharedInstance();
        historyStore.setFilter(null);
    }

    render() {
        const historyStore = SessionHistoryStore.sharedInstance();
        LocaleStore.observeLanguage();
        const periods = historyStore.getPeriods();
        const selected = historyStore.selectedPeriodIndex;
        const selectedFilter = historyStore.filter;
        const isCustomPeriod = historyStore.customPeriod;
        const customPeriodStart = historyStore.customPeriodStart;
        const customPeriodEnd = historyStore.customPeriodEnd;
        const navigation = Navigation.sharedInstance();
        const historyRecords = historyStore?.records?.slice();
        const statsStore = SessionStatsStore.sharedInstance();
        const stat = statsStore.stat;
        const isPersonalChargingEnabled = this.props.route.params.isPersonalChargingEnabled();

        store.actions.postperiod = async () => {
            historyStore.load();
            statsStore.load();
        };

        return (
            <ThemeConsumer>{({theme}) => {
                return <FlatList
                    style={styles.container}
                    contentContainerStyle={theme.Container.byPadding}
                    data={historyStore.list}
                    renderItem={({item}) => <HistoryLine
                        note={item.note}
                        timestamp={item.session.startedAt}
                        duration={item.session.duration}
                        value={item.session}
                        leftColumn="energy"
                        noteIcon={item.type === 'public' ? theme.SessionHistory.noteIcons.public : theme.SessionHistory.noteIcons.private}
                        energy={item.session.energy}
                        type={item.type}
                        paymentStatus={item.session.paymentStatus}
                        onPress={() => {
                            if (item.session.isPersonal) {
                                navigation.navigate('PersonalChargingSummaryContainer', {
                                    sessionId: item.session.id,
                                    isHistory: true,
                                });
                            } else {
                                navigation.navigate('HistoryDetails', {sessionId: item.session.id, item });
                            }
                        }}
                    >{(item.session.paymentStatus === 'failed' || item.session.paymentStatus === 'partially_paid') &&
                        <Notices.PaymentFailedNotice
                            session={item.session}
                            inverse
                            onMap={false}
                            iconStyle={styles.failureIcon}
                        />}</HistoryLine>}
                    ItemSeparatorComponent={Divider}
                    ListHeaderComponentStyle={{zIndex: 1}}
                    ListHeaderComponent={<>
                        <CompositeInput label={__('sessions-history.period-label')}>
                            <Select fullWidth={false} options={periods} selected={periods[selected]}
                                    onChange={(selected: any)  => {
                                        const newlySelectedPeriodIndex = periods.findIndex(value => value === selected);

                                        if (selected !== newlySelectedPeriodIndex) {
                                            historyStore.selectedPeriodIndex = selected = newlySelectedPeriodIndex;

                                            if (selected === SessionHistoryStore.CUSTOM_PERIOD_INDEX) {
                                                historyStore.setCustomPeriod(true);
                                            } else if (selected === SessionHistoryStore.THIS_MONTH_INDEX) {
                                                historyStore.setCustomPeriodStart(getStartOfThisMonth());
                                                historyStore.setCustomPeriodEnd(getToday());
                                                historyStore.setCustomPeriod(false);
                                                historyStore.load();
                                                statsStore.load();
                                            } else if (selected === SessionHistoryStore.LAST_MONTH_INDEX) {
                                                historyStore.setCustomPeriodStart(getStartOfLastMont());
                                                historyStore.setCustomPeriodEnd(getEndOfLastMont());
                                                historyStore.setCustomPeriod(false);
                                                historyStore.load();
                                                statsStore.load();
                                            } else {
                                                historyStore.setCustomPeriodStart(undefined);
                                                historyStore.setCustomPeriodEnd(undefined);
                                                historyStore.setCustomPeriod(false);
                                                historyStore.load();
                                                statsStore.load();
                                            }
                                        }
                                    }} />
                        </CompositeInput>
                        {isCustomPeriod &&
                            <FormStoreProvider store={store} style={{width: '100%'}}>
                                    <View style={styles.fieldsContainer}>
                                        <View style={styles.fieldWrapper}>
                                            <Field
                                                style={{width: '100%'}}
                                                type={'Date'}
                                                placeholder={__('sessions-history.from-date')}
                                                name="from_date"
                                                value={''}
                                                format={LocaleStore.sharedInstance().displayDateFormat}
                                                maxDate={customPeriodEnd ? new Date(moment(customPeriodEnd, LocaleStore.sharedInstance().displayDateFormat).toString()) : new Date(moment().format(DEFAULT_DATE_FORMAT))}
                                                confirmBtnText={__('message.confirm')}
                                                cancelBtnText={__('message.cancel')}
                                                iconComponent={<StandaloneIcon
                                                    source={theme.SessionHistory.calendarIcon}
                                                    style={styles.padRight} />}
                                                onChanged={(value) => {
                                                    historyStore.setCustomPeriodStart(value)
                                                }}
                                            />
                                        </View>
                                        <View style={styles.fieldWrapper}>
                                            <Field
                                                style={{width: '100%'}}
                                                type={'Date'}
                                                placeholder={__('sessions-history.to-date')}
                                                value={''}
                                                name="to_date"
                                                format={LocaleStore.sharedInstance().displayDateFormat}
                                                confirmBtnText={__('message.confirm')}
                                                cancelBtnText={__('message.cancel')}
                                                minDate={customPeriodStart ? new Date(moment(customPeriodStart, LocaleStore.sharedInstance().displayDateFormat).toString()) : undefined}
                                                maxDate={new Date(moment().format(DEFAULT_DATE_FORMAT))}
                                                iconComponent={<StandaloneIcon
                                                    source={theme.SessionHistory.calendarIcon}
                                                    style={styles.padRight} />}
                                                onChanged={(value) => {
                                                    historyStore.setCustomPeriodEnd(value)
                                                }}
                                            />
                                        </View>
                                </View>
                                <Action disableIfInvalid type={Button} name="postperiod"
                                        title={__('sessions-history.set-period')}
                                        validateOnPress
                                        style={{marginTop: 20}}
                                />
                            </FormStoreProvider>}
                        {(stat &&
                            <View style={styles.space}>
                                <View>
                                    <View style={[styles.row, styles.flexEnd]}>
                                        <Text style={theme.SessionHistory.statValueStyle}>{stat.all_kwh}</Text>
                                    </View>
                                    {(isPersonalChargingEnabled && <View>
                                        <View style={[styles.row, styles.spaceBetween, styles.smallSpace]}>
                                            <View style={[styles.row, styles.baseline]}><StandaloneIcon style={styles.statLabelIcon} source={theme.SessionHistory.noteIcons.private} /><Text>{__('sessions-history.home-charging')}:</Text></View>
                                            <Text>{stat.personal_kwh}</Text>
                                        </View>
                                        <View style={[styles.row, styles.spaceBetween, styles.smallSpace]}>
                                            <View style={[styles.row, styles.baseline]}><StandaloneIcon style={styles.statLabelIcon} source={theme.SessionHistory.noteIcons.public} /><Text>{__('sessions-history.public-charging')}:</Text></View>
                                            <Text>{stat.public_kwh}</Text>
                                        </View>
                                    </View>)}
                                </View>
                                {(isPersonalChargingEnabled && <View style={[styles.tabNavContainer, {borderBottomColor: theme.Divider.style.backgroundColor}]}>
                                    <TouchableOpacity onPress={() => {historyStore.setFilter(null)}}>
                                        <View style={[styles.tabNavItem, (selectedFilter === null) ? styles.tabNavItemActive : null]}>
                                            <Text>{__('sessions-history.all-sessions')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity  onPress={() => {historyStore.setFilter('private')}}>
                                        <View style={[styles.tabNavItem, selectedFilter === 'private' ? styles.tabNavItemActive : null]}>
                                            <StandaloneIcon style={styles.tabNavIcon} source={theme.SessionHistory.noteIcons.private} />
                                            <Text>{__('sessions-history.home-sessions')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {historyStore.setFilter('public')}}>
                                        <View  style={[styles.tabNavItem, selectedFilter === 'public' ? styles.tabNavItemActive : null]}>
                                            <StandaloneIcon style={styles.tabNavIcon} source={theme.SessionHistory.noteIcons.public} />
                                            <Text>{__('sessions-history.public-sessions')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>) || (
                                    <View>
                                        <Text style={theme.SessionHistory.historyTitleStyle}>{__('sessions-history.history-title')}</Text>
                                        <Divider />
                                    </View>
                                ) }
                            </View>
                        ) || <ActivityIndicator style={{marginVertical: 34}} />
                        }
                    </>
                    }
                    ListEmptyComponent={<>
                        {((historyRecords && historyRecords.length === 0)
                                || (historyRecords && historyRecords.length !== 0 && historyRecords.map(entry => entry.type !== selectedFilter)))
                            && <Text style={{marginVertical: 30}}>{__('sessions-history.no-data')}</Text>
                            || <ActivityIndicator style={{marginVertical: 50}} />
                        }
                    </>}
                />}
            }</ThemeConsumer>
        );
    }
}

function getStartOfLastMont() {
    return moment(new Date()).subtract(1, 'months').startOf('month').format(LocaleStore.sharedInstance().displayDateFormat);
}

function getEndOfLastMont() {
    return moment(new Date()).subtract(1, 'months').endOf('month').format(LocaleStore.sharedInstance().displayDateFormat);
}

function getStartOfThisMonth() {
    return moment(new Date()).startOf('month').format(LocaleStore.sharedInstance().displayDateFormat);
}

function getToday() {
    return moment(new Date()).format(LocaleStore.sharedInstance().displayDateFormat);
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    baseline: {
        alignItems: Platform.OS === 'android' ? 'center' : 'baseline',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    flexEnd: {
        justifyContent: 'flex-end',
    },
    space: {
        marginVertical: 20,
    },
    smallSpace: {
        marginVertical: 10,
    },
    padRight: {
        marginRight: 5,
    },
    statLabelIcon: {
        height: 15,
        marginRight: 10,
    },
    tabNavContainer: {
        flexDirection: 'row',
        borderBottomWidth: StyleSheet.hairlineWidth,
        alignItems: 'flex-end',
        marginTop: 40,
    },
    tabNavItem: {
        flexDirection: 'row',
        opacity: 0.4,
        paddingRight: 10,
        marginRight: 10,
        paddingBottom: 15,
        alignItems: Platform.OS === 'android' ? 'center' : 'baseline',
        borderBottomWidth: 2,
    },
    tabNavItemActive: {
        opacity: 1,
        borderBottomColor: '#fff',
    },
    tabNavIcon: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
    container: {
        color: '#000',
        overflow: "visible"
    },
    fieldsContainer: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        zIndex: 2,
    },
    fieldWrapper: {
        flex: 1,
        marginTop: 20,
        marginBottom: 0,
        zIndex: 3,
        backgroundColor: '#fff',
        width: '49%',
        flexBasis: 'auto',
        flexGrow: 0,
    },
    failureIcon: {
        width: 20,
        height: 20
    }
});
