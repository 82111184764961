import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {miscImages} from '../../res/images';
import {Text} from 'react-native-elements';
import fonts, {FontWeight} from '../../res/fonts';
import colors from '../../res/colors';
import {LocationObject} from '@ampeco/charge-models';
import {MapNavigateService} from '@ampeco/charge-services';
import {RTLImage} from "../../../frontend_modules/rtl-image";

const Wrapper = ({children, location, locate}: { children: React.ReactChild, location: LocationObject, locate: boolean }): JSX.Element => {
    if (locate) {
        return <TouchableOpacity
            style={styles.location}
            onPress={() => {
                MapNavigateService.onNavigationPress(location)
            }}
        >
            {children}
        </TouchableOpacity>
    }

    return <View style={styles.location}>{children}</View>;
}

export default function InfoBoxAddressComponent({location, showDetails, locate = false}: { location: LocationObject, showDetails: boolean, locate?: boolean }): JSX.Element {
    const renderContents = () => {
        return (<View style={styles.infoBoxContainer}>
            <View style={styles.locationIcon}>
                <RTLImage alt="" source={miscImages.buttonLocation} style={{width: 16, height: 16}} resizeMode={'contain'}/>
            </View>
            <View style={{flex: 1}}>
                <Text
                    style={[styles.locationTitle]}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}
                >{location?.name}</Text>
                {showDetails && <Text
                    style={[styles.locationSubtitle]}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                >{location?.description}</Text>}
            </View>
        </View>);
    }

    return (<div style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <Wrapper locate={locate} location={location}>
            { renderContents() }
        </Wrapper>
    </div>);
}

const styles = StyleSheet.create({
    infoBoxContainer: {
        maxWidth: 'calc(100% - 20px)',
        display: 'flex',
        flexDirection: 'row'
    },
    location: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    locationTitle: {
        fontSize: 18,
        fontFamily: fonts.regular,
        color: colors.textEmphasisColor,
        textAlign: 'left',
    },
    locationSubtitle: {
        fontSize: 12,
        fontWeight: FontWeight.light,
        color: colors.textEmphasisColor,
        textAlign: 'left',
    },
    locationIcon: {
        height: 30,
        width: 30,
        marginRight: 10,
        borderRadius: 15,
        backgroundColor: colors.buttonPrimaryColor,
        justifyContent: 'center',
        alignItems: 'center',
    }
});
