import React from 'react';
import {Image, StyleSheet} from 'react-native';
import {withTheme} from 'react-native-elements';

export default withTheme((props: any) => {
    let copyOfProps = {...props};
    const {style} = props;
    if (copyOfProps && style)
        delete copyOfProps.style;
    return <Image resizeMode="contain"
        {...copyOfProps} style={StyleSheet.flatten([{tintColor: props.theme.Text.style.color}, style])} />;
});
