import {Connector} from './Location';
import {PendingChargingSession} from './PendingChargingSession';
import {Session, SessionScheduleType} from './Session';
import {PersonalTariff} from './Tariff';

export interface ExistingPersonaCharger {
    id: number;
    name: string;
}

export interface PCEVSE {
    id: string;
    maxPower: number | null;
    identifier: string;
    currentType: 'ac' | 'dc';
    status: 'ready' | 'preparing' | 'charging' | 'suspended' | 'finishing' | 'fault' | 'reserved' | 'unavailable' | 'no_connection';
    connectors: Connector[];
    pendingSession: PendingChargingSession | null;
    session: Session | null;
    tariff?: PersonalTariff;
}

export interface EVReference {
    consumption_kwh_per_100km: string;
    id: string;
    name: string;
}
export interface BleProvisioning {
    prefix: string;
    pop: string;
    provisioning_timestamp?: string | null;
}

export interface BleInstaller {
    main_fuse_limit: number;
    use_ctclamp?: boolean;
    ctclamp_value?: {
        value: string;
        timestamp?: string;
    }
    not_configured?: boolean;
}

export interface ChargingProfile {
    departure_time: string;
    ev_reference: EVReference;
    ev_reference_id: string;
    id: string;
    measure_unit: string;
    target_charge: number;
    measure_unit_options: {key: string; label: string};
}

export interface BlePersonalCharger extends PersonalCharger {
    ble_installer?: BleInstaller;
}

export interface PersonalCharger {
    id: string;
    max_current_a: number;
    allowed_min_current_a: number;
    status: 'available' | 'fault' | 'long-term unavailable' | 'no_connection' | 'unavailable' | 'undefined';
    is_rebooting: boolean;
    is_connector_lock_supported: boolean;
    user_manual_url: string;
    ble_provisioning_type: string;
    ble_provisioning_settings: BleProvisioning;
    allowed_smart_charging_modes: {
        mode: SmartChargingMode;
        defaults: Partial<SmartChargingAgileOctopus | SmartChargingSchedule | SmartChargingOctopusGo | SmartChargingEnergiElspotPrices | SmartChargingNordPoolDayAheadPrices>;
    }[];
    has_electricity_tax_reimbursement: boolean;

    evses: PCEVSE[];
    name: string;
    location: {
        latitude: number;
        longitude: number;
    };
    location_id: number | null;
    postcode: string;
    timezone?: string;
    smart_charging: SmartChargingAgileOctopus | SmartChargingSchedule | SmartChargingOctopusGo | SmartChargingEnergiElspotPrices | SmartChargingNordPoolDayAheadPrices | SmartChargingSolar;
    requires_authorization: boolean;
    enable_keep_awake: boolean;
    connector_lock: boolean;
    min_charging_current: number;
    light_intensity: number;
    track_electricity_cost:
        | {
              start_time: string;
              end_time: string;
              price: number;
          }[]
        | null;
    isElectricityCostTrackingAvailable: boolean;
    is_authorizing: boolean;
    new_pin?: number;
    old_pin?: number;
    photo?: string;
    plug_and_charge: boolean;
    plug_and_charge_options: {
        mode: 'disabled' | 'basic' | 'smart';
        off_peak_start_time?: string;
        off_peak_end_time?: string;
        with_target_charge?: boolean;
        target_charge_kwh?: number;
        departure_time: string;
    };
    smart_charging_enabled: boolean;
    allowed_solar_min_power_kw: number;
    allowed_max_power_kw: number;
    scheduling_intervals: SchedulingIntervals;
    profile: ChargingProfile;
    last_month_energy_kwh: number;
    last_month_electricity_cost: number;
    electricity_cost_currency_id: string;
    has_third_party_electricity_cost_integration_enabled: boolean;
    subscription?: {
        subscriptionRequired?: boolean;
        subscriptionPlanIds?: number[];
    }
    randomised_delay_enabled: boolean;
    security_events_logs?: SecurityEventsLog[];
    firmware_version: string | null;
    isFirmwareUpdatesSupported: boolean;
    latestFirmwareUpdateExecution?: FirmwareUpdateExecution;
    isFirmwareUpdating: boolean;
    isTamperDetectionNotifications: boolean;
}

export enum FirmwareUpdateExecutionStatuses {
    SCHEDULED = 'scheduled',
    PENDING = 'pending',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
}

export interface FirmwareUpdateExecution {
    chargePointId: string;
    status: FirmwareUpdateExecutionStatuses;
    startedAt: Date | null;
    finishedAt: Date | null;
}

export interface SchedulingIntervals {
    off_peak_is_set: boolean;
    off_peak_start_time: string;
    off_peak_end_time: string;
    solar_is_set: boolean;
    solar_end_time: string;
    solar_start_time: string;
    solar_max_power: string;
}

export type SmartChargingMode = 'agile-octopus' | 'schedule' | 'octopus-go' | 'energi-elspot-prices' | 'nord-pool-dayahead-prices' | 'optimised';

export interface SmartChargingSchedule extends SmartCharging {
    enabled: boolean;
    mode: SmartChargingMode;
    start_time: string;
    end_time: string;
    target_charge: {
        min_kwh: number;
        max_kwh: number | null;
    } | null;
    type: SessionScheduleType;
}

export interface SmartChargingAgileOctopus extends SmartCharging {
    enabled: boolean;
    mode: SmartChargingMode;
    max_price: number;
    target_charge: {
        departure_time: string;
        min_kwh: number;
        max_kwh: number | null;
    } | null;
}

export interface SmartChargingOctopusGo extends SmartCharging {
    enabled: boolean;
    mode: SmartChargingMode;
    target_charge: {
        min_kwh: number;
        max_kwh: number | null;
    } | null;
    off_peak_start_time: string;
    off_peak_end_time: string;
}

export interface SmartChargingEnergiElspotPrices extends SmartCharging {
    enabled: boolean;
    mode: SmartChargingMode;
    target_charge: {
        departure_time: string;
        min_kwh: number;
        max_kwh: number | null;
    },
    location: string,
    locations: {key: string; label: string}[] | null;
}

export interface SmartChargingNordPoolDayAheadPrices extends SmartCharging {
    enabled: boolean;
    mode: SmartChargingMode;
    target_charge: {
        departure_time: string;
        min_kwh: number;
        max_kwh: number | null;
    },
    location: string,
    locations: {key: string; label: string}[] | null;
}

export interface SmartCharging {
    use_solar: boolean;
    max_current_from_grid_a: number;
    solar_stable_time_sec: number;
}

export interface SmartChargingSolar extends SmartCharging {
    enabled: boolean;
    mode: SmartChargingMode;
}

export type SecurityEventsLogType = 'TamperDetectionActivated';

export interface SecurityEventsLog {
    type: SecurityEventsLogType;
    info: string;
    timestamp: Date;
}

export const UNLOCK_NOT_SUPPORTED_STATUS = 'NotSupported';

export type UnlockConnectorChangesPayload = {
    chargePointId: string;
    unlocked: boolean;
    failReason: string | null;
}

export type ElectricityPriceRangeSchema = {
    min: number,
    max: number,
    defaultThreshold: number,
}

export type PersonalChargingOnboardingStepAttribute = {
    [index: string]: string;
}

export type OnboardingSteps = {
    mainText: PersonalChargingOnboardingStepAttribute | null,
    step1Title: PersonalChargingOnboardingStepAttribute | null,
    step1Description: PersonalChargingOnboardingStepAttribute | null,
    step2Title: PersonalChargingOnboardingStepAttribute | null,
    step2Description: PersonalChargingOnboardingStepAttribute | null,
    step3Title: PersonalChargingOnboardingStepAttribute | null,
    step3Description: PersonalChargingOnboardingStepAttribute | null,
    isStep3Enabled: boolean,
}

export type PersonalChargingSettingsResponse = {
    isPersonalChargingEnabled: boolean,
    isPersonalChargingDefault: boolean,
    timePeriodGranularity: number,
    electricityPriceRange: ElectricityPriceRangeSchema,
    onboardingSteps: OnboardingSteps,
}
