import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import settings from '../../settings';
import MapClusterComponent from "../../components/Map/MapClusterComponent";
import { observer } from "mobx-react";
import MapPopupComponent from "../../components/Map/MapPopupComponent";
import { Dimensions, StyleSheet, View } from 'react-native';
import { LocationStore, PinsStore } from "@ampeco/charge-stores";
import GlobalStore from "../../stores/GlobalStore";
import { debounce } from "throttle-debounce";
import UserLocationStore from "../../stores/UserLocationStore";
import { splitGeoToLatLng } from "../../utils/Map";
import PlacesSearch from "../../components/Map/PlacesSearch";
import colors from '../../res/colors';
import { Range } from '@ampeco/charge-stores/PinsStore';

const BOUNDS_CHANGED_DELAY = 300;

const Map = observer((): JSX.Element => {
    const defaultZoom = 8;
    const windowHeight = Dimensions.get('window').height;
    const headerHeight = 44;
    const defaultCoordinates = {
        lat: settings.maps.defaults.coordinates.latitude,
        lng: settings.maps.defaults.coordinates.longitude
    };
    const globalStore = GlobalStore.sharedInstance();
    const locationStore = LocationStore.sharedInstance();
    const userLocationStore = UserLocationStore.sharedInstance();
    const pinsStore = PinsStore.sharedInstance();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: settings.maps.apiKey,
        language: localStorage.getItem('user-language') ?? settings.locale.default ?? 'en',
        libraries: ['places']
    });

    const [map, setMap] = useState<google.maps.Map>(null);

    const [center, setCenter] = useState({
        lat: parseFloat(defaultCoordinates.lat),
        lng: parseFloat(defaultCoordinates.lng),
    });
    const customMapStyle = [
        {
            // hide points of interest from map
            featureType: 'poi.business',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
    ];
    const mapOptions = globalStore.isPoiEnabled ? undefined : {styles: customMapStyle};

    const [pins, setPins] = useState([]);

    const [zoom, setZoom] = useState(defaultZoom)
    const [height, setHeight] = useState(() => windowHeight - headerHeight)
    const [opacity, setOpacity] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const determineZoom = () => {
        const delta = globalStore.mapCoordinates.longitudeDelta;

        if (!delta) {
            return defaultZoom;
        }

        return Math.round(Math.log(Dimensions.get('window').width / delta) / Math.LN2);
    }

    const calculateRegion = (bounds: google.maps.LatLngBounds): Range => {
        const center = bounds.getCenter();

        const ne_lat = bounds.getNorthEast().lat();
        const ne_lng = bounds.getNorthEast().lng();
        const sw_lat = bounds.getSouthWest().lat();
        const sw_lng = bounds.getSouthWest().lng();

        const latitude = center.lat();
        const longitude = center.lng();
        const latitudeDelta = Math.abs(ne_lat - sw_lat);
        const longitudeDelta = Math.abs(ne_lng - sw_lng);

        return  {
            latitude,
            longitude,
            latitudeDelta,
            longitudeDelta,
        };
    }

    useEffect(() => {
        setPins(pinsStore.pins);
    }, [pinsStore.pins]);

    useEffect(() => {
        if (!globalStore.booting && globalStore.mapCoordinates.latitude && globalStore.mapCoordinates.longitude) {
            setCenter({
                lat: globalStore.mapCoordinates.latitude,
                lng: globalStore.mapCoordinates.longitude,
            });
        }
    }, [globalStore.booting]);

    useEffect(() => {
        if (isLoaded && !globalStore.booting) {
            (async () => {
                try {
                    setShowLoader(true);
                    const position = await userLocationStore.getCurrentPosition();
                    setCenter({
                        lat: position.latitude,
                        lng: position.longitude
                    });

                    // Try to fit within the map viewport nearest pin and user's location.
                    const nearestObject = userLocationStore.getNearestPinCoordinates(pins);
                    if (nearestObject) {
                        const split = splitGeoToLatLng(nearestObject.geo)

                        const bounds = new google.maps.LatLngBounds();
                        bounds.extend(new google.maps.LatLng(position.latitude, position.longitude));
                        bounds.extend(new google.maps.LatLng(split.latitude, split.longitude));

                        map.fitBounds(bounds);
                    }
                } catch (error) {
                    console.log(`Cannot access User's Geolocation: ${error}`);

                    setCenter({
                        lat: globalStore.mapCoordinates.latitude,
                        lng: globalStore.mapCoordinates.longitude,
                    });

                    setZoom(determineZoom())
                } finally {
                    setShowLoader(false);
                    setOpacity(1);
                }
            })();
        }
    }, [isLoaded, globalStore.booting]);

    const debounced = debounce(500, (map) => {
        const newHeight = (Dimensions.get('window').height - headerHeight);
        setHeight((previousHeight) => {
            if (previousHeight !== newHeight) {
                return newHeight;
            }

            return previousHeight;
        });

        const center = map.getCenter();
        const bounds = map.getBounds();
        const zoom = map.getZoom();

        google.maps.event.trigger(map, "resize");
        map.setCenter(center);
        map.fitBounds(bounds);
        map.setZoom(zoom);
    });

    function handleBoundsChanged() {
        if (map) {
            const bounds = map.getBounds();
            pinsStore.boundaries = {
                southWest: {
                    latitude: bounds.getSouthWest().lat(),
                    longitude: bounds.getSouthWest().lng()
                },
                northEast: {
                    latitude: bounds.getNorthEast().lat(),
                    longitude: bounds.getNorthEast().lng()
                },
            };
            const region = calculateRegion(bounds);
            pinsStore.updateRegion(region);

            if(pinsStore.dynamicClustering) {
                pinsStore.loadFromBackend()
            } else {
                pinsStore.triggerClustering();
            }
            //  this.handleZoomToCoordinates(); <- we do not need as it seems to target only mobile sc-106586
        }
    }

    const renderMap = () => {
        return (
            <GoogleMap
                options={mapOptions} // Apply the custom map style
                mapContainerClassName="mapContainer"
                mapContainerStyle={{ width: '100%', height, opacity }}
                center={center}
                zoom={zoom}
                onBoundsChanged={debounce(BOUNDS_CHANGED_DELAY, handleBoundsChanged)}
                onClick={() => {
                    if (!globalStore.isMenuOpen)
                        locationStore.openedPinLocation = false;

                }}
                onLoad={(map) => {
                    if (center.lat && center.lng) {
                        map.setCenter(center);
                    }

                    window.addEventListener('resize', () => {
                        debounced(map);
                    });

                    setMap(map);
                }}
            >
                {globalStore.isLocationSearchBoxEnabled ? <PlacesSearch map={map} /> : null}
                <MapClusterComponent pins={pins} />
                <MapPopupComponent />
            </GoogleMap>
        );
    };

    if (loadError) {
        return <View style={styles.loading}>
            <View style={styles.loadingText}>
                <h1>Map cannot be loaded right now, sorry.</h1>
            </View>
        </View>;
    }

    if (showLoader) {
        return <View style={styles.loading}>
            <View style={styles.loadingText}>
                <h1>Loading map...</h1>
            </View>
        </View>;
    }

    return isLoaded ? renderMap() : null;
});

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: colors.headerBackgroundColor,
        color: colors.textLabelColor,
    },
    loadingText: {
        marginTop: '-44'
    }
});

export default Map;
