import {StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native';
import React from 'react';

type CardWidgetBoxProps = {
    title?: React.ReactNode;
    body: React.ReactNode;
    containerStyle?: ViewStyle;
    rowStyle?: ViewStyle;
    onPress?: () => void;
}

export function CardWidgetBox({title, body, containerStyle, rowStyle, onPress}: CardWidgetBoxProps) {
    const ContainerComponent = onPress ? TouchableOpacity : View as React.ElementType;

    return (
        <ContainerComponent onPress={onPress} style={[styles.boxContainer, containerStyle]}>
            {title ? <View style={[styles.boxRow, rowStyle]}>
                {title}
            </View> : null}
            <View>
                {body}
            </View>
        </ContainerComponent>
    );
}

const styles = StyleSheet.create({
    boxContainer: {
        flex: 1,
        padding: 15,
    },
    boxRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
});
