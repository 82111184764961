import net from '@ampeco/net';
import {PaymentTransaction} from '@ampeco/models';

export function getTransaction(paymentTransactionId: string): Promise<PaymentTransaction> {
    return net.get(`/app/payment-transactions/${paymentTransactionId}`);
}

export function cancelPendingScaTransaction(paymentTransactionId: string): Promise<null> {
    return net.patch(`/app/payment-transactions/${paymentTransactionId}/cancel-sca`, []);
}
