import color from 'color';
import skin from './skin';

const theme = window.initialConfig.app?.theme;
const skinColors = skin().colors;

const accentColor = theme?.accentColor || '#038BC5';
const backgroundColor = theme?.backgroundColor || '#000';
const menuBarsColor = theme?.menuBarsColor || 'white';
const headerBackgroundColor = theme?.headerBackgroundColor || theme?.backgroundColor || 'black';
const connectorsTintColor = theme?.connectorTintColor || 'white';
const chargingProgressGenericBorder = theme?.chargingProgressGenericBorderColor || 'white';
const chargingProgressGenericSegment = theme?.chargingProgressGenericSegmentColor || 'rgb(0, 159, 18)';
const dividerColor = theme?.dividerColor || '#535353';
const locationInfoBoxColor = theme?.locationInfoBoxColor || theme?.backgroundColor || '#000';
const buttonPrimaryDisabledColor = accentColor ? color(accentColor).desaturate(0.7).hex() : '#82ACBE';
const pinTextColor = theme?.pinTextColor || 'white';
const noticeTextColor = theme?.noticeTextColor || 'black';
const noticeBackgroundColor = theme?.noticeBackgroundColor || 'white';
const mapNoticeTextColor = theme?.mapNoticeTextColor || 'white';
const mapNoticeBackgroundColor = theme?.mapNoticeBackgroundColor || 'black';

const colors = {
    backgroundColor,
    statusBarStyle: 'light-content', // used for the status bar colors
    statusBarStyleInverted: 'dark-content', // inverted statusBar, only on iOS
    menuBackgroundColor: backgroundColor,
    dividerColor, // Horizontal divider color

    headerBackgroundColor,
    tabBarUnselectedColor: '#CCCCCC',
    tabBarSelectedColor: 'white',

    connectorsTintColor,
    menuBarsColor,
    locationInfoBoxColor,
    pinTextColor,
    buttonPrimaryColor: accentColor, // blue buttons
    buttonActiveColor: '#00A700', // green indicating its on
    buttonInactiveColor: '#CCCCCC', // gray indicating its off
    buttonPrimaryDisabledColor,
    buttonSecondaryColor: '#999999',
    buttonDestructiveColor: '#CA512B',
    buttonTextColor: 'white',
    buttonActiveTextColor: 'white',
    buttonInactiveTextColor: '#000000',

    textPlaceholderColor: '#CCCCCC',
    textEmphasisColor: 'white',
    textColor: '#CCCCCC',
    textLabelColor: '#999999', // Used for drop-down labels and for menu labels
    textErrorColor: '#f11',

    errorBackgroundColor: '#CA512B',
    errorTextColor: 'white',

    chargingProgressOnBar: 'rgb(0, 159, 18)', // '#009F12',
    chargingProgressOffBar: 'rgb(204, 204, 204)', // '#CCCCCC',
    chargingProgressText: 'black',

    chargingProgressGenericOffBar: 'rgb(51,51,51)',
    chargingProgressGenericOnBar: chargingProgressGenericSegment, // #038BC5
    chargingProgressGenericBorder,

    activeStep: accentColor,
    inactiveStep: '#707070',

    topUpPackageBackgroundColor: '#3E3E3E',
    switchTrackColor: '#333333',
    switchInactiveColor: '#777777',

    completedColor: '#00A500', //  green color used for charging and statuses
    inUse: '#CA512B',
    available: '#009F12',

    agileStreetsEcoColor: '#00A700',
    agileStreetsBoostColor: '#038BC5',
    agileStreetsBoostDisabledColor: color('#038BC5').desaturate(0.7).lightness(60).rgb().string(),
    agileStreetsEcoDisabledColor: '#045213',
    agileStreetsEcoDisabledTextColor: '#808080',

    noticeTextColor,
    noticeBackgroundColor,
    mapNoticeTextColor,
    mapNoticeBackgroundColor,

    ...skinColors,
};
export default colors;
