import React, {Component} from 'react';
import { Location } from '@ampeco/charge-models';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import { MapNavigateService } from '@ampeco/charge-services';
import {ThemeConsumer, Text} from 'react-native-elements';
import {ChargeTheme} from '@ampeco/theme';
import {Distance} from './Distance';
import { RTLImage } from '@ampeco/rtl-image';

export class Address extends Component<{ location: Location, showDistance?: boolean, dontDoDirections?: boolean, hideNavigation?: boolean }> {
    onNavigationPress() {
        MapNavigateService.onNavigationPress(this.props.location);
    }

    render() {
        if (this.props.dontDoDirections || this.props.hideNavigation) {
            return this.renderContent();
        } else {
            return <TouchableOpacity onPress={() => this.onNavigationPress()}>
                {this.renderContent()}
            </TouchableOpacity>;
        }
    }

    renderContent() {
        return <ThemeConsumer>{({theme}) => {
            return <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <View style={[styles.location]}>
                    {!this.props.hideNavigation && <View style={[theme.Address.locationIconContainerStyle, styles.locationIcon]}>
                        <RTLImage source={theme.Address.locationIconSource} style={[theme.Address.locationIconStyle, styles.locationIconImage]}/>
                    </View>}
                    <View style={{flex: 1}}>
                        <Text style={[theme.Address.locationTitleStyle]} numberOfLines={1}
                              ellipsizeMode={'tail'}>{this.props.location.name}</Text>
                        <Text style={theme.Address.locationSubtitleStyle} ellipsizeMode={'tail'}
                              numberOfLines={1}>{this.props.location.description}</Text>
                    </View>
                </View>
                {this.props.showDistance && <Distance location={this.props.location}/>}
            </View>;
        }}</ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    location: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    locationIcon: {
        marginRight: 10,
        height: 30,
        width: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    locationIconImage: {
        marginRight: 2,
        marginTop: 2,
        height: 20,
        width:20,
    },
});
