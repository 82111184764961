import React, {ReactElement} from 'react';
import {SessionStartStore} from '@ampeco/charge-stores';
import {Button, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {ActivityIndicator} from 'react-native';
import colors from '../../res/colors';
import {ButtonIcon} from '@ampeco/icons';
import {ActiveTermStore} from "@ampeco/terms";

const StartSessionButton: React.FC<{
    icon: any;
    onPress: () => void,
    buttonColor?: any;
    disabledButtonColor?: any;
    disabledTitleStyle?: any;
    containerStyle?: any;
    buttonTitle?: string;
    optimised?: boolean;
    boost?: boolean;

}> = ({icon, onPress, buttonColor, disabledButtonColor, disabledTitleStyle, containerStyle, buttonTitle, optimised, boost}): ReactElement|null => {
    const sessionStore = SessionStartStore.sharedInstance();
    const activeTermStore = ActiveTermStore.sharedInstance();

    const disabledBtn = Boolean(sessionStore.loading || sessionStore.waitingForCar) || sessionStore.startDisabled || activeTermStore.activeTerm !== null;
    const title = (!boost && !optimised)  ? buttonTitle || __('session.start.button')
        : sessionStore.loading
        ? __('session.start.button-loading')
        : (sessionStore.waitingForCar
                ? __('session.start.button-waiting')
                : buttonTitle || __('session.start.button')
        );
    const isLoading = (boost || optimised) && (sessionStore.loading || sessionStore.waitingForCar);

    return <ThemeConsumer>{({theme}) => {
        return (
            <Button
                disabled={disabledBtn}
                title={title}
                icon={isLoading
                    ? <ActivityIndicator color={colors.buttonTextColor}/>
                    : <ButtonIcon source={icon} style={{width: 21, height: 21}} />
                }
                onPress={() => onPress()}
                disabledStyle={disabledBtn ? {backgroundColor: disabledButtonColor ? disabledButtonColor : theme.Button.disabledStyle?.backgroundColor} : undefined}
                buttonStyle={buttonColor ? {backgroundColor: buttonColor} : undefined}
                disabledTitleStyle={disabledBtn ? {color: disabledTitleStyle ? disabledTitleStyle : theme.Button.disabledTitleStyle.color} : undefined}
                containerStyle={containerStyle ?? undefined}
            />
        )
    }}</ThemeConsumer>;
}

export default StartSessionButton;
