import React, {useEffect, useState} from "react";
import {EVSE} from "@ampeco/charge-models";
import {Image, StyleSheet} from "react-native";
const QRCode = require('qrcode.react');
import {formatEnergy, formatPower} from "../../utils/Energy";
import {format} from "@ampeco/currency";
import {displayImages} from "../../res/images";

interface Props {
    evse: EVSE
}

const EvseStatus = ({evse}: Props): JSX.Element => {
    const [currentDuration, setCurrentDuration] = useState(0);

    useEffect(() => {
        if (evse.status === 'charging') {
            const interval = setInterval(() => {
                setCurrentDuration(currentDuration => currentDuration + 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [evse.status]);

    useEffect(() => {
        setCurrentDuration(evse?.activeSession?.totalDuration);
    }, [evse?.activeSession?.totalDuration])

    const statusLabels = {
        'available': {
            bg: 'В готовност!',
            en: 'Ready!',
        },
        'preparing': {
            bg: 'Подготовка...',
            en: 'Preparing...',
        },
        'finishing': {
            bg: 'Приключване...',
            en: 'Finishing...'
        },
        'faulted': {
            bg: 'Повреда',
            en: 'Fault'
        },
        'unavailable': {
            bg: 'Повреда',
            en: 'Fault'
        },
        'reserved': {
            bg: 'Резервирано',
            en: 'Reserved'
        },
        'suspendedEV': {
            bg: 'Паузирано',
            en: 'Suspended'
        },
        'suspendedEVSE': {
            bg: 'Паузирано',
            en: 'Suspended'
        },
        'out of order': {
            bg: 'Не работи',
            en: 'Out of order!',
        }
    };

    const getObjValueByKey = (obj: any, key: string): any => {
        const idx = Object.keys(obj).findIndex(i => i === key);

        return Object.values(obj)[idx];
    };

    const duration = (seconds: number): string => {
        const date = new Date(0);
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
    }

    const classes = ['evseItem', 'col', evse.status];

    if (evse.status === 'charging') {
        const activeSession = evse?.activeSession;

        return (<div className={classes.join(' ')}>
            <div className="row first">
                <div className="charging-metric energy">
                    <span className="label"><strong>Енергия</strong> | Energy</span>
                    <span className="value">{activeSession?.energy ? formatEnergy(activeSession.energy, 1) : '-'}</span>
                </div>
            </div>
            <div className="row second with-cols">
                <div className="charging-metric power">
                    <span className="label"><strong>Мощност</strong> | Power</span>
                    <span className="value">{activeSession?.power ?formatPower(activeSession.power, 1) : '-'}</span>
                </div>
                <div className="charging-metric duration">
                    <span className="label"><strong>Време</strong> | Duration</span>
                    <span className="value">{duration(currentDuration || activeSession?.totalDuration || 0)}</span>
                </div>
            </div>
            <div className="row third">
                <div className="charging-metric amount">
                    { activeSession?.currency
                        ? (<>
                            <span className="label"><strong>Сума</strong> | Amount:</span>
                            <span className="value">{!activeSession.currency ? '-' : format(activeSession.totalAmount, activeSession.currency)}</span>
                        </>)
                        : null
                    }
                </div>
            </div>
        </div>);
    }

    // All other states besides `charging`
    return (<div className={classes.join(' ')}>
        <div className="statusImage">
            {['available', 'reserved', 'preparing'].includes(evse.status)
                ? <QRCode value={evse.qrUrl} size={200}/>
                : (evse.status in displayImages) &&
                <Image
                    source={displayImages[evse.status]} style={[styles.statusImage]}
                    resizeMode={'center'}
                />
            }
        </div>
        {
            Object.keys(statusLabels).includes(evse.status) &&
            <div className="statuses">
                <h2>{getObjValueByKey(statusLabels, evse.status)?.bg || ''}</h2>
                <h3>{getObjValueByKey(statusLabels, evse.status)?.en || ''}</h3>
            </div>
        }
    </div>);
}

const styles = StyleSheet.create({
    statusImage: {
        height: 200,
        width: 270
    }
});

export default EvseStatus;
