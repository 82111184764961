import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { AddPaymentMethod } from '@ampeco/payment-methods';
import { BasePage } from '../BasePage';
import NavigationService, { HomeModalParamList } from '@ampeco/navigation';
import { Redirect } from 'react-router';

function AddPaymentMethodScreen({ location }: { location: { state: HomeModalParamList['AddPaymentMethod'] } }): JSX.Element {
    const navigationService = NavigationService.sharedInstance();
    const navigation: any = {
        setOptions: () => {/**/ },
        addListener: () => {/**/ },
        removeListener: () => {/**/ },
        navigate: navigationService.navigate.bind(navigationService),
    };

    const params = location.state;
    if (!params || !params.url || !params.successUrl) {
        return <Redirect to={"/"} />
    }

    return <BasePage
        title={'addPaymentMethod.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('PaymentMethods')
        }}
    >
        <SafeAreaProvider>
            <AddPaymentMethod
                navigation={navigation}
                route={{
                    params,
                }}
            />
        </SafeAreaProvider>
    </BasePage>;
}

export default AddPaymentMethodScreen;
