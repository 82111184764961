import React from 'react';
import {observer} from 'mobx-react';
import {__} from '@ampeco/i18n';
import {Action, Error, Field, FormStore, FormStoreProvider} from '@ampeco/forms';
import {Button, Input, ThemeConsumer} from 'react-native-elements';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Signup} from '@ampeco/charge-api';
import Alert from '@ampeco/alert';
import Navigation from '@ampeco/navigation';

const store = new FormStore();

interface Props {
    email?: string;
}

const showDone = (title: string, description: string) => {
    Alert.sharedInstance().show(
      title,
      description,
        [
            {text: __('forgotten.done-alert.ok')},
        ],
        {cancelable: false},
    );
};



store.actions.forgot = async () => {
    await Signup.forgottenPassword(store.form.username as string)
      .then(() => {
          showDone(__('forgotten.done-alert.header'), __('forgotten.done-alert.text'));
      })
      .catch((e) => {
          let title = '';
          let description = '';
          if (e.response.status === 429) {
              description = e.response.data.message;
          } else {
              title = __('forgotten.done-alert.header');
              description = __('forgotten.done-alert.text');
          }
          showDone(title, description);
      })
      .finally(() => {
          Navigation.sharedInstance().goBack();
      });
};

store.rules.username = 'required|email|max:255';

@observer
export default class Forgotten extends React.Component<Props, any> {
    componentDidMount() {
        store.reset();
        if (this.props.email !== undefined) {
            store.form.username = this.props.email;
        }
    }

    render() {
        return <ThemeConsumer>{({theme}) => (
            <ScrollView>
                <View style={StyleSheet.flatten([theme.Container?.byMargin, {marginBottom: 60, marginTop: 50}])}>
                    <FormStoreProvider store={store}>
                        <Field
                            type={Input}
                            name="username"
                            placeholder={__('login.username')}
                            autoCapitalize="none"
                            textContentType="username"
                            keyboardType="email-address"
                            autoFocus={true}
                            style={{textAlign: 'left'}}
                        />

                        <Error/>

                        <Action
                            type={Button}
                            disableIfInvalid
                            validateOnPress
                            name="forgot"
                            title={__('forgotten.submit')}
                        />
                    </FormStoreProvider>
                </View>
            </ScrollView>
        )}</ThemeConsumer>
    }
}
