import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text, useTheme} from 'react-native-elements';
import {__, LocaleStore} from '@ampeco/i18n';
import {formatDistance, formatEnergy} from '@ampeco/utils';
import {UnitFormat} from '@ampeco/charge-models';

type CustomConfigurableSliderProps = {
    agilePrice: string;
    boostPrice: string;
    kwh: number;
    referenceRange: number;
    label?: string;
}

export default (props: CustomConfigurableSliderProps) => {
    const {theme} = useTheme();
    const unit = LocaleStore.sharedInstance().displayUnit;
    let distance = props.kwh * props.referenceRange;
    if (unit === 'imperial') {
        distance =  props.kwh * props.referenceRange * 0.62137;
    }

    return (
      <View style={[theme.OptimisedOffPeak?.Slider.marker, styles.marker, {justifyContent: 'center'}]}>
          <View style={{
              position: 'absolute',
              width: 160,
              height: 70,
              backgroundColor: theme.OptimisedOffPeak?.Slider.tooltipBackgroundColor.backgroundColor,
              top: -80,
              borderWidth: 1,
              borderRadius: 3,
              borderColor: theme.OptimisedOffPeak?.Slider.tooltipBorderColor,
              justifyContent: 'center',
          }}>
              <View style={{borderBottomWidth: 1, paddingHorizontal: 5, borderColor: theme.OptimisedOffPeak?.Slider.tooltipBorderColor}}>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <Text style={[theme.OptimisedOffPeak?.toolTipAdditionalInfoTextStyle, {textTransform: 'uppercase' }]}>{props.label ? props.label : __('optimised-off-peak.agile_charging_label')}:</Text>
                      <Text style={theme.OptimisedOffPeak?.toolTipAdditionalInfoTextStyle}>{props.agilePrice}</Text>
                  </View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <Text style={[theme.OptimisedOffPeak?.toolTipAdditionalInfoTextStyle, {textTransform: 'uppercase' }]}>{__('optimised-off-peak.boost_charging_label')}:</Text>
                      <Text style={theme.OptimisedOffPeak?.toolTipAdditionalInfoTextStyle}>{props.boostPrice}</Text>
                  </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5}}>
                  <Text style={theme.OptimisedOffPeak?.toolTipAdditionalInfoTextStyle}>{formatEnergy(props.kwh * 1000)}</Text>
                  <Text style={theme.OptimisedOffPeak?.toolTipAdditionalInfoTextStyle}>{formatDistance(Number(distance.toFixed(0)), LocaleStore.sharedInstance().displayUnit as UnitFormat)}</Text>
              </View>
          </View>
      </View>
    );
}

const styles = StyleSheet.create({
    marker: {
        width: 32,
        height: 32,
        borderWidth: 1,
        borderRadius: 16,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginTop: 5,
    },
});
