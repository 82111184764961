import net from '@ampeco/net';
import { computed, observable } from 'mobx';

export interface Cacheable {
    id: number;
    updatedAt: string;
}

interface CacheableBuilder<T extends Cacheable> {
    new(): T;
    build(arg: any): T;
    cacheName(): string;
    endpoint(): string;
    endpointVersion?: string
    cacheTime(): number;
    formatResponse?(params: { [key: string]: { [key: string]: string | null } }, response: any): { [key: string]: boolean | T | null }
}

export default class CacheStore<T extends Cacheable> {
    private static caches: { [key: string]: CacheStore<any> } = {};

    public static getInstance<T extends Cacheable>(classToCache: CacheableBuilder<T>): CacheStore<T> {
        const key = classToCache.cacheName();
        if (!CacheStore.caches[key]) {
            CacheStore.caches[key] = new CacheStore(classToCache);
        }

        return CacheStore.caches[key];
    }

    private classToCache: CacheableBuilder<T>;

    @observable
    cache: { [key: string]: T } = {};

    private timestamps: { [key: string]: number } = {};

    private idsToFetch: string[] = [];

    private timeoutId: number | null = null;
    private loading: boolean = false;

    @computed
    get isLoading() {
        return this.loading;
    }

    constructor(classToCache: CacheableBuilder<T>) {

        this.classToCache = classToCache;
    }
    /**
     *
     * @param ids
     * @param force Forces fetching the data from the server even if we have recent and cached version.
     * @param fetchIfExists Fetches the data only if same id is already cached.
     * @returns
     */
    public fetch(ids: string | string[], force: boolean = false, fetchIfExists: boolean = false): void {
        if (!ids) {
            return;
        }

        if (typeof ids !== 'object') {
            ids = [ids + ''];
        }

        let willFetch: boolean = false;

        ids.filter(Boolean).forEach(id => {
            if (fetchIfExists && !this.isCached(id)) {
                return;
            }
            // If we're forcing a fetch on the store, we skip the cacheTime check
            if (!force && this.cacheIsValid(id)) {
                return;
            }

            willFetch = true;
            if (this.idsToFetch.indexOf(id) < 0) {
                this.idsToFetch.push(id);
            }
        });

        if (willFetch) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.doFetch.bind(this), 200);
        }
    }

    public clearCachedData(shouldReload: boolean = false): void {
        const ids = Object.keys(this.cache);

        this.cache = {};
        this.timestamps = {};

        if (shouldReload) {
            this.fetch(ids);
        }
    }

    public invalidateCacheData(): void {
        this.timestamps = {};
    }

    private formatFetchResponse(params: { [key: string]: { [key: string]: string | null } }) {
        if (this.classToCache.formatResponse) {
            const f = this.classToCache.formatResponse;
            return (response: any) => {
                return f(params, response);
            }
        } else {
            return (response: { [key: string]: boolean | T | null }) => {
                return response;
            }
        }
    }

    private doFetch() {
        if (this.loading) {
            this.timeoutId = setTimeout(this.doFetch.bind(this), 200);
            return;
        }

        this.loading = true;
        this.timeoutId = null;

        const ids = this.idsToFetch;
        this.idsToFetch = [];

        if (ids.length < 1) {
            return;
        }

        const cacheName = this.classToCache.cacheName();
        const params: { [key: string]: { [key: string]: string | null; }; } = {};
        params[cacheName] = {};

        ids.forEach(id => {
            if (this.cache[id] && this.cache[id].id !== -1) {
                params[cacheName][id] = this.cache[id].updatedAt;
            } else {
                params[cacheName][id] = null;
                delete this.cache[id];
            }
        });

        this.request(params);
    }

    public request(params: { [key: string]: { [key: string]: string | null; } }) {
        const ids = Object.keys(params[this.classToCache.cacheName()]) || [];

        net.post(this.classToCache.endpoint(), params, this.classToCache.endpointVersion || 'v1')
            .then(this.formatFetchResponse(params))
            .then((response: { [key: string]: any }) => {
                const fetchedAt = Date.now();

                Object.entries(response).forEach(([id, element]) => {
                    if (typeof element !== 'boolean') {
                        this.add(id, element, fetchedAt);
                    } else {
                        this.timestamps[id] = fetchedAt;
                    }
                })

                const newIds = Object.keys(response);
                // remove the elements that were not found on the server
                ids.forEach(id => {
                    if (!newIds.includes(id)) {
                        this.remove(id, fetchedAt);
                    }
                })

                this.loading = false;
            })
            .catch(() => {
                ids.forEach(id => {
                    this.remove(id);
                });
                this.loading = false;
            });
    }

    public remove(id: string, timestamp: number = Date.now()) {
        this.cache[id] = this.classToCache.build({ id: -1 });
        this.timestamps[id] = timestamp;
    }

    public add(id: string, item: any, fetchedAt: number) {
        this.cache[id] = this.classToCache.build(item);
        this.timestamps[id] = fetchedAt;
    }

    public findOrFetch(id: string): T | undefined {
        const item: T | undefined = this.cache[id];

        if (!item || !this.cacheIsValid(id)) {
            this.fetch(id);
        }

        return (!item || item?.id === -1) ? undefined : item;
    }

    public where(id: string): T | undefined {
        const item = this.cache[id];
        if (item?.id === -1) {
            return undefined;
        }
        return item;
    }

    private cacheIsValid(id: string) {
        const cacheTime = this.classToCache.cacheTime() * 1000; // from seconds to milliseconds

        return this.timestamps[id] && this.timestamps[id] >= Date.now() - cacheTime;
    }

    private isCached(id: string): boolean {
        return this.timestamps[id] !== undefined;
    }
}
