import Bugsnag, { NotifiableError } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {Client} from '@bugsnag/browser'
import {LoggingSettings, MockClient} from './index';
import {BreadcrumbType} from '@bugsnag/core';

let _client: Client | MockClient | null = null;

export let init = (settings: () => LoggingSettings) => {
    _client = getClient(settings());
};

export let getClient = (settings?: LoggingSettings) => {
    if (_client) {
        return _client;
    }

    if (settings && settings.apiKey) {
        _client = Bugsnag.start({
            apiKey: settings.apiKey,
            plugins: [new BugsnagPluginReact()],
            appVersion: settings?.appVersion || '',
            releaseStage: settings?.releaseStage || 'production',
        });

        return _client;
    } else {
        return {
            leaveNavigationBreadcrumb(message: string) {
                console.debug(message);
            },
            leaveBreadcrumb(message: string, metadata?: IMetadata | undefined, type?: BreadcrumbType | undefined) {
                console.debug(message, metadata);
            },
            notify(error: NotifiableError, info?: string, metadata?: IMetadata | undefined) {
                console.debug(error, info);
            },
            setUser(user: { id: string, name: string, email?: string }) {
                console.debug(user);
            },
        }
    }
};

interface IMetadata {
    [key: string]: any
}

export let getNavigationContainer = () : any =>
{
    return null;
}

export let leaveNavigationBreadcrumb = (message: string) => {
    if (!message) {
        return;
    }
    if (message.length >= 30) {
        message = message.substring(0, 29);
    }

    getClient().leaveBreadcrumb(message, undefined, 'navigation');
};

export let leaveBreadcrumb = (message: string, metadata?: IMetadata | undefined, type?: BreadcrumbType | undefined) => {
    return getClient().leaveBreadcrumb(message, metadata, type);
};

export let notify = (error: any, info?: string, metadata?: IMetadata | undefined) => {
    return getClient().notify(error, (event) => {
        event.addMetadata('metadata', {
            message: info,
            ...metadata,
        });
    });
};

export let setUser = (user: { id: string, name: string, email?: string }) => {
    return getClient().setUser(`${user.id}`, user?.email || '', `${user.name}`);
};
