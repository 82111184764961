import React from 'react';
import {View} from 'react-native';
import CheckBox from './CheckBox';
import {CheckBoxProps, Text} from 'react-native-elements';
import {FieldInput, FieldInputProps} from './FieldInput';
import { ThemeConsumer } from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

type CheckboxAdapterProps = FieldInputProps & CheckBoxProps;

export class CheckBoxAdapter extends React.Component<CheckboxAdapterProps, any> implements FieldInput {
    constructor(props: Readonly<CheckboxAdapterProps>) {
        super(props);
        this.state = {checked: false};
    }

    render() {
        const {
            onChange,
            value,
            errorMessage,
            onPress,
        } = this.props;

        return <View>
            <CheckBox {...this.props}
                      checked={!!value}
                      onPress={() => {
                          this.setState({checked: !this.state.checked}, () => {
                              onChange(this.state.checked);
                          });
                          if (onPress !== undefined) {
                              onPress();
                          }
                      }}
            />

            {errorMessage && !this.props.noError && !this.props.noError ? <ThemeConsumer<AmpecoTheme>>
                {({ theme }) => (
                    <Text style={theme.Input ? theme.Input.errorStyle : undefined}>{errorMessage}</Text>
                )}</ThemeConsumer> : null}
        </View>;
    }
}
