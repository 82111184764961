import React from "react";
import {TitleComponent} from "../components/TitleComponent/TitleComponent";
import {useLocation} from "react-router-dom";
import {View, StyleSheet} from "react-native";

export type BasePageProps = {
    children: React.ReactNode;
    title?: string;
    onGoBackClick?: () => void;
    isGoBackLinkDisabled?: boolean;
    overrideStyles?: {
        [key:string]: any
    }
};

export function BasePage(
    {children, title = '', onGoBackClick, isGoBackLinkDisabled = false, overrideStyles}: BasePageProps
) {
    const location = useLocation<any>();

    const styles = StyleSheet.compose({
        container: {
            marginHorizontal: 25
        }
    }, overrideStyles);

    return (
        <View style={styles.container}>
            <>
                <TitleComponent
                    title={title}
                    componentTitle={location?.state?.title}
                    onGoBackClick={onGoBackClick}
                    goBackDisabled={isGoBackLinkDisabled}
                />
                {children}
            </>
        </View>
    );
}

export const NoMarginOverrideStyle = {
    container: {
        marginHorizontal: 0
    }
}
