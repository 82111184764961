import React from 'react';
import {observer} from 'mobx-react';
import {Details} from '@ampeco/charge-screens';
import {Action, FormStore, FormStoreProvider} from '@ampeco/forms';
import {View} from 'react-native';
import {ButtonIcon} from '@ampeco/icons';
import {icons} from '../../res/images';
import {__} from '@ampeco/i18n';
import NavigationService from '@ampeco/navigation';
import {Button} from 'react-native-elements';
import {BasePage} from "../BasePage";

const store = new FormStore();

store.actions.edit = async () => {
    return NavigationService.sharedInstance().navigate('EditProfile');
}

const ViewProfile = observer(() => {
    return (
        <BasePage title={'profile.title'}>
            <View>
                <View style={styles.profileDetails}>
                    <Details/>
                </View>

                <FormStoreProvider store={store}>
                    <Action
                        name='edit'
                        type={Button}
                        title={__('profile.edit')}
                        icon={<ButtonIcon source={icons.edit} style={{width: 21, height: 21}} />}
                    />
                </FormStoreProvider>
            </View>
        </BasePage>
    )
});

export default ViewProfile;

const styles = {
    profileDetails: {
        paddingBottom: 10,
        textAlign: 'left',
    },
};
