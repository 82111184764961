export class SessionChangesMonitorProxy {
    private static class: any;

    static init(monitorClass: any){
        SessionChangesMonitorProxy.class = monitorClass;
    }

    static getClass(){
        return SessionChangesMonitorProxy.class;
    }
}
