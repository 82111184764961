import net from '../../net';
import {computed, observable} from 'mobx';

export interface Field {
    type: 'input' | 'select' | 'checkbox';
    options?: { [index: string]: string };
    label: string;
    show?: { field: string, value: any }[];
    default?: any;
    required?: any;
    hidden?: boolean
}

const shouldShow = (form: { form: { [index: string]: any } }, field: Field) => {
    if (!field.show) {
        return true;
    }
    let show = true;
    field.show.forEach(({field, value}) => {
        if (form.form[field] !== value) {
            show = false;
        }
    });
    return show;
};

export {shouldShow};

class InvoiceDetailsStore {
    @observable ready = false;
    @observable fields?: { [index: string]: Field };
    @observable details?: { [index: string]: any };
    @observable detailsRequired: boolean = false;
    @observable hasMissingContentFields: boolean = false;

    @computed get canAddPaymentMethod() {
        if (!this.ready) {
            return false;
        }

        return !this.detailsAreMissing;
    }

    @computed get detailsAreMissing(): boolean {

        if (!this.detailsRequired) {
            return false;
        }

        if (this.detailsRequired && this.details === undefined) {
            return true;
        }

        return this.hasMissingContentFields;
    }

    clearDetails() {
        this.fields = undefined;
        this.details = undefined;
    }

    async loadDetails() {
        this.ready = false;
        try {
            await this.load();
        } catch (e) {
            // ignore error
        }
        this.ready = true;
    }

    async load() {
        const res = await net.get('app/invoice_details');
        this.fields = res.fields;
        this.detailsRequired = res.detailsRequired || false;
        this.details = res.details ? res.details : undefined;
        this.hasMissingContentFields = res.hasMissingContentFields || false;
    }

    async save(details: { [index: string]: any }) {
        if (details.invoice_type !== 'Company') {
            details.company_id = undefined;
        }

        if (details.invoice_type !== 'Donation') {
            details.love_code = undefined;
        }

        if (details.carrier_type !== 'Mobile') {
            details.mobile_bar_code = undefined;
        }

        if (details.carrier_type !== 'Citizen') {
            details.citizen_id = undefined;
        }

        this.details = await net.post('app/invoice_details', details);
        this.hasMissingContentFields = false;
    }
}

export default new InvoiceDetailsStore();
