import {observable} from 'mobx';
import {SubscriptionBillingHistory} from '@ampeco/charge-models';
import {SubscriptionPlans as API} from '@ampeco/charge-api';

export default class SubscriptionBillingHistoryStore {
    private static instance: SubscriptionBillingHistoryStore;

    @observable records: SubscriptionBillingHistory[] = [];
    @observable isLoading: boolean = false;

    static sharedInstance(): SubscriptionBillingHistoryStore {
        if (SubscriptionBillingHistoryStore.instance === undefined) {
            SubscriptionBillingHistoryStore.instance = new SubscriptionBillingHistoryStore();
        }
        return SubscriptionBillingHistoryStore.instance;
    }

    public async load() {
        try {
            this.isLoading = true;
            this.records = await API.billingHistory();
        } catch (error) {
            this.records = [];
        } finally {
            this.isLoading = false;
        }
    }
}
