import {AppState, AppStateStatus} from 'react-native';
import {observable} from 'mobx';

export default class ApplicationStateService {

    private static instance?: ApplicationStateService;

    @observable appState: AppStateStatus = 'active';

    static sharedInstance(): ApplicationStateService {
        if (ApplicationStateService.instance === undefined) {
            ApplicationStateService.instance = new ApplicationStateService();
        }
        return ApplicationStateService.instance;
    }

    constructor() {
        AppState.addEventListener('change', this._handleAppStateChange.bind(this));
    }

    _handleAppStateChange(appState: AppStateStatus) {
        if (
            this.appState.match(/inactive|background/) &&
            appState === 'active'
        ) {
            this.appState = appState;
        } else if (
            this.appState.match(/inactive|active/) &&
            appState === 'background'
        ) {
            this.appState = appState;
        }
    }
}
