import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { AddPaymentMethodNative } from '@ampeco/payment-methods';
import { BasePage } from '../BasePage';
import NavigationService, { HomeModalParamList } from '@ampeco/navigation';

function AddPaymentMethodNativeScreen({ location }: { location: { state: HomeModalParamList['AddPaymentMethodNative'] } }): JSX.Element {

    const navigationService = NavigationService.sharedInstance();
    const navigation: any = {
        setOptions: () => {/**/ },
        addListener: () => {/**/ },
        removeListener: () => {/**/ },
        navigate: navigationService.navigate.bind(navigationService),
    };

    const params = location.state;

    return <BasePage
        title={'addPaymentMethod.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('PaymentMethods')
        }}
    >
        <SafeAreaProvider>
            <AddPaymentMethodNative
                navigation={navigation}
                route={{
                    params,
                }}
            />
        </SafeAreaProvider>
    </BasePage>;
}

export default AddPaymentMethodNativeScreen;
