import React, {Component} from 'react';
import {Animated, Image, StyleSheet, View} from 'react-native';
import {Text, ThemeProps, withTheme} from 'react-native-elements';
import BarsAnimation from './BarsAnimation';
import {ChargeTheme} from '@ampeco/theme';

interface Props {
    percent?: number;
}

class CarChargeAnimation extends Component<Props & ThemeProps<ChargeTheme>> {

    animations = new BarsAnimation(5, this.props.theme.CarChargeAnimation.chargingProgressOffBar, this.props.theme.CarChargeAnimation.chargingProgressOnBar);

    componentDidMount(): void {
        this.animations.percent = this.props.percent;
        this.animations.playAnimation();
    }

    componentWillUnmount(): void {
        this.animations.stopAnimation();
    }

    render() {

        return <View style={[this.props.theme.Container.byPaddingAndMarginTop, styles.container]}>
            <Image source={this.props.theme.CarChargeAnimation.chargingBackgroundImage}/>
            <View style={[styles.battery, {left: 166 + 25 + (this.props.percent === undefined ? 20 : 0)}]}>
                {this.animations.animatedStyles.map((style, id) => (
                    <Animated.View key={id} style={[styles.cell, style]}/>),
                )}
                {this.props.percent !== undefined && (this.props.percent >= 0 && this.props.percent <= 100) &&
                <Text style={[this.props.theme.CarChargeAnimation.percentStyle, styles.percent]}>{this.props.percent}%</Text>
                }
            </View>
        </View>;
    }
}

export default withTheme<Props, ChargeTheme>(CarChargeAnimation);

const styles = StyleSheet.create({
    container: {
        marginTop: 45,
        marginBottom: 15,
    },
    battery: {
        position: 'absolute',
        top: 85,
        left: 166 + 25,
        flexDirection: 'row',
    },
    cell: {
        margin: 1,
        height: 18,
        width: 8,
    },
    percent: {
        fontSize: 15,
        fontFamily: 'RobotoCondensed-Regular',
        paddingLeft: 12,
        lineHeight: 18,
    },
});
