import { ProviderContext } from '@ampeco/forms';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {IconNode, Text, ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

// tslint:disable-next-line:interface-over-type-literal
export interface IconTextButtonProps {
    title: string;
    onPress?: () => void;
    icon: IconNode;
}

export default class IconTextButton extends React.Component<IconTextButtonProps, ProviderContext> {
    render() {
        return <ThemeConsumer<AmpecoTheme>>{({theme}) => { return <TouchableOpacity activeOpacity={0.6} onPress={this.props.onPress}>
            <View style={styles.container}>
                {this.props.icon}
                <Text style={StyleSheet.flatten([styles.buttonStyle, theme.IconTextButton.textStyle])}>{this.props.title}</Text>
            </View>
        </TouchableOpacity>;}}</ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 14,
        marginTop: 14,
    },
    buttonStyle: {
        marginLeft: 5,
    },
});
