import React from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import NavigationService, { HomeModalParamList } from '@ampeco/navigation';
import { PaymentsStore } from '@ampeco/payment-methods';
import * as Api from '../api/payments';
import { __ } from "@ampeco/i18n";
import {startSession} from "../../../frontend/services/StartSession";
import {SessionStartStore} from "@ampeco/charge-stores";
import GlobalStore from "../../../frontend/stores/GlobalStore";

type AddPaymentMethod3dsProps = StackScreenProps<
    HomeModalParamList,
    'AddPaymentMethodNative3ds'
>;

export default class AddPaymentMethodNative3ds extends React.Component<AddPaymentMethod3dsProps> {

    constructor(props: AddPaymentMethod3dsProps) {
        super(props);
    }

    componentDidMount(): void {
        const siteUrl = window.location.search;
        const urlParams = new URLSearchParams(siteUrl);
        let setupIntent = urlParams.get('setup_intent');
        let setupIntentClientSecret = urlParams.get('setup_intent_client_secret');
        const postParams = {
            'client_secret': setupIntentClientSecret,
            'setup_intent_reference': setupIntent
        };
        const paymentsStore = PaymentsStore.sharedInstance();
        const sessionStore = SessionStartStore.sharedInstance();

        Api.postPayment(postParams).then(result => {
            if(GlobalStore.sharedInstance().isAdHocUser) {
                if(result && this.props.route.evse) {
                    PaymentsStore.sharedInstance().selectPaymentMethod(result);
                    sessionStore.setLoading(null);
                    try {
                        startSession(this.props.route.evse);
                        GlobalStore.sharedInstance().openMenu();
                    } catch (err) {
                        console.log('error start session');
                    }
                }
            } else {
                paymentsStore.payments?.methods.push(result);
                NavigationService.sharedInstance().navigate('PaymentMethods');
            }
        }).catch((reason) => {
            paymentsStore.showLastPaymentFailure(__('addPaymentMethod.error'), __('addPaymentMethod.adding-failed'));
        });

    }

    render(): React.ReactNode {
        return (<></>);
    }
};
