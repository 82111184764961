import net from '@ampeco/net';
import {Session, SmartChargingAgileOctopus, SmartChargingOctopusGo, SmartChargingSchedule} from '@ampeco/charge-models';

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export async function startSession(evseId: string, paymentMethodId?: string, attributes: {[index: string]: any} = {}): Promise<Session> {
    return (await net.post('/app/session/start', {evseId, paymentMethodId, ...attributes})).session as Session;
}

export async function boostSession(evseId: string) {
    return (await net.post('/app/session/start', {evseId, smartCharging: {enabled: false}})).session as Session;
}

export async function stopSession(sessionId: string): Promise<Session> {
    return (await net.post(`/app/session/${sessionId}/end`)).session as Session;
}
export async function boostRandomisedDelay(sessionId: string): Promise<Session> {
    return (await net.post(`/app/session/${sessionId}/boost`)).session as Session;
}

export async function getSessionInfo(sessionId: string): Promise<Session> {
    return (await net.get(`/app/session/${sessionId}?withPowerHistory=1&withSmartChargingSchedule=1&withTaxName=1`)).session as Session;
}

export async function updateSessionInfo(
    sessionId: string,
    update: {summarySeen?: boolean; smartCharging?: RecursivePartial<SmartChargingAgileOctopus | SmartChargingOctopusGo | SmartChargingSchedule>},
): Promise<Session> {
    return (await net.patch(`/app/session/${sessionId}`, update)).session as Session;
}

export async function getActiveSessionInfo(): Promise<Session | undefined> {
    try {
        return (await net.get('/app/session/active')).session as Session;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return;
        }
        throw error;
    }
}

export async function checkCanStart(evseId: string, paymentMethodId: string): Promise<void> {
    return net.options('/app/session/start', {evseId, paymentMethodId});
}

export async function paySession(sessionId: string, paymentMethodId: string): Promise<Session> {
    return (await net.post(`/app/session/${sessionId}/pay`, {paymentMethodId})).session as Session;
}

export async function sendRemoteStart(sessionId: string): Promise<Session> {
    return (await net.post(`/app/session/${sessionId}`)).session as Session;
}

export interface ThresholdResponse {
    kwhMaxAvailable: number;
    kwhThreshold?: number;
}

export async function getThreshold(evseId: string, chargeBy: string): Promise<ThresholdResponse> {
    return (net.get('/app/calculate-threshold?evseId=' + evseId + '&chargeBy=' + chargeBy));
}

export async function getLatestUnknown(evseId: string): Promise<Session> {
    return (await net.get(`/app/evses/${evseId}/latest-unknown-session`)).session as Session;
}
