interface LatLng {
    latitude: number;
    longitude: number;
}

export function splitGeoToLatLng(geo: string): LatLng {
    const [latitude, longitude] = geo.split(',').map(parseFloat);

    return {
        latitude,
        longitude
    }
}
