import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react';
import {Action, Error, FormStore, FormStoreProvider} from '@ampeco/forms';
import {Button} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import palette from '../../res/palette';
import {ButtonIcon} from '@ampeco/icons';
import {icons} from '../../res/images';
import GlobalStore from '../../stores/GlobalStore';
import {UpdateProfile} from '@ampeco/charge-models';
import {Wizard} from '@ampeco/charge-flow';
import {ProfileFormFields} from '@ampeco/profile-fields';
import {Profile} from '@ampeco/charge-api';
import NavigationService from '@ampeco/navigation';

const store = new FormStore();

@observer
export default class ContactDetails extends Wizard {

    componentDidMount() {
        store.reset();

        this.props.navigation.setOptions({title: __('profile.details')});
    }

    componentWillUnmount(): void {
        GlobalStore.sharedInstance().resetFlow();
    }

    render() {
        store.actions.postdetails = async () => {
            const globalStore = GlobalStore.sharedInstance();
            await Profile.patch(store.form as unknown as UpdateProfile);
            await globalStore.statusUpdate();
            //invoice load
            store.reset();
            NavigationService.sharedInstance().navigate('Home');
        };

        const globalStore = GlobalStore.sharedInstance();

        return (
            <View>
                {this.renderSteps()}
                <View style={[palette.containerMargin, {marginBottom: 60}]}>
                    <FormStoreProvider store={store}>
                        <ProfileFormFields
                            formStore={store}
                            profile={globalStore.profile}
                        />

                        <Error/>

                        <Action
                            disableIfInvalid type={Button} name="postdetails"
                            title={__('register.submit')}
                            iconRight={this.hasNext() ? true : false}
                            icon={this.hasNext() ? <ButtonIcon source={icons.forward}/> :
                                <ButtonIcon source={icons.finish}/>}
                            validateOnPress
                        />

                    </FormStoreProvider>
                </View>
            </View>
        );
    }
}
