import {StyleProp, TextProps} from 'react-native';
import React from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';
import {ChargeTheme} from '@ampeco/theme';

export default class TextSubTitle extends React.Component<{style?: StyleProp<TextProps>}> {
    render() {
        return (<ThemeConsumer>{({theme}) => {
            return <Text style={[theme.TextSubTitle.style]}>{this.props.children}</Text>
        }}</ThemeConsumer>);
    }
}
