import React from 'react';
import {StyleProp, StyleSheet, TextStyle, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {humanizeDuration} from '@ampeco/humanize-duration';
import {formatEnergy, formatPower} from '@ampeco/utils';
import {Session} from '@ampeco/charge-models';
import Currency, {format} from '@ampeco/currency';
import {ChargeTheme} from '@ampeco/theme';
import {SessionStatsStore} from '@ampeco/charge-stores';

function Stat(props: {label: string, value: string, hasAsterisk?: boolean, valueStyle?: StyleProp<TextStyle>, labelStyles?: StyleProp<TextStyle>;}) {
    return <ThemeConsumer>{({theme}) => { return <View style={styles.statBox}>
        <Text style={[theme.SessionSummaryStatsComponent?.statTitle, props.labelStyles]}>{props.label}</Text>
        <Text style={[theme.SessionSummaryStatsComponent?.statValue, props.valueStyle]}>{props.value + (props.hasAsterisk ? '*' : '')}</Text>
    </View>; }}</ThemeConsumer>;
}

interface Props {
    session: Session;
}
interface State {}
export class SummaryStatsComponent extends React.Component<Props, State> {

    render() {
        const {session} = this.props;
        const statsStore = SessionStatsStore.sharedInstance();
        return <ThemeConsumer>{({theme}) => {
            const labelStyle = theme.SessionSummaryStatsComponent?.labelStyle;
            const textStyle = theme.SessionSummaryStatsComponent?.textStyles;

            return <View style={[theme.Container?.byPaddingAndMarginTop, styles.container]}>
                {statsStore.isShowPowerEnabled ? <Stat
                    labelStyles={[labelStyle, styles.labelStyles]}
                    valueStyle={textStyle}
                    label={__('personal-charging.session-summary.avg-power')}
                    value={formatPower((session.powerAvg || 0))}
                /> : null}
                {statsStore.isShowEnergyEnabled ? <Stat
                    labelStyles={[labelStyle, styles.labelStyles]}
                    valueStyle={textStyle}
                    label={__('personal-charging.session-summary.total-energy')}
                    value={formatEnergy(session.energy)}
                /> : null}
                {statsStore.isShowDurationEnabled ? <Stat
                    labelStyles={[labelStyle, styles.labelStyles]}
                    valueStyle={textStyle}
                    label={__('personal-charging.session-summary.duration')}
                    value={humanizeDuration(session.totalDuration, true)}
                /> : null}
                {!session.isPersonal && statsStore.isShowAmountEnabled ? <Stat
                    labelStyles={[labelStyle, styles.labelStyles]}
                    valueStyle={textStyle}
                    label={__('session.running.amount')}
                    value={session.currency ? format(session.totalAmount, session.currency) : '-'}
                    hasAsterisk={session.currency && session.isUsingChargingAllowance}
                /> : null}
        </View>; }}</ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    statBox: {
        alignItems: 'center',
    },
    labelStyles: {
        marginBottom: 5,
    },
});
