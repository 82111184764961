import net from '@ampeco/net';
import {Reservation} from '@ampeco/charge-models';

export async function reserveNow(evseId: string): Promise<Reservation> {
    return (await net.post('/app/reservation', {evseId})).reservation as Reservation;
}

export async function cancelReservation(reservationId: string): Promise<Reservation> {
    return (await net.delete(`/app/reservation/${reservationId}`)).reservation as Reservation;
}

export async function getReservationInfo(reservationId: string|null = null): Promise<Reservation|null> {
    try {
        if (!reservationId) {
            return (await net.get('/app/reservation')).reservation as Reservation;
        } else {
            return (await net.get(`/app/reservation/${reservationId}`)).reservation as Reservation;
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return null;
        }
        throw error;
    }
}

export async function getReservationRestriction(): Promise<string|null> {
    return (await net.get('/app/reservation/restriction')).restriction;
}
