import React, {MouseEventHandler} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Link} from 'react-router-dom';
import palette from '../../res/palette';
import colors from "../../res/colors";
import {matchRoutePath} from "../../services/NavigationService";
import {LinkProps} from "react-router-dom";
import {icons} from "../../res/images";
import {RTLImage} from '@ampeco/rtl-image';

type MenuItemProps = {
    id: string;
    text: string;
    route: string;
    className: string;
    icon?: string;
    state?: {[key: string]: any};
    onPress?: MouseEventHandler;
    disclosure?: boolean;
};

export default function MenuItem(props: MenuItemProps) {
    const matchedRoute = matchRoutePath(props.route);
    let linkToProp: LinkProps['to'] = matchedRoute.path;

    if (props.state) {
        linkToProp = {
            pathname: matchedRoute.path,
            state: props.state
        }
    }

    return (
      <Link onClick={props.onPress} id={props.id} to={linkToProp} className={props.className} style={{'textDecoration': 'none', 'display': 'flex'}}>
          <View style={StyleSheet.flatten([styles.container, props.disclosure ? {flex: 50} : null, {textAlign: 'left'}])}>
              {props.icon ? <View style={styles.imageContainer}>
                  <RTLImage source={props.icon} style={styles.image} resizeMode={'contain'}/>
              </View> : null}
              <Text style={[palette.emphasis, palette.light, styles.text]}>{props.text}</Text>
          </View>
          {props.disclosure ? <View style={styles.disclosure}>
              <RTLImage source={icons.disclosure} style={styles.disclosureIcon} />
          </View> : null}
      </Link>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 14,
        paddingTop: 14,
        paddingLeft: 15,
        textAlign: 'left',
    },
    imageContainer: {
        // flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        flexBasis: '5%',
        flexGrow: 0,
        flexShrink: 0,
    },
    text: {
        fontSize: 16,
        flex: 5,
        alignItems: 'center',
    },
    image: {
        height: 20,
        width: 20,
        tintColor: colors.textEmphasisColor,
    },
    disclosure: {
        flex: 5,
        justifyContent: 'center',
        alignItems: 'center',
        width: 10,
        marginRight: 14,
    },
    disclosureIcon: {
        tintColor: palette.text.color,
        width: 5,
        height: 12,
    },
});
