import React, { FunctionComponent, ReactChildren } from 'react';
import { Props as BaseProps } from './Props';
import { ThemeConsumer, Text } from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import { View } from 'react-native';

export interface Props extends BaseProps {
    collapsed: boolean;
    errorMessage: string;
}

const Select: FunctionComponent<ReactChildren & Props & any> = (props) => {
    if (props.children !== undefined) {
        return props.children;
    }

    return (<ThemeConsumer<AmpecoTheme>>{({ theme }) => {
        return <View style={[props.styleProp, { flexDirection: 'row' }]}>
            <select
                disabled={props.disabled}
                style={props.fullWidth ? { width: '100%' } : {}}
                onChange={(e) => {
                    // @ts-ignore
                    props.onChange && props.onChange(e.target.value, e.target.selectedIndex);
                }}
                value={props.selected || props.value}
            >
                <option value=''></option>
                {props.options.map((option: any, index: any) => {
                    if (typeof option === 'object') {
                        return <option
                            key={index}
                            value={option.key}
                        >{option.label}</option>
                    }
                    return <option
                        key={index}
                        value={option}
                    >{option}</option>
                })}
            </select>
            {props.errorMessage ?
                <Text style={theme.Input ? theme.Input.errorStyle : undefined}>{props.errorMessage}</Text>
                : null}
        </View>;
    }}</ThemeConsumer>);
}

export default Select;
