import {observer} from 'mobx-react';
import React, {Component} from 'react';
import Select from '@ampeco/select';
import {__, LocaleStore} from '@ampeco/i18n';
import {Text, ThemeConsumer} from 'react-native-elements';
import {View, ViewStyle, StyleProp, StyleSheet, ActivityIndicator, I18nManager} from 'react-native';
import {StandaloneIcon} from '@ampeco/icons';
import {Profile} from '@ampeco/charge-api';

export function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
}

interface Props {
    shouldPersist?: boolean;
    showLabel?: boolean;
    callback?: (locale: string) => void;
    style?: StyleProp<ViewStyle>;
}

@observer
export default class LanguageSelect extends Component<Props, { languageCollapsed: boolean, isLoading: boolean }> {
    constructor(props: Props) {
        super(props);
        this.state = {languageCollapsed: true, isLoading: false};
    }

    render() {
        const localeStore = LocaleStore.sharedInstance();

        LocaleStore.observeLanguage();

        const props = this.props;
        return <ThemeConsumer<any>>{({theme}) => {
            return <View style={[viewStyle.content, this.props.style]}>
                {this.props.showLabel && <Text style={theme.Settings.label}>{__('settings.locale-label')}:</Text>}
                <Select
                    options={Object.values(localeStore.languages)}
                    selected={localeStore.availableLanguages[localeStore.language]}
                    onChange={
                        async (item) => {
                            const lang = getKeyByValue(localeStore.languages, item) || 'en';

                            try {
                                if (props.shouldPersist) {
                                    if (LocaleStore.isRTL(lang) !== I18nManager.isRTL) {
                                        // App will get restarted, so wait for the API response
                                        this.setState({isLoading: true});
                                        await Profile.updateLocale(lang);
                                    } else {
                                        await Profile.updateLocale(lang);
                                    }

                                }

                                localeStore.setLanguage(lang);

                                if (this.props.callback) {
                                    this.props.callback(lang);
                                }

                            } catch (e) {
                                console.log('could not persist the language');
                            } finally {
                                this.setState({isLoading: false});
                            }
                        }
                    }
                    renderRow={
                        (item, index, isSelected) => {
                            const selectedStyle = (theme.Select && theme.LanguageSelector.selectedStyle) || undefined;
                            const unselectedStyle = (theme.Select && theme.LanguageSelector.unselectedStyle) || undefined;

                            return <Text style={[viewStyle.rowText, theme.LanguageSelector.textStyle, isSelected ? selectedStyle : unselectedStyle]}>
                                {item}
                            </Text>;
                        }
                    }
                    renderSelected={
                        (selected, selectedImage) => {
                            return <View style={viewStyle.wrapper}>
                                {this.state.isLoading ?
                                <ActivityIndicator/>
                                :
                                <>
                                    {selected && <Text style={{marginRight: 10, ...theme.LanguageSelector.textStyle}}>{selected}</Text>}
                                    <View style={viewStyle.icon}><StandaloneIcon source={theme.Select.selectIconSource}/></View>
                                </>
                                }

                            </View>;
                        }
                    }
                    fullWidth={false}
                />
            </View>;
        }}</ThemeConsumer>;
    }
}

const viewStyle = StyleSheet.create({
    content: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: 0,
        marginVertical: 0,
    },
    rowText: {
        padding: 15,
        paddingHorizontal: 25,
    },
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {alignContent: 'flex-end'},
});
