import net from '@ampeco/net';
import {
    Partner,
} from '@ampeco/charge-models';

export async function getPartnersList(): Promise<Partner[]> {
    return (await net.get('/app/partners/list')).data as Partner[];
}

export async function addPartner(partnerId: string) {
    return (await net.post('/app/partners/'+partnerId+'/select')).data;
}
