import net, {setAuthenticationInfo} from '@ampeco/net';
import {CreateUser} from '@ampeco/charge-models';


export async function register(profile: CreateUser): Promise<boolean> {
    const issued_on = new Date();

    let data = {
        ...profile,
    };

    if (net.getSettings().pass_client_id_and_secret_from_register_call) {
        data = {
            ...data,
            client_id: net.getSettings().client_id,
            client_secret: net.getSettings().client_secret
        }
    }

    const res = await net.post('/app/profile/register', data);
    await setAuthenticationInfo({...res, issued_on});
    return true;
}

/**
 * Checks if email is valid and can be registered.
 *
 * @export
 * @param {string} email
 * @returns {boolean}
 */
export async function emailIsAvailable(email: string) {
    try {
        await net.post('/app/profile/register/check', {email});
    } catch (err) {
        if (err.response && err.response.status === 422) {
            return false;
        }
        throw err;
    }

    return true;
}

export async function forgottenPassword(email: string) {
    await net.post('/app/profile/forgotten_password', {email});
}
