import React from 'react';
import {View, Image, StyleSheet, Animated, Easing} from 'react-native';

const loadStates = {
    sending: 'loadStates/sending',
    success: 'loadStates/success',
    fail: 'loadStates/fail',
};

interface LoadState {}

interface Props {
    source: Object | number;
    imageStyle?: Object;
    style?: Object;
    renderLoadingIndicator?: () => any;
    renderError?: () => any;
    onFail?: () => void;
    onSuccess?: () => void;
    animated?: boolean;
    resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center';
}

interface State {
    loadState: LoadState;
    animatedFade: Object;
}

export default class ImageLoader extends React.PureComponent<Props, State> {
    static defaultProps = {animated: false};

    state = {loadState: loadStates.sending, animatedFade: new Animated.Value(0)};

    componentDidUpdate(_: any, lastState: State) {
        const {loadState} = this.state;
        const {onFail, onSuccess, animated} = this.props;

        if (lastState.loadState !== loadState) {
            switch (loadState) {
                case loadStates.success:
                    if (animated) {
                        this.animateFadeIn();
                    }
                    if (onSuccess) {
                        onSuccess();
                    }
                    break;
                case loadStates.fail:
                    if (onFail) {
                        onFail();
                    }
                    break;
                default:
            }
        }
    }

    render() {
        const {loadState} = this.state;
        const {style} = this.props;
        return <View style={style} accessibilityIgnoresInvertColors>
            {this.renderImage()}
            {loadState === loadStates.fail ? this.renderError() : <View />}
            {loadState === loadStates.sending ? this.renderLoadingIndicator() : <View />}
        </View>;
    }

    renderImage = () => {
        const {animatedFade} = this.state;
        const {animated, imageStyle, resizeMode} = this.props;
        let {source} = this.props;
        let ImageComp = Image;
        const imageStyles = [styles.fill, imageStyle];

        if (this.state.loadState === loadStates.sending) {
            setTimeout(() => {
                if (this.state.loadState === loadStates.sending)
                    this.onLoadFail();
            }, 20000);
        }

        if (animated) {
            ImageComp = Animated.Image;
            const positionY = animatedFade.interpolate({inputRange: [0, 1], outputRange: [12, 0]});
            const animatedStyle = {opacity: animatedFade, transform: [{translateY: positionY}]};
            imageStyles.push(animatedStyle);
        }
        if (typeof source === 'string') {
            source = {uri: source as string};
        }
        return <Image source={source} style={imageStyles} resizeMode={resizeMode || 'contain'}
            onError={this.onLoadFail} onLoad={this.onLoadSuccess} />;
    };

    renderLoadingIndicator = () => {
        const {renderLoadingIndicator} = this.props;
        return renderLoadingIndicator ? <View style={[styles.fill, styles.centerContent]}>
            {renderLoadingIndicator()}
        </View> : null;
    };

    renderError = () => {
        const {renderError} = this.props;
        return renderError
            ? <View style={[styles.fill, styles.centerContent]}>{renderError()}</View>
            : null;
    };

    onLoadSuccess = () => this.setState({loadState: loadStates.success});

    onLoadFail = () => this.setState({loadState: loadStates.fail});

    animateFadeIn = () => {
        const {animatedFade} = this.state;
        Animated.timing(animatedFade, {
            toValue: 1,
            duration: 260,
            easing: Easing.out(Easing.back(2)),
            useNativeDriver: true,
        }).start();
    };
}

const styles = StyleSheet.create({
    fill: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    centerContent: {
        justifyContent: 'center',
        alignItems: 'center',
    },
});
