import Echo from 'laravel-echo';
import BroadcastService, {BroadcastSettings} from "../../frontend_modules/broadcast/BroadcastService";
import {LocationObject} from '@ampeco/charge-models';
import {LocationStore, PinsStore} from "@ampeco/charge-stores";
import {reaction} from "mobx";

class WebsocketMonitor {
    private echo?: Echo;

    private subscribed?: string;
    private settings: () => BroadcastSettings;

    init(settings: () => BroadcastSettings) {
        this.settings = settings;
        this.subscribe();
        reaction(() => BroadcastService.sharedInstance(this.settings).connected, connected => {
            if (connected) {
                this.subscribe();
            } else {
                this.unsubscribe();
            }
        }, {name: 'Listen for location changes reaction', fireImmediately: true});
    }

    async subscribe() {
        if (this.subscribed) {
            return;
        }
        const service = BroadcastService.sharedInstance(this.settings);
        const channelName = service.channelPrefix() + '.locations';
        this.subscribed = channelName;

        this.echo = await service.connect();

        const channel = this.echo.channel(channelName);
        channel.listen('LocationChanged', this.onLocationChanged.bind(this));
        channel.listen('EVSEBecameAvailable', this.onEVSEBecameAvailable.bind(this));
        channel.listen('EVSEBecameUnavailable', this.onEVSEBecameUnavailable.bind(this));

        console.log('Echo: Subscribed to ' + channelName);
    }

    unsubscribe() {
        if (!this.subscribed) {
            return;
        }
        this.echo && this.echo.leave(this.subscribed);
        this.echo && console.log('Echo: Unsubscribed from ' + this.subscribed);
        this.subscribed = undefined;
    }

    private onLocationChanged = (payload: any) => {
        const location_id = payload.location_id;
        if (!PinsStore.sharedInstance().filteredPins.some((pin) => pin.id === location_id)) {
            return;
        }
        LocationObject.fetch(location_id, false, true);
        //PinsStore.sharedInstance().reloadPinFromBackend(payload.location_id); see sc-74270
    };

    private onEVSEBecameAvailable = (payload: any) => {
        this.switchAvailability(payload.location_id, payload.evse_id, true, payload.reservation_id)
    };
    private onEVSEBecameUnavailable = (payload: any) => {
        this.switchAvailability(payload.location_id, payload.evse_id, false, payload.reservation_id);
    };

    private switchAvailability = (locationId: string, evseId: string, isAvailable: boolean, reservationId: string | null) => {
        const locationStore = LocationStore.sharedInstance();

        const location = locationStore.getLocationByEVSEId(evseId, 'id');

        if (location === undefined) {
            return;
        }

        const evse = locationStore.getEVSE(location, evseId, ['id']);

        if (evse !== undefined) {
            evse.isAvailable = isAvailable;
            evse.reservationId = reservationId;
        }
    }
}

export default new WebsocketMonitor();
