import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {Content} from '@ampeco/charge-screens';
import InfoBoxAddressComponent from '../../components/Map/InfoBoxAddressComponent';
import {LocationStore} from '@ampeco/charge-stores';
import {LocationObject} from '@ampeco/charge-models';
import {BasePage} from "../BasePage";
import {useRouteMatch} from "react-router";
import NavigationService from "@ampeco/navigation";

const LocationWrapper = () => {
    const match = useRouteMatch<{locationId: string}>();
    const locationStore = LocationStore.sharedInstance();
    useEffect(() => {

        if (match?.params?.locationId) {
            setLocation(match.params.locationId);
        }
    }, []);
    const setLocation = (locationId: string) => {

        if (!locationStore.focusedLocationId) {
            locationStore.focusedLocationId = locationId;
            LocationObject.fetch(locationId);
        }
    };

    return (
        <BasePage title={'web-langs.connector-details'} onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home');
        }}>
            <div>
                <div style={{marginBottom: 20, marginTop: 20}}>
                    <InfoBoxAddressComponent
                        location={locationStore.focusedLocation}
                        showDetails
                        locate
                    />
                </div>
                {locationStore.focusedLocation && <Content/>}
            </div>
        </BasePage>
    );
};

LocationWrapper.displayName = 'LocationWrapper';

export default observer(LocationWrapper);
