import React from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';
import Notice from '@ampeco/notice';
import {StandaloneIcon} from '@ampeco/icons';
import {__} from '@ampeco/i18n';
import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import PaymentsStore from '../../PaymentsStore';
import {observer} from 'mobx-react';

interface Props {
    style?: ViewStyle;
    navigation?: any;
}

@observer
class PaymentFailureNotice extends React.Component<Props> {

    render() {
        const store = PaymentsStore.sharedInstance();
        const shouldShow = store.shouldShowPaymentFailureNotice;
        const error = store.lastPaymentFailure;
        const title = store.paymentFailureTitle;
        return shouldShow && <ThemeConsumer<AmpecoTheme>>{({theme}) => {
            return <Notice
                style={{
                    ...styles.container,
                    backgroundColor: theme.PaymentMethods.failNoticeBackgroundColor,
                    ...this.props.style || {},
                }}>
                <StandaloneIcon source={theme.PaymentMethods.failNoticeIcon}
                    style={[styles.icon, theme.PaymentMethods.failNoticeIconStyle, {tintColor: theme.PaymentMethods.failNoticeBoldText.color}]} />
                <View style={styles.wrapper}>
                    <Text style={[theme.PaymentMethods.failNoticeBoldText, styles.boldText]}>
                        {title ? title : __('payment-failure-notice.payment-failed')}
                    </Text>
                    <Text style={[theme.PaymentMethods.failNoticeRegularText, styles.text]}>
                        {error}
                    </Text>
                </View>
            </Notice>;
        }}</ThemeConsumer> || <></>;
    }
}

export default PaymentFailureNotice;

// NOTE: isFocused prop is not used and is deprecated
const PaymentFailureNoticeOutsideNavigation = (props: {isFocused: boolean;} & Props) => {
    return <PaymentFailureNotice navigation={undefined} {...props} />;
};

export {PaymentFailureNoticeOutsideNavigation};

const styles = StyleSheet.create({
    container: {
        height: 'auto',
        borderRadius: 5,
    },
    wrapper: {
        paddingBottom: 10,
        paddingTop: 10,
        marginLeft: 2 * 8 + 22,
    },
    icon: {
        // marginLeft: 8, marginRight: 8,
        position: 'absolute',
        left: 8,
    },
    text: {
        fontSize: 14,
    },
    boldText: {
        fontSize: 14,
    },
});
