import {View} from "react-native";
import {Button, ThemeConsumer} from "react-native-elements";
import {__} from "@ampeco/i18n";
import {PaymentsStore} from '@ampeco/payment-methods';
import React from "react";


export default function () {
  return (<ThemeConsumer>{({theme}) => (
    <>
      <View testID="braintree-web-id"></View>
      {PaymentsStore.sharedInstance().shouldShowBraintreeAddBtn ? <Button
        title={__('payment_methods.add')}
        onPress={async () => {
          await PaymentsStore.sharedInstance().finishAddingBraintreePaymentMethod();
        }}
      /> : null}
    </>
  )}</ThemeConsumer>);
}
