import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Action, Error, Field, FormStore, FormStoreProvider, PasswordRevealInput} from '@ampeco/forms';
import {Button, Input, ThemeProps, withTheme} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {ButtonIcon, StandaloneIcon, TextButton} from '@ampeco/icons';
import {OAuthAPI} from '@ampeco/auth';
import AmpecoTheme from '@ampeco/theme';

const store = new FormStore();

store.rules.username = 'required|email|max:255';
store.rules.password = 'required|min:6|max:255';

interface Props {
    onLogin?: () => Promise<void>;
    onNavigate?: () => void;
    onForgotten?: (email?: string) => void;
    onRegister?: () => void;
    clearStore?: (callback: () => void) => void;
    style?: ViewStyle;
}

class Login extends React.Component<Props & ThemeProps<AmpecoTheme>> {
    componentDidMount(): void {
        this.props.clearStore && this.props.clearStore(store.reset);
    }

    render() {
        const onNavigate = () => {
            store.reset();

            if (this.props.onNavigate) {
                this.props.onNavigate();
            }
        };

        store.actions.login = async () => {
            await OAuthAPI.login(store.form.username as string, store.form.password as string);
            this.props.onLogin && await this.props.onLogin();
            store.reset();
        };

        store.httpErrors[401] = __('message.error.login-failed');

        return (
            <View style={[styles.formBox, this.props.theme.Container.byPadding, {flexDirection: 'column'}, this.props.style]}>
                <View>
                    <FormStoreProvider store={store}>
                        <Field
                            testID="username"
                            type={Input}
                            name="username"
                            placeholder={__('login.username')}
                            leftIcon={<StandaloneIcon source={this.props.theme.Login.images.emailSmall} style={this.props.theme.Login.iconsStyle} />}
                            leftIconContainerStyle={{marginLeft: 0, marginRight: 10, padding: 0}}
                            autoCapitalize="none"
                            autoCompleteType="username"
                            textContentType="username"
                            keyboardType="email-address"
                            // autoFocus={true}
                            style={{margin: 0, textAlign: 'left'}}
                        />
                        <Field
                            testID="password"
                            type={PasswordRevealInput}
                            name="password"
                            placeholder={__('login.password')}
                            leftIcon={<StandaloneIcon source={this.props.theme.Login.images.passwordSmall} style={this.props.theme.Login.iconsStyle} />}
                            leftIconContainerStyle={{marginLeft: 0, marginRight: 10, padding: 0}}
                            textContentType="password"
                            style={{margin: 0, textAlign: 'left'}}
                        />

                        <Error/>

                        <Action
                            type={Button}
                            icon={<ButtonIcon source={this.props.theme.Login.images.login}
                                              style={{width: 21, height: 21}}/>}
                            disableIfInvalid
                            validateOnPress
                            name="login"
                            title={__('login.button-login')}
                            style={styles.loginButton}
                        />
                    </FormStoreProvider>
                </View>

                <View style={styles.registerBox}>
                    <TextButton
                        style={this.props.theme.Login.textButtons}
                        onPress={() => {
                            this.props.onForgotten && this.props.onForgotten(store.form.username as string);
                            onNavigate();
                        }}>
                        {__('login.button-forgotten')}
                    </TextButton>
                    <TextButton
                        style={this.props.theme.Login.textButtons}
                        onPress={() => {
                            this.props.onRegister && this.props.onRegister();
                            onNavigate();
                        }}>
                        {__('login.button-register')}
                    </TextButton>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    registerBox: {
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        maxHeight: 50,
    },
    formBox: {
        flex: 0,
        width: '100%',
        justifyContent: 'center',
    },
    loginButton: {
        alignSelf: 'stretch',
    },
});

export default withTheme<Props, AmpecoTheme>(Login);
