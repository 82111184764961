import net from '@ampeco/net';

export async function consent(versionId: string): Promise<boolean> {
    try {
        await net.post(`app/terms/consent/${versionId}`);
        return true;
    } catch (e) {
        return false;
    }
}

export async function load() {
    const res = await net.get('app/terms');

    return res.data;
}
