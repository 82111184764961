import net from '@ampeco/net';
import {action, autorun, computed, observable} from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {setLocale} from './helpers';
import {I18N, I18NSettings, TranslationPack} from './i18n';
import {I18nManager, NativeModules, Platform} from 'react-native';
import RNRestart from 'react-native-restart';
import {
    DateFormats,
    DateFormatTypes,
    NumberSeparatorTypes,
    TimeFormats,
    TimeFormatTypes,
    UnitFormat,
    UserLocalizationSettings,
} from '@ampeco/charge-models/UserLocalizationSettings';

export const storageKeys = {
    language: '@Language',
    forceCachedLocale: '@forceCachedLocale',
};

export interface LocaleStoreConfiguration {
    translations: TranslationPack;
    i18n: I18NSettings;
    onSetLocale: (newLocale: string) => void;
}

const DEFAULT_DECIMALS = 2;
export const DEFAULT_DATE_FORMAT = DateFormats[DateFormatTypes.DATE_FORMAT_Y_M_D_DASH].toUpperCase();
export const DEFAULT_TIME_FORMAT = TimeFormats[TimeFormatTypes.TIME_FORMAT_AM_PM];
export const DEFAULT_UNIT_FORMAT = UnitFormat.UNIT_FORMAT_METRIC;

export default class LocaleStore {

    private static instance: LocaleStore;

    private static initialized = false;

    public availableLanguages: { [index: string]: string };

    public onSetLocale?: (newLocale: string) => void;

    @observable enabledLanguages: string[] | null = null;
    @observable language: string = 'en';

    @observable customLocaleCode: string | null = null;
    @observable localizationSettings: UserLocalizationSettings = {
        decimals: DEFAULT_DECIMALS,
        decimal_separator: NumberSeparatorTypes.period,
        thousands_separator: NumberSeparatorTypes.space,
        date_format: null,
        default_time_format: null,
        default_units: null,
    };

    /**
     * Changes language
     *
     * @param {string} language
     * @memberof LocaleStore
     */
    setLanguage(language: string) {
        this.language = language;
    }

    setEnabledLanguages(languages: string[] | null) {
        this.enabledLanguages = languages;
    }

    set defaultLocale(defaultLocale: string) {
        I18N.setDefaultLocale(defaultLocale);
    }

    get defaultLocale(): string {
        return I18N.getDefaultLocale();
    }

    /**
     * Available languages for selection. Observable
     *
     * @readonly
     * @memberof LocaleStore
     */
    @computed get languages() {
        return this.filterEnabled(this.enabledLanguages, this.availableLanguages);
    }

    @computed get hasMoreThanOneLanguage() {
        return this.enabledLanguages && this.enabledLanguages.length > 1;
    }

    @computed get displayTimeFormat() {
        return this.localizationSettings.default_time_format ? TimeFormats[this.localizationSettings.default_time_format] : DEFAULT_TIME_FORMAT;
    }

    @computed get displayUnit() {
        return this.localizationSettings.default_units ?? DEFAULT_UNIT_FORMAT;
    }

    @computed get displayDateFormat() {
        return this.localizationSettings.date_format ? DateFormats[this.localizationSettings.date_format].toUpperCase() : DEFAULT_DATE_FORMAT;
    }

    /**
     * Currently selected language. Observable.
     *
     * @readonly
     * @static
     * @type {string}
     * @memberof LocaleStore
     */
    @computed static get language(): string {
        return LocaleStore.sharedInstance().language;
    }

    @computed static get languages(): { [index: string]: any } {
        return LocaleStore.sharedInstance().languages;
    }

    static sharedInstance(): LocaleStore {
        if (!LocaleStore.instance || !LocaleStore.initialized) {
            throw new Error('Using LocaleStore without initialization');
        }
        return this.instance;
    }

    static init(configuration: LocaleStoreConfiguration) {
        console.log('Initializing LocaleStore');
        I18N.init(configuration.translations, configuration.i18n);
        LocaleStore.instance = new LocaleStore(configuration.onSetLocale, configuration.i18n.available);
        LocaleStore.initialized = true;
    }

    static observeLanguage() {
        // tslint:disable-next-line: no-unused-expression
        LocaleStore.sharedInstance().language;
    }

    // @computed

    constructor(onSetLocale: (newLocale: string) => void, availableLanguages: { [index: string]: string }) {
        this.onSetLocale = onSetLocale;
        this.availableLanguages = availableLanguages;
        AsyncStorage.getItem(storageKeys.language).then((lang) => {
            if (lang !== null) {
                this.language = lang;
            }
        });

        autorun(async () => {
            if (this.language && Object.keys(this.languages).includes(this.language)) {
                let locale = this.language;
                if (this.customLocaleCode) {
                    const tenantSlang = this.language + '-x-' + this.customLocaleCode;
                    if (this.availableLanguages[tenantSlang] !== undefined) {
                        locale = tenantSlang;
                    }
                }

                if (this.onSetLocale) {
                    this.onSetLocale(this.language);
                }

                console.log('Setting locale to ' + locale);
                setLocale(locale);
                net.setAcceptLanguage(this.language);

                await AsyncStorage.setItem(storageKeys.language, this.language);

                const localeRTL = I18N.isRTL(this.language);

                if (localeRTL !== I18nManager.isRTL) {
                    // TODO: Polyfill this for web to do window.reload()
                    I18nManager.forceRTL(localeRTL);
                    I18nManager.allowRTL(false);
                    if (Platform.OS !== 'web') {
                        setTimeout(() => RNRestart.Restart(), 200);
                    }
                }
            }
        }, { name: 'Language setup' });
    }

    // TODO: extract or extend array
    private filterEnabled(enabled: any[] | null, available: { [index: string]: any }) {
        if (enabled === null) {
            return available;
        }
        return Object.keys(available)
            .filter(key => enabled.indexOf(key) !== -1)
            .reduce((obj: { [index: string]: string }, key) => {
                obj[key] = available[key];
                return obj;
            }, {});
    }

    @action.bound
    public static async setDesiredLanguage(userDesire?: string) {
        const forceCachedLocale = await AsyncStorage.getItem(storageKeys.forceCachedLocale);
        const desired = forceCachedLocale ? await AsyncStorage.getItem(storageKeys.language) : LocaleStore.evaluateDesiredLocale(userDesire);
        if (desired) {
            if (LocaleStore.language !== desired) {
                LocaleStore.sharedInstance().setLanguage(desired);
            }
        } else {
            LocaleStore.sharedInstance().setLanguage(LocaleStore.sharedInstance().defaultLocale);
        }
        await AsyncStorage.removeItem(storageKeys.forceCachedLocale);
    }

    private static evaluateDesiredLocale(userDesire?: string) {
        const locale = userDesire || LocaleStore.sharedInstance().defaultLocale;

        if (locale && Object.keys(LocaleStore.languages).includes(locale)) {
            return locale;
        }

        return null;
    }

    public static isRTL(locale: string): boolean {
        return locale.indexOf('he') === 0 || locale.indexOf('ar') === 0;
    }

    public static deviceLocale(): string|null {
        const deviceLocale = Platform.OS === 'ios'
            ? NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0] // iOS 13
            : NativeModules.I18nManager.localeIdentifier;

        return typeof deviceLocale === 'string' ? deviceLocale : null;
    }
}
