import net from '@ampeco/net';
import {HelpCenter} from '../models/HelpCenter';
import {FAQ} from '../models/FAQ';

export async function getHelpCenter(): Promise<HelpCenter> {
    return net.getOrWarn('/app/content/help_center');
}

export async function getFaqs(): Promise<FAQ[]> {
    return (await net.getOrWarn('/app/content/faq')).faqs;
}
