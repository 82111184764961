import net from '@ampeco/net';
import {
    SubscriptionPlan,
    SubscriptionBillingHistory,
    SubscriptionSessionHistory,
    UserSubscription,
} from '@ampeco/charge-models';

export async function getList(): Promise<SubscriptionPlan[]> {
    return (await net.get('/app/subscription_plans')).data as SubscriptionPlan[];
}

export interface SubscriptionActivatePayload {
    plan: number|string;
    paymentMethod?: number|string|null;
}

export async function activate(payload: SubscriptionActivatePayload): Promise<boolean> {
    return (await net.post('/app/subscriptions/activate', payload)) as boolean;
}

export async function cancel(): Promise<boolean> {
    return (await net.patch('/app/subscriptions/cancel', [])) as boolean;
}

export function canUseChargingAllowance(evseId: string): Promise<boolean> {
    return net.get(`/app/can-use-charging-allowance/${evseId}`)
        .then(({ can_use }) => can_use);
}

export async function billingHistory(): Promise<SubscriptionBillingHistory[]> {
    return (await net.get('/app/subscriptions/billing-history')).data as SubscriptionBillingHistory[];
}

export async function sessionHistory(): Promise<SubscriptionSessionHistory[]> {
    return (await net.get('/app/subscriptions/session-history')).data as SubscriptionSessionHistory[];
}

export async function pay(paymentMethodId: string): Promise<UserSubscription> {
    return (await net.post('/app/subscriptions/pay', {paymentMethodId})).data as UserSubscription;
}

export async function hasObligationsMessage(): Promise<string|null> {
    return (await net.get('/app/subscriptions/obligations-message')) as string|null;
}

export function makeObligationsMessageSeen(): Promise<boolean> {
    return (net.patch('/app/subscriptions/obligations-message-seen', []));
}
