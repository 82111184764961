import Navigation from '@ampeco/navigation';
import {Platform} from 'react-native';
import {FlowStore} from '@ampeco/charge-stores';

type Step = 'Register' | 'AddPartner' | 'ContactDetails' | 'FirstPaymentMethod' | 'SubscriptionPlansStep';

export default abstract class AbstractFlow {
    protected readonly steps: Step[];
    protected index = 0;

    constructor() {
        this.steps = [];
        this.collectSteps();
    }

    public get totalSteps() {
        return this.steps.length;
    }

    public get current(): Step|null {
        if (this.totalSteps <= 0) {
            return null;
        }
        return this.steps[this.index];
    }

    public get activeStep() {
        return this.index + 1;
    }

    public goToNext() {
        if (this.totalSteps <= this.index + 1) {
            return null;
        }
        this.index += 1;
        return this.current;
    }

    public async navigateToNext() {
        const next = this.goToNext();
        if (this.totalSteps > 0 && Platform.OS !== 'web') {
            await Navigation.sharedInstance().goBack();
        }

        if (next) {
            if (Platform.OS !== 'web') {
                Navigation.sharedInstance().navigate('HomeModal', {screen: next, params: {steps: [this.activeStep, this.totalSteps], flow: this}});
            } else {
                Navigation.sharedInstance().navigate(next);
            }

        }

        if (!next) {
            FlowStore.isFlowClosed = true;
        }
    }

    public navigate(routeName?: string) {
        const start = this.current;
        if (!start) {
            return;
        }

        if (routeName) {
            Navigation.sharedInstance().navigate(routeName, {screen: start, params: {steps: [this.activeStep, this.totalSteps], flow: this}});
        } else {
            Navigation.sharedInstance().navigate('HomeModal', {screen: start, params: {steps: [this.activeStep, this.totalSteps], flow: this}});
        }
    }

    protected abstract collectSteps(): void;
}
