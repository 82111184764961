import React from 'react';
import {StandaloneIcon} from '@ampeco/icons';
import {__} from '@ampeco/i18n';
import {Text, ThemeConsumer} from 'react-native-elements';
import {View} from 'react-native';
import AmpecoTheme from '@ampeco/theme';

interface Props {
    color?: string;
}

interface State {}

export default class ImageFailLoaderComponent extends React.PureComponent<Props, State> {
    state = {};

    render() {
        const {color} = this.props;
        return <ThemeConsumer<AmpecoTheme>>{({theme}) => (
            <View>
                <StandaloneIcon source={theme.Components.FailImage.icon} color={color} vertical/>
                <Text>{__('image.failed-image')}</Text>
            </View>
        )}
        </ThemeConsumer>
    }
}
