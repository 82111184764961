import {action, observable, runInAction} from 'mobx';
import {SessionStat} from '@ampeco/charge-models';
import {bugsnag} from '@ampeco/logging';
import {Profile} from '@ampeco/charge-api';
import SessionHistoryStore from './SessionHistoryStore';
import moment from 'moment';
import {LocaleStore} from '@ampeco/i18n';
import {DEFAULT_DATE_FORMAT} from '@ampeco/i18n';

export default class SessionStatsStore {
    private static instance: SessionStatsStore;
    @observable stat?: SessionStat;
    @observable isShowEnergyEnabled: boolean = true;
    @observable isShowPowerEnabled: boolean = true;
    @observable isShowDurationEnabled: boolean = true;
    @observable isShowAmountEnabled: boolean = true;

    static sharedInstance(): SessionStatsStore {
        if (SessionStatsStore.instance === undefined) {
            SessionStatsStore.instance = new SessionStatsStore();
        }
        return SessionStatsStore.instance;
    }

    @action.bound
    async load() {
        this.stat = undefined;
        try {
            const historyStore = SessionHistoryStore.sharedInstance();
            const stat = await Profile.sessionStats(0,
                historyStore.customPeriodStart ? moment(historyStore.customPeriodStart, LocaleStore.sharedInstance().displayDateFormat).format(DEFAULT_DATE_FORMAT) : undefined,
                historyStore.customPeriodEnd ? moment(historyStore.customPeriodEnd, LocaleStore.sharedInstance().displayDateFormat).format(DEFAULT_DATE_FORMAT) : undefined,
            );
            runInAction(() => {
                this.stat = stat
            });
        } catch (error: any) {
            if (!['ECONNABORTED', 401].includes(error?.code) || error instanceof Error) {
                bugsnag.notify(error, 'Failed Loading Session Stats');
            }
        }
    }

    @action.bound
    setSessionChargingStatsDefaults(sessionChargingStatsDefaults?: { isShowEnergyEnabled: boolean; isShowPowerEnabled: boolean; isShowDurationEnabled: boolean; isShowAmountEnabled: boolean; }) {
        this.isShowEnergyEnabled = sessionChargingStatsDefaults?.isShowEnergyEnabled !== false;
        this.isShowPowerEnabled = sessionChargingStatsDefaults?.isShowPowerEnabled !== false;
        this.isShowDurationEnabled = sessionChargingStatsDefaults?.isShowDurationEnabled !== false;
        this.isShowAmountEnabled = sessionChargingStatsDefaults?.isShowAmountEnabled !== false;
    }
}
