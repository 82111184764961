import React, {useCallback} from 'react';
import {View} from 'react-native';
import {Term} from '@ampeco/terms';
import {Disclosure} from '@ampeco/charge-screens';
import Navigation from '@ampeco/navigation';
import {Field} from '@ampeco/forms';
import {CheckBox} from 'react-native-elements';

interface Props {
    terms: Term[];
    language: string;
}

export default function ConsentCheckboxes({terms, language}: Props) {
    async function onPressCallback(term: Term) {
        if (!term.urls) {
            return undefined;
        }

        await Navigation.sharedInstance().navigate('Terms', {
                title: term.title,
                url: term.urls[language],
            },
        );
    }

    return <>{terms.map((term) => {
        return <View key={term.title}>
            {term.title && <Disclosure
              text={term.title}
              onPress={useCallback(() => !term.urls ? undefined : onPressCallback(term), [])}
            />}

            <Field
                type={CheckBox}
                title={term.consent_request}
                name={'accept ' + term.title}
            />
        </View>;
    })}</>
}
