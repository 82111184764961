import React, {Component} from 'react';
import {ActivityIndicator, View, ViewStyle, StyleProp, StyleSheet} from 'react-native';
import Select from '@ampeco/select';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import {Text, ThemeConsumer} from 'react-native-elements';
import DevEndpointStore from './DevEndpointStore';

interface Props {
    style?: StyleProp<ViewStyle>;
}

@observer
export default class EndpointSelect extends Component<Props> {
    componentDidMount(): void {
        const devEntrypoint = DevEndpointStore.sharedInstance();
        if (devEntrypoint.deploys === null) {
            devEntrypoint.loadDeploysFromBackend()
                .then(() => {});
        }
    }

    render() {
        const devEntrypoint = DevEndpointStore.sharedInstance();
        const deploys = devEntrypoint.deploys;
        const endpoints = devEntrypoint.endpoints;
        const deployNames = devEntrypoint.deploysName;
        const selectedDeployName = devEntrypoint.deployName || '';
        const selectedDeploy = devEntrypoint.deploy;
        const selectedEndpointName = devEntrypoint.endpointName;
        return <ThemeConsumer<any>>{({theme}) => {
            return <View style={[styles.container, this.props.style]} >
                <Text style={theme.Settings.label}>Server:</Text>
                <View style={{alignItems: 'flex-end'}}>
                    {deploys === null && <ActivityIndicator/>}
                    {deploys !== null &&
                    <Select options={deployNames} selected={selectedDeployName}
                            onChange={this.onDeployChange}/>}
                    {deploys !== null && selectedDeploy !== null &&
                    <Select options={Object.keys(endpoints || {})}
                            selected={selectedEndpointName || ''}
                            onChange={this.onEndpointChange}/>}
                </View>
            </View>;
        }}</ThemeConsumer>;
    }

    @action.bound
    onDeployChange(item: string) {
        const devEntrypoint = DevEndpointStore.sharedInstance();

        if (devEntrypoint.deploys === null) {
            return;
        }

        const itemIndex = devEntrypoint.deploysName.indexOf(item);
        if (itemIndex === -1) {
            return;
        }

        devEntrypoint.endpoints = {};
        devEntrypoint.endpointName = null;
        devEntrypoint.endpoint = null;
        devEntrypoint.deploy = devEntrypoint.deploys[itemIndex];

    }

    onEndpointChange(item: string) {
        const devEntrypoint = DevEndpointStore.sharedInstance();
        devEntrypoint.endpointName = item;
        devEntrypoint.endpoint = devEntrypoint.endpoints ? devEntrypoint.endpoints[item] : null;
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: 0,
        marginTop: 10,
    },
});
