import {action, computed, observable, reaction} from 'mobx';
import {EVSE, Location, LocationObject, PricePeriod, Tariff} from '@ampeco/charge-models';
import Currency from '@ampeco/currency';
import {CacheStore} from '@ampeco/cache';
import {Location as LocationAPI} from '@ampeco/charge-api';
import {getPricePeriod} from '@ampeco/charge-api/locations';
import moment from 'moment';
import {evseMatches} from './util';
import {DEFAULT_DATE_FORMAT} from '@ampeco/i18n';

let instance: LocationStore;

/**
 * The locations are now stored in the CacheStore.getInstance(LocationObject)
 */
export default class LocationStore {

    @observable focusedLocationId?: string;
    @observable openedPinLocation: boolean = false;
    @observable currencies: Currency[] = [];
    @observable tariffs: Tariff[] = [];
    @observable pricePeriods?: PricePeriod[] = undefined;

    @computed get focusedLocation() {
        if (this.focusedLocationId !== undefined && this.focusedLocationId !== '-1') {
            const location = LocationObject.where(this.focusedLocationId);
            if (!location) {
                LocationObject.fetch(this.focusedLocationId);
            }
            return location;
        }
    }

    @computed get nonEmptyPricePeriods() {
        return this.pricePeriods?.filter(period => period.connectionFee || period.idle?.fee || period.duration?.fee || period.energyPerKwh);
    }

    static sharedInstance(): LocationStore {
        if (instance === undefined) {
            instance = new LocationStore();
        }
        return instance;
    }

    getCurrencyByCode(code: string | null): Currency | null {
        if (!code) {
            return null;
        }

        return this.currencies.find((currency: Currency) => currency.code === code) || null;
    }

    getTariffById(id: string | null| undefined): Tariff | null {
        if (!id) {
            return null;
        }

        return this.tariffs.find((tariff: Tariff) => tariff.id === id) || null;
    }

    tariffIsPaid(tariff: Tariff) {
        if (['free', 'charging not allowed'].includes(tariff.priceType)) {
            return false;
        }
        if (tariff.priceType === 'flat rate') {
            return tariff.priceForSession !== null && tariff.priceForSession > 0;
        }
        if (tariff.priceType === 'duration+energy') {
            return (tariff.priceForEnergy !== null && tariff.priceForEnergy > 0)
                || (tariff.priceForDuration !== null && tariff.priceForDuration > 0);
        }
        return true;
    }

    tariffAllowsCharging(tariff: Tariff): boolean {
        return tariff.priceType !== 'charging not allowed';
    }

    getLocationByEVSEId(evse_id: string, keys = ['identifier', 'emi3Identifier']): Location | undefined {
        const found = Object.entries(CacheStore.getInstance(LocationObject).cache).find(([_, location]) => {
            if (location.id === -1) {
                return false;
            }
            return location.zones.find(zone => zone.evses.find((evse) => evseMatches(evse, evse_id, keys)));
        });
        if (found) {
            return found[1];
        }
        return undefined;
    }

    getEVSE(location: Location, evse_id: string, keys = ['identifier', 'emi3Identifier']): EVSE | undefined {
        return location.zones.reduce<EVSE | undefined>(
            (previousFound, zone) => previousFound || zone.evses.find((evse) => evseMatches(evse, evse_id, keys)), undefined,
        );
    }

    protected async getLocationFromBackendByEvseIdentifier(evse_id: string) {
        const {location, evseIdentifier, tariffs, currencies} = await LocationAPI.byEVSEIdentifier(evse_id);

        currencies.map(this.updateCurrency);
        tariffs.map(this.updateTariff);

        if (!location) {
            throw new Error('Location Not found');
        }

        this.updateLocation(location);
        const evse = this.getEVSE(location, evseIdentifier || evse_id, ['identifier', 'roamingEvseId', 'emi3Identifier']);

        if (evse === undefined) {
            throw new Error('EVSE Not found');
        }

        return {location, evse, tariff: this.getTariffById(evse.tariffId)};
    }

    async resolveEVSE(evse_id: string): Promise<{location: Location, evse: EVSE | undefined; tariff: Tariff | null}> {
        const location = this.getLocationByEVSEId(evse_id);

        if (location !== undefined) {
            const evse = this.getEVSE(location, evse_id);
            return {location, evse, tariff: this.getTariffById(evse?.tariffId)};
        } else {
            return this.getLocationFromBackendByEvseIdentifier(evse_id);
        }
    }

    getTariffIndex(tariff_id: string): number {
        return this.tariffs.findIndex((tariff, index, list) => tariff.id === tariff_id);
    }

    getCurrencyIndex(code: string): number {
        return this.currencies.findIndex((currency, index, list) => currency.code === code);
    }

    public updateLocation(location: Location) {
        CacheStore.getInstance(LocationObject).add(location.id + '', location, Date.now())
    }

    public clearCachedData() {
        CacheStore.getInstance(LocationObject).clearCachedData();
    }

    @action.bound
    updateTariff(tariff: Tariff) {
        const index = this.getTariffIndex(tariff.id);
        if (index === -1) {
            this.tariffs.push(tariff);
        } else {
            this.tariffs[index] = tariff;
        }
    }
    @action.bound
    updateCurrency(currency: Currency) {
        const index = this.getCurrencyIndex(currency.code);
        if (index === -1) {
            this.currencies.push(currency);
        } else {
            this.currencies[index] = currency;
        }
    }

    @action.bound
    reset() {
        this.tariffs = [];
        this.currencies = [];
        this.focusedLocationId = undefined;
        this.clearCachedData();
    }

    @action.bound
    async loadPricePeriod(tariffId: string, date?: string | null): Promise<void> {
        if (!date) {
            date = moment().format(DEFAULT_DATE_FORMAT);
        }
        getPricePeriod(tariffId, date).then((pricePeriod: PricePeriod[]) => {
            this.pricePeriods = pricePeriod;
        }).catch((err) => {
        });
    }

    async getPricePeriods(tariffId: string, date?: string | null) {
        if (!date) {
            date = moment().format(DEFAULT_DATE_FORMAT);
        }

        return getPricePeriod(tariffId, date);
    }
}
