import AbstractFlow from './AbstractFlow';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {ProfileFieldsStore} from '@ampeco/profile-fields';

export default class Flow extends AbstractFlow {
    protected collectSteps(): void {
        //
    }

    protected static requiresPaymentMethodCallback: () => boolean;
    protected static requiresSubscriptionCallback: () => boolean;

    public static setRequiresPaymentMethodCallback(callback: () => boolean) {
        Flow.requiresPaymentMethodCallback = callback;
    }

    public static setRequiresSubscriptionCallback(callback: () => boolean) {
        Flow.requiresSubscriptionCallback = callback;
    }

    public static get requiresPaymentMethod(): boolean {
        if (!Flow.requiresPaymentMethodCallback) {
            return false;
        }

        return Flow.requiresPaymentMethodCallback();
    }

    public static get isAccountCompleted() {
        return ProfileFieldsStore.sharedInstance().isAccountCompleted;
    }

    public static get profileComplete() {
        const globalStore = GlobalStoreProxy.sharedInstance();
        if (!globalStore.profile) {
            return false;
        }

        return ProfileFieldsStore.sharedInstance().isProfileComplete(globalStore.profile);
    }

    public static get isPartnerRequired() {
        return ProfileFieldsStore.sharedInstance().isPartnerRequired();
    }

    public static get requiresSubscription(): boolean {
        if (!Flow.requiresSubscriptionCallback) {
            return false;
        }

        return Flow.requiresSubscriptionCallback();
    }

    public static get canAddPartner(): boolean {
        return ProfileFieldsStore.sharedInstance().canAddPartner();
    }

}
