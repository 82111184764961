import {BalanceStore} from '@ampeco/balance';
import Currency from '@ampeco/currency';
import {__} from '@ampeco/i18n';
import Select from '@ampeco/select';
import AmpecoTheme from '@ampeco/theme';
import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {ThemeProps, withTheme} from 'react-native-elements';
import {PaymentMethod} from '@ampeco/models';
import PaymentsStore from './PaymentsStore';

interface Props {
  addPaymentMethodNavigation?: (screen: string, params: any) => void;
  currency?: Currency;
  amount?: number; // Assign only when you want to
  paymentAddedCallback?: () => void;
  beforeAddingCallback?: () => void;
}

@observer
class PaymentMethodSelect extends Component<ThemeProps<AmpecoTheme> & Props> {
  balanceOptionIsAvailable() {
    const store = PaymentsStore.sharedInstance();
    if (!store.canUseServiceWithoutCard()) {
      return false;
    }

    if (this.props.amount) {
      // we want to pay for a competed session
      return !store.payments || store.payments.methods.length === 0;
    } else if (this.props.currency) {
      // we want to start a new session
      return store.canUseServiceWithoutCard();
    }
    return !store.payments || store.payments.methods.length === 0;
  }

  render() {
    const store = PaymentsStore.sharedInstance();
    const {addPaymentMethodNavigation} = this.props;

    const options: Array<{type: string; id?: string}> = [];
    const stringOptions: string[] = [];

    if (store.payments) {
      store.payments.methods.map(pm => {
        if (store.allowPaymentMethod || pm.type === 'corporate') {
          options.push({
            type: pm.type,
            id: pm.id,
          });
          stringOptions.push(pm.name);
        }
      });
    }

    // add balance if necessary
    if (this.balanceOptionIsAvailable()) {
      options.push({
        type: 'balance',
      });

      stringOptions.push(__('addPaymentMethod.balance').replace(':amount', BalanceStore.sharedInstance().formattedWalletBalance));
    }

    // add "Add new" button if necessary
    if (addPaymentMethodNavigation && store.paymentsEnabled && PaymentsStore.sharedInstance().canAddPaymentMethod() && store.allowPaymentMethod) {
      options.push({
        type: 'new',
      });
      stringOptions.push(__('addPaymentMethod.shortcut'));
    }

    const selected = stringOptions.find(option => option === store.lastPaymentMethod?.name);

    return <Select options={stringOptions}
                   selected={selected}
                   itemHeight={50}
                   onChange={(pmName: string, index: number) => {
                     if (!store.payments) {
                       return;
                     }

                     let selectedOption: {type: string; id?: string};
                     if (index === undefined) {
                       return;
                     } else {
                       selectedOption = options[index];
                     }

                     index = parseInt(index + '', 10);

                     if (addPaymentMethodNavigation && options[index].type === 'new') {
                       this.props.beforeAddingCallback && this.props.beforeAddingCallback();
                       store.newPaymentMethod(addPaymentMethodNavigation)
                         .then((paymentMethod?: PaymentMethod) => {
                           paymentMethod && this.props.paymentAddedCallback && this.props.paymentAddedCallback();
                         });
                       return;
                     }

                     if (!selectedOption || !selectedOption.id) {
                       if (selectedOption && selectedOption.type === 'balance') {
                         store.selectPaymentMethod(null);
                       }
                       return;
                     }

                     const selected = store.payments.methods.find(pm => pm.id === selectedOption.id);
                     if (selected) {
                       store.selectPaymentMethod(selected);
                     }
                   }}
                   fullWidth={false}
    />;
  }
}

export default withTheme<Props, AmpecoTheme>(PaymentMethodSelect);
