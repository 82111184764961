import colors from './colors';
import fonts, {FontWeight} from './fonts';

const palette = {
    header: {
        fontFamily: fonts.light,
        fontWeight: FontWeight.light,
        fontSize: 18,
        color: colors.textEmphasisColor,
    },
    text: {
        fontFamily: fonts.regular,
        fontWeight: FontWeight.regular,
        fontSize: 14,
        color: colors.textColor,
    },
    emphasis: {
        fontFamily: fonts.regular,
        fontWeight: FontWeight.regular, fontSize: 14, color: colors.textEmphasisColor,
    },
    label: {
        fontFamily: fonts.regular,
        fontWeight: FontWeight.regular,
        fontSize: 12,
        color: colors.textLabelColor,
    },
    light: {
        fontFamily: fonts.light,
        fontWeight: FontWeight.light,
    },
    bold: {
        fontFamily: fonts.bold,
        fontWeight: FontWeight.bold,
    },
    condensedLight: {
        fontFamily: fonts.condensedLight,
        fontWeight: FontWeight.light,
    },
    condensedBold: {
        fontFamily: fonts.condensedBold,
        fontWeight: FontWeight.bold,
    },
    condensed: {
        fontFamily: fonts.condensed,
        fontWeight: FontWeight.bold,
    },
    regular: {
        fontFamily: fonts.regular,
        fontWeight: FontWeight.regular,
    },
    medium: {
        fontFamily: fonts.medium,
        fontWeight: FontWeight.medium,
    },
    large: {
        fontSize: 18,
    },
    extraLarge: {
        fontSize: 24,
    },
    buttonIcon: {
        tintColor: colors.buttonTextColor,
    },
    standaloneIcon: {
        tintColor: colors.textColor,
    },
    containerMargin: {
        marginLeft: 0,
        marginRight: 0,
    },
    containerPadding: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    containerLayout: {
        paddingTop: 20,
        marginLeft: 25,
        marginRight: 25,
    },
    steps: {
        active: colors.activeStep,
        inactive: colors.inactiveStep,
    },
};

export default palette;
