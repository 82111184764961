import React, {useCallback, useEffect} from 'react';
import {Login} from '@ampeco/auth-screens';
import NavigationService from "@ampeco/navigation";
import GlobalStore from "../../stores/GlobalStore";
import {useHistory} from 'react-router';
import {observer} from 'mobx-react';
import RegisterFlow from "../../services/RegisterFlow";
import {BasePage} from "../BasePage";

const LoginWrapper: React.FC = observer(() => {
    const history = useHistory();
    const globalStore = GlobalStore.sharedInstance();

    useEffect(() => {
        if (globalStore.isLoggedIn) {
            history.push({pathname: '/'});
            history.replace({pathname: '/'});
        }
    }, [globalStore.isLoggedIn]);

    const onRegister = useCallback(async () => {
        globalStore.flow = new RegisterFlow();
        globalStore.flow.navigate('Register');
    },[]);

    return (
        <BasePage title={'login.title'}>
            <Login
                onLogin={useCallback(() => {
                    globalStore.reloadResourcesOnLogin();
                    NavigationService.sharedInstance().navigate('Home');
                }, [])}
                onRegister={onRegister}
                onForgotten={() => {
                    NavigationService.sharedInstance().navigate('Forgotten')
                }}
            />
        </BasePage>
    );
});

export default LoginWrapper;
