import {action, observable} from 'mobx';
import {Term} from './Term';
import ActiveTermStore from './ActiveTermStore';
import {load} from './api';
import AsyncStorage from '@react-native-async-storage/async-storage';

let instance: TermsStore;

export const termsStorageKeys = {
    termsOfUse: '@AcceptedTermsOfUse',
};
export class TermsStore {
    @observable terms: Term[] = [];
    @observable isTOUEnabledForUnregisteredUsers: boolean = false;
    @observable isToUAccepted: boolean = false;

    constructor() {
        AsyncStorage.getItem(termsStorageKeys.termsOfUse).then((key) => {
            if (key !== null) {
                this.isToUAccepted = true;
            }
        });
    }

    @action.bound
    async setIsToUAccepted(enabled: boolean) {
        await AsyncStorage.setItem(termsStorageKeys.termsOfUse, 'true');
        this.isToUAccepted = true;
    }

    @action.bound
    resetToUAccepted() {
        AsyncStorage.removeItem(termsStorageKeys.termsOfUse);
        this.isToUAccepted = false;
    }

    static sharedInstance(): TermsStore {
        if (instance === undefined) {
            instance = new TermsStore();
        }

        return instance;
    }

    async load(): Promise<Term | null> {
        this.terms = await load() ?? [];

        const found = this.terms?.find((term: Term) => term.consent_required);

        if (found && !this.isToUAccepted) {
            ActiveTermStore.sharedInstance().setActiveTerm(found);

            return found;
        }

        return null;
    }
}
