import {TermsStore} from "@ampeco/terms";
import {AuthSettings, EVSE, LocationObject, Profile, Session, Status} from "@ampeco/charge-models";
import {action, observable, computed, autorun, reaction} from "mobx";
import * as Bootstrap from '../api/bootstrap';
import {__, LocaleStore} from "@ampeco/i18n";
import {CacheStore} from "@ampeco/cache";
import {setAuthenticationInfo} from "@ampeco/net";
import {bugsnag} from "@ampeco/logging";
import {Flow} from "@ampeco/charge-flow";
import RequirementsForUsageFlow from "../services/RequirementsForUsageFlow";
import {ProfileFieldsStore} from "@ampeco/profile-fields";
import {FavoritesStore, MenuItemsStore, PinsStore, ProfileStore, SessionSummaryStore} from "@ampeco/charge-stores";
import SessionWebsocketMonitor from "../services/SessionWebsocketMonitor";
import settings from "../settings";
import Navigation from '@ampeco/navigation';
import BroadcastConnectionMonitor from "../services/BroadcastConnectionMonitor";
import {SessionChangesMonitorProxy} from '@ampeco/charge-services';
import {BalanceStore} from '@ampeco/balance';
import {PaymentsStore} from '@ampeco/payment-methods';
import {PaymentMethod} from "@ampeco/models";
import Alert from "@ampeco/alert";
import {InvoiceDetailsStore} from "@ampeco/invoices";
import ApplicationStateService from '@ampeco/appstate-service';
import {getUserSettings} from "@ampeco/charge-api/profile";
import {MIN_PASSWORD_LENGTH} from "../constants";

interface MapCoordinates {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
}

let instance: GlobalStore | null = null;

export default class GlobalStore {
    @observable isMenuOpen = false;
    @observable booting = true;
    @observable profile: Profile | null = null;
    @observable activeSession: Session | null = null;
    @observable finishedSession: Session | null = null;
    @observable isLocationSearchBoxEnabled = false;
    @observable shouldIssueRecipe = false;
    @observable isPoiEnabled = true;
    @observable authSettings: AuthSettings = {
        passwordPolicy: {
            passwordLength: MIN_PASSWORD_LENGTH,
            useCapitalLetters: false,
            useDigits: false,
            useSpecialCharacters: false,
        },
    }

    mapCoordinates: MapCoordinates | null = null;

    static sharedInstance(): GlobalStore {
        if (instance === null) {
            instance = new GlobalStore();
        }
        return instance;
    }

    constructor() {
        autorun(async () => {
            if (this.activeSession) {
                if (CacheStore.getInstance(LocationObject).where(this.activeSession.locationId) === undefined) {
                    CacheStore.getInstance(LocationObject).fetch(this.activeSession.locationId)
                }
            }
        }, {name: 'Load location of active session'});

        autorun(() => {
            if (this.sessionIsRunning) {
                Navigation.sharedInstance().navigate('SessionRunning');
            }
        }, {name: 'Session running'});

        reaction(
            () => ApplicationStateService.sharedInstance().appState === 'active',
            () => {
                if (ApplicationStateService.sharedInstance().appState === 'active') {
                    {
                        if (!this.profile) {
                            return;
                        }

                        try {
                            this.statusUpdate();
                        } catch (e) {
                            if (e.code !== 'ECONNABORTED') {
                                throw e;
                            }
                        }
                        TermsStore.sharedInstance().load();
                        const summaryStore = SessionSummaryStore.sharedInstance();
                        if (summaryStore.session) {
                            summaryStore.loadSessionInfo(summaryStore.session.id);
                        }
                    }
                }
            },
            {name: 'Reload Balance Reaction'},
        );
    }

    async bootstrap(): Promise<any> {
        if (this.booting) {
            const status = await this.statusUpdate();

            this.mapCoordinates = status.region || Object.assign({
                latitudeDelta: 0,
                longitudeDelta: 0,
            }, settings.maps.defaults.coordinates);

            this.profile = status.profile || null;
            if (this.profile) {
                bugsnag.setUser({
                    id: this.profile.id,
                    name: this.profile.firstName + ' ' + this.profile.lastName,
                    email: this.profile.email,
                });
            }
            const locale = this.profile?.locale || localStorage.getItem('user-language');

            if (status.new_languages.enabled && status.new_languages.enabled.includes(locale)) {
                LocaleStore.sharedInstance().setLanguage(locale);
            } else {
                LocaleStore.sharedInstance().setLanguage(status.new_languages.default ?? 'en');
            }

            BalanceStore.sharedInstance().walletBalance = status.walletBalance || 0;
            BalanceStore.sharedInstance().walletCurrency = status.walletCurrency || null;

            LocaleStore.sharedInstance().localizationSettings =  (await getUserSettings()).localizationSettings;
            LocaleStore.sharedInstance().customLocaleCode = status.custom_locale_code || null;

            await TermsStore.sharedInstance().load();
            LocaleStore.sharedInstance().setEnabledLanguages(status.new_languages ? status.new_languages.enabled : ['en']);
            ProfileFieldsStore.sharedInstance().profileFields = status.profile_fields.fields;
            ProfileFieldsStore.sharedInstance().profileFlags = status.profile_fields.flags;
            ProfileFieldsStore.sharedInstance().validators = status.profile_fields.validators;
            FavoritesStore.sharedInstance().favoritesList = status.favorites || [];
            this.setActiveSession(status.activeSession || null);

            const pinsStore = PinsStore.sharedInstance();
            pinsStore.dynamicClustering = status.dynamic_clustering;
            pinsStore.pinsLimit = status.pinsLimit;

            BroadcastConnectionMonitor.init(() => settings.broadcast);
            SessionWebsocketMonitor.init(() => settings.broadcast);
            SessionChangesMonitorProxy.init(SessionWebsocketMonitor);

            PaymentsStore.sharedInstance().setCanAddPaymentMethodCallback(() => this.canAddPaymentMethod);
            InvoiceDetailsStore.loadDetails();
            PaymentsStore.sharedInstance().loadBillingSettings();
//            MenuItemsStore.sharedInstance().loadFromBackend();

            if (status.country_list) {
                ProfileFieldsStore.sharedInstance().countryList = status.country_list;
            }

            this.booting = false;
        }
    }
    @computed get canAddPaymentMethod() {
        const profileFieldStore = ProfileFieldsStore.sharedInstance();
        const flags = profileFieldStore.profileFlags;
        if (flags[ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_PROFILE_INCOMPLETE] && !profileFieldStore.isProfileComplete(this.profile)) {
            return false;
        }

        if (flags[ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_EMAIL_NOT_VERIFIED] && !ProfileStore.sharedInstance().emailVerified) {
            return false;
        }

        if (flags[ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_PHONE_NOT_VERIFIED] && !ProfileStore.sharedInstance().phoneVerified) {
            return false;
        }

        return true;
    }

    @computed get isGuestUser() {
        return !this.profile;
    }

    @computed get isAdHocUser() {
        return !!this.profile?.isAdHoc;
    }

    @action.bound
    async addPaymentMethod(navigate: (screen: string, params: any) => void, beforeNavigateCallback?: () => void):
        Promise<PaymentMethod | undefined> {
        if (!this.canAddPaymentMethod) {
            Alert.sharedInstance().show(__('verify.cannot-add-payment-method-title'), __('verify.cannot-add-payment-method-message'));
            return;
        }

        if (!InvoiceDetailsStore.canAddPaymentMethod) {
            navigate('InvoiceDetailsEdit', {message: __('invoices.message')});
            return undefined;
        }

        return PaymentsStore.sharedInstance().newPaymentMethod(navigate, beforeNavigateCallback);
    }

    async reloadTranslatableResources(): Promise<any> {
        CacheStore.getInstance(LocationObject).clearCachedData(true);
        await TermsStore.sharedInstance().load();
    }

    async reloadResourcesOnLogin(): Promise<void> {
        PaymentsStore.sharedInstance().removeLastPaymentMethod();
        const status = await this.statusUpdate();
        await LocaleStore.setDesiredLanguage(status.profile?.locale);
        this.reloadTranslatableResources();
    }

    managedByOperator(evse: EVSE) {
        if (evse.managedByOperator === undefined) {
            return true;
        } else {
            return evse.managedByOperator;
        }
    }

    evseIsOccupiedByCurrentUser(evse: EVSE): boolean {
        return this.activeSession !== null && this.sessionIsRunning && this.activeSession.evseId === evse.identifier;
    }

    @action.bound
    openMenu() {
        this.isMenuOpen = true;
    }

    @action.bound
    closeMenu() {
        this.isMenuOpen = false;
    }

    @action.bound
    setProfile(profile: Profile): void {
        this.profile = profile;
    }

    @action.bound
    unsetProfile(): void {
        this.profile = null;
        FavoritesStore.sharedInstance().favoritesList = [];
        const localeStore = LocaleStore.sharedInstance();
        localeStore.setLanguage(localStorage.getItem('user-language') || localeStore.defaultLocale);
        PaymentsStore.sharedInstance().unsetPayments();
        InvoiceDetailsStore.clearDetails();
        setAuthenticationInfo(null);
        this.reloadResourcesOnLogin();
    }

    @computed get isLoggedIn(): boolean {
        return !(!this.profile || this.profile.isAdHoc);
    }

    @computed get displayName(): string {
        if (this.profile !== null) {
            return [this.profile.firstName, this.profile.lastName].join(' ');
        } else {
            return __('profile.anonymous');
        }
    }

    async reloadProfile() {
        try {
            await this.statusUpdate();
        } catch (e) {
            if (e.code !== 'ECONNABORTED') {
                throw e;
            }
        }
    }

    @action.bound
    async statusUpdate(): Promise<Status> {
        const status = await Bootstrap.getStatus();

        this.profile = status.profile || null;
        await TermsStore.sharedInstance().load();
        FavoritesStore.sharedInstance().favoritesList = status.favorites || [];

        BalanceStore.sharedInstance().walletBalance = status.walletBalance || 0;
        BalanceStore.sharedInstance().walletCurrency = status.walletCurrency || null;

        ProfileStore.sharedInstance().setUnpaidSession(status.unpaidSession || null);
        PaymentsStore.sharedInstance().setPayments(status.payments || null);
        PaymentsStore.sharedInstance().setCanUseServiceWithoutCardCallback(() => ProfileStore.sharedInstance().isLoggedIn);
        await PaymentsStore.sharedInstance().initPaymentProcessor(status.payments || null);

        // Add hidden menu items based on settings
        if (Boolean(status.hide_balance)) {
            MenuItemsStore.sharedInstance().addHiddenItem('balance');
        }

        if (Boolean(status.hide_vouchers)) {
            MenuItemsStore.sharedInstance().addHiddenItem('vouchers');
        }

        if (this.profile) {
            bugsnag.setUser({
                id: this.profile.id,
                name: this.profile.firstName + ' ' + this.profile.lastName,
                email: this.profile.email,
            });
        }

        this.isLocationSearchBoxEnabled = status.web.isLocationSearchBoxEnabled;
        this.isPoiEnabled = status.isPoiEnabled;

        if (status.user_billing.receipt_issue_period === 'each') {
            this.shouldIssueRecipe = true;
        }

        this.authSettings = Object.keys(status.auth).length ? status?.auth : {
            passwordPolicy: {
                passwordLength: MIN_PASSWORD_LENGTH,
                useCapitalLetters: false,
                useDigits: false,
                useSpecialCharacters: false,
            },
        };

        return status;
    }

    private _flow: Flow | undefined;

    get flow(): Flow {
        if (!this._flow) {
            return new RequirementsForUsageFlow();
        }
        return this._flow;
    }

    set flow(flow: Flow) {
        this._flow = flow;
    }

    resetFlow() {
        this._flow = undefined;
    }
    async reloadResources(status: Status): Promise<void> {
        await LocaleStore.setDesiredLanguage(status.profile?.locale);
        await this.reloadTranslatableResources();
    }

    async reloadResourcesOnRegistration(): Promise<void> {
        PaymentsStore.sharedInstance().removeLastPaymentMethod();
        const status = await this.statusUpdate();
        await this.reloadResources(status);
    }

    @action.bound
    setActiveSession(session: Session): void {
        this.activeSession = session;
    }

    @action.bound
    setFinishedSession(session: Session): void {
        this.finishedSession = session;
    }

    @computed get sessionIsRunning() {
        return this.activeSession !== null
            && this.activeSession !== undefined
            && this.activeSession.status !== 'starting'
            && this.activeSession.status !== 'finished'
            && this.activeSession.status !== 'failed'
            ;
    }
}
