import Echo from 'laravel-echo';
import BroadcastService, {BroadcastSettings} from "../../frontend_modules/broadcast/BroadcastService";
import DisplayStore from "../stores/DisplayStore";

class DisplayWebsocketMonitor {
    private echo?: Echo;

    private subscribed?: string;
    private settings: () => BroadcastSettings;

    init(settings: () => BroadcastSettings) {
        this.settings = settings;
        this.subscribe();
    }

    async subscribe() {
        if (this.subscribed) {
            return;
        }
        const service = BroadcastService.sharedInstance(this.settings, () => {console.log('autorun callback')});
        const channelName = DisplayWebsocketMonitor.channelName(service);
        this.subscribed = channelName;

        this.echo = await service.connect();

        const channel = this.echo.channel(channelName);

        channel.listen('ChargePointStateChangeForDisplay', this.onChargePointStateChangeForDisplay.bind(this));

        console.log('Echo: Subscribed to ' + channelName);
    }

    private onChargePointStateChangeForDisplay = (payload: any) => {
        const displayStore = DisplayStore.sharedInstance();

        const chargePointIdentifier = displayStore.chargePoint?.networkId;

        if (chargePointIdentifier === payload.chargePointId) {
            displayStore.refreshFromBacked(payload.chargePointId);
        }
    }

    private static channelName(service: BroadcastService) {
        const displayStore = DisplayStore.sharedInstance();

        const chargePointIdentifier = displayStore.chargePoint?.networkId;

        return `${service.channelPrefix()}.displays.${chargePointIdentifier}`;
    }
}

export default new DisplayWebsocketMonitor();
