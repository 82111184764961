export default class Alert {
    private static instance: Alert;
    private adapter: any;
    private isFunctional: boolean = false;

    static sharedInstance(): Alert {
        if (Alert.instance === undefined) {
            Alert.instance = new Alert();
        }
        return Alert.instance;
    }

    init(adapter: any, isFunctional: boolean = false) {
        this.adapter = adapter;
        this.isFunctional = isFunctional;
    }

    show(title: string, description?: string, buttons?: any, options?: any) {
        if (!this.adapter) {
            return;
        }

        if (this.isFunctional) {
            this.adapter().alert(title, description, buttons, options);
        } else {
            this.adapter.alert(title, description, buttons, options);
        }
    }
}
