import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {Marker} from "@react-google-maps/api";
import settings from "../../settings";
import {Clusterer} from "@react-google-maps/marker-clusterer";
import {LocationStore} from "@ampeco/charge-stores";
import {Pin, LocationObject} from "@ampeco/charge-models";
import {splitGeoToLatLng} from "../../utils/Map";
import NavigationService from '@ampeco/navigation';

interface IProps {
    clusterer: Clusterer;
    pins: Pin[];
}

const MapClusterPinsComponent = observer(({clusterer, pins}: IProps) => {
    const locationStore = LocationStore.sharedInstance();

    const setPinAsNewLocation = (pin: Pin) => {
        locationStore.focusedLocationId = pin.id;
        locationStore.openedPinLocation = true;
        LocationObject.fetch(pin.id);
        NavigationService.sharedInstance().navigate('Location', {
            locationId: pin.id
        });
    }

    const onMarkerClick = useCallback((pin: Pin) => {
        if (!locationStore.focusedLocationId) {
            setPinAsNewLocation(pin);
        } else if (locationStore.focusedLocationId !== pin.id) {
            setPinAsNewLocation(pin);
        } else if (locationStore.focusedLocationId === pin.id) {
            locationStore.openedPinLocation = !locationStore.openedPinLocation;
        } else {
            locationStore.openedPinLocation = false;
        }

    }, []);

    return (<>{pins.map((pin: Pin) => {
        const {latitude: lat, longitude: lng} = splitGeoToLatLng(pin.geo);

        return (<Marker
            key={pin.id}
            position={{lat, lng}}
            clusterer={clusterer}
            icon={{
                url: settings.images + '/pin-single.png',
            }}
            onClick={() => {
                onMarkerClick(pin);
            }}
        />);
    })}</>);
});

export default MapClusterPinsComponent;
