import React from 'react';
import {StyleSheet, View} from 'react-native';
import {InputProps, Text, ThemeConsumer} from 'react-native-elements';
import DateTimePicker from 'react-datetime-picker';
import AmpecoTheme from '@ampeco/theme';

export type DateTimePickerProps = {
    value?: string;
    onChange: (value?: Date) => void;
    errorMessage: string;
    editable: boolean;
} & InputProps;

export const DatetimePickerAdapter = (props: DateTimePickerProps) => {

    const containerStyle = [styles.container];
    const {
        onChange,
        value,
        errorMessage,
        mode,
    } = props;

    const date: Date = value
        ? mode === 'time'
            ? new Date(Date.parse((new Date()).toDateString() + ' ' + value))
            : new Date(value)
        : new Date();

    return <ThemeConsumer<AmpecoTheme>>{({theme}) => (
        <View style={containerStyle}>
            <DateTimePicker
                is24Hour={true}
                style={styles.calendar}
                mode={mode}
                onChange={(event: Event, date?: Date) => onChange(date)}
                value={date}
            />
            {errorMessage ? <Text style={theme.Input ? theme.Input.errorStyle : undefined}>{errorMessage}</Text> : null}
        </View>
    )}</ThemeConsumer>;

};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    calendar: {
        width: '100%',
        marginBottom: 10,

    },
});
