import React from 'react';
import {Observer, observer} from 'mobx-react';
import {IReactionDisposer, reaction} from 'mobx';
import Navigation, {HomeStackParamList} from '@ampeco/navigation';
import {__} from '@ampeco/i18n';
import {Image, StyleSheet, Text, View} from 'react-native';
import {PaymentsStore, SCAServiceFactory} from '../index';
import {Button, ThemeConsumer} from 'react-native-elements';
import {ButtonIcon} from '@ampeco/icons';
import {getTransaction, cancelPendingScaTransaction} from '../api/paymentTransactions';
import {PaymentTransaction} from '@ampeco/models';
import Currency, {format} from '@ampeco/currency';
import {StackScreenProps} from '@react-navigation/stack';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';

type State = {
  isLoading: boolean;
  isPayNowLoading: boolean;
  isCancelLoading: boolean;
  paymentTransaction: PaymentTransaction | null;
  errorMessage: string | null;
}

type NavigationProps = StackScreenProps<HomeStackParamList, 'PaymentTransactionSca'>;

const defaultState = {
  isLoading: true,
  isPayNowLoading: false,
  isCancelLoading: false,
  paymentTransaction: null,
  errorMessage: null,
}

@observer
class PaymentTransactionSca extends React.Component<NavigationProps, State> {
  static titleTranslationKey: () => string;
  static reaction: IReactionDisposer | null = null;
  private readonly focusListener: () => void;

  constructor(props: NavigationProps) {
    super(props);

    this.state = defaultState;

    this.getPaymentTransaction = this.getPaymentTransaction.bind(this);
    this.onPayNow = this.onPayNow.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.focusListener = props.navigation.addListener('focus', () => {
      this.getPaymentTransaction();
    });
  }

  componentDidMount(): void {
    this.props.navigation.setOptions({title: this.props.route.params.title});
    this.getPaymentTransaction();

    const paymentStore = PaymentsStore.sharedInstance();

    paymentStore.setPaymentTransactionScaSucceeded(null);

    PaymentTransactionSca.reaction = reaction(
        () => paymentStore.isPaymentTransactionScaSucceeded,
        () => {
          const flag = paymentStore.isPaymentTransactionScaSucceeded;
          if (flag) { // SCA is successful
            if (this.props.route.params.onSuccessRoute) {
              Navigation.sharedInstance().navigate(
                  this.props.route.params.onSuccessRoute,
                  this.props.route.params.onSuccessRouteParams || {},
              );
            } else {
              Navigation.sharedInstance().goBack();
            }
          } else if (flag === false) { // SCA is unsuccessful
            if (this.props.route.params.onFailRoute) {
              Navigation.sharedInstance().navigate(
                  this.props.route.params.onFailRoute,
                  this.props.route.params.onFailRouteParams || {},
              );
            } else {
              Navigation.sharedInstance().goBack();
            }
          }
        },
    )
  }

  componentWillUnmount(): void {
    PaymentTransactionSca.reaction && PaymentTransactionSca.reaction();
    PaymentTransactionSca.reaction = null;

    if (this.focusListener) {
      this.props.navigation.removeListener('focus', this.focusListener);
    }


    this.setState(() => {
      return defaultState;
    });
  }

  async onPayNow () {
    this.setState((state) => {
      return {
        ...state,
        isPayNowLoading: true,
      }
    });

    const scaService = SCAServiceFactory.get(this.state.paymentTransaction);

    await scaService.openChallenge();
  }

  async onCancel () {
    try {
      this.setState((state) => {
        return {
          ...state,
          isCancelLoading: true,
        }
      });

      await cancelPendingScaTransaction(this.props.route.params.paymentTransactionId);

      if (this.props.route.params.onCancelRoute) {
        Navigation.sharedInstance().navigate(
            this.props.route.params.onCancelRoute,
            this.props.route.params.onCancelRouteParams || {},
        );
      } else {
        Navigation.sharedInstance().goBack();
      }
    } catch (e) {
      this.setState((state) => {
        return {
          ...state,
          isCancelLoading: false,
          errorMessage: __('payment-transaction-sca.cancel-failed-message'),
        }
      });
    }
  }

  getPaymentTransaction() {
    (async () => {
      try {
        const paymentTransaction = await getTransaction(this.props.route.params.paymentTransactionId);

        if (paymentTransaction.status !== 'pending' || !paymentTransaction.paymentMethod) {
          Navigation.sharedInstance().switchToMain();
        }

        Currency.fetch(paymentTransaction.currencyId as unknown as string);

        this.setState((state) => {
          return {
            ...state,
            paymentTransaction,
            isLoading: false,
          };
        });
      } catch (e) {
        this.setState((state) => {
          return {
            ...state,
            isLoading: false,
            errorMessage: __('payment-transaction-sca.loading-details-failed-message'),
          }
        });
      }
    })();
  }

  render () {
    const paymentTransaction = this.state.paymentTransaction;

    if (this.state.isLoading || !paymentTransaction) {
      return <ConfigurableActivityIndicator/>
    }

    if (this.state.errorMessage) {
      return (
          <ThemeConsumer>{({theme}) => {
            return (
                <View style={styles.container}>
                  <Text style={theme.PaymentTransactionSca?.errorTextStyle}>
                    {this.state.errorMessage}
                  </Text>
                </View>
            )
          }}</ThemeConsumer>
      );
    }

    return <ThemeConsumer>{({theme}) => {
      return <Observer>{() => {
        return <View style={styles.container}>
          <View>
            <Text style={[styles.title, theme.PaymentTransactionSca?.titleStyle]}>
              {__('payment-transaction-sca.title')}
            </Text>
            <Text style={[styles.description, theme.PaymentTransactionSca?.descriptionStyle]}>
              { __('payment-transaction-sca.description')}
            </Text>
          </View>
          <View style={styles.paymentFailedContainer}>
            <View style={styles.amountDueContainer}>
              <Text style={theme.PaymentTransactionSca?.amountDueLabelText}>
                {__('payment-transaction-sca.amount-due')}
              </Text>
              <Text style={theme.PaymentTransactionSca?.amountDueValueText}>
                { format(paymentTransaction.totalAmount, Currency.where(paymentTransaction.currencyId as unknown as string)) }
              </Text>
            </View>
            <View style={styles.paymentMethodSelectContainer}>
              <Text style={[styles.payViaText, theme.PaymentTransactionSca?.payViaLabelText]}>
                {__('payment-transaction-sca.pay-via')}
              </Text>
              {paymentTransaction.paymentMethod ? <View style={styles.paymentMethodContainer}>
                <Image
                    source={theme.PaymentMethods?.paymentMethodTypeImages?.[paymentTransaction.paymentMethod.type]}
                    style={styles.paymentMethodIcon}
                    resizeMode={'contain'}
                />
                <Text
                    style={[theme.Text?.style]}
                >{paymentTransaction.paymentMethod.name}</Text>
              </View> : null}
            </View>
          </View>
          <View style={styles.buttonsContainer}>
            <Button
                loading={this.state.isPayNowLoading}
                disabled={this.state.isPayNowLoading || this.state.isCancelLoading}
                title={__('payment-transaction-sca.buttons.pay-now')}
                icon={<ButtonIcon source={theme.PaymentTransactionSca?.payNowIcon} />}
                onPress={this.onPayNow}
            />
            <Button
                buttonStyle={theme.PaymentTransactionSca?.cancelButtonStyle}
                disabledStyle={theme.PaymentTransactionSca?.cancelButtonDisabledStyle}
                titleStyle={theme.PaymentTransactionSca?.cancelButtonTitleStyle}
                disabledTitleStyle={theme.PaymentTransactionSca?.cancelButtonDisabledTitleStyle}
                loading={this.state.isCancelLoading}
                disabled={this.state.isPayNowLoading || this.state.isCancelLoading}
                title={__('payment-transaction-sca.buttons.cancel')}
                icon={<ButtonIcon source={theme.PaymentTransactionSca?.cancelIcon} />}
                onPress={this.onCancel}
            />
          </View>
        </View>
      }}</Observer>
    }}</ThemeConsumer>
  }
}

export default PaymentTransactionSca;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 35,
    paddingVertical: 25,
  },
  title: {
    marginBottom: 10,
  },
  description: {
    marginBottom: 40,
  },
  paymentFailedContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  amountDueContainer: {
    flex: 2,
  },
  paymentMethodSelectContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 6,
  },
  payViaText: {
    marginLeft: 0,
    marginBottom: 5,
  },
  buttonsContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  paymentMethodContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentMethodIcon: {
    marginRight: 5,
    width: 30,
    height: 25,
  },
});
