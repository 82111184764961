import {action, computed, observable} from 'mobx';
import {UserProfileFieldValidator} from './types';
import { __, LocaleStore } from '@ampeco/i18n';
import { PartnerInvites } from '@ampeco/charge-models';
import {Flow} from '@ampeco/charge-flow';

let instance: ProfileFieldsStore | null = null;

export enum ProfileFieldState {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    REQUIRED = 'required',
    VERIFIED = 'verified',
}

export default class ProfileFieldsStore {
    static PROFILE_FIELD_EMAIL = 'email';
    static PROFILE_FIELD_FIRST_NAME = 'firstName';
    static PROFILE_FIELD_MIDDLE_NAME = 'middleName';
    static PROFILE_FIELD_LAST_NAME = 'lastName';
    static PROFILE_FIELD_PHONE = 'phone';
    static PROFILE_FIELD_COUNTRY = 'country';
    static PROFILE_FIELD_STATE = 'state';
    static PROFILE_FIELD_CITY = 'city';
    static PROFILE_FIELD_POST_CODE = 'postalCode';
    static PROFILE_FIELD_ADDRESS = 'address';
    static PROFILE_FIELD_PERSONAL_ID = 'personalId';
    static PROFILE_FIELD_VEHICLE_NO = 'vehicleNo';
    static PROFILE_FIELD_PARTNER_LABEL = 'addPartnerLabel';

    static PROFILE_FLAG_NO_SERVICE_IF_PROFILE_INCOMPLETE = 'no_service_if_profile_incomplete';
    static PROFILE_FLAG_NO_SERVICE_IF_EMAIL_NOT_VERIFIED = 'no_service_if_email_not_verified';
    static PROFILE_FLAG_NO_SERVICE_IF_PHONE_NOT_VERIFIED = 'no_service_if_phone_not_verified';
    static PROFILE_FLAG_NO_PAYMENT_IF_PROFILE_INCOMPLETE = 'no_payment_if_profile_incomplete';
    static PROFILE_FLAG_NO_PAYMENT_IF_EMAIL_NOT_VERIFIED = 'no_payment_if_email_not_verified';
    static PROFILE_FLAG_NO_PAYMENT_IF_PHONE_NOT_VERIFIED = 'no_payment_if_phone_not_verified';
    static PROFILE_FLAG_ALLOW_USER_TO_EXPORT_PRIVATE_DATA = 'allow_user_to_export_private_data';
    static PROFILE_FLAG_ALLOW_USER_TO_REMOVE_OWN_ACCOUNT = 'allow_user_to_remove_own_account';
    static PROFILE_FLAG_ALLOW_USER_TO_ADD_PARTNER = 'allow_user_to_add_partner';

    static sharedInstance(): ProfileFieldsStore {
        if (instance === null) {
            instance = new ProfileFieldsStore();
        }
        return instance;
    }

    @observable profileFields: {[key: string]: ProfileFieldState} = {};
    @observable profileFlags: {[key: string]: boolean} = {};
    @observable validators: { [key: string]: UserProfileFieldValidator } = {};
    @observable labels: { [key: string]: {} } = {};
    @observable countryList: { key: string; label: string, states?: { key: string; label: string }[] }[] = [];
    @observable invites: PartnerInvites = {has_invites: false, partners: {}};

    @action.bound
    public resetInvites() {
        this.invites = {has_invites: false, partners: {}};
    }

    @computed get isAccountCompleted() {
        return !Flow.isPartnerRequired && Flow.profileComplete && !Flow.requiresPaymentMethod && !Flow.requiresSubscription;
    }

    public isProfileComplete(profile: any): boolean {
        if (!profile) {
            return true;
        }
        let complete = true;
        availableProfileFields.forEach(fieldName => {
            if (
                !this.profileFields[fieldName]
                || this.profileFields[fieldName] === ProfileFieldState.DISABLED
            ) {
                return;
            }

            if (
                (
                    this.profileFields[fieldName] === ProfileFieldState.REQUIRED
                    || this.profileFields[fieldName] === ProfileFieldState.VERIFIED
                )
                && !profile[fieldName]
            ) {
                complete = false;
            }
        });

        return complete;
    }

    public isPartnerRequired() {
        const partnerFlag = this.profileFlags[ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_ADD_PARTNER] ?? false;

        return partnerFlag && !this.invites.has_invites;
    }

    public canAddPaymentMethod(profile: any): boolean {
        if (
            this.profileFlags[ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_PROFILE_INCOMPLETE]
            && !this.isProfileComplete(profile)
        ) {
            return false;
        }
        return true;
    }

    @computed get canAddPaymentMethodAfterRegister() {
        const profileFields = this.profileFields;
        const flags = this.profileFlags;

        if (
            flags[ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_EMAIL_NOT_VERIFIED]
            && profileFields[ProfileFieldsStore.PROFILE_FIELD_EMAIL] === ProfileFieldState.VERIFIED
        ) {
            return false;
        }

        if (
            flags[ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_PHONE_NOT_VERIFIED]
            && profileFields[ProfileFieldsStore.PROFILE_FIELD_PHONE] === ProfileFieldState.VERIFIED
        ) {
            return false;
        }

        return true;
    }

    public canExportPrivateData(): boolean {
        return this.profileFlags[ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_EXPORT_PRIVATE_DATA];
    }

    public canRemoveOwnAccount(): boolean {
        return this.profileFlags[ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_REMOVE_OWN_ACCOUNT];
    }

    public canAddPartner(): boolean {
        if (this.profileFlags[ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_ADD_PARTNER]) {
            return !this.invites.has_invites;
        }
        return false;
    }

    public getAddPartnetLabel(): string {
        if (this.canAddPartner()) {
            let addPartnerLabel = __('add-partner.default-label');
            if (this.labels['addPartnerLabel'] && this.labels['addPartnerLabel'][LocaleStore.language]) {
                addPartnerLabel =  this.labels['addPartnerLabel'][LocaleStore.language];
            }
            return addPartnerLabel;
        }
        return '';
    }
}

export const availableProfileFields = [
    ProfileFieldsStore.PROFILE_FIELD_EMAIL,
    ProfileFieldsStore.PROFILE_FIELD_FIRST_NAME,
    ProfileFieldsStore.PROFILE_FIELD_MIDDLE_NAME,
    ProfileFieldsStore.PROFILE_FIELD_LAST_NAME,
    ProfileFieldsStore.PROFILE_FIELD_PHONE,
    ProfileFieldsStore.PROFILE_FIELD_COUNTRY,
    ProfileFieldsStore.PROFILE_FIELD_STATE,
    ProfileFieldsStore.PROFILE_FIELD_CITY,
    ProfileFieldsStore.PROFILE_FIELD_POST_CODE,
    ProfileFieldsStore.PROFILE_FIELD_ADDRESS,
    ProfileFieldsStore.PROFILE_FIELD_VEHICLE_NO,
    ProfileFieldsStore.PROFILE_FIELD_PERSONAL_ID,
    ProfileFieldsStore.PROFILE_FIELD_PARTNER_LABEL,
];

export const defaultValueFields = [
    ProfileFieldsStore.PROFILE_FIELD_COUNTRY,
];

export const availableFlags = [
    ProfileFieldsStore.PROFILE_FLAG_NO_SERVICE_IF_PROFILE_INCOMPLETE,
    ProfileFieldsStore.PROFILE_FLAG_NO_SERVICE_IF_EMAIL_NOT_VERIFIED,
    ProfileFieldsStore.PROFILE_FLAG_NO_SERVICE_IF_PHONE_NOT_VERIFIED,
    ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_PROFILE_INCOMPLETE,
    ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_EMAIL_NOT_VERIFIED,
    ProfileFieldsStore.PROFILE_FLAG_NO_PAYMENT_IF_PHONE_NOT_VERIFIED,
    ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_EXPORT_PRIVATE_DATA,
    ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_REMOVE_OWN_ACCOUNT,
    ProfileFieldsStore.PROFILE_FLAG_ALLOW_USER_TO_ADD_PARTNER,
];
