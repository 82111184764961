import React, {useState} from 'react';
import {StyleSheet, TextInput, TouchableOpacity, View, ViewStyle} from 'react-native';
import {InputProps, Text, ThemeConsumer} from 'react-native-elements';
import DateTimePicker from 'react-time-picker';
import moment from 'moment';

import AmpecoTheme from '@ampeco/theme';
import {FieldInputProps} from '@ampeco/forms';
import {StandaloneIcon} from "@ampeco/icons";
import {__, LocaleStore} from '@ampeco/i18n';
import {TimeFormatTypes} from '@ampeco/charge-models';

export type TimePickerProps = {
    value: string,
    editable: boolean,
    confirmBtnText: string;
    cancelBtnText: string;
    headerText: string;
    is24Hour: boolean;
    containerStyle?: ViewStyle
    touchableOpacityContainerStyle?: ViewStyle
    selectIconVisible?: boolean;
} & FieldInputProps & InputProps;

type Time = string;

const toTime = (date: Date) => {
    return ('0' + date.getHours()).slice(-2) + ':' +
    ('0' + date.getMinutes()).slice(-2) as Time;
}

const toDate = (time?: Time) => {
    const date = new Date();
    const split = time ? time.split(':') : [];
    if (split[0] !== undefined) {
        date.setHours(parseInt(split[0], 10));
    }
    if (split[1] !== undefined) {
        date.setMinutes(parseInt(split[1], 10));
    }
    return date;
}

export const TimePickerAdapter = (props: TimePickerProps) => {
    const containerStyle = StyleSheet.flatten([styles.container, props.containerStyle]);
    const {
        onChange,
        value,
        errorMessage,
    } = props;

    const date = toDate(value);

    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

    return <ThemeConsumer<AmpecoTheme>>{({ theme }) => (
        <View style={containerStyle}>
            <TouchableOpacity onPress={() => setDatePickerVisibility(true) } style={StyleSheet.flatten([styles.touchableOpacityContainerStyle, props.touchableOpacityContainerStyle])}>
                <View style={props.selectIconVisible ? {flexDirection: 'row', justifyContent: 'center', alignItems: 'center'} : {}}>
                    <TextInput
                        {...props}
                        value={value ? moment(value, 'HH:mm:ss').format(LocaleStore.sharedInstance().displayTimeFormat as TimeFormatTypes) : undefined}
                        editable={false}
                        style={StyleSheet.flatten([theme.Datepicker.dateText, props.inputStyle])}
                        onTouchStart={() => setDatePickerVisibility(true)}
                    />
                    {props.selectIconVisible && <StandaloneIcon style={{marginLeft: 15}} source={theme.Select.selectIconSource} />}
                </View>
            </TouchableOpacity>
            <DateTimePicker
                {...props}
                isVisible={isDatePickerVisible}
                onConfirm={(newDate: Date) => {
                    setDatePickerVisibility(false);
                    onChange(toTime(newDate));
                }}
                onChange={() => {}}
                onCancel={() => setDatePickerVisibility(false)}
                style={[styles.timePicker]}
                display="spinner"
                mode="time"
                date={date}
                cancelTextIOS={props.cancelBtnText}
                confirmTextIOS={props.confirmBtnText}
                headerTextIOS={props.headerText}
            />
            {errorMessage && !props.noError ? <Text style={theme.Input ? theme.Input.errorStyle : undefined}>{errorMessage}</Text> : null}
        </View>
    )}</ThemeConsumer>;
};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    timePicker: {
        width: '100%',
    },
    touchableOpacityContainerStyle: {
        height: 54,
        justifyContent: 'center',
    },
});
