import React from 'react';
import {__} from '@ampeco/i18n';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {BasePage} from '../BasePage';
import NavigationService from '@ampeco/navigation';
import {InvoiceDetailsEdit} from '@ampeco/invoices';
import {useLocation} from "react-router-dom";
import {StyleSheet} from "react-native";
import {SessionStartStore} from "@ampeco/charge-stores";
import GlobalStore from "../../stores/GlobalStore";

function InvoiceDetailsEditScreen(): JSX.Element {
    const location = useLocation<{intendedNavigation?: {
        screen: string;
        evseId: string;
    }}>();
    const navigation: any = {
        title: __('invoices.details'),
        setOptions: () => {/**/},
        addListener: () => {/**/},
        removeListener: () => {/**/},
    };

    return <BasePage
        title={'invoices.details'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('PaymentMethods')
        }}
    >
        <SafeAreaProvider style={styles.container}>
            <InvoiceDetailsEdit
                navigation={navigation}
                route={{
                    params: {
                        successCallback: async () => {
                            if (location.state.intendedNavigation) {
                                if (GlobalStore.sharedInstance().shouldIssueRecipe) {
                                    NavigationService.sharedInstance().navigate('EmailReceipt', {
                                        intendedNavigation: {
                                            screen: 'SessionStart',
                                            evseId: SessionStartStore.sharedInstance().evse.identifier,
                                            evse: SessionStartStore.sharedInstance().evse
                                        }
                                    });
                                } else {
                                    NavigationService.sharedInstance().navigate(
                                        location.state.intendedNavigation.screen, {evseId: location.state.intendedNavigation.evseId}
                                    );
                                }
                            } else {
                                NavigationService.sharedInstance().navigate('PaymentMethods');
                            }
                        }
                    }
                }}
            />
        </SafeAreaProvider>
    </BasePage>;
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 35,
    }
});

export default InvoiceDetailsEditScreen;
