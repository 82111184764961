import React from 'react';
import {FlatList, StyleSheet, View} from 'react-native';
import {__, LocaleStore} from '@ampeco/i18n';
import {Observer, observer} from 'mobx-react';
import {Divider, Text, ThemeConsumer} from 'react-native-elements';
import {IReactionDisposer, reaction} from 'mobx';
import BalanceStore from '../stores/BalanceStore';
import BalanceHistoryStore from '../stores/BalanceHistoryStore';
import AmpecoTheme from '@ampeco/theme';
import {format} from '@ampeco/currency';
import {TopUpPackages as API} from '../api';
import {Switch} from '@ampeco/forms';
import {AddButton} from '@ampeco/components';
import images from '../images';
import {TextButton} from '@ampeco/icons';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import Alert from '@ampeco/alert';
import {StackScreenProps} from '@react-navigation/stack';
import Navigation, {HomeStackParamList} from '@ampeco/navigation';
import BalanceHistoryLine from './Balance/BalanceHistoryLine';

type BalanceProps = StackScreenProps<
    HomeStackParamList,
    'Balance'
>;

@observer
export default class Balance extends React.Component<BalanceProps, {onPurchaseCallback: () => void}> {
    static reaction: IReactionDisposer | null = null;

    componentDidMount(): void {
        this.props.navigation.setOptions({title: __('wallet.title')});

        BalanceHistoryStore.sharedInstance().loadMoreRecords();
        BalanceStore.sharedInstance().load();
        Balance.reaction = reaction(
            () => LocaleStore.language,
            () => {
                this.props.navigation.setOptions({title: __('wallet.title')});
            },
        );
    }

    componentWillUnmount(): void {
        Balance.reaction && Balance.reaction();
        Balance.reaction = null;
        BalanceHistoryStore.sharedInstance().resetPagination();
    }

    dependsOn(v: any) {};

    render() {
        this.dependsOn(LocaleStore.language);

        this.dependsOn(BalanceStore.sharedInstance().formattedWalletBalance);
        const store = BalanceHistoryStore.sharedInstance();
        const balanceStore = BalanceStore.sharedInstance();
        const autoTopUpPackage = balanceStore.autoTopUpPackage;
        const topUpInternalEnabled = balanceStore.topUpInternalEnabled;
        const topUpExternalEnabled = balanceStore.topUpExternalEnabled;
        const autoTopUpEnabled = balanceStore.autoTopUpEnabled;
        this.dependsOn(balanceStore.autoTopUpPackage && balanceStore.topUpInternalEnabled && balanceStore.autoTopUpEnabled);
        const records = store.records;
        const shouldShowTopupCallback = (this.props.route.params || {}).shouldShowTopup;

        const shouldShowTopupSection = topUpInternalEnabled && autoTopUpEnabled && shouldShowTopupCallback && (typeof shouldShowTopupCallback === 'function' && shouldShowTopupCallback());
        const anyTopUpEnabled = topUpInternalEnabled || topUpExternalEnabled;

        return (
            <ThemeConsumer<AmpecoTheme>>{({ theme}) => (
                <FlatList
                    contentContainerStyle={[styles.borderedList, theme.Balance.borderedList]}
                    style={theme.Balance.listStyle}
                    data={store.list}
                    onEndReached={() => {
                        if (!store.isLoading) {
                            store.loadMoreRecords();
                        }
                    }}
                    renderItem={({item}) => <BalanceHistoryLine
                        leftColumn={'value'}
                        note={item.note}
                        receiptUrl={item.receiptUrl}
                        noteIcon={item.type === 'transaction' ? images.publicPlace : undefined}
                        duration={item.duration}
                        value={{totalAmount: item.amount, currency: item.currency }}
                        timestamp={item.timestamp}
                        app_info={item.app_info}
                    />}
                    ItemSeparatorComponent={Divider}
                    ListHeaderComponent={
                        <View>
                            <View style={theme.Balance.balanceContainer}>
                                <Text style={theme.Balance.textLabel}>{__('wallet.balance-label')}</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <Text style={theme.Balance.balance}>{balanceStore.formattedWalletBalance}</Text>
                                    {anyTopUpEnabled && <AddButton style={{marginLeft: 21}} iconStyle={theme.Balance.addIconStyle} onPress={() => {

                                        if (typeof shouldShowTopupCallback === 'function' && !shouldShowTopupCallback()) {
                                            Alert.sharedInstance().show(
                                                __('verify.cannot-add-payment-method-title'),
                                                __('verify.cannot-add-payment-method-message'),
                                            );
                                        } else {
                                            if (topUpInternalEnabled) {
                                                this.props.navigation.navigate('TopUpPackages', {
                                                    autoTopUp: false,
                                                    afterPurchaseCallback: this.props.route.params.afterPurchaseCallback,
                                                });
                                            } else if (topUpExternalEnabled) {
                                                this.props.navigation.navigate('ExternalBalanceTopUp', {
                                                    url: balanceStore.externalBalanceTopUpUrl,
                                                    onClose: async (success: boolean, shouldNavigateBack: boolean = true) => {
                                                        try {
                                                            if (shouldNavigateBack) {
                                                                Navigation.sharedInstance().goBack()
                                                            }
                                                        } catch (e) {
                                                            throw e
                                                        }
                                                    },
                                                });
                                            }
                                        }

                                    }}/>}
                                </View>
                            </View>
                            <Divider style={theme.Balance.space}/>
                            {shouldShowTopupSection && <View style={theme.Balance.balanceContainer}>
                                <View style={theme.Balance.autoTopUpContainer}>
                                    <Text>{__('top-up-package.auto_top_up')} {autoTopUpPackage ? '(' : null}</Text>
                                    {autoTopUpPackage && <TextButton
                                        textStyle={theme.Balance.autoTopUpAmountButton}
                                        style={styles.autoTopUpButtonStyle}
                                        onPress={() => {
                                            this.props.navigation.navigate('TopUpPackages', {
                                                autoTopUp: true,
                                                afterPurchaseCallback: this.props.route.params.afterPurchaseCallback,
                                            });
                                        }
                                        }>{format(autoTopUpPackage.price, autoTopUpPackage.currency)}</TextButton>}
                                    {autoTopUpPackage && <Text>)</Text>}
                                </View>
                                <Switch value={balanceStore.userAutoTopUpEnabled} onChange={(value) => {
                                    if (value) {
                                        const shouldShowTopup = this.props.route.params.shouldShowTopup;
                                        if (shouldShowTopup && !shouldShowTopup()) {
                                            Alert.sharedInstance().show(
                                                __('verify.cannot-add-payment-method-title'),
                                                __('verify.cannot-add-payment-method-message'),
                                            );
                                        } else {
                                            this.props.navigation.navigate('TopUpPackages', {
                                                autoTopUp: true,
                                                afterPurchaseCallback: this.props.route.params.afterPurchaseCallback,
                                            });
                                        }
                                    } else {
                                        balanceStore.autoTopUpPackage = null;
                                        API.disableAutoTopUP();
                                    }
                                }}/>
                            </View>}
                            {shouldShowTopupSection && <Divider style={theme.Balance.space}/>}
                            {records && records.length > 0 && <><Text style={theme.Balance.historyHeader}>{__('wallet.history-title')}</Text><Divider/></>}
                        </View>
                    }
                    ListEmptyComponent={
                        records && records.length === 0 && React.Fragment || <ConfigurableActivityIndicator/>
                    }
                    ListFooterComponent={
                        <Observer>
                            {
                                () => (
                                    store.isLoading ?
                                        <ConfigurableActivityIndicator /> :
                                        null
                                )
                            }
                        </Observer>
                    }
                />
            )}
            </ThemeConsumer>
        );
    }
}

const styles = StyleSheet.create({
    borderedList: {
        paddingLeft: 25,
        paddingRight: 25,
    },
    autoTopUpButtonStyle: {
        paddingHorizontal: 5,
    },
    textContainer: {
        paddingHorizontal: 25,
        marginTop: 28,
    },
});
