import {Dimensions, Image, Platform, StyleSheet, View} from 'react-native';
import React, {Fragment} from 'react';
import {Location} from '@ampeco/charge-models';
import {Divider, ThemeConsumer} from 'react-native-elements';
import {EVSE, Zone, What3words} from './index';
import {observer} from 'mobx-react';
import {HTMLRenderer} from '@ampeco/html-renderer';
import {ChargeTheme} from '@ampeco/theme';
import {LocationStore} from '@ampeco/charge-stores';
import WorkingHoursList from './WorkingHoursList';
const {width} = Dimensions.get('window');

export default observer((props: {}) => {
    const location = LocationStore.sharedInstance().focusedLocation;
    if (!location) {
        return null;
    }
    return <ThemeConsumer>{({theme}) => {

        return <View style={[theme.PullUpPanelContent.containerStyle, styles.container]}>
            <View style={theme.PullUpPanelContent.offset}>
                {location && location.what3words_address &&
                <What3words location={location} navigationService={props.navigationService}/>}
                {location && <HTMLRenderer text={location.detailed_description}/>}
                {location && location.workingHours && <WorkingHoursList workingHours={location.workingHours} />}
                {location && location.zones.map((zone, zoneid) => {
                    return <Fragment key={'zone-' + zoneid}>{[(zone.title ?
                        <Fragment key="zone"><Divider
                            style={[theme.PullUpPanelContent.fragmentStyle, styles.divider, styles.fragmentStyle]}/><Zone
                            zone={zone}/></Fragment> :
                        <Fragment key="no-zone"></Fragment>), zone.evses.map(evse => {
                        return location && (<Fragment key={'evse-' + evse.identifier}>
                            <Divider style={[styles.divider]}/>
                            <EVSE
                                evse={evse}
                                location={location}
                            />
                        </Fragment>);
                    })]}</Fragment>;
                })
                }
                <Divider style={[styles.divider]}/>
                {location.additional_description ? <>
                    <HTMLRenderer text={location.additional_description}/>
                    <Divider style={[styles.divider]}/>
                </> : null}
                <View style={{flexWrap: 'wrap', flexDirection: 'column', marginTop: 25}}>
                    {location && location.images && location.images.map(image => {
                        const imageWidth = Platform.OS === 'web' ? 280 : (width - 50);

                        return <Image key={image} resizeMode={'contain'} source={{uri: image}} style={{
                            width: imageWidth,
                            height: imageWidth * (3 / 4),
                            marginBottom: 25,
                            borderColor: 'white',
                            borderWidth: 1,
                        }}/>;
                    })}
                </View>
            </View>
        </View>;
    }}</ThemeConsumer>;
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
    },
    divider: {
        marginVertical: 10,
    },
    fragmentStyle: {
        height: 1,
        marginTop: 20,
    },
});
