import {action, computed, observable} from 'mobx';
import {EVSE, Location, Session, NoPaymentMethod, Tariff} from '@ampeco/charge-models';
import {__} from '@ampeco/i18n';
import {ReservationStore} from './index';
import {Session as SessionAPI} from '@ampeco/charge-api';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {SessionPollingService, translateSessionStartErrorCodeToMessage, SessionMock, ReservationService} from '@ampeco/charge-services';
import {ToasterStore} from '@ampeco/toaster';
import Alert from '@ampeco/alert';
import { SessionUtils } from '@ampeco/charge-screens';

export default class SessionStartStore {
    private static instance: SessionStartStore;

    @observable evse: EVSE|null = null;
    @observable location: Location|null = null;

    @observable startDisabled: boolean = false;
    @observable isEvaluatingCanStartSession: boolean = false;
    @observable shouldShowTopUpButton: boolean = false;
    @observable shouldShowViewUnpaidSessionButton: boolean = false;
    @observable unableToChargeReason: string = '';
    @observable loading: {[key: string]: any}|null = null;
    @observable unlockingBarrier: boolean = false;
    @observable waitingForCar: {[key: string]: any}|null = null;
    @observable loweringParkingBarrier: boolean = false;

    // agile streets
    @observable gettingThreshold: boolean = false;
    @observable kwhMaxAvailable: number|null = null;
    @observable kwhThreshold: number|null = null;
    @observable optimizedCharging: boolean = false;
    @observable targetChargeKwh: number|null = null;
    @observable errorMessageCalculateThreshold: string|null = null;

    @observable kwh: number|null = null;

    poll: SessionPollingService | undefined = undefined;

    static sharedInstance(): SessionStartStore {
        if (SessionStartStore.instance === undefined) {
            SessionStartStore.instance = new SessionStartStore();
        }
        return SessionStartStore.instance;
    }

    @action.bound
    set(evse: EVSE, location: Location) {
        this.evse = evse;
        this.location = location;
    }

    canStart(evseId: any, paymentMethodId: any) {
        // Set defaults prior to checking
        this.changeState(false, true, false, false, '');

        SessionAPI.checkCanStart(evseId, paymentMethodId).then((result: any) => {
            this.startDisabled = false;
            this.isEvaluatingCanStartSession = false;
        }).catch((error: any) => {
            if (error.response && error.response.status === 403) {
                this.changeState(true, false, true, false, error.response.data.message)
            } else if (error.response && error.response.status === 413) {
                this.changeState(true, false, false, false, error.response.data.message)
            } else if (error.response && error.response.status === 418) {
                this.changeState(true, false, false, false, __('session.start.add-payment-method-to-start-charging'))
            } else if (error.response && error.response.status === 425) {
                this.changeState(true, false, false, false, __('session.start.account_verification_not_complete'))
            } else if (error.response && error.response.status === 409) {
                this.changeState(true, false, false, false, __('session.start.concurrent-session-failure'))
            } else if (error.response && error.response.status === 402) {
                this.changeState(true, false, false, true, __('session.start.unpaid-session-failure'))
            } else if (error.response && error.response.status === 407) {
                this.changeState(true, false, false, false, __('session.start.payment-method-cannot-be-used'))
            } else if (error.response && error.response.status === 408) {
                this.changeState(true, false, false, false, __('session.start.access_is_restricted'))
            }
            else {
                this.changeState(true, false, false, false, __('session.start.unable-to-start-charging'))
            }

            ReservationStore.sharedInstance().reservationButtonEnabled = false;
        })
    }

    async start(evse: EVSE, paymentMethodCallback: any, attributes = {}): Promise<Session|undefined> {

        const paymentMethod = paymentMethodCallback();

        if (GlobalStoreProxy.sharedInstance().mockSession) {
            return SessionMock.sharedInstance().start(evse.identifier, paymentMethod);
        } else {
            try {
                return await SessionAPI.start(evse.id, paymentMethod ? paymentMethod.id : null, attributes);
            } catch (error) {
                if (error.code !== 'ECONNABORTED') {
                    throw error;
                }
            }

        }
    }

    sendRemoteStart() {
        const activeSession = GlobalStoreProxy.sharedInstance().activeSession;

        if (!activeSession) {
            return;
        }
        this.setWaitingForCar(null);
        this.setLoading({});
        SessionAPI.sendRemoteStart(activeSession.id).then((session: Session) => {
            GlobalStoreProxy.sharedInstance().activeSession = session;
            this.setWaitingForCar({});
        }).finally(() => {
            this.setLoading(null);
        });
    }

    startSession(evse: EVSE, paymentMethodCallback: any, attributes = {}) {
        if (this.loading) {
            return;
        }

        if (this.waitingForCar) {
            return;
        }

        this.setLoading(attributes);
        this.start(evse, paymentMethodCallback, attributes)
            .then((session: Session|undefined) => {
                this.setWaitingForCar(attributes);

                if (this.poll) {
                    this.poll.stop();
                }

                if (!session) {
                    this.setWaitingForCar(null);
                    return;
                }

                this.poll = SessionPollingService.poll(evse, session, 'starting', session => {
                    GlobalStoreProxy.sharedInstance().setActiveSession(session);
                    if (session.status === 'expired') {
                        SessionStartStore.sharedInstance().setUnlockingBarrier(false);
                        SessionStartStore.sharedInstance().setExpirationReason(__('session.start.barrier.expiration-reason.' + (session.expirationReason ?? 'unknown')));
                    }

                    (new ReservationService(GlobalStoreProxy.sharedInstance())).updateActiveReservation();

                    this.setWaitingForCar(null);
                });
            })
            .catch((reason: any) => {

                if (reason instanceof NoPaymentMethod) {
                    ToasterStore.sharedInstance().setMessage(reason.message);
                } else {
                    const message = translateSessionStartErrorCodeToMessage(reason);
                    if (reason.response && reason.response.status === 412) {
                        Alert.sharedInstance().show('', message)
                        return;
                    }
                    ToasterStore.sharedInstance().setMessage(message);
                }

            })
            .finally(() => {
                this.setLoading(null);
            });
    }

    unlockBarrier(evse: EVSE, paymentMethodCallback: any) {
        if (this.unlockingBarrier) {
            return;
        }

        this.setUnlockingBarrier(true);
        this.setExpirationReason(null);
        this.start(evse, paymentMethodCallback)
            .catch((reason: any) => {

                this.setUnlockingBarrier(false);
                if (reason instanceof NoPaymentMethod) {
                    ToasterStore.sharedInstance().setMessage(reason.message);
                } else {
                    const message = translateSessionStartErrorCodeToMessage(reason);
                    ToasterStore.sharedInstance().setMessage(message);
                }

            });
    }

    changeState(startDisabled: boolean, isEvaluatingCanStartSession: boolean, shouldShowTopUpButton: boolean, shouldShowViewUnpaidSessionButton: boolean, unableToChargeReason: string) {
        this.startDisabled = startDisabled;
        this.isEvaluatingCanStartSession = isEvaluatingCanStartSession;
        this.shouldShowTopUpButton = shouldShowTopUpButton;
        this.shouldShowViewUnpaidSessionButton = shouldShowViewUnpaidSessionButton;
        this.unableToChargeReason = unableToChargeReason;
    }

    @action.bound
    setLoading(value: {[key: string]: any}|null) {
        this.loading = value;
    }

    @action.bound
    setUnlockingBarrier(value: boolean) {
        this.unlockingBarrier = value;
    }

    @action.bound
    setWaitingForCar(value: {[key: string]: any}|null) {
        this.waitingForCar = value;
    }

    @action.bound
    setExpirationReason(reason: string | null) {
        this.expirationReason = reason;
    }
    @computed
    get parkingBarrierIsUnlocked() {
        const activeSession = GlobalStoreProxy.sharedInstance().activeSession;
        if (!activeSession) {
            return false;
        }

        return activeSession.barrierHardwareStatus === 'unlocked';
    }

    @computed
    get vehicleParked() {
        const activeSession = GlobalStoreProxy.sharedInstance().activeSession;

        return SessionUtils.isVehicleParked(activeSession);
    }

    static lastPeriodAmount(activeSession?: Session) {
        if (!activeSession || !activeSession.lastPeriod) {
            return 0;
        }

        return activeSession.lastPeriod.amount;
    }

    @observable vehicleNotParkedInTime: boolean = false;
    @observable vehicleStartedChargingInTime: boolean = true;
    @observable waitingVehicleToStartCharging: boolean = true;
    @observable expirationReason: string|null = null;

    public static getIdleFee(activeSession?: Session, tariff?: Tariff|null) {

        if (!tariff || !activeSession || !activeSession.lastPeriod) {
            return 0;
        }

        const lastPeriodState = activeSession.lastPeriod.state;

        const [first, second] = lastPeriodState.split('@');

        if (second === 'day') {
            return tariff?.priceForIdleAtDay
        } else if (second === 'night') {
            return tariff?.priceForIdleAtNight
        } else {
            return tariff?.priceForIdle
        }
    }

    getThreshold(evseId: any, endTime: string) {
        this.gettingThreshold = true;
        this.errorMessageCalculateThreshold = null;
        SessionAPI.getThreshold(evseId, endTime).then((result) => {
            console.log('response received', result);
            this.gettingThreshold = false;
            this.kwhMaxAvailable = result.kwhMaxAvailable;
            this.kwhThreshold = result.kwhThreshold || null;
        }).catch((e: any) => {
            this.gettingThreshold = false;
            this.errorMessageCalculateThreshold = e.response?.data?.message || __('agile-streets.unknown-error');
        });
    }

    setOptimizedCharging(value: boolean) {
        this.optimizedCharging = value;
    }

    setTargetCharge(value: number|null) {
        this.targetChargeKwh = value;
    }
}
