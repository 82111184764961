import { LocaleStore, __ } from '@ampeco/i18n';
import Navigation, { HomeStackParamList } from '@ampeco/navigation';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import WebView from 'react-native-webview';
import {WebViewNavigation} from 'react-native-webview';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';

export default function SCAChallenge() {
    const navigation = useNavigation();
    const route = useRoute<RouteProp<HomeStackParamList, 'SCAChallenge'>>();
    const redirectUrl = route.params.redirectUrl;
    const successUrl = route.params.returnUrl;
    const returnUrls = route.params.failureUrl ? [successUrl, route.params.failureUrl] : [successUrl];
    const sessionId = route.params.sessionId;

    useEffect(() => {
        navigation.setOptions({ title: __('session.running.sca-challenge-title') });
    }, [LocaleStore.language]);

    const onNavigationStateChange = (webViewState: WebViewNavigation) => {
        // If the bank redirected to our domain we should close the webview, no matter success, fail etc.
        if (returnUrls.some(returnUrl => webViewState.url.startsWith(returnUrl))) {
            Navigation.sharedInstance().goBack();

            if (sessionId) {
                Navigation.sharedInstance().navigate('SessionSummary', {dismissButton: true, sessionId});
            }
        }
    };

    return <WebView
        visible
        style={Platform.OS === 'web' ? styles.iframe : null}
        source={{ uri: redirectUrl }}
        onNavigationStateChange={onNavigationStateChange}
        javaScriptEnabled
        domStorageEnabled
    />;
}

const styles = {
    iframe: {
        height: 'calc(100vh - 135px)',
    },
}