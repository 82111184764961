import BaseSCAService from './BaseSCAService';
import Navigation from '@ampeco/navigation';
import { PaymentTransactionDefaultScaMeta as DefaultScaMeta} from '@ampeco/models';

import {Logger} from '@ampeco/logging';

export default class GenericSCAService extends BaseSCAService {
    handleOpenChallenge(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (!this.canRedirect()) {
                Logger.error(`Missing redirect,return and failure url for transactionID: ${this.transaction?.id}`);
                reject();
            }

            const {redirectUrl, returnUrl, failureUrl} = this.transaction?.scaMeta as DefaultScaMeta;
            // TODO: SCAService has nothing to do with Session or SessionId.In Near future refactor it with callback or event.
            const sessionId = this.transaction?.sessionId;
            Navigation.sharedInstance().navigate('SCAChallenge', {
                redirectUrl,
                returnUrl,
                failureUrl,
                sessionId,
            });

            resolve();
        });
    }
}
