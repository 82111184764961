
import {leaveNavigationBreadcrumb} from './bugsnag';

const prepareLog = (...args: any): string => {
  let value = args[0];
  if (!value) { return ''; }
  if (value instanceof Error) { value = value.stack || value.message; }
  if (typeof value !== 'string') { value += ''; }
  return value;
};

const log = (...args: any) => {
  console.log(...args);
  const value = prepareLog(...args);
  leaveNavigationBreadcrumb(value);
};

const warn = (...args: any) => {
  if (!args) { return; }
  // if (isNotProd()) {console.warn(...args)};
  args = prepareLog(...args);
 
};

const error = (...args: any) => {
  if (!args) { return; }
  log('Error Logger');
  if (!!args && args[0] instanceof Error) {
     log(args[0]);
    } else {
      log(`LOGGER ERROR ${String(args[0])}`);
    }
  console.warn(...args);
  args = prepareLog(...args);

};

export default {log, warn, error};
