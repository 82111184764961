import {KeyboardTypeOptions, Platform, TextInputIOSProps, TextInputProps} from 'react-native';

export interface ProfileFieldConfig {
    autoCapitalize: TextInputProps['autoCapitalize'];
    autoCompleteType?: TextInputProps['autoCompleteType'];
    keyboardType: KeyboardTypeOptions;
    textContentType: TextInputIOSProps['textContentType'];
    validation: string;
}

export const fieldConfig: {[key: string]: ProfileFieldConfig; } = {
    email: {
        autoCapitalize: 'none',
        autoCompleteType: 'email',
        keyboardType: 'email-address',
        textContentType: 'emailAddress',
        validation: 'max:255',
    },
    firstName: {
        autoCapitalize: 'words',
        keyboardType: 'default',
        textContentType: 'givenName',
        validation: 'max:255',
    },
    middleName: {
        autoCapitalize: 'words',
        keyboardType: 'default',
        textContentType: 'middleName',
        validation: 'max:255',
    },
    lastName: {
        autoCapitalize: 'words',
        keyboardType: 'default',
        textContentType: 'familyName',
        validation: 'max:255',
    },
    phone: {
        autoCapitalize: 'none',
        autoCompleteType: 'tel',
        keyboardType: Platform.OS === 'android' ? 'phone-pad' : 'numbers-and-punctuation',
        textContentType: 'telephoneNumber',
        validation: 'max:255|phone',
    },
    country: {
        autoCapitalize: 'words',
        autoCompleteType: 'off',
        keyboardType: 'default',
        textContentType: 'countryName',
        validation: 'max:255',
    },
    state: {
        autoCapitalize: 'words',
        autoCompleteType: 'off',
        keyboardType: 'default',
        textContentType: 'countryName',
        validation: 'max:255',
    },
    city: {
        autoCapitalize: 'words',
        autoCompleteType: 'off',
        keyboardType: 'default',
        textContentType: 'addressCity',
        validation: 'max:255',
    },
    postalCode: {
        autoCapitalize: 'characters',
        autoCompleteType: 'off',
        keyboardType: 'name-phone-pad',
        textContentType: 'postalCode',
        validation: 'max:255',
    },
    address: {
        autoCapitalize: 'none',
        autoCompleteType: 'street-address',
        keyboardType: 'default',
        textContentType: 'streetAddressLine1',
        validation: 'max:255',
    },
    vehicleNo: {
        autoCapitalize: 'none',
        autoCompleteType: 'off',
        keyboardType: 'default',
        textContentType: 'none',
        validation: 'max:255',
    },
    personalId: {
        autoCapitalize: 'none',
        autoCompleteType: 'off',
        keyboardType: 'default',
        textContentType: 'none',
        validation: 'max:255',
    },
};

export interface UserProfileFieldValidator {
    validator: string;
    visualization: string;
}
