import {confirmAlert} from 'react-confirm-alert';
import {AlertButton} from 'react-native';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {__} from '@ampeco/i18n';
import './alert.scss';
import HTML from 'react-native-render-html';
import React from 'react';

class AlertAdapter {
    alert(title: string, description?: string, buttons?: any, options?: any) {
        let alertOptions = {
            title,
            childrenElement: () => <HTML html={description} />,
            buttons: this.transformButtonsOption(buttons)
        }

        if (options?.cancelable) {
            alertOptions = Object.assign(alertOptions, {
                closeOnEscape: true,
                closeOnClickOutside: true,
            });
        } else {
            alertOptions = Object.assign(alertOptions, {
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }

        confirmAlert(alertOptions)
    }

    protected transformButtonsOption(buttons: AlertButton[]) {
        if (!buttons) {
            return [{
                label: __('message.ok'),
                onClick: (): null => null,
            }];
        }

        return buttons?.map(option => {
            return {
                label: option?.text,
                onClick: option?.onPress,
                className: option?.style || ''
            }
        });
    }
}

export default new AlertAdapter();
