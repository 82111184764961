import {EVSE, NoPaymentMethod} from "@ampeco/charge-models";
import {LocationStore, SessionStartStore} from "@ampeco/charge-stores";
import {PaymentsStore} from "@ampeco/payment-methods";
import {__} from "@ampeco/i18n";
import NavigationService from "@ampeco/navigation";
import {PaymentMethod} from "@ampeco/models";

export function startSession(evse: EVSE, attributes = {}) {
    const startSessionStore = SessionStartStore.sharedInstance();
    const additionalAttributes = {...attributes};
    startSessionStore.startSession(evse, () => {
        const locationStore = LocationStore.sharedInstance();
        const paymentsStore = PaymentsStore.sharedInstance();
        const tariff = locationStore.getTariffById(evse.tariffId);

        const isPaid = tariff && locationStore.tariffIsPaid(tariff);

        if (paymentsStore.lastPaymentMethod === null && isPaid && !paymentsStore.canUseServiceWithoutCard()) {
            throw new NoPaymentMethod(__('session.start.no-payment-method'));
        }

        return paymentsStore.lastPaymentMethod && isPaid ? paymentsStore.lastPaymentMethod : null;
    }, additionalAttributes)
}

export function addPaymentMethodAndStartSession (evse: EVSE) {
    const sessionStore = SessionStartStore.sharedInstance();
    PaymentsStore.sharedInstance().newPaymentMethod(
        NavigationService.sharedInstance().navigate.bind(NavigationService.sharedInstance()),
        () => {/**/},
        {},
        undefined,
        {
            screen: 'SessionStart',
            evseId: sessionStore.evse.identifier
        }
    ).then((paymentMethod?: PaymentMethod) => {
        sessionStore.setLoading(null);
        if (paymentMethod) {
            startSession(sessionStore.evse);
        }
    })
}
