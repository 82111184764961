import React from "react";
import WebView from "react-native-webview";

type Props = { loginUrl: string | undefined, onNavigationStateChange: (webViewState: any) => Promise<void> };

class SsoLogin extends React.Component<Props> {
    render() {
        if (!this.props.loginUrl) {
            return;
        }
        return <WebView
            visible
            incognito={true}
            source={{ uri: this.props.loginUrl }}
            onNavigationStateChange={this.props.onNavigationStateChange}
            javaScriptEnabled={true}
            domStorageEnabled={true}
        />;
    }
}

export default SsoLogin;
