import React from 'react';
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native';
import {Zone} from '@ampeco/charge-models';
import {Text, ThemeConsumer} from 'react-native-elements';
import {ChargeTheme} from '@ampeco/theme';
import Alert from '@ampeco/alert'

export default (props: {zone: Zone, key?: any}) => (
    <ThemeConsumer>{({theme}) => {
        return <View style={styles.container}>
            <Text style={[theme.Zone.textStyle, styles.textTitle]}>{props.zone.title}</Text>
            {props.zone.description && <TouchableOpacity activeOpacity={0.6} onPress={e => Alert.sharedInstance().show(props.zone.title || '', props.zone.description || '')}><Image source={theme.Zone.iconSource} style={[theme.Zone.iconStyle, styles.icon]} resizeMode={'contain'}/></TouchableOpacity>}
        </View>
    }}</ThemeConsumer>
);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        height: 20,
        width: 26,
    },
    textTitle: { maxWidth: '80%'},

});
