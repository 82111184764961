import Select from '@ampeco/select';
import {Image, StyleSheet, Text, View} from 'react-native';
import {StandaloneIcon} from '@ampeco/icons';
import React from 'react';
import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

interface Props {
    icon: {[index: string]: any};
    navigateToItem: any;
    getOptions: () => Array<{ label: any; icon: any; navigateTo: () => void; } | { label: any; icon: any; navigateTo: (argument: any) => void; }>
}

const ButtonOptions = (props: Props) => (
    <ThemeConsumer<AmpecoTheme>>{({theme}) => { return <View style={{flexDirection: 'row', alignItems: 'center'}}><Select
        options={props.getOptions()}
        itemHeight={50}
        onChange={(item: any) => {
            if (!item.navigateTo) {
                return;
            }

            item.navigateTo(props.navigateToItem);
        }}
        renderRow={(item: any) => {
            return <View style={styles.renderRowContainer}>
                <StandaloneIcon source={item.icon} style={{marginRight: 10}} />
                <Text style={[theme.Fonts.light, {fontSize: 16}]}>{item.label}</Text></View>;
        }}
        renderSelected={() => {
            return <View style={styles.wrapper}>
                <View style={styles.icon}><StandaloneIcon source={props.icon} /></View>
            </View>;
        }}
    /></View>;
    }}</ThemeConsumer>
);

export default ButtonOptions;

const styles = StyleSheet.create({
    renderRowContainer: {
        flexDirection: 'row',
        height: 40,
        alignItems: 'center',
        marginLeft: 10,
        paddingRight: 10,
        marginVertical: 4,
    },
    icon: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 25,
    },
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        width: 45,
    },
});
