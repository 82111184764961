import React, {Component} from 'react';
import {EVSE, Location} from '@ampeco/charge-models';
import {StyleSheet, StyleProp, ViewStyle, View} from 'react-native';
import Connector from './Connector';

export class ConnectorList extends Component<{ evse: EVSE; location: Location, style?: StyleProp<ViewStyle> }> {
    render() {
        return <View style={[styles.evses, this.props.style]}>
            {this.props.evse.connectors.map((connector, connectorId) => {
                return <View key={connectorId} style={styles.evse}><Connector connector={connector}/></View>;
            })}
        </View>;
    }
}

const styles = StyleSheet.create({
    evses: {
        flexDirection: 'row',
        flex: 1,
    },
    evse: {
        marginLeft: 10,
    },
});
