import humanizeDuration from 'humanize-duration';
import {__} from '@ampeco/i18n';

const humanizer = humanizeDuration.humanizer({
    delimiter: ' ',
    spacer: ' ',
});

humanizer.languages.short = {
    y: () => __('duration.y'),
    mo: () => __('duration.mo'),
    w: () => __('duration.w'),
    d: () => __('duration.d'),
    h: () => __('duration.h'),
    m: () => __('duration.m'),
    s: () => __('duration.s'),
    ms: () => __('duration.ms'),
};

export function localizedShortDuration(durationInSeconds: number, shouldCeil: boolean = false, locale?: string) {
    if (shouldCeil) {
        durationInSeconds = Math.ceil(durationInSeconds / 60) * 60;
    }

    const durationInMilliseconds = durationInSeconds * 1000;
    return humanizer(durationInMilliseconds, {
        language: 'short',
    });
}
