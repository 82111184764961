import {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import GlobalStore from '../stores/GlobalStore';

/**
 * Open the menu when a specific query parameter exists in the url and its value is true.
 * For example ?openMenu=true - will open the menu.
 *
 * @param queryParam
 */
function useOpenMenu(queryParam: string) {
    const globalStore = GlobalStore.sharedInstance();
    const router = useLocation();

    useLayoutEffect(() => {
        const interval = setTimeout(() => {
            if (!globalStore.booting) {
                const query = new URLSearchParams(router.search);
                if (query.get(queryParam) === 'true' && !globalStore.isMenuOpen) {
                    globalStore.openMenu();
                }
            }
        }, 500);

        return () => clearInterval(interval);
    }, [router, globalStore.booting]);
}

export default useOpenMenu;
