// @flow
import React from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import {TextButton} from '@ampeco/icons';
import {Text, Divider, ThemeConsumer} from 'react-native-elements';
import {Logger} from '@ampeco/logging';
import {__} from '@ampeco/i18n';
import {StandaloneIcon, ButtonIcon} from '@ampeco/icons';
import AmpecoTheme from '@ampeco/theme';

const PIN_LENGTH = 4;

interface Props {
    onPinEntered: (pinCode: string) => Promise<any>;
    title?: string;
    hideLock?: boolean;
    errorTitle?: string;
    pinLength?: number;
}
interface State {
    code: string;
    errorTitle?: string;
    hideError?: boolean;
}

const NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

class PinCodeView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            code: '',
            hideError: true,
            errorTitle: props.errorTitle,
        };
    }
    render() {
        const {code} = this.state;
        return <ThemeConsumer<AmpecoTheme>>{({theme}) => (
            <View style={[styles.wrapper, theme.PersonalAsset.wrapper]}>
                {this.renderHeader()}
                <View style={[styles.containerNumpad, theme.PersonalAsset.containerNumpad]}>
                    <View style={styles.inputSection}>
                        {this.renderCodeInputLine(theme, code)}
                    </View>
                    {this.renderError(theme)}
                    <View style={styles.numpad}>
                        {NUMBERS.map((item, key) => {
                            return this.renderButton(item, key, theme);
                        })}
                        {this.renderEraseButton(theme)}
                    </View>
                    <Divider style={styles.divider}></Divider>
                </View>
            </View>
        )}
        </ThemeConsumer>;
    }

    renderHeader = () => {
        const {title} = this.props;
        if (!title) {
            return <View />;
        }

        return <View style={styles.header}><Text>{title}</Text></View>;
    }

    renderCodeInputLine = (theme: AmpecoTheme, code: string) => {
        const codeLines = []
        const {pinLength} = this.props;
        let i = 0
        while (i < (pinLength || PIN_LENGTH)) {
            codeLines.push(<View style={[styles.line, theme.PersonalAsset.line]}><Text style={styles.numbers}>{code && code[i] ? code[i] : undefined}</Text></View>)
            i++
        }

        return codeLines
    }

    renderError = (theme: AmpecoTheme) => {
        const {code, errorTitle, hideError} = this.state;
        if (!errorTitle || hideError) {
            return <View style={styles.errorStyles} />;
        }
        return <View style={styles.errorStyles}>
            <Text style={[theme.Text.style, {color: theme.Input.errorStyle.color}]}>{errorTitle}</Text>
        </View>;
    }

    renderButton = (key: number, index: number, theme: AmpecoTheme) => {
        return <View style={styles.numpadInputWrapper} key={index}>
            <TextButton textStyle={[styles.numpadText, theme.PersonalAsset.numpadText]} style={[styles.numpadButton, theme.PersonalAsset.numpadButton]} onPress={() => this.pressedKey(key)}>{key.toString()}
            </TextButton>
        </View>;
    }

    renderEraseButton(theme: AmpecoTheme) {
        return <View style={styles.numpadInputWrapper}>
            <TouchableOpacity
                style={[styles.numpadButton, styles.noBorder, theme.PersonalAsset.numpadButton, theme.PersonalAsset.noBorder]}
                onPress={this.pressedBackspace}>
                <ButtonIcon source={theme.PersonalAsset.backIcon} />
            </TouchableOpacity>
        </View>;
    }

    pressedKey(key: number) {
        const {code, hideError} = this.state;
        if (!hideError)
            this.setState({hideError: true});
        if (code.length < PIN_LENGTH) {
            this.setState({code: code + key}, this.checkPIN);
        }
    }

    checkPIN = async (): Promise<any> => {

        const {onPinEntered} = this.props;
        const {code} = this.state;
        if (code.length !== PIN_LENGTH) {
            return Promise.resolve();
        }

        try {
            const response = await onPinEntered(code);
            if (!response) {
                this.setState({hideError: false});
                return Promise.reject(new Error('No Response'));
            } else {
                this.setState({hideError: true, code: ''});
                return Promise.resolve();
            }
        } catch (error) {
            Logger.log(error);
            this.setState({code: '', hideError: false});
            return undefined;
        }
    }

    pressedBackspace = () => {
        let {code} = this.state;
        code = code.substring(0, code.length - 1);
        this.setState({code});
    }
}

export default PinCodeView;

const space = 15;
const smallSpace = space / 2;

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        padding: 40,
    },
    containerNumpad: {
        flex: 1,
        justifyContent: 'space-around',
        top: -space,
        alignItems: 'center',
    },
    errorStyles: {
        alignSelf: 'center',
        flexDirection: 'row',
        top: space,
        height: 50,
    },
    errorIcon: {
        marginLeft: space,
    },
    inputSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    line: {
        height: 30,
        width: 40,
        borderBottomWidth: 1,
        margin: 0,
        marginRight: smallSpace * 1.3,
        marginLeft: smallSpace * 1.3,
        justifyContent: 'center',
        alignItems: 'center',
    },
    numbers: {
        textAlign: 'center',
        fontSize: 20,
    },
    numpad: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    numpadInputWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '33%',
        marginBottom: space,
    },
    numpadButton: {
        height: 60,
        width: 60,
        borderRadius: 30,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noBorder: {
        borderWidth: 0,
        height: 25,
        width: 25,
    },
    numpadText: {
        fontSize: 20,
    },
    backIcon: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: space * 2,
    },
    divider: {
        margin: -space,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
