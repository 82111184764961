import {observer} from 'mobx-react';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Field, FormStore, WithLabel} from '@ampeco/forms';
import {fieldConfig} from './types';
import {Input, Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import ProfileFieldsStore, {availableProfileFields, ProfileFieldState} from './ProfileFieldsStore';
import {SelectAdapter} from '@ampeco/select';
import {ChargeTheme} from '@ampeco/theme';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {autorun} from 'mobx';

interface Props {
    formStore: FormStore;
    profile: any;
    showEmail?: boolean;
    useCountryDropdown?: boolean;
}

@observer
class ProfileFormFields extends React.Component<Props> {

    static defaultProps = {
        useCountryDropdown: true,
    };

    componentDidMount() {
        const profileFields = ProfileFieldsStore.sharedInstance().profileFields;

        const setValidationRules = () => {
            const validators = ProfileFieldsStore.sharedInstance().validators;

            availableProfileFields.forEach(fieldName => {
                if (!this.props.showEmail && fieldName === ProfileFieldsStore.PROFILE_FIELD_EMAIL) {
                    return;
                }

                if (
                  !profileFields[fieldName]
                  || profileFields[fieldName] === ProfileFieldState.DISABLED
                ) {
                    return;
                }

                let validation = '';
                if (
                  profileFields[fieldName] === ProfileFieldState.REQUIRED
                  || profileFields[fieldName] === ProfileFieldState.VERIFIED
                ) {
                    validation += 'required|';
                }
                validation += fieldConfig[fieldName].validation;

                if (validators && validators[fieldName]) {
                    validation += '|' + validators[fieldName].validator;
                }
                this.props.formStore.rules[fieldName] = validation;

            });
        }

        const populateFormFields = () => {
            if (this.props.profile !== null) {
                availableProfileFields.forEach(fieldName => {
                    if (!this.props.showEmail && fieldName === ProfileFieldsStore.PROFILE_FIELD_EMAIL) {
                        return;
                    }

                    if (
                      !profileFields[fieldName]
                      || profileFields[fieldName] === ProfileFieldState.DISABLED
                    ) {
                        return;
                    }

                    this.props.formStore.form[fieldName] = this.props.profile[fieldName] as string;
                });
            }
        }

        autorun(() => {
            setValidationRules();
            populateFormFields();
        })
    }

    render() {
        const profileFieldsStore = ProfileFieldsStore.sharedInstance();
        const profileFields = profileFieldsStore.profileFields;
        const validators = profileFieldsStore.validators;
        const fields: Element[] = [];

        const countryList = profileFieldsStore.countryList;
        console.log(this.props.formStore.form.country); // render on country change

        return <ThemeConsumer<ChargeTheme>>{({theme}) => {
            let hasRequiredFields = false;
            const globalStore = GlobalStoreProxy.sharedInstance();

            availableProfileFields.forEach(fieldName => {
                if (!this.props.showEmail && fieldName === ProfileFieldsStore.PROFILE_FIELD_EMAIL) {
                    return;
                }

                if (
                  !profileFields[fieldName]
                  || profileFields[fieldName] === ProfileFieldState.DISABLED
                ) {
                    return;
                }

                let fieldLabel = __('profile.label.' + fieldName);
                if (validators && validators[fieldName]) {
                    fieldLabel += ' (' + validators[fieldName].visualization + ')';
                    this.props.formStore.messages[fieldName] = {};
                    this.props.formStore.messages[fieldName][validators[fieldName].validator] = __('message.validation_error.' + fieldName).replace(':format', validators[fieldName].visualization);
                }
                const fieldConf = fieldConfig[fieldName];

                if (
                  profileFields[fieldName] === ProfileFieldState.REQUIRED
                  || profileFields[fieldName] === ProfileFieldState.VERIFIED
                ) {
                    hasRequiredFields = true;
                    fieldLabel += ' *';
                }
                if (!this.props.formStore.form?.country && globalStore.defaultRegion?.country) {
                    this.props.formStore.form.country = globalStore.defaultRegion.country;
                }
                if (fieldName === 'country' && this.props.useCountryDropdown) {
                    const countries: { key: string, label: string }[] = [
                        {key: '', label: fieldLabel},
                    ];

                    countryList.forEach(elem => countries.push(elem));

                    fields.push(<Field
                      name={fieldName}
                      type={SelectAdapter}
                      key={fieldName}
                      options={countries}
                      styleSelectProp={theme.Select.selectPlaceholderStyle}
                      styleProp={styles.selectFieldStyle}
                      fullWidth={true}
                      underline={true}
                    />);
                } else if (fieldName === 'state' && this.props.useCountryDropdown) {
                    const selectedCountry = this.props.formStore.form.country;

                    if (!selectedCountry) {
                        delete this.props.formStore.form.state;
                        return;
                    }
                    const countryModel = countryList.find(country => country.key === selectedCountry);
                    if (!countryModel) {
                        delete this.props.formStore.form.state;
                        return;
                    }
                    if (!countryModel.states) {
                        delete this.props.formStore.form.state;
                        return;
                    }
                    const states = [{
                        key: '',
                        label: fieldLabel,
                    }];

                    countryModel.states.forEach(element => states.push(element));

                    fields.push(<Field
                      name={fieldName}
                      type={SelectAdapter}
                      key={fieldName}
                      options={states}
                      styleSelectProp={theme.Select.selectPlaceholderStyle}
                      styleProp={styles.selectFieldStyle}
                      fullWidth={true}
                      underline={true}
                    />);

                } else {
                    fields.push(<React.Fragment key={fieldName}><WithLabel label={fieldLabel}><Field
                      type={Input}
                      name={fieldName}
                      key={fieldName}
                      autoCapitalize={fieldConf.autoCapitalize}
                      autoCompleteType={fieldConf.autoCompleteType}
                      keyboardType={fieldConf.keyboardType}
                      textContentType={fieldConf.textContentType}
                      style={{textAlign: 'left'}}
                    /></WithLabel></React.Fragment>);
                }
            });

            const noticeStyle = StyleSheet.flatten([styles.noticeStyle, theme.Text.style]);

            return <View>
                {hasRequiredFields && <Text style={noticeStyle}>{__('profile.notice.required_fields')}</Text>}
                {!hasRequiredFields && <Text style={noticeStyle}>{__('profile.notice.all_fields_optional')}</Text>}
                {fields}
            </View>;
        }}</ThemeConsumer>;
    }
}

export default ProfileFormFields;

const styles = StyleSheet.create({
    selectFieldStyle: {
        paddingVertical: 5,
        marginTop: 5,
        marginBottom: 20,
    },
    noticeStyle: {
        marginBottom: 20,
    },
});
