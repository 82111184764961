import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {__, LocaleStore} from '@ampeco/i18n';
import {Text, ThemeConsumer} from 'react-native-elements';
import {View} from 'react-native';
import {Switch} from '@ampeco/forms';
import {Profile} from '@ampeco/charge-api';

interface Props {
    shouldPersist: boolean;
    profileValue: boolean;
    onValueChange: (value: boolean) => void;
}

@observer
export default class ReceiveNewsAndPromos extends Component<Props> {
    render() {
        LocaleStore.observeLanguage();

        const props = this.props;
        return <ThemeConsumer<any>>{({theme}) => {
            return  <View style={theme.Settings.viewNoBottom}>
                <Text style={theme.Settings.label}>{__('settings.news-and-promotions-label')}:</Text>
                <View>
                    <Switch
                        value={props.profileValue}
                        onChange={async (value) => {
                            if (props.shouldPersist) {
                                props.onValueChange(value);
                                await Profile.updateNewsAndPromotionsPreferences(value);
                            }
                        }}
                    />
                </View>
            </View>;
        }}</ThemeConsumer>;
    }
}
