import React from 'react';
import {BasePage} from "../BasePage";
import {Forgotten} from "@ampeco/auth-screens";

const ForgottenPasswordWrapper = () => {
    return (
        <BasePage title={'forgotten.title'}>
            <Forgotten />
        </BasePage>
    )
}

export default ForgottenPasswordWrapper;
