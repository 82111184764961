import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-elements';
import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

interface Step {
    stepNumber: number;
    title: string;
    children: string;
}

const Step: React.FC<Step> = ({stepNumber, title, children}) => (
    <ThemeConsumer<AmpecoTheme>>{({theme}) => (
        <View style={styles.line}>
            <View style={[styles.roundNumber, theme.Components.roundNumber]}>
                <Text allowFontScaling={false} style={[styles.roundNumberText, theme.Components.roundNumberText]}>{stepNumber}</Text></View>
            <View style={styles.stepContent}>
                <Text style={[styles.stepTitle, theme.Components.stepTitle]}>{title}</Text>
                <Text style={[styles.stepDescr, theme.Components.stepDescr]}>{children}</Text>
            </View>
        </View>
    )}
    </ThemeConsumer>
);

export default Step;

const styles = StyleSheet.create({
    line: {
        flex: 1,
        flexDirection: 'row',
        marginVertical: 15,
    },
    roundNumber: {
        width: 50,
        height: 50,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    roundNumberText: {
        fontFamily: 'Roboto-Light',
        fontSize: 26,
    },
    stepContent: {
        justifyContent: 'center',
        paddingLeft: 15,
        flex: 1,
    },
    stepTitle: {
        fontFamily: 'Roboto-medium',
        fontSize: 14,
        marginBottom: 5,
    },
    stepDescr: {
        fontFamily: 'Roboto-Regular',
        fontSize: 12,
    },

});
