import {Animated} from 'react-native';

export default class BarsAnimation {

    private numberOfBars: number;
    private offColor: string;
    private onColor: string;
    private animations: any;
    private playing?: Animated.CompositeAnimation;

    constructor(numberOfBars: number, offColor: string, onColor: string, percent?: number) {
        this.numberOfBars = numberOfBars;
        this.offColor = offColor;
        this.onColor = onColor;
        this.animations = Array.apply(null, {length: numberOfBars}).map(() => new Animated.Value(0));
        this.playing = undefined;

        this.percent = percent;
    }

    private _percent: number | undefined;

    get percent() {
        return this._percent;
    }

    set percent(value: number | undefined) {
        this._percent = value;

        this.reset();
    }

    get animatedStyles() {
        const animatedInterpolations = this.animations.map(animated => animated.interpolate({
            inputRange: [0, 1],
            outputRange: [this.offColor, this.onColor],
        }));

        return animatedInterpolations.map(interpolation => ({backgroundColor: interpolation}));

    }

    stopAnimation = () => {
        if (this.playing) {
            this.playing.stop();
            this.playing = undefined;
        }
        this.reset();
    };

    playAnimation = () => {

        const animations = this.animations.slice(this.getNumberOfOnBars());

        if (animations.length > 0) {
            this.playing = Animated.loop(
                Animated.sequence(
                    [
                        Animated.stagger(1000, animations.map(animation => (
                            Animated.timing(animation, {
                                toValue: 1,
                                duration: 500,
                                delay: 500,
                                useNativeDriver: false,
                            })
                        ))),
                        Animated.parallel(animations.map(animation => (
                            Animated.timing(animation, {
                                toValue: 0,
                                duration: 500,
                                delay: 500,
                                useNativeDriver: false,
                            })
                        ))),
                    ],
                ),
            );

            this.playing.start();
        }
    };

    private reset = () => {
        const numberOfOnBars = this.getNumberOfOnBars();
        this.animations.map(style => style.setValue(0));
        this.animations.slice(0, numberOfOnBars).map(style => style.setValue(1));
    };

    private getNumberOfOnBars = () => {
        if (this.percent !== undefined) {
            return Math.floor((this.percent / 100) * this.numberOfBars);
        }
        return 0;
    };

}
