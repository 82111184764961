import React from 'react';
import {Image, StyleSheet, TouchableOpacity, View, Platform, I18nManager} from 'react-native';
import {Button, Text, ThemeConsumer} from 'react-native-elements';
import Currency, {format} from '@ampeco/currency';
import moment from 'moment';
import {humanizeDuration} from '@ampeco/humanize-duration';
import {ButtonIcon, StandaloneIcon} from '@ampeco/icons';
import {Common} from '@ampeco/components';
import Alert from '@ampeco/alert';
import AmpecoTheme from "@ampeco/theme";

const BalanceHistoryLine: React.FC<{
    value?: {
        totalAmount: number,
        currency: Currency,
    },
    note: string,
    timestamp: Date,
    duration?: number, // in seconds
    onPress?: () => void,
    receiptUrl: string | null,
    paymentIcon?: any,
    app_info?: string,
}> = ({
    onPress,
    timestamp,
    value,
    duration,
    note,
    receiptUrl,
    paymentIcon,
    app_info,
}) => {
        const content = () => {
            return (
                <ThemeConsumer<AmpecoTheme>>{({ theme }) => {
                    return (
                        <View>
                            <View style={StyleSheet.flatten([styles.container])}>
                                <View>
                                    <Text style={[styles.timestamp, styles.labelText, theme.History?.textStyle]}>
                                        {moment(timestamp).format('YYYY-MM-DD')}
                                    </Text>
                                    {value && <View style={[styles.amountWrpper, I18nManager.isRTL ? {flexDirection: 'row-reverse'} : null]}>
                                        {value.totalAmount >= 0 ?
                                            <Text
                                                style={StyleSheet.flatten([styles.valueText, theme.Fonts?.condensedBold, theme.History?.plusStyle])}>+ </Text> :
                                            <Text
                                                style={StyleSheet.flatten([styles.valueText, theme.Fonts?.condensedBold, theme.History?.minusStyle])}>- </Text>
                                        }
                                        <Text
                                            style={StyleSheet.flatten([styles.valueText, theme.Fonts?.condensedLight, theme.History?.textStyle])}>
                                            {format(Math.abs(value.totalAmount), value.currency)}
                                        </Text>
                                    </View>}
                                </View>
                                <View style={[styles.rightContainer]}>
                                    <View style={styles.rightDescriptionWrapper}>
                                        {paymentIcon ? <Image source={paymentIcon} style={styles.paymentMethodIcon} resizeMode={'contain'}/> : null}
                                        <Text style={[styles.rightDescriptionText, theme.History?.textStyle]}>{note}</Text>
                                        {app_info ?
                                            <TouchableOpacity
                                                onPress={() => {
                                                    Alert.sharedInstance().show(
                                                        '',
                                                        app_info,
                                                    )
                                                }}
                                            >
                                                <ButtonIcon source={theme.Balance?.infoIcon} style={styles.infoIcon} />
                                            </TouchableOpacity>
                                            : null}
                                    </View>
                                    {duration !== null && duration !== undefined &&
                                        <View style={styles.durationWrapper}>
                                            <StandaloneIcon source={theme.HistoryLine.timerIcon} style={theme.HistoryLine.timerIconStyle} resizeMode={'contain'} />
                                            <Text
                                                style={StyleSheet.flatten([
                                                    styles.labelText, theme.History?.textStyle,
                                                ])}>
                                                {humanizeDuration(duration, true)}
                                            </Text></View>}
                                    {receiptUrl ? <Button
                                        containerStyle={[styles.downloadReceipt]}
                                        type="clear"
                                        icon={<StandaloneIcon source={theme.HistoryLine?.downloadIcon} style={theme.HistoryLine?.downloadIconStyle}/>}
                                        onPress={() => {
                                            Common.openURL(receiptUrl);
                                        }}/> : null}
                                </View>
                            </View>
                        </View>
                    );
                }}</ThemeConsumer>
            );
        };
        if (onPress) {
            return <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
                {content()}
            </TouchableOpacity>;
        } else {
            return content();
        }
    };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center',
    },
    rightContainer: {
        flex: 2.2,
        marginLeft: 10,
        alignItems: 'flex-end',
    },
    timestamp: {
        flexDirection: 'row',
        marginVertical: 4,
        justifyContent: 'space-between',
    },
    amountWrpper: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    durationWrapper: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    labelText: {
        fontSize: 11,
    },
    rightDescriptionWrapper: {
        marginVertical: 4,
        alignItems: 'flex-end',
    },
    paymentMethodIcon: {
        marginRight: 5,
        width: 23,
        height: 16,
    },
    rightDescriptionText: {
        fontSize: 14,
    },
    valueText: {
        height: Platform.OS === 'android' ? 24 : -1,
        fontSize: 22,
    },
    downloadReceipt: {},
    icon: {
        marginRight: 5,
        width: 20,
        height: 20,
    },
    infoIcon: {
        width: 25,
        height: 25,
        marginTop: 5,
    },
});

export default BalanceHistoryLine;
