import React from 'react';
import {Input, InputProps} from 'react-native-elements';
import {FieldInput} from './FieldInput';
import {TextStyle} from 'react-native';

export class InputAdapter extends React.Component<{
    value: any,
    onChange: (value: any) => void,
    errorMessage: string,
    editable: boolean,
    onChangeText: (value: any) => void,
} & InputProps> implements FieldInput {
    render() {

        const {
            onChange,
            value,
            onChangeText,
        } = this.props;

        return <Input
            {...this.props}
            value={value}
            onChangeText={(text: string) => {
                onChange(text);
                if (onChangeText !== undefined) {
                    onChangeText(text);
                }
            }}
        />;
    }
}
