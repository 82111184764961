import React, {useEffect} from 'react';
import {ActivityIndicator, ScrollView, StyleSheet, View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Button, CheckBox, Input, Text, ThemeConsumer} from 'react-native-elements';
import {Action, Error, Field, FormStore, FormStoreProvider, WithLabel} from '@ampeco/forms';
import InvoiceDetailsStore, {shouldShow} from '../stores/InvoiceDetailsStore';
import Select from '@ampeco/select';
import {observer, Observer} from 'mobx-react';
import AmpecoTheme from '@ampeco/theme';
import {__, LocaleStore} from '@ampeco/i18n';
import {HomeModalParamList} from '@ampeco/navigation';
// @ts-ignore
import {ButtonIcon} from '@ampeco/icons';

const form = new FormStore();

type InvoiceDetailsEditProps = StackScreenProps<HomeModalParamList, 'InvoiceDetailsEdit'>;

const InvoiceDetailsEdit: React.FC<InvoiceDetailsEditProps> = ({ navigation, route }) => {

    useEffect(() => {
        navigation.setOptions({title: __('invoices.edit-details-title')});
    }, [LocaleStore.language]);

    const message = route.params?.message;

    useEffect(() => {
        if (!InvoiceDetailsStore.fields) {
            InvoiceDetailsStore.loadDetails()
        }
    }, []);

    const defaultSuccessCallback = () => navigation.goBack();
    const successCallback = route.params?.successCallback || defaultSuccessCallback;

    useEffect(() => {
        form.reset();

        if (InvoiceDetailsStore.details) {
            form.form = {...InvoiceDetailsStore.details};
        }

        InvoiceDetailsStore.fields && Object.entries(InvoiceDetailsStore.fields).forEach(([key, field]) => {
            if (form.form[key] === undefined) {
                form.form[key] = field.default;
            }
        });
        form.actions.submit = async () => {
            await InvoiceDetailsStore.save(form.form);
            successCallback();
        };

    }, [InvoiceDetailsStore.ready]);

    return <ThemeConsumer<AmpecoTheme>>{
        ({theme}) => (
            <Observer>{() => (!InvoiceDetailsStore.ready || !InvoiceDetailsStore.fields ? <ActivityIndicator/> : <SafeAreaView style={[]}>
                <ScrollView>
                    <View style={theme.Container.layout}>
                        {message && <Text style={styles.message}>{message}</Text>}
                        <FormStoreProvider store={form}>
                            {InvoiceDetailsStore.fields && Object.entries(InvoiceDetailsStore.fields).map(([key, field]) => (
                                <React.Fragment key={key}>
                                    {shouldShow(form, field)
                                        && <WithLabel label={field.label}>
                                            <Field
                                                type={field.type === 'input' ? Input
                                                    : (field.type === 'checkbox' ? CheckBox
                                                        : (field.type === 'select' ? Select : Input))}
                                                name={key}
                                                fullWidth={true}
                                                styleProp={field.type === 'select' ? {
                                                    borderBottomColor: theme.Divider.style.backgroundColor,
                                                    borderBottomWidth: 1,
                                                    paddingVertical: 5,
                                                    marginBottom: 20,
                                                } : {}}
                                                options={field.options ? Object.keys(field.options).map(key => ({
                                                    key,
                                                    label: field.options && field.options[key],
                                                })) : []}

                                            />
                                        </WithLabel>
                                    }

                                </React.Fragment>
                            ))}
                            <Error/>
                            <Action type={Button}
                                    title={__('invoices.save-button')}
                                    icon={<ButtonIcon source={theme.Invoices.saveButtonIcon}/>}
                                    name="submit"/>
                        </FormStoreProvider>
                    </View>
                </ScrollView>
            </SafeAreaView>)}</Observer>
        )}</ThemeConsumer>;
};

export default observer(InvoiceDetailsEdit);

const styles = StyleSheet.create({
    message: {
        marginBottom: 25,
    },
});
