import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router-dom";
import {observer} from 'mobx-react';
import NotFound from '../../pages/NotFound/NotFound';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import './DisplayComponentStyles.scss';
import EvseList from './EvseList';
import DisplayStore from "../../stores/DisplayStore";
import DisplayWebsocketMonitor from '../../services/DisplayWebsocketMonitor';
import settings from "../../settings";

interface MatchParams {
    identifier: string
}

const DisplayComponent = observer(({match}: RouteComponentProps<MatchParams>): JSX.Element => {
    const chargePointIdentifier = match?.params?.identifier;
    if (chargePointIdentifier === undefined) {
        return <NotFound />;
    }

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const displayStore = DisplayStore.sharedInstance();

    const loadData = async () => {
        try {
            setLoading(true);
            setError(null);

            await displayStore.loadFromBackend(chargePointIdentifier);

            DisplayWebsocketMonitor.init(() => settings.broadcast);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();

        document.body.classList.add('displays');

        return () => {
            document.body.classList.remove('displays');
        }
    }, []);

    useEffect(() => {
        if (error !== null) {
            const timeout = setTimeout(() => {
                loadData();
            }, 5 * 60 * 1000); // 5 minutes

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [error]);

    if (loading) {
        return <div className="loading"><ConfigurableActivityIndicator /></div>;
    }

    return (
        <div className="display-wrapper">
            {
                !error
                    ? displayStore.chargePoint && <EvseList chargePoint={displayStore.chargePoint}/>
                    : <div className="systemError">
                        <h2>Няма връзка с централния сървър. <br/>Възможно е станцията все пак да може да бъде използвана с мобилното приложение или през уеб портала.</h2>
                        <h3>No connection to the display cloud. <br/>The charging station may still be usable via the mobile app.</h3>
                    </div>
            }
        </div>
    );
});

export default DisplayComponent;
