
import {SCAService, PaymentTransaction as Transaction} from '@ampeco/models';
import {PaymentsStore} from '@ampeco/payment-methods';
import StripeSCAService from './StripeSCAService';
import GenericSCAService from './GenericSCAService';

export default class SCAServiceFactory {
    public static get(transaction: Transaction | null): SCAService {
        switch (PaymentsStore.sharedInstance().payments?.processor) {
            case PaymentsStore.PROCESSOR_STRIPE:
                return new StripeSCAService(transaction);
            default:
                return new GenericSCAService(transaction);
        }
    }
}