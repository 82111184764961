export const evseMatches = (evse: any, evse_id: string, keys: string[]) => {
    for (const key of keys) {
        if (
            ((evse[key]) + '' === evse_id + '') ||
            (((evse[key]) + '').toUpperCase() === (evse_id + '').toUpperCase())
        ) {
            return true;
        }
    }
    return false;
};
