import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {FavoritesStore} from '@ampeco/charge-stores';
import {TouchableHighlight, View} from 'react-native';
import {Divider, Text} from 'react-native-elements';
import InfoBoxAddressComponent from '../components/Map/InfoBoxAddressComponent';
import {ConnectorTypesList} from '@ampeco/charge-screens';
import NavigationService from '@ampeco/navigation';
import {icons} from '../res/images';
import {__} from '@ampeco/i18n';
import GlobalStore from '../stores/GlobalStore';
import LocationStore from '@ampeco/charge-stores/LocationStore';
import {BasePage} from "./BasePage";

const Favorites = observer(() => {
    const globalStore = GlobalStore.sharedInstance();
    const favoritesStore = FavoritesStore.sharedInstance();
    const locationStore = LocationStore.sharedInstance();

    useEffect(() => {
      favoritesStore.loadFromBackend();
   }, [globalStore.booting]);

    const isLastElement = (index: number) => {
        return index === favoritesStore.favorites.length - 1;
    };

    return (
        <BasePage title={'favorites.title'}>
            <View style={styles.container}>
                {favoritesStore.favorites && favoritesStore.favorites.length > 0 &&
                    <View>
                        {favoritesStore.favorites.map((favorite, index) => {
                            return <TouchableHighlight
                                key={index} onPress={async () => {
                                locationStore.focusedLocationId = favorite.id.toString();
                                await NavigationService.sharedInstance().navigate('Location', {
                                    locationId: favorite.id
                                });
                            }}
                            >
                                <View>
                                    <InfoBoxAddressComponent location={favorite} showDetails />
                                    <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <ConnectorTypesList location={favorite} limit={5}/>
                                        <TouchableHighlight onPress={() => favoritesStore.toggleFavorite(favorite)}>
                                            <img alt={__('favorites.remove')} src={icons.delBtn} height={'30'} width={'30'}/>
                                        </TouchableHighlight>
                                    </View>
                                    {!isLastElement(index) && <View style={{marginBottom: 10}}>
                                        <Divider/>
                                    </View>}
                                </View>
                            </TouchableHighlight>;
                        })}
                    </View>
                }

                {favoritesStore.favorites && favoritesStore.favorites.length === 0 && <View>
                    <Text>{__('favorites.no-data')}</Text>
                </View>}
            </View>
        </BasePage>
    )
});

export default Favorites;

const styles = {
    container: {
        marginTop: 20,
    },
};
