import {observer} from 'mobx-react';
import React from 'react';
import {__} from '@ampeco/i18n';
import {I18nManager, View} from 'react-native';
import {Action, Error, Field, FormStore, FormStoreProvider} from '@ampeco/forms';
import {Button, IconNode, Input, Text, ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import {shouldRedeemButtonBeDisabledCall, redeemVoucher} from './api';

const formStore = new FormStore();
formStore.rules.code = 'required|min:4';

interface Props {
    code?: string;
    actionIcon: IconNode;
    isLoggedIn: boolean;
    callback: () => void;
}

@observer
export default class RedeemVoucherForm extends React.Component<Props, { disableButton: boolean }> {

    constructor(props: Props) {
        super(props);
        this.state = {
            disableButton: false,
        }
    }

    componentDidMount() {
        shouldRedeemButtonBeDisabledCall().then(maxAttemptsReached => this.setState({
            disableButton: maxAttemptsReached,
        }));
        formStore.reset();
        formStore.form.code = this.props.code || null;
        formStore.actions.redeem = async () => {
            try {
                await redeemVoucher(formStore.form.code as string);
                this.props.callback();
            } catch (e) {
                if (e.response.status === 429) {
                    this.setState({disableButton: true})
                }
                throw e;
            }

        };
    }

    render() {
        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => {
                return <FormStoreProvider store={formStore}>
                    <View style={theme.Vouchers.codeContainer}>
                        <Text style={theme.Vouchers.textLabel}>{__('redeem.code-label')}</Text>
                        <Field
                            type={Input}
                            placeholder="ABCDEFG"
                            textAlign={I18nManager.isRTL ? 'end' :'right'}
                            name="code"
                            autoCapitalize="characters"
                            inputContainerStyle={theme.Vouchers.codeInputContainer}
                            containerStyle={{
                                flex: 1,
                            }}
                            inputStyle={[theme.Vouchers.codeInput, {marginTop: 40, textAlign: 'left'}]}
                            errorStyle={{paddingTop: 20, alignSelf: 'flex-end'}}
                        />
                    </View>

                    <Error />

                    {this.props.isLoggedIn &&
                        <>
                            <Action
                                validateOnPress={true}
                                type={Button}
                                icon={this.props.actionIcon}
                                name="redeem"
                                title={__('redeem.button')}
                                style={theme.Vouchers.button}
                                disabled={this.state.disableButton}

                                // containerStyle={{marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0, paddingLeft: 0, paddingRight: 0}}
                            />
                            <Text
                                style={theme.Vouchers.notice}>{this.state.disableButton ? __('redeem.max_attempts_reached') : __('redeem.notice')}</Text>
                        </>
                    }

                </FormStoreProvider>;
            }}</ThemeConsumer>
        );
    }
}
