import React from 'react';
import {Alert, StyleSheet, Text, View, ViewStyle} from 'react-native';
import {Action, Error, Field, FormStore, FormStoreProvider, PasswordRevealInput} from '@ampeco/forms';
import {__} from '@ampeco/i18n';
import {Button, Input, ThemeConsumer} from 'react-native-elements';
import {ButtonIcon} from '@ampeco/icons';
import AmpecoTheme from '@ampeco/theme';
import {OAuthAPI} from '@ampeco/auth';

interface Props {
    resetCode: string;
    onSuccess?: () => void;
    clearStore?: (callback: () => void) => void;
    style?: ViewStyle;
}

const store = new FormStore();

store.rules.username = 'required|email|max:255';
store.rules.password = 'required|strongPassword|max:255';
store.rules.again = 'required|strongPassword|max:255|same:password';

export default class ResetPassword extends  React.Component<Props> {
    componentDidMount() {
        this.props.clearStore && this.props.clearStore(store.reset);
    }

    render() {
        store.actions.resetPassword = async () => {
            const username = store.form.username as string;
            const password = store.form.password as string;
            await OAuthAPI.resetPassword(
                username,
                password,
                store.form.again as string,
                this.props.resetCode as string,
            );
            store.reset();

            await OAuthAPI.login(
                username,
                password,
            );
            this.props.onSuccess && this.props.onSuccess();
        };

        store.httpErrors[401] = __('message.error.reset_password_error');
        store.httpErrors[403] = __('message.error.reset_password_invalid_code');
        store.httpErrors[404] = __('message.error.reset_password_invalid_user');

        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => (
                <View style={[styles.formBox, {paddingHorizontal: 25, flexDirection: 'column'}, this.props.style]}>
                    <View>
                        <FormStoreProvider store={store}>
                            <Field
                                type={Input}
                                name="username"
                                placeholder={__('login.username')}
                                autoCapitalize="none"
                                autoCompleteType="username"
                                textContentType="username"
                                keyboardType="email-address"
                                style={{margin: 0, textAlign: 'left'}}
                            />
                            <Field
                                type={PasswordRevealInput}
                                name="password"
                                placeholder={__('password.label.password')}
                                textContentType="password"
                                style={{margin: 0, textAlign: 'left'}}
                            />
                            <Field
                                type={PasswordRevealInput}
                                name="again"
                                placeholder={__('password.label.again')}
                                textContentType="password"
                                style={{margin: 0, textAlign: 'left'}}
                            />

                            <Error/>

                            <Action
                                type={Button}
                                icon={<ButtonIcon source={theme.Login.images.passwordSmall}
                                                  style={{width: 18, height: 21}}/>}
                                disableIfInvalid
                                validateOnPress
                                name="resetPassword"
                                title={__('reset_password.button_label')}
                                style={styles.submitButton}
                            />
                        </FormStoreProvider>
                    </View>
                </View>
            )}</ThemeConsumer>
        );
    }
}

const styles = StyleSheet.create({
    formBox: {
        flex: 0,
        width: '100%',
        justifyContent: 'center',
    },
    submitButton: {
        alignSelf: 'stretch',
    },
});
