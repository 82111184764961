import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import React from 'react';
import {GestureResponderEvent, StyleSheet, View, ViewStyle} from 'react-native';
import {ButtonIcon} from '@ampeco/icons';
import {TouchableOpacity} from 'react-native-gesture-handler';

class AddButton extends React.Component<{
    onPress?: ((event: GestureResponderEvent) => void),
    style?: ViewStyle,
    iconStyle?: ViewStyle,
}, {}> {
    render(): React.ReactElement {
        const {
            onPress,
            style,
            iconStyle
        } = this.props;

        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => (
                <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
                    <View style={[{...theme.AddButton.containerStyle}, styles.container, style]}>
                        <ButtonIcon source={theme.AddButton.addIcon} style={iconStyle}/>
                    </View>
                </TouchableOpacity>
            )}
            </ThemeConsumer>
        );
    }
}

export {AddButton};

const styles = StyleSheet.create({
    container: {
        width: 28,
        height: 28,
        borderRadius: 14,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
