import React from 'react';
import {InputProps} from 'react-native-elements';
import {FieldInput} from '@ampeco/forms/FieldInput';
import PaymentMethodDropdown from './PaymentMethodDropdown';

export class PaymentMethodDropdownAdapter extends React.Component<{
    value: any,
    onChange: (value: any) => void,
    errorMessage: string,
    editable: boolean,
} & InputProps> implements FieldInput {
    render() {

        const {
            onChange,
            value,
        } = this.props;

        return <PaymentMethodDropdown
            {...this.props}
            value={value}
            onChange={(PaymentMethodId: string) => {
                onChange(PaymentMethodId);
            }}
        />;
    }
}
