import React, {useCallback} from 'react';
import './getOurAppStyles.scss';
import {Button, ThemeConsumer} from 'react-native-elements';
import settings from '../../settings';
import {storeImages} from '../../res/images';
import {LocaleStore, __} from '@ampeco/i18n';
import {ChargeTheme} from "@ampeco/theme";

export default function GetOurApp(): JSX.Element {
    LocaleStore.observeLanguage();
    const onGoogleClick = useCallback(() => {
        const url = `http://play.google.com/store/apps/details?id=${settings.appStores.googleStoreId}`;

        window.open(url, "_blank")
    }, []);
    const onAppleClick = useCallback(() => {
        const url = `http://appstore.com/${settings.appStores.appleStoreId}`;

        window.open(url, "_blank")
    }, []);
    return <ThemeConsumer<ChargeTheme>>{({theme}) => {
        return <div className="container">
            <div style={theme.Text.style}>
                {__('get-our-app.title').replace(':appName', settings.appName)}
            </div>
            <div className="link-wrapper">
                {settings.appStores?.googleStoreId?.length > 0
                    ? (<div className="link-item" style={{marginLeft: '10px'}} onClick={onGoogleClick}><img
                        className="store-img" src={storeImages.googleStore} height={'100%'}/></div>)
                    : null
                }
                {settings.appStores?.appleStoreId?.length > 0
                    ? (<div className="link-item" onClick={onAppleClick}><img className="store-img"
                                                                              src={storeImages.appleStore}
                                                                              height={'100%'}/></div>)
                    : null
                }
            </div>
            <div className="margin-spaces" style={{display: 'none'}}>
                - {__('get-our-app.or')} -
            </div>
            <div className="add-hoc-wrapper" style={{display: 'none'}}>
                <Button title={__('get-our-app.button-title')}></Button>
                <div className='add-hoc-text'> {__('get-our-app.ad-hoc')}</div>
            </div>
        </div>;
    }}</ThemeConsumer>
}
