import {observer} from 'mobx-react';
import React from 'react';
import {ActivityIndicator, ScrollView, StyleSheet, View, Platform} from 'react-native';
import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__, LocaleStore} from '@ampeco/i18n';
import {HomeTabBarParamList} from '@ampeco/navigation';
import InfoCenterStore from '../stores/InfoCenterStore';
import {SafeAreaView} from 'react-native-safe-area-context';
import {IReactionDisposer, reaction} from 'mobx';
import Faqs from './InfoCenter/Faqs';
import HelpDesk from './InfoCenter/HelpDesk';
import AmpecoTheme from '@ampeco/theme';

type InfoCenterNavigationProp = BottomTabNavigationProp<
    HomeTabBarParamList,
    "Help"
    >;

type InfoCenterProps = {
    name?: string;
    onLogout?: () => void;
    navigation?: InfoCenterNavigationProp;
};

@observer
export default class InfoCenter extends React.Component<InfoCenterProps, {visible: boolean;}> {
    private onFocusSubscription: () => void;

    static reaction: IReactionDisposer | null = null;

    constructor(props: InfoCenterProps) {
        super(props);
        this.onFocusSubscription = () => {
            InfoCenterStore.sharedInstance().loadFromBackend();
        };
    }

    componentDidMount() {
        if (this.props.navigation) {
            this.props.navigation.setOptions({title: __('info-center.title')});
            this.props.navigation.addListener('focus', this.onFocusSubscription);
        }

        if (Platform.OS === 'web') {
            InfoCenterStore.sharedInstance().loadFromBackend();
        }

        InfoCenter.reaction = reaction(
            () => LocaleStore.sharedInstance().language,
            () => this.props.navigation?.setOptions({title: __('info-center.title')}),
        );
    }

    componentWillUnmount() {
        this.props.navigation?.removeListener('focus', this.onFocusSubscription);
        InfoCenter.reaction && InfoCenter.reaction();
        InfoCenter.reaction = null;
    }

    render() {
        console.log(LocaleStore.language); // Reload on language change

        const store = InfoCenterStore.sharedInstance();
        const faqs = store.faqs;
        const help = store.help;

        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => {
                return (
                    <SafeAreaView style={{flex: 1}}>
                        <ScrollView style={{flex: 1}}>
                            <View style={theme.InfoCenter.containerStyles}>
                                <Text style={[theme.InfoCenter.header, styles.title]}>{__('info-center.help-desk.title')}</Text>
                                {help && <HelpDesk />}
                                {!help && <ActivityIndicator />}

                                {(faqs?.length as number > 0) ? <Text style={[theme.InfoCenter.header, styles.title]}>{__('info-center.faq.title')}</Text> : null}
                                {faqs === null && <ActivityIndicator />}
                                {(faqs?.length as number > 0) ? <Faqs /> : null}

                            </View>
                        </ScrollView>
                    </SafeAreaView>
                );
            }}
            </ThemeConsumer>
        );
    }
}

const styles = StyleSheet.create({
    title: {
        marginTop: 35,
        paddingBottom: 10,
    },
});
