import React from 'react';
import Currency, {format} from '@ampeco/currency';
import {__} from '@ampeco/i18n';
import PriceRow from '../../PriceRow';
import {PriceFieldsFormatterStore} from '@ampeco/charge-stores';

interface Props {
    powerLevels: any;
    currency: Currency;
}
const AveragePowerLevelsPrices: React.FC<Props> = (props: Props) => {
    const priceFormatterStore = PriceFieldsFormatterStore.sharedInstance();

    return (
        props.powerLevels.map((level: { averagePowerUpToKw: { toString: () => string; }; pricePerMinute: number; }, levelId: number) => {
           return (<PriceRow key={'powerlevel-' + levelId} label={levelId < props.powerLevels.length - 1
                ? __('session.start.pricing.up-to').replace(':average_power_kw', Number.parseFloat(level.averagePowerUpToKw.toString()).toFixed(2).toString())
                : __('session.start.pricing.above').replace(':average_power_kw', Number.parseFloat(props.powerLevels[levelId - 1].averagePowerUpToKw.toString()).toFixed(2).toString())
            }
                      value={format(level.pricePerMinute, props.currency, __('session.start.pricing.min'))}/>)
        }))
}

export default AveragePowerLevelsPrices;
