import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PaymentMethods } from '@ampeco/payment-methods';
import { BasePage } from '../BasePage';
import NavigationService from '@ampeco/navigation';
import { InvoiceDetailsView } from '@ampeco/invoices';

function PaymentMethodsScreen(): JSX.Element {
    const navigationService = NavigationService.sharedInstance();
    const navigation: any = {
        setOptions: () => {/**/ },
        navigate: navigationService.navigate.bind(navigationService),
    };

    return <BasePage
        title={'payment_methods.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home')
        }}
    >
        <SafeAreaProvider>
            <PaymentMethods
                navigation={navigation}
                route={{ params: {} }}
            />

            <InvoiceDetailsView
                navigate={(route) => {
                    NavigationService.sharedInstance().navigate(route);
                }}
            />
        </SafeAreaProvider>
    </BasePage>;
}

export default PaymentMethodsScreen;
