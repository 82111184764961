import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import './react-accordion-styles.scss';

interface Props {
    list: any[],
    header: any,
    body: any;
}

export const AccordionList = (props: Props) => {
    const renderHeader = (item: any) => {
        return <AccordionItemHeading>
            <AccordionItemButton>
                {props.header(item)}
            </AccordionItemButton>
        </AccordionItemHeading>;
    };
    const renderBody = (item: any) => {
        return (<AccordionItemPanel>
            {props.body(item)}
        </AccordionItemPanel>);
    };
    const _renderItem = ({item, index}: any) => {

        return <AccordionItem key={index}>
            {renderHeader(item)}
            {renderBody(item)}
        </AccordionItem>;
    };
    return <Accordion allowZeroExpanded>
        {props.list.map((item, index) => _renderItem({item, index}))}
    </Accordion >;
};

