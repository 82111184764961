import {action, computed, observable} from 'mobx';
import {CurrentType, EnergyUnit, SubscriptionPlan, SubscriptionType, UserSubscription} from '@ampeco/charge-models';
import {SubscriptionPlans as API} from '@ampeco/charge-api';
import moment, {now} from 'moment';
import Currency, {format} from '@ampeco/currency';
import {__} from '@ampeco/i18n';

export default class SubscriptionPlanStore {
    private static instance: SubscriptionPlanStore;

    @observable subscriptionPlans: SubscriptionPlan[] = [];
    @observable subscriptionsEnabled: boolean = false;
    @observable showNotice: boolean = false;
    @observable currentSubscription?: UserSubscription | null;
    @observable hasFailedPayment: boolean = false;
    @observable paymentFailedDueAmount?: Number;
    @observable paymentFailedMessage?: string;
    @observable paymentFailedTitle?: string;
    @observable obligationsMessage?: string;

    static sharedInstance(): SubscriptionPlanStore {
        if (SubscriptionPlanStore.instance === undefined) {
            SubscriptionPlanStore.instance = new SubscriptionPlanStore();
        }
        return SubscriptionPlanStore.instance;
    }

    public async load() {
        try {
            this.subscriptionPlans = await API.getList();
            this.subscriptionPlans.forEach((plan: SubscriptionPlan) => {
                Currency.fetch(plan.currency_id);
                // @TODO remove the line below after the post-paid feature flag is removed from the BE,right now type is not returned and causes several side effects
                plan.type = plan.type || SubscriptionType.PRE_PAID;
            });
        } catch (e) {
            this.subscriptionPlans = [];
        }
    }

    @action.bound
    setCurrentSubscription(currentSubscription?: UserSubscription) {
        this.currentSubscription = currentSubscription || null;

        if (!this.currentSubscription) {
            return;
        }

        this.currentSubscription.plan.type = this.currentSubscription.plan.type || SubscriptionType.PRE_PAID;

        Currency.fetch(this.currentSubscription.currency_id);

        if (this.currentSubscription.has_failed_payment) {
            const isSuspended = this.currentSubscription.status === 'suspended';
            const failedPaymentMessage = isSuspended ?
                __('subscriptionPlans.payment_failed_messages.suspended') :
                __('subscriptionPlans.payment_failed_messages.not_suspended');
            this.setPaymentFailure(true, undefined, failedPaymentMessage, this.currentSubscription.total_amount.due);
        } else {
            this.resetPaymentFailure();
        }
    }

    @computed get hasSubscription(): boolean {
        if (!this.currentSubscription) {
            return false;
        }

        return moment(this.currentSubscription.end_date).diff(now()) > 0;
    }

    @action.bound
    setPaymentFailure(hasFailed: boolean, title?: string, message?: string, dueAmount?: Number) {
        this.hasFailedPayment = hasFailed;
        this.paymentFailedTitle = title;
        this.paymentFailedMessage = message;
        this.paymentFailedDueAmount = dueAmount;
    }

    @action.bound
    resetPaymentFailure() {
        this.hasFailedPayment = false;
        this.paymentFailedTitle = undefined;
        this.paymentFailedMessage = undefined;
        this.paymentFailedDueAmount = undefined;
    }

    @action.bound
    setCurrentSubscriptionToPending() {
        if (this.currentSubscription) {
            this.currentSubscription.status = 'pending';
        }
    }

    public async canUseChargingAllowance(evseId: string) {
        if (this.subscriptionsEnabled) {
            return API.canUseChargingAllowance(evseId).catch(() => false);
        } else {
            return false;
        }
    }

    public getRemainingAllowance(currentType: CurrentType, energyUnit: EnergyUnit = 'kWh'): number {
        if (! this.currentSubscription || this.currentSubscription.charging_allowance_type === 'none') {
            return 0;
        }

        const remainingKwh = this.currentSubscription.charging_allowances[currentType] - this.currentSubscription.charging_allowance_usage[currentType];

        return energyUnit === 'kWh' ? remainingKwh : remainingKwh * 1000;
    }

    public getFormattedDueAmount() {
        if (!this.currentSubscription) {
            return '0.0';
        }

        return format(this.currentSubscription.total_amount.due, Currency.where(this.currentSubscription.currency_id));
    }

    public getCurrentSubscriptionPlan() {
        return this.currentSubscription?.plan;
    }

    @action.bound
    public async fetchObligationsMessage() {
        return API.hasObligationsMessage().then((response: any) => {
            this.obligationsMessage = response.message;
        }).catch(() => false);
    }

    @action.bound
    public makeObligationsMessageSeen() {
        return API.makeObligationsMessageSeen().catch(() => false);
    }
}
