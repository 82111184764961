// A singleton that holds the currently displaying session object
import {action, computed, observable} from 'mobx';
import {Session, UNLOCK_NOT_SUPPORTED_STATUS, UnlockConnectorChangesPayload} from '@ampeco/charge-models';
import {Session as SessionAPI} from '@ampeco/charge-api';

export default class SessionSummaryStore {
    private static instance: SessionSummaryStore;

    @observable session?: Session = undefined;

    @observable isWaitingForUnlockConnectorResult: boolean = false;
    @observable unlockConnectorResult: UnlockConnectorChangesPayload | null = null;

    static sharedInstance(): SessionSummaryStore {
        if (SessionSummaryStore.instance === undefined) {
            SessionSummaryStore.instance = new SessionSummaryStore();
        }
        return SessionSummaryStore.instance;
    }

    async loadSessionInfo(sessionId: string, clearCurrentSession: boolean = true) {
        if (clearCurrentSession) {
            this.session = undefined;
        }
        const session = await SessionAPI.info(sessionId)
        this.setSession(session)
    }

    @action.bound
    setSession(session: Session) {
        this.session = session
    }

    @action.bound
    setUnlockConnectorResult(result: UnlockConnectorChangesPayload | null) {
        this.unlockConnectorResult = result;
    }

    @computed get isUnlockConnectorEnabled() {
        if (this.isWaitingForUnlockConnectorResult) {
            return false;
        }

        if (!this.unlockConnectorResult) {
            return true;
        }

        return !this.unlockConnectorResult.unlocked && this.unlockConnectorResult.failReason !== UNLOCK_NOT_SUPPORTED_STATUS;
    }
}
