import React from 'react';
import GlobalStore from "../../stores/GlobalStore";
import {observer} from 'mobx-react';
import {Register} from "@ampeco/charge-screens";
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import {View} from "react-native";
import {useLocation} from "react-router-dom";
import {BasePage} from "../BasePage";

type LocationState = {
  title: string;
  url: string;
};

const RegisterWrapper: React.FC = observer(() => {
    const location = useLocation<LocationState>();
    const globalStore = GlobalStore.sharedInstance();

    const navigation: any = {
        title: location.state?.title,
        url: location.state?.url,
        getParam: (query: string) => {
            if (query === 'steps') {
                return [globalStore.flow.activeStep, globalStore.flow.totalSteps];
            }

            if (query === 'flow') {
                return globalStore.flow
            }

            return navigation[query];
        }
    };

    return (
        <BasePage title={'register.title'}>
            {!globalStore.booting && <Register navigation={navigation}/>}
            {globalStore.booting && (
                <View style={{marginTop: 20}}>
                    <ConfigurableActivityIndicator/>
                </View>
            )}
        </BasePage>
    )
});

export default RegisterWrapper;
