import {StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';
import React, {useState} from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import {ButtonIcon} from '@ampeco/icons';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';

const SwipeButton: React.FC<{
    position: number,
    del?: boolean,
    active?: boolean,
    onPress: (callback: (loading: boolean) => void) => void,
    label: string,
    style?: ViewStyle,
    icon?: any,
}> = ({
    position,
    del,
    onPress,
    label,
    style,
    active,
    icon,
}) => {
    const [loading, setLoading] = useState(false);

    return(
        <ThemeConsumer<AmpecoTheme>>{({theme}) => (<TouchableOpacity
            style={[
                styles.backRightBtn,
                style,
                {right: 65 * position, ...(del ? theme.SwipeButton.deleteStyle : (active ? theme.SwipeButton.activeStyle : theme.SwipeButton.editStyle))},
            ]}
            onPress={() => {
                onPress((loading) => {
                    setLoading(loading);
                });
            }}
        >
            {!loading && icon && <ButtonIcon style={StyleSheet.flatten([styles.textIcon, {tintColor: theme.SwipeButton.textStyle.color}])} source={icon}/>}
            {!loading && <Text style={StyleSheet.flatten([styles.text, theme.SwipeButton.textStyle])}>
                {label}
            </Text>}
            {loading && <ConfigurableActivityIndicator/>}
        </TouchableOpacity>)}</ThemeConsumer>
    );
};

export default SwipeButton;

const styles = StyleSheet.create({
    text: {
        fontSize: 10,
        textAlign: 'center',
    },
    textIcon: {
        marginBottom: 10,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 65,
    },
});
