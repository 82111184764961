// WARNING: When renaming an image file, make sure that the tenants overriding it are renamed as well!

import settings from "../settings";

export const icons = {
    finish: `${settings.images}/icon-finish.png`,
    back: `${settings.images}/icon-back.png`,
    forward: `${settings.images}/icon-forward.png`,
    burger: `${settings.images}/icon-burger.png`,
    close: `${settings.images}/icon-close.png`,
    login: `${settings.images}/icon-login.png`,
    emailSmall: `${settings.images}/icon-email-small.png`,
    passwordSmall: `${settings.images}/icon-password-small.png`,
    facebook: `${settings.images}/icon-facebook-login.png`,
    google: `${settings.images}/icon-google-login.png`,
    receipts: `${settings.images}/icon-receipts.png`,
    disclosure: `${settings.images}/icon-disclosure.png`,
    settings: `${settings.images}/icon-settings.png`,
    redeem: `${settings.images}/icon-redeem.png`,
    freeCharging: `${settings.images}/icon-freeCharging.png`,
    terms: `${settings.images}/icon-terms.png`,
    plus: `${settings.images}/icon-plus.png`,
    mapTabBar: `${settings.images}/icon-map.png`,
    qrCodeTabBar: `${settings.images}/icon-qrcode-tabBar.png`, // TabBarButton
    info: `${settings.images}/icon-info.png`,
    checkboxOff: `${settings.images}/checkbox-off.png`,
    checkboxOn: `${settings.images}/checkbox-on.png`,
    register: `${settings.images}/icon-register.png`,
    dropDown: `${settings.images}/icon-drop-down.png`,
    placeholder: `${settings.images}/icon-placeholder.png`,
    export: `${settings.images}/icon-export.png`,
    password: `${settings.images}/icon-password.png`,
    removeAccount: `${settings.images}/icon-removeAccount.png`,
    save: `${settings.images}/icon-save.png`,
    add: `${settings.images}/icon-add.png`,
    paymentRemove: `${settings.images}/icon-payment-remove.png`,
    filter: `${settings.images}/icon-filter.png`,
    filterSelected: `${settings.images}/icon-filter-selected.png`,
    qrCode: `${settings.images}/icon-qr-code.png`,
    slideIndicator: `${settings.images}/slide-indicator.png`,
    power: `${settings.images}/icon-power.png`,
    update: `${settings.images}/icon-update.png`,
    headset: `${settings.images}/icon-headset.png`,
    envelope: `${settings.images}/icon-envelope.png`,
    startSession: `${settings.images}/icon-start-session.png`,
    endSession: `${settings.images}/icon-end-session.png`,
    cancelReservation: `${settings.images}/icon-remove.png`,
    sessionTabBar: `${settings.images}/icon-session-tab-bar.png`,
    favoriteOn: `${settings.images}/icon-favorite-on.png`,
    favoriteOff: `${settings.images}/icon-favorite-off.png`,
    favorites: `${settings.images}/icon-favorites.png`,
    trendingUp: `${settings.images}/icon-trending-up.png`,
    trendingDown: `${settings.images}/icon-trending-down.png`,
    reservation: `${settings.images}/icon-reservation.png`,
    warningBlack: `${settings.images}/icon-warning-black.png`,
    warningWhite: `${settings.images}/icon-warning-white.png`,
    payNow: `${settings.images}/icon-pay-now.png`,
    downloadPDF: `${settings.images}/icon-download-pdf.png`,
    timer: `${settings.images}/icon-timer.png`,

    calendar: `${settings.images}/icon-calendar.png`,
    tag: `${settings.images}/icon-tag.png`,
    paymentMethod: `${settings.images}/icon-payment-method.png`,
    duration: `${settings.images}/icon-duration.png`,
    flash: `${settings.images}/icon-flash.png`,
    locationPin: `${settings.images}/icon-location-pin.png`,
    rfidTags: `${settings.images}/icon-rfid-tags.png`,
    scanRfidCard: `${settings.images}/icon-scan-your-card.png`,

    search: `${settings.images}/icon-search.png`,
    distanceFlag: `${settings.images}/icon-distance-flag.png`,

    edit: `${settings.images}/icon-edit.png`,
    link: `${settings.images}/icon-link.png`,
    unlink: `${settings.images}/icon-unlink.png`,
    logout: `${settings.images}/icon-logout.png`,
    moreDetailsBelow: `${settings.images}/icon-more-details-below.png`,

    showPassword: `${settings.images}/icon-show-password.png`,
    hidePassword: `${settings.images}/icon-hide-password.png`,

    publicPlace: `${settings.images}/icon-place-public.png`,
    privatePlace: `${settings.images}/icon-place-home.png`,

    del: `${settings.images}/icon-delete.png`,
    delBtn: `${settings.images}/delete-btn-small.png`,
    adHoc: `${settings.images}/icon-adhoc.png`,
    personalTab: `${settings.images}/home-charging-tab-icon.png`,
    agileStreetsOptimized: `${settings.images}/AgileStreetsOptimized.png`,
    agileStreetsNonOptimized: `${settings.images}/AgileStreetsNonOptimized.png`,
    agileStreetsIcon: `${settings.images}/agileStreetsIcon.png`,
    agileStreetsTargetChargeIcon: `${settings.images}/agileStreetsTargetChargeIcon.png`,
    agileStreetsEndTimeIcon: `${settings.images}/agileStreetsEndTimeIcon.png`,
};

export const filterCheckboxImages = {
    off: `${settings.images}/checkbox-small-off.png`,
    on: `${settings.images}/checkbox-small-on.png`,
};

export const pinImages: {[index: string]: any;} = {
    pin: `${settings.images}/pin-single.png`,
    pinMultiple: `${settings.images}/pin-multiple.png`,
    pinAvailability001: `${settings.images}/pin-availability-001.png`,
    pinAvailability010: `${settings.images}/pin-availability-010.png`,
    pinAvailability011: `${settings.images}/pin-availability-011.png`,
    pinAvailability012: `${settings.images}/pin-availability-012.png`,
    pinAvailability013: `${settings.images}/pin-availability-013.png`,
    pinAvailability021: `${settings.images}/pin-availability-021.png`,
    pinAvailability031: `${settings.images}/pin-availability-031.png`,
    pinAvailability100: `${settings.images}/pin-availability-100.png`,
    pinAvailability101: `${settings.images}/pin-availability-101.png`,
    pinAvailability102: `${settings.images}/pin-availability-102.png`,
    pinAvailability103: `${settings.images}/pin-availability-103.png`,
    pinAvailability110: `${settings.images}/pin-availability-110.png`,
    pinAvailability111: `${settings.images}/pin-availability-111.png`,
    pinAvailability112: `${settings.images}/pin-availability-112.png`,
    pinAvailability120: `${settings.images}/pin-availability-120.png`,
    pinAvailability121: `${settings.images}/pin-availability-121.png`,
    pinAvailability130: `${settings.images}/pin-availability-130.png`,
    pinAvailability201: `${settings.images}/pin-availability-201.png`,
    pinAvailability210: `${settings.images}/pin-availability-210.png`,
    pinAvailability211: `${settings.images}/pin-availability-211.png`,
    pinAvailability301: `${settings.images}/pin-availability-301.png`,
    pinAvailability310: `${settings.images}/pin-availability-310.png`,
    homePin: `${settings.images}/pin-home.png`,
};

export const miscImages = {
    logoLarge: `${settings.images}/logo_large.png`,
    logoPrimer: `${settings.images}/logo_primer.png`,
    logoBottom: `${settings.images}/logo_bottom.png`,
    chargingBackground: `${settings.images}/charging-graphic-bg.png`,

    buttonLocation: `${settings.images}/button-location.png`,
    buttonMyLocation: `${settings.images}/button-my-location.png`,

    connectionError: `${settings.images}/no-connection-error.png`,
    unknown: `${settings.images}/unknown.png`,

    cardFront: `${settings.images}/credit-card-front.png`,
    cardBack: `${settings.images}/credit-card-back.png`,
    smallPersonalCharger: `${settings.images}/default-cp.png`,
    personalCharger: `${settings.images}/default-cp.png`,
    unavailableCloud: `${settings.images}/unavailable-cloud.png`,
};

export const connectorImages: {[index: string]: any;} = {
    j1772: `${settings.images}/connector-j1772.png`,
    type2: `${settings.images}/connector-type2.png`,
    type3: `${settings.images}/connector-type3.png`,
    ccs1: `${settings.images}/connector-combo1.png`,
    ccs2: `${settings.images}/connector-combo2.png`,
    chademo: `${settings.images}/connector-chademo.png`,
    catarc: `${settings.images}/connector-catarc.png`,
    schuko: `${settings.images}/connector-schuko.png`,
    cee16: `${settings.images}/connector-cee16.png`,
    cee32: `${settings.images}/connector-cee32.png`,
    inductive: `${settings.images}/connector-inductive.png`,
    avcon: `${settings.images}/connector-avcon.png`,
    nacs: `${settings.images}/connector-nacs.png`,
    'nema-5-20': `${settings.images}/connector-nema-5-20.png`,
    'type-e-french': `${settings.images}/connector-type-e-french.png`,
    'type-g-british': `${settings.images}/connector-type-g-british.png`,
    'type-j-swiss': `${settings.images}/connector-type-j-swiss.png`,
};

export const paymentMethodImages: {[index: string]: any;} = {
    visa: `${settings.images}/payment-method-icon-visa.png`,
    mastercard: `${settings.images}/payment-method-icon-mastercard.png`,
    maestro: `${settings.images}/payment-method-icon-maestro.png`,
    amex: `${settings.images}/payment-method-icon-amex.png`,
    paypal: `${settings.images}/payment-method-icon-paypal.png`,
    unknown: `${settings.images}/payment-method-icon-unknown.png`,
};

export const flagImages: {[index: string]: any;} = {
    bg: `${settings.images}/flag-BG.png`,
    en: `${settings.images}/flag-EU.png`,
};

export const storeImages: {[index: string]: any;} = {
    appleStore: `${settings.images}/apple-store-image.png`,
    googleStore: `${settings.images}/google-store-image.png`
};

export const displayImages: { [index: string]: any } = {
    preparing: `${settings.images}/icon-plugin.png`,
    finishing: `${settings.images}/icon-unplug.png`,
    faulted: `${settings.images}/icon-fault.png`,
    unavailable: `${settings.images}/icon-fault.png`,
    suspendedEV: `${settings.images}/icon-suspended.png`,
    suspendedEVSE: `${settings.images}/icon-suspended.png`,
    'out of order': `${settings.images}/icon-out-of-order.png`,
};
