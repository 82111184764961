import React from 'react';
import {CheckBoxProps, Text, ThemeConsumer} from 'react-native-elements';
import {Image, View} from 'react-native';
import {ChargeTheme} from '@ampeco/theme';

export default class CheckBox extends React.Component<CheckBoxProps, any> {
    constructor(props: CheckBoxProps) {
        super(props);
    }

    render() {
        return <ThemeConsumer<ChargeTheme>>{({theme}) => {
            return <View>
                <label style={{display: 'flex', alignItems: 'center' }}>
                    <Image
                        source={{
                            uri: this.props.checked ? theme?.CheckBox?.checkedIcon : theme?.CheckBox?.uncheckedIcon,
                            width: 12, height: 12
                        }}
                        resizeMode={'contain'}
                    />
                    <input
                        type="checkbox"
                        checked={this.props.checked}
                        onChange={() => {
                            this.props.onPress && this.props.onPress()
                        }}
                        style={{display: 'none'}}
                    />
                    <Text style={[theme?.CheckBox?.textStyle, {marginLeft: 10}]}>
                        {this.props.title}
                    </Text>
                </label>
            </View>
        }}</ThemeConsumer>
    }
}
