import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {EVSE, Connector} from '@ampeco/charge-models';
import {Text, ThemeConsumer} from 'react-native-elements';
import {ChargeTheme} from '@ampeco/theme';

export default (props: {evse?: EVSE, connector: Connector, key?: any}) => (
    <ThemeConsumer>{({theme}) => {
        return <View style={styles.container}>
            <View style={styles.innerContainer}>
                <Image source={theme.Connector.connectorImages[props.connector.icon]} style={[theme.Connector.iconStyle, styles.icon]} resizeMode={'center'} />
                <Text style={[theme.Connector.titleStyle, styles.textTitle]}>{props.connector.name}</Text>
                {props.evse && props.evse.maxPower !== null &&
                    <Text style={[theme.Connector.subtitleStyle, styles.textSubtitle]}>{Math.round(props.evse.maxPower / 1000)}kW {props.evse.currentType.toUpperCase()}</Text>
                }
            </View>
        </View>;
    }}</ThemeConsumer>
);

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        alignItems: 'flex-start',
        marginRight: 10,
        flex: 1,
        maxWidth: 75,
        minWidth: 35,
    },
    innerContainer: {
        alignItems: 'center',
    },
    icon: {
        height: 40,
        width: 40,
    },
    textTitle: {lineHeight: 10, fontSize: 10, marginTop: 3, textAlign: 'center'},
    textSubtitle: {lineHeight: 10, fontSize: 10, marginTop: 2},
});
