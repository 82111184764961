import React from 'react';
import Steps from './Steps';
import Flow from './Flow';
import {StackNavigationOptions} from '@react-navigation/stack';

type Props = {
    navigation?: {
        setOptions: (options: Partial<StackNavigationOptions>) => void;
    };
    route?: {
        params?: {
            steps?: [number, number];
            flow?: Flow;
        };
    };
};

interface State {
    steps?: [number, number];
    flow?: Flow;
}

/**
 * Base class for all wizards:
 * Register Profile
 * Contact details
 * Drivers licence
 * Add your first payment method
 *
 */
export default class Wizard extends React.Component<Props, State> {

    state = {
        steps: undefined,
        flow: undefined,
    };
    static getDerivedStateFromProps(props: Props) {
        const { route } = props;
        const steps = route?.params?.steps;
        const flow = route?.params?.flow;

        return {steps, flow};
    }

    protected renderSteps() {
        if (!this.state.steps) {
            return;
        }

        return <Steps
            activeStep={this.state.steps[0]}
            totalSteps={this.state.steps[1]}/>;
    }
    protected hasNext() {
        if (!this.hasSteps()) {
            return false;
        }
        return this.state.steps && this.state.steps[0] < this.state.steps[1];
    }
    protected hasSteps() {
        return this.state.steps !== undefined;
    }
    protected getFlow(): Flow|undefined {
        return this.state.flow;
    }
}
