import React from 'react';
import {StackScreenProps} from '@react-navigation/stack';
import {HomeTabBarParamList} from '@ampeco/navigation';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {SessionRunning} from './SessionRunning';
import {Observer, observer} from 'mobx-react';
import {__} from '@ampeco/i18n';
import {SafeAreaView} from 'react-native';
import {SessionChangesMonitorProxy} from '@ampeco/charge-services';
import {Session} from '@ampeco/charge-models';

type NavigationProp = StackScreenProps<HomeTabBarParamList, 'ActiveSession'>;

@observer
export class ActiveSession extends React.Component<NavigationProp> {
  componentDidMount() {
    this.props.navigation.setOptions({ title: __('session.running.title') });
  }

  onSessionStopped(session: Session) {
    GlobalStoreProxy.sharedInstance().activeSession = (session.status !== 'finished') ? session : null;

    if (session.status !== 'waiting_for_lock') {
      SessionChangesMonitorProxy.getClass()?.sessionHasEnded?.(session);
    }

    return Promise.resolve();
  }

  onSessionBoosted(session: Session) {
    GlobalStoreProxy.sharedInstance().setActiveSession(session);

    return Promise.resolve();
  }

  render() {
    return (
        <SafeAreaView style={{ flex: 1 }}>
          <Observer>
            {
              () => {
                const activeSessionId = GlobalStoreProxy.sharedInstance().activeSession?.id;
                const activeSession = GlobalStoreProxy.sharedInstance().activeSession;

                if (!activeSessionId || !activeSession) {
                  return null;
                }

                return (
                    <SessionRunning
                        session={activeSession}
                        onSessionStopped={this.onSessionStopped.bind(this)}
                        onSessionBoosted={this.onSessionBoosted.bind(this)}
                    />
                )
              }
            }
          </Observer>
        </SafeAreaView>
    )
  }
}
