import validator from 'validator';
import {__} from '@ampeco/i18n';
import {FormStore} from './FormStore';
import {isValidPhoneNumber} from 'libphonenumber-js/max';
import {PasswordPolicy} from '@ampeco/charge-models';
import { GlobalStoreProxy } from '@ampeco/charge-globalstore';

const MIN_PASSWORD_LENGTH = 6;

const required = (text: any) => {
    if (typeof text === 'string') {
        return !validator.isEmpty(text);
    } else if (typeof text === 'boolean') {
        return text;
    } else if (typeof text === 'undefined') {
        return false;
    } else if (text === null) {
        return false;
    } else {
        return true;
    }
}

const integer = (text: any, options?: string[]) => {

    if (Number.isInteger(Number(text)) && Number(text) > 0) {
        return true;
    }

    return false;
}

const numeric = (text: any, options?: string[]) => {
    return !Number.isNaN(Number(text));
}

const positive = (text: any, options?: string[]) => {
    return numeric(text) && Number(text) >= 0;
}

export const Validator: { [index: string]: (text: string, options?: string[]) => boolean } = {
    required,
    email: (text: any, options?: string[]) => {
        // @ts-ignore
        function array_flip(trans) {
            if (trans === undefined) {
                return {};
            }
            let key;
            const tmp_ar = {};

            for (key in trans) {
                if (trans.hasOwnProperty(key)) {
                    // @ts-ignore
                    tmp_ar[trans[key]] = true;
                }
            }

            return tmp_ar;
        }

        if (text === undefined) {
            return false;
        }
        if (typeof text !== 'string') {
            return false;
        }

        return validator.isEmail(text, array_flip(options));
    },
    max: (text: any, options?: string[]) => {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }
        const maxLength = Number(options[0]);
        if (typeof text === 'string') {
            return text.length <= maxLength;
        } else if (typeof text === 'number') {
            return text.toString.length <= maxLength;
        }
        return true;
    },
    min: (text: any, options?: string[]) => {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }
        const minLength = Number(options[0]);
        if (typeof text === 'string') {
            return text.length >= minLength;
        } else if (typeof text === 'number') {
            return text.toString.length >= minLength;
        }
        return true;
    },
    same(text: any, options?: string[]) {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }
        const fieldName = options[0];
        const formStore: FormStore = this as unknown as FormStore;
        if (formStore.form[fieldName] === text) {
            return true;
        }
        return false;
    },
    different(text: any, options?: string[]) {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }
        const fieldName = options[0];
        const formStore: FormStore = this as unknown as FormStore;
        if (formStore.form[fieldName] !== text) {
            return true;
        }
        return false;
    },
    regex(text: any, options?: string[]) {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }
        const regExpComponents = options[0].split('/');
        return RegExp(regExpComponents[1].replace(/[\\]/g, '\\$&'), regExpComponents[2]).test(text);
    },
    requiredIf(text: any, options?: string[]) {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }

        const fieldName = options[0];
        const fieldValue = options[1];

        const formStore: FormStore = this as unknown as FormStore;

        if (fieldValue && (['true', 'false'].includes(fieldValue.toLowerCase()) && formStore.form[fieldName] === !!fieldValue) || formStore.form[fieldName] === fieldValue) {
            return required(text)
        }

        return true
    },
    integerIf(text: any, options?: string[]) {
        if (!(options && typeof options[0] === 'string')) {
            return false;
        }

        const fieldName = options[0];
        const fieldValue = options[1];

        const formStore: FormStore = this as unknown as FormStore;

        if (fieldValue && (['true', 'false'].includes(fieldValue.toLowerCase()) && formStore.form[fieldName] === !!fieldValue) || formStore.form[fieldName] === fieldValue) {
            return integer(text)
        }

        return true
    },
    phone(text: any, options?: string[]) {
        if (typeof text === 'undefined' || text === null) {
            return true;
        }
        const value = text.toString();
        if (value === '') {
            return true;
        }
        return isValidPhoneNumber(value);
    },
    integer,
    numeric,
    positive,
    strongPassword(text: any) {
        const passwordPolicy: PasswordPolicy = GlobalStoreProxy.sharedInstance().authSettings.passwordPolicy;

        let valid = text.length >= (passwordPolicy.passwordLength ?? MIN_PASSWORD_LENGTH);

        if (passwordPolicy.useCapitalLetters) {
            valid = valid && /[A-Z]/.test(text) && /[a-z]/.test(text);
        }

        if (passwordPolicy.useDigits) {
            valid = valid && /\d/.test(text);
        }

        if (passwordPolicy.useSpecialCharacters) {
            valid = valid && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(text);
        }

        return valid;
    },
};

export function defaultErrorMessages() {
    return {
        required: __('message.error.required'),
        email: __('message.error.email'),
        max: __('message.error.max'),
        min: __('message.error.min'),
        same: __('message.error.same'),
        different: __('message.error.different'),
        regex: __('message.error.regex'),
        requiredIf: __('message.error.required'),
        integer: __('message.error.integer'),
        integerIf: __('message.error.integer'),
        numeric: __('message.error.numeric'),
        positive: __('message.error.positive'),
        phone: __('message.error.phone'),
        strongPassword: (options?: string[]) => {
            const passwordPolicy: PasswordPolicy = GlobalStoreProxy.sharedInstance().authSettings.passwordPolicy;
            const errorParts = [];
            const minPassLength = passwordPolicy.passwordLength ?? MIN_PASSWORD_LENGTH;

            if (minPassLength) {
                errorParts.push(__('message.error.password-policy.password-length').replace('{passwordLength}', minPassLength.toString()));
            }
            if (passwordPolicy.useCapitalLetters) {
                errorParts.push(__('message.error.password-policy.use-capital-letters'));
            }
            if (passwordPolicy.useDigits) {
                errorParts.push(__('message.error.password-policy.use-digits'));
            }
            if (passwordPolicy.useSpecialCharacters) {
                errorParts.push(__('message.error.password-policy.use-special-characters'));
            }

            return `${__('message.error.password-policy.password-must-be')} ${errorParts.join(', ')}.`;
        },
    };
}
