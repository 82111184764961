import React, {useEffect, useState} from 'react';
import {Image, ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import {TopUpPackages as API} from '../api';
import {__, LocaleStore} from '@ampeco/i18n';
import {Button, Text, ThemeConsumer} from 'react-native-elements';
import {Observer} from 'mobx-react';
import TopUpPackagesStore from '../stores/TopUpPackagesStore';
import {format, withNoDecimals} from '@ampeco/currency';
import {TopUpPackage} from '../TopUpPackage';
import BalanceStore from '../stores/BalanceStore';
import {PaymentsStore} from '@ampeco/payment-methods';
import {PaymentMethod} from '@ampeco/models';
import AmpecoTheme from '@ampeco/theme';
import {confirm} from '@ampeco/utils';
import {ToasterStore} from '@ampeco/toaster';
import {InvoiceDetailsStore} from '@ampeco/invoices';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import Alert from '@ampeco/alert';
import {ProfileStore} from '@ampeco/charge-stores';
import {StackScreenProps} from '@react-navigation/stack';
import Navigation, {HomeStackParamList} from '@ampeco/navigation';
import {Common} from '@ampeco/components';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';

type TopUpPackagesProps = StackScreenProps<
    HomeStackParamList,
    'TopUpPackages'
>;

const TopUpPackages: React.FC<TopUpPackagesProps> = ({navigation, route}) => {
    const store = TopUpPackagesStore.sharedInstance();
    const balanceStore = BalanceStore.sharedInstance();
    const paymentStore = PaymentsStore.sharedInstance();
    const navigationService = Navigation.sharedInstance();

    const afterPurchaseCallback = route.params?.afterPurchaseCallback;
    const autoTopUp = route.params?.autoTopUp;
    const [loading, setLoading] = useState(true);
    const [isUserInvoiceDetailsMissing, setIsUserInvoiceDetailsMissing] = useState(InvoiceDetailsStore.detailsAreMissing);

    useEffect(() => {
        const title = route.params?.autoTopUp ? __('top-up-package.title_auto') : __('top-up-package.title');
        navigation.setOptions({title});
    }, [LocaleStore.language]);

    const load = async () => {
        setLoading(true);
        try {
            store.topUpPackages = await API.get();
        } catch (error) {
            ToasterStore.sharedInstance().setMessage(__('top-up-package.fetch_top_up_packages_failure'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isUserInvoiceDetailsMissing) {
            setLoading(false);
            return;
        }
        load();

        return () => {
            paymentStore.setPaymentTransactionScaSucceeded(null);
        }
    }, [isUserInvoiceDetailsMissing]);

    const purchase = async (item: TopUpPackage, paymentMethod: PaymentMethod, enableAutoTopUp: boolean = false) => {
        setLoading(true);

        try {
            await API.purchase(item.id, paymentMethod.id, enableAutoTopUp);

            if (enableAutoTopUp) {
                balanceStore.autoTopUpPackage = item;
            }

            if (afterPurchaseCallback) {
                afterPurchaseCallback();
            }
        } catch (error) {
            if (error.response?.status === 428 && error.response?.data?.transactionId) {
                PaymentsStore.sharedInstance().selectPaymentMethod(paymentMethod);
                const shouldShowTopup = GlobalStoreProxy.sharedInstance().canAddPaymentMethod;
                navigationService.navigate('PaymentTransactionSca', {
                    paymentTransactionId: error.response?.data?.transactionId,
                    title: __('payment-transaction-sca.titles.top-up'),
                    onSuccessRoute: 'Balance',
                    onSuccessRouteParams: {
                        shouldShowTopup: () => shouldShowTopup,
                    },
                    onFailRoute: 'TopUpPackages',
                    onFailRouteParams: {
                        shouldShowTopup: () => shouldShowTopup,
                    },
                    onCancelRoute: 'Balance',
                    onCancelRouteParams: {
                        shouldShowTopup: () => shouldShowTopup,
                    },
                });
            } else {
                ToasterStore.sharedInstance().setMessage(__('top-up-package.purchase_top_up_failure'));
            }
        } finally {
            setLoading(false);
        }
    };

    const enableAutoTopUp = async (item: TopUpPackage) => {
        setLoading(true);

        try {
            await API.enableAutoTopUP(item.id);

            balanceStore.autoTopUpPackage = item;

            if (afterPurchaseCallback) {
                afterPurchaseCallback();
            }
        } catch (error) {
            ToasterStore.sharedInstance().setMessage(__('top-up-package.enable_auto_top_up_failure'));
        } finally {
            setLoading(false);
        }
    };

    const handleAutoTopUp = async (item: TopUpPackage) => {
        const paymentStore = PaymentsStore.sharedInstance();
        const paymentMethod = paymentStore.defaultPaymentMethod;
        const balance = BalanceStore.sharedInstance().walletBalance;

        if (paymentMethod) {
            if (balance > balanceStore.autoTopUpThreshHold) {
                const ok = await confirm(
                    __('top-up-package.confirm_purchase_title'),
                    __('top-up-package.confirm_auto_top_up_delayed_purchase_description')
                        .replace('{autoTopUpTriggerAmount}', format(balanceStore.autoTopUpThreshHold, item.currency))
                        .replace('{price}', format(item.price, item.currency))
                        .replace('{total}', format(item.price + item.bonus, item.currency)),
                    __('message.confirm'),
                    __('message.cancel'),
                );

                if (!ok) {
                    return;
                }

                return enableAutoTopUp(item);
            } else {
                const ok = await confirm(
                    __('top-up-package.confirm_purchase_title'),
                    __('top-up-package.confirm_auto_top_up_right_away_purchase_description')
                        .replace('{paymentMethod}', paymentMethod.name)
                        .replace('{autoTopUpTriggerAmount}', format(balanceStore.autoTopUpThreshHold, item.currency))
                        .replace('{price}', format(item.price, item.currency))
                        .replace('{total}', format(item.price + item.bonus, item.currency)),
                    __('message.confirm'),
                    __('message.cancel'),
                );

                if (!ok) {
                    return;
                }

                return purchase(item, paymentMethod, true);
            }
        } else {
            let options = {};

            if (balance <= balanceStore.autoTopUpThreshHold) {
                options = {top_up_package_id: item.id};
            }

            await paymentStore.newPaymentMethod(navigation.navigate, () => {
                // do nothing
            }, options);
            return enableAutoTopUp(item);
        }
    };

    const handleTopUp = async (item: TopUpPackage) => {
        const paymentStore = PaymentsStore.sharedInstance();
        const paymentMethod = paymentStore.defaultPaymentMethod;

        if (paymentMethod) {
            const ok = await confirm(
                __('top-up-package.confirm_purchase_title'),
                __('top-up-package.confirm_purchase_description')
                    .replace('{paymentMethod}', paymentMethod.name)
                    .replace('{price}', format(item.price, item.currency))
                    .replace('{total}', format(item.price + item.bonus, item.currency)),
                __('message.confirm'),
                __('message.cancel'),
            );

            if (!ok) {
                return;
            }

            return purchase(item, paymentMethod, false);
        } else {
            await paymentStore.newPaymentMethod(navigation.navigate, () => {}, {top_up_package_id: item.id});

            if (afterPurchaseCallback) {
                afterPurchaseCallback();
            }
        }
    };

    const onTopUpPackagePress = async (item: TopUpPackage) => {
        const profileStore = ProfileStore.sharedInstance();

        let alert = null;

        if (!profileStore.emailVerified && !profileStore.phoneVerified) {
            alert = __('top-up-package.alerts.email_phone_must_be_verified');
        } else if (!profileStore.emailVerified) {
            alert = __('top-up-package.alerts.email_must_be_verified');
        } else if (!profileStore.phoneVerified) {
            alert = __('top-up-package.alerts.phone_must_be_verified');
        }

        if (alert) {
            Alert.sharedInstance().show('', alert);
            return;
        }

        if (autoTopUp) {
            return handleAutoTopUp(item);
        } else {
            return handleTopUp(item);
        }
    };

    const shouldShowSCAError = paymentStore.isPaymentTransactionScaSucceeded === false;

    const renderSCAError = (theme: any) => {
        return <View>
            <Text style={[theme.TopUpBalance.scaErrorTitle, styles.scaErrorTitle]}>
                {__('top-up-package.sca-failed-title')}
            </Text>
            <Text style={[theme.TopUpBalance.scaErrorDescription, styles.scaErrorDescription]}>
                {__('top-up-package.sca-failed-description')}
            </Text>
        </View>;
    };

    return <ThemeConsumer<AmpecoTheme>>{({theme}) => (
        <ScrollView indicatorStyle="white" showsVerticalScrollIndicator={true} style={{flex: 1}}>
            <View style={theme.Balance.container}>
                {!loading && shouldShowSCAError ? renderSCAError(theme) : null }
                {!loading && store.topUpPackages && store.topUpPackages.length !== 0 && <Text style={{marginBottom: 24}}>{autoTopUp ? __('top-up-package.description_auto') : __('top-up-package.description')}</Text>}
                <Observer>{() => (
                    <View style={styles.container}>
                        {
                            isUserInvoiceDetailsMissing && <>
                                <Text>{__('top-up-package.invoice-details-missing')}</Text>
                                <Button title={__('invoices.missing-details-button')} onPress={() => navigationService.navigate('InvoiceDetailsEdit', {
                                    message: __('top-up-package.invoice-details-missing-additional'),
                                    successCallback: async () => {
                                        setIsUserInvoiceDetailsMissing(false);
                                        await navigationService.goBack();
                                    },
                                })} />
                            </>
                        }
                        {loading ? <ConfigurableActivityIndicator /> : null}
                        {!loading && store.topUpPackages && store.topUpPackages.length === 0 && !isUserInvoiceDetailsMissing && <Text>{__('top-up-package.no_packages')}</Text>}
                        {!loading && store.topUpPackages && store.topUpPackages.length !== 0 && !isUserInvoiceDetailsMissing && <View style={styles.packages}>{store.topUpPackages.map((item) => (
                            <TouchableOpacity style={[theme.TopUpBalance.topUpPackage, styles.package]} activeOpacity={0.6} key={item.id} onPress={() => onTopUpPackagePress(item)}>
                                <View style={styles.packageContainer}>
                                    {item.bonus > 0 &&
                                        <View style={[styles.bonusContainer, theme.TopUpBalance.bonusContainerStyle]}>
                                            <Text
                                                style={{...theme.TopUpBalance.bonus}}>{format(item.bonus, item.currency)} {__('top-up-package.bonus')}</Text>
                                        </View>}
                                    <Text
                                        adjustsFontSizeToFit
                                        numberOfLines={1}
                                        style={theme.TopUpBalance.price}>{withNoDecimals(item.price, item.currency)}</Text>
                                </View>
                            </TouchableOpacity>
                        ))}</View>}
                        {!loading && balanceStore.topUpAdditionalInformation !== '' && balanceStore.topUpAdditionalInformation && <View style={styles.textContainer}>
                            <Text style={theme.TopUpBalance.infoTextStyle}>
                                {balanceStore.topUpAdditionalInformation}
                            </Text>
                            {balanceStore.topUpLearnMoreUrl !== '' && balanceStore.topUpLearnMoreUrl && <Button
                                style={styles.learnMoreButton}
                                title={__('top-up-package.learn_more_button')}
                                onPress={() => {
                                    if (balanceStore.topUpLearnMoreUrl) {
                                        Common.openURL(balanceStore.topUpLearnMoreUrl);
                                    }
                                }}
                            />}
                        </View>}
                        {paymentStore.bankLogo ? <TouchableOpacity style={styles.logosLink} onPress={
                            () => {
                                if (paymentStore.bankLogoLink) {
                                    Common.openURL(paymentStore.bankLogoLink);
                                }
                            }
                        }>
                            <Image style={styles.paymentsLogos} source={{uri: paymentStore.bankLogo}} resizeMode="contain"/>
                        </TouchableOpacity> : null}
                    </View>
                )}</Observer>
            </View>
        </ScrollView>)}
    </ThemeConsumer>;
};

export default TopUpPackages;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',

    },
    packages: {
        flex: 0,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    package: {
        marginBottom: 25,
        height: 92,
        width: '47%',
        borderRadius: 5,
    },
    bonusContainer: {
        position: 'absolute',
        top: -6,
        right: 25,
        left: 25,
        height: 20,
        borderRadius: 10,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    packageContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    textContainer: {
        marginTop: 25,
    },
    learnMoreButton: {
        marginTop: 25,
    },
    logosLink: {
        marginTop: 20,
        height: 38,
    },
    paymentsLogos: {
        flex: 1,
    },
    scaErrorTitle: {
        marginBottom: 25,
    },
    scaErrorDescription: {
        marginBottom: 50,
    },
});
