import React from 'react';
import {InfoCenter as HelpDesk} from '@ampeco/info-center';
import {__} from '@ampeco/i18n';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {BasePage} from "../BasePage";

function InfoCenter(): JSX.Element {
    const navigation: any = {
        title: __('info-center.title'),
        setOptions: () => {/**/},
        addListener: () => {/**/},
        removeListener: () => {/**/},
    };

    return (
        <BasePage title={'info-center.help-desk.title'}>
            <SafeAreaProvider>
                <HelpDesk navigation={navigation} />
            </SafeAreaProvider>
        </BasePage>
    )
}

export default InfoCenter;
