import {action, observable} from 'mobx';

let swiperStoreInstance: SwiperStore;

export default class SwiperStore {
    static sharedInstance() {
        if (swiperStoreInstance === undefined) {
            swiperStoreInstance = new SwiperStore();
        }
        return swiperStoreInstance;
    }

    @observable isInnerSwiperActive: boolean = false;
    @action.bound
    setIsInnerSwiperActive(isActive: boolean) {
        this.isInnerSwiperActive = isActive;
    }
}
