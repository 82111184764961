import React from 'react';
import {StandardToDContext} from './StandardToDContext';
import {StandardToDTariff} from './StandardToDTariff';
import {PricePeriod, Tariff} from '@ampeco/charge-models';
import Currency from '@ampeco/currency';
import {LocationStore} from '@ampeco/charge-stores';
import {findCurrentPeriod} from './utils';

interface StandardToDProviderProps {
  tariff: Tariff;
  currency: Currency;
  isPersonalCP: boolean;
}

interface StandardToDProviderState {
  initialPeriodIndex: number | null;
  pricePeriods: PricePeriod[];
  isPricePeriodsLoading: boolean;
}

export class StandardToDProvider extends React.Component<StandardToDProviderProps, StandardToDProviderState> {
  constructor(props: StandardToDProviderProps) {
    super(props);

    this.state = {
      initialPeriodIndex: null,
      pricePeriods: [],
      isPricePeriodsLoading: false,
    }
  }
  async componentDidMount() {
    await this.loadPricePeriods();

    this.setState({
      initialPeriodIndex: findCurrentPeriod(this.state.pricePeriods),
    })
  }

  async handlePreviousDay(date: string) {
    return this.loadPricePeriods(date);
  }

  async handleNextDay(date: string) {
    return this.loadPricePeriods(date);
  }

  async loadPricePeriods(date?: string) {
    try {
      this.setState({
        isPricePeriodsLoading: true,
      });
      const pricePeriods = await LocationStore.sharedInstance().getPricePeriods(this.props.tariff.id, date);

      this.setState({
        pricePeriods,
      })
    } finally {
      this.setState({
        isPricePeriodsLoading: false,
      })
    }
  }

  render() {
    if (this.state.initialPeriodIndex === null) {
      return null;
    }

    return (
      <StandardToDContext.Provider value={{
        pricePeriods: this.state.pricePeriods,
        pricePeriodsLoading: this.state.isPricePeriodsLoading,
        handlePreviousDay: this.handlePreviousDay.bind(this),
        handleNextDay: this.handleNextDay.bind(this),
        isPersonalCP: this.props.isPersonalCP,
      }}>
        <StandardToDTariff tariff={this.props.tariff} currency={this.props.currency} initialPeriodIndex={this.state.initialPeriodIndex}/>
      </StandardToDContext.Provider>
    );
  }
}
