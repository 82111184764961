import React from 'react';
import Currency, {format} from '@ampeco/currency';
import {__} from '@ampeco/i18n';
import PriceRow from '../../PriceRow';
import {PriceFieldsFormatterStore} from '@ampeco/charge-stores';
import {PeakPowerLevel} from '@ampeco/charge-models';
import {PricingHeader} from '@ampeco/charge-screens';
interface Props {
    powerLevels: PeakPowerLevel[];
    currency: Currency;
    regularUseMinutes: number | null;
}
const PeakPowerLevelsPrices: React.FC<Props> = (props: Props) => {

    const priceFormatterStore = PriceFieldsFormatterStore.sharedInstance();

    return (
        props.powerLevels.map((level, levelId) => {

            return <React.Fragment key={'powerlevel-' + levelId}><PricingHeader padTop>{levelId < props.powerLevels.length - 1
                ? __('session.start.pricing.peak-up-to').replace(':peak_power_kw', Number.parseFloat(level.peakPowerUpToKw.toString()).toFixed(2).toString())
                : __('session.start.pricing.peak-above').replace(':peak_power_kw', Number.parseFloat(props.powerLevels[levelId - 1].peakPowerUpToKw.toString()).toFixed(2).toString())
            }</PricingHeader>
                <PriceRow label={__('session.start.pricing.regular')} value={format(level.regularPricePerMinute, props.currency, __('session.start.pricing.min'))} />
                <PriceRow label={__('session.start.pricing.excess').replace(':minutes', props.regularUseMinutes ? props.regularUseMinutes.toString() : '')} value={format(level.excessUsePricePerMin, props.currency, __('session.start.pricing.min'))} />
            </React.Fragment>;
        }))
}

export default PeakPowerLevelsPrices;
