import React from 'react';
import {Animated, StyleSheet, TouchableOpacity, View} from 'react-native';
import {__} from '@ampeco/i18n';
import {Text, ThemeProps, withTheme} from 'react-native-elements';
import BarsAnimation from './BarsAnimation';
import {ChargeTheme} from '@ampeco/theme';
import Alert from '@ampeco/alert';
import {ButtonIcon} from '@ampeco/icons';

interface Props {
    percent?: number;
    isCharging: boolean;
    isUnknown: boolean;
    evseStatus: string;
}

class GenericChargeAnimation extends React.Component<Props & ThemeProps<ChargeTheme>> {

    animations = new BarsAnimation(4, this.props.theme.GenericChargeAnimation.chargingProgressGenericOffBar, this.props.theme.GenericChargeAnimation.chargingProgressGenericOnBar);

    constructor(props: Props & ThemeProps<ChargeTheme>) {
        super(props);
    }

    componentDidMount(): void {
        this.animations.percent = this.props.percent;
        if (this.props.isCharging) {
            this.animations.playAnimation();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props & ThemeProps<ChargeTheme>>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.isCharging !== this.props.isCharging) {
            if (this.props.isCharging) {
                this.animations.playAnimation();
            } else {
                this.animations.stopAnimation();
            }
        }
    }

    componentWillUnmount(): void {
        this.animations.stopAnimation();
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const isSuspended = this.props.evseStatus === 'suspendedEV';
        let status = null;
        if (this.props.isCharging) {
            status = __('session.running.charging');
        } else if (isSuspended) {
            status = __('session.running.suspended');
        } else if (this.props.isUnknown) {
            status = null;
        } else {
            status = __('session.running.waiting');
        }


        return <View style={styles.container}>
            {!isSuspended ? this.renderStatusMessage(status) :
                <TouchableOpacity
                    style={styles.statusContainer}
                    onPress={() => {
                        Alert.sharedInstance().show('', __('personalAssets.info-alerts.suspended'));
                    }}
                >
                    {status && this.renderStatusMessage(status)}
                    <ButtonIcon source={this.props.theme.GenericChargeAnimation.infoIconSource} style={styles.infoIcon} />
                </TouchableOpacity>
            }
            <View style={[this.props.theme.GenericChargeAnimation.cellStyle, styles.cell]}>
                {this.animations.animatedStyles.map((style, id) => (
                    <Animated.View key={id} style={[styles.bar, style]} />
                ))}
                <View style={[this.props.theme.GenericChargeAnimation.cellStyle, styles.cellPlus, this.props.theme.GenericChargeAnimation.cellPlusStyle]} />
            </View>
        </View>;
    }

    renderStatusMessage(status: string) {
        return(
            <Text style={StyleSheet.flatten([styles.title, this.props.theme.GenericChargeAnimation.statusStyle])}>
                {status} {(this.props.percent && this.props.percent >= 0 && this.props.percent <= 100) ? this.props.percent + '%' : ''}
            </Text>
        )
    }
}

export default withTheme<Props, ChargeTheme>(GenericChargeAnimation);

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',

        height: 180,
    },
    cell: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: 180,
        height: 80,
        borderWidth: 3,
        borderRadius: 8,
    },
    bar: {
        borderRadius: 5,
        height: 80 - 16,
        width: (180 - (4 * 10)) / 4,
    },
    cellPlus: {
        width: 10,
        height: 35,
        position: 'absolute',
        right: -10,
        borderRadius: 5,

    },
    title: {
        color: 'white',
        marginBottom: 5,
    },
    statusContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIcon: {
        width: 25,
        height: 25,
        marginRight: 8,
        marginBottom: 5,
    },
});
