import React from 'react';
import GlobalStore from '../stores/GlobalStore';
import {Text} from 'react-native-elements';
import {observer} from 'mobx-react';
import {Image, View} from 'react-native';
import {LocationObject} from '@ampeco/charge-models';
import {icons, miscImages} from '../res/images';
import {__} from '@ampeco/i18n';
import {Dot} from 'react-animated-dots';
import colors from '../res/colors';
import NavigationService from '@ampeco/navigation';

const Logo = observer(() => {
    if (GlobalStore.sharedInstance().sessionIsRunning) {
        return <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Image source={{uri: icons.sessionTabBar}} style={[{tintColor: colors.menuBarsColor, width: 80, height: 32, marginRight: 5}]} resizeMode={'center'} />
            <Text
                style={{marginRight: 5, color: colors.menuBarsColor}} onPress={() => {
                LocationObject.fetch(GlobalStore.sharedInstance().activeSession.locationId, true);
                GlobalStore.sharedInstance().openMenu();
                NavigationService.sharedInstance().navigate('SessionRunning');
            }}>
                {__('session.running.title_screen')}
            </Text>
            <View style={{flexDirection: 'row'}}>
                <Dot style={{color: colors.menuBarsColor}}>.</Dot>
                <Dot style={{color: colors.menuBarsColor}}>.</Dot>
                <Dot style={{color: colors.menuBarsColor}}>.</Dot>
            </View>
        </View>
    }

    return <img alt="Logo" src={miscImages.logoLarge} height={'32px'}/>;
});

export default Logo;
