import {bugsnag} from '@ampeco/logging';
import {Linking, Platform} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {ToasterStore} from '@ampeco/toaster';

let emulator: boolean = false;
DeviceInfo.isEmulator()
    .then(res => emulator = res)
    // tslint:disable-next-line: no-empty
    .catch(() => {});

export function isIOS(): boolean {
    return Platform.OS === 'ios';
}

export function isAndroid(): boolean {
    return Platform.OS === 'android';
}

export function isWeb(): boolean {
    return Platform.OS === 'web';
}

export const isEmulator = (): boolean => emulator;

export const isValidURL = async (url: string) => {
    return Linking.canOpenURL(url);
};

export const appendProtocolIfMissing = (url: string, protocol: string = 'http'): string => {
    const regExp = new RegExp(/:\/\//, 'i');
    if (regExp.test(url)) {
        return url;
    }
    return protocol + '://' + url;
}

export const uniqueID = (): string => {
    function chr4() {
        return Math.random().toString(16).slice(-4);
    }
    return chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};

export const openURL = async (url: string, withErrorLogging: boolean = false) => {
    try {
        // @ts-ignore
        const embeddedWebAppUrl = GlobalStoreProxy.sharedInstance().findEmbeddedWebAppUrl !== undefined ? GlobalStoreProxy.sharedInstance().findEmbeddedWebAppUrl(url) : null;
        if (embeddedWebAppUrl && GlobalStoreProxy.sharedInstance().navigateToEmbeddedWebAppUrl !== undefined) {
            // @ts-ignore
            return GlobalStoreProxy.sharedInstance().navigateToEmbeddedWebAppUrl(embeddedWebAppUrl);
        }
        const success = await Linking.openURL(url);
        bugsnag.leaveBreadcrumb('OpenURL ' + (success ? 'Success' : 'Failed'), {
            url,
        });
    } catch (e) {
        if (withErrorLogging) {
            bugsnag.leaveBreadcrumb('OpenURL Failed', {
                url,
                exception: e,
            });
        }
    }
};
