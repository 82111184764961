import AdapterInterface from './AdapterInterface';
import {ReservationStore} from '@ampeco/charge-stores';
import moment from 'moment';
import {Reservation} from '@ampeco/charge-models';

export default class MockAdapter implements AdapterInterface {

    async reserve(evseId: string) {
        return new Promise<Reservation>((resolve, reject) => {
            setTimeout(() => {
                const mock: Reservation = {
                    id: '1',
                    status: 'active',
                    reservedAt: moment().toDate(),
                    periodMinutes: 15,
                    evseId,
                };
                ReservationStore.sharedInstance().updateReservation(mock);
                resolve(mock);
            }, 1000);
        });
    }

    async cancel(reservationId: string): Promise<{}> {
        return new Promise<{}>((resolve, reject) => {
            setTimeout(() => {
                ReservationStore.sharedInstance().updateReservation(null);
                resolve();
            }, 1000);
        });
    }

    async active(): Promise<Reservation|null> {
        return ReservationStore.sharedInstance().activeReservation;
    }

    async restriction() {
        setTimeout(() => {
            ReservationStore.sharedInstance().updateReservationRestriction(null);
        }, 100);
    }
}
