import {observer} from 'mobx-react';
import React from 'react';
import {StackScreenProps} from '@react-navigation/stack';
import {HomeModalParamList} from '@ampeco/navigation';
import {__, LocaleStore} from '@ampeco/i18n';
import {WebView, WebViewMessageEvent} from 'react-native-webview';
import {IReactionDisposer, reaction} from 'mobx';
import {Dimensions, Platform, Text, View} from 'react-native';
import {ThemeProps, withTheme} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import PaymentsStore from '../PaymentsStore';

type AddPaymentMethodProps = StackScreenProps<
  HomeModalParamList,
  'AddPaymentMethod'
> & ThemeProps<AmpecoTheme>;

// The callback is required in order to avoid using uninitialized location store

@observer
class AddPaymentMethod extends React.Component<AddPaymentMethodProps, {
    url: string,
    html: string,
    sourceType: string,
    successUrl: string,
    waitForSuccessUrl: boolean,
    onClose?: (success: boolean, shouldNavigateBack?: boolean) => void,
    loaded: boolean,
    injectedJavaScript?: string,
    onMessage?: (event: WebViewMessageEvent) => void,
}> {

    static reaction: IReactionDisposer | null = null;
    private closed = false;

    private didBlurSubscription?: any;

    constructor(props: AddPaymentMethodProps) {
        super(props);
        this.state = {
            url: props.route.params.url,
            html: props.route.params.url,
            sourceType: props.route.params.sourceType,
            successUrl: props.route.params.successUrl,
            waitForSuccessUrl: props.route.params.waitForSuccessUrl,
            onClose: props.route.params.onClose,
            loaded: false,
            injectedJavaScript: props.route.params.injectedJavaScript,
            onMessage: props.route.params.onMessage,
        };
    }

    componentDidMount(): void {
        this.props.navigation.setOptions({title: __('addPaymentMethod.title')});

        this.didBlurSubscription = this.props.navigation.addListener('blur', () => {
            if (!this.closed) {
                this.closed = true;
                if (this.state.onClose) {
                    this.state.onClose(false, false);
                }
            }
        });
        AddPaymentMethod.reaction = reaction(
          () => LocaleStore.language,
          () => {
              this.props.navigation.setOptions({title: __('addPaymentMethod.title')});
          },
        );
    }

    componentWillUnmount(): void {
        this.didBlurSubscription && this.didBlurSubscription();
        AddPaymentMethod.reaction && AddPaymentMethod.reaction();
        AddPaymentMethod.reaction = null;
    }

    _onNavigationStateChange = (webViewState: any) => {
        if (webViewState.url.startsWith(this.state.successUrl)
          && (!this.state.waitForSuccessUrl || !webViewState.navigationType)
          && !this.closed
        ) {
            if (webViewState.navigationType === 'formsubmit') {
                return;
            }

            this.closed = true;
//            this.props.navigation.goBack();
            if (this.state.onClose) {
                this.state.onClose(true);
            }
        }
    }

    _onRedirectedToUrl = (webViewState: {url: string, navigationType?: string}) => {
        if (webViewState.url && typeof webViewState.url.startsWith === 'function' && webViewState.url.startsWith(this.state.successUrl)
          && (!this.state.waitForSuccessUrl || !webViewState.navigationType)
          && !this.closed
        ) {
            this.closed = true;
            if (this.state.onClose) {
                this.state.onClose(true);
            } else {
                const paymentsStore = PaymentsStore.sharedInstance();
                if (paymentsStore.onAddPaymentMethodCloseHandler) {
                    paymentsStore.onAddPaymentMethodCloseHandler(true);
                }
            }
        }
    }


    render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <WebView
          style={[
              Platform.OS === 'web' ? styles.iframe : null,
              Platform.OS === 'web' && this.state.url.includes('rapyd') ? styles.iframeRapyd : null]}
          source={this.state.sourceType === 'uri' ? {uri: this.state.url} : {html: this.state.html} }
          onNavigationStateChange={this._onNavigationStateChange}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          injectedJavaScriptBeforeContentLoaded={this.state.injectedJavaScript}
          onLoad={() => this.setState({loaded: true})}
          renderError={(domain, code, desc) =>  (
            <View style={{flex: 1, backgroundColor: 'red'}} >
                <Text style={[{padding: 16}, this.props.theme.NetworkError.titleStyle]}>{desc}</Text>
            </View>
          )}
          onError={() => <View style={{flex: 1, backgroundColor: 'blue'}} />}
          onMessage={(event) => {
              this._onRedirectedToUrl({url: event.nativeEvent.data});
          }}
        />;
    }
}

const windowWidth = Dimensions.get('window').width;

const styles = {
    iframe: {
        height: 'calc(100vh - 135px)',
    },
    iframeRapyd: {
        transform:windowWidth > 768 ? ' scale(0.5) ' : '',
        transformOrigin:windowWidth > 768 ? ' 0 0 ' : '',
        width: windowWidth > 768 ? '750px' : '100%',
        backgroundColor: 'white'
    },
}

export default withTheme(AddPaymentMethod);
