import {toast, Slide} from "react-toastify";
import fonts from "../res/fonts";

export const ToasterStore = {
    sharedInstance: (): any => {
        const options = {
            autoClose: 8000,
            hideProgressBar: true,
            transition: Slide,
            pauseOnHover: false,
            style: {
                fontSize: 13,
                fontFamily: fonts.regular
            }
        }

        return {
            setMessage: (message: string) => {
                toast.error(message, options);
            },
            setSuccessMessage: (message: string) => {
                toast.success(message, options);
            }
        }
    }
}
