import React, {useEffect, useState} from 'react';
import {LocationStore, ReservationStore, SessionStartStore} from '@ampeco/charge-stores';
import {PriceTypes} from '@ampeco/charge-models';
import {Observer, observer} from 'mobx-react';
import {Button, Divider, Text, ThemeConsumer} from 'react-native-elements';
import GetOurApp from '../../components/Common/GetOurApp';
import {SessionHeader} from './SessionHeader';
import {__} from '@ampeco/i18n';
import {Redirect, useRouteMatch} from 'react-router';
import NavigationService from '@ampeco/navigation';
import './sessionStartStyles.scss';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import {Pricing} from '@ampeco/charge-screens';
import GlobalStore from '../../stores/GlobalStore';
import {TouchableOpacity, View} from 'react-native';
import {icons} from '../../res/images';
import {ButtonIcon} from '@ampeco/icons';
import settings from '../../settings';
import {ActiveTermStore, TermsStore} from '@ampeco/terms';
import {BasePage} from "../BasePage";
import {CompositeInput} from '@ampeco/forms';
import {BraintreeAddButton, PaymentFailureNotice, PaymentMethodSelect, PaymentsStore} from '@ampeco/payment-methods';
import {OAuthAPI} from '../../../frontend_modules/auth';
import {InvoiceDetailsStore} from '@ampeco/invoices';
import {PaymentMethod} from '@ampeco/models';
import {startSession} from "../../services/StartSession";
import StartSessionButton from "./StartSessionButton";

type MatchState = {
    evseId: string;
    locationId: string;
};

const startAdHocSessionButton = () => {
    const sessionStore = SessionStartStore.sharedInstance();
    const globalStore = GlobalStore.sharedInstance();
    const isDisabled = !!(sessionStore.loading || sessionStore.waitingForCar);

    const continueAfterTermsAreAccepted = () => {
        if (InvoiceDetailsStore.detailsRequired && !InvoiceDetailsStore.details) {
            NavigationService.sharedInstance().navigate('InvoiceDetailsEdit', {
                intendedNavigation: {
                    screen: 'SessionStart',
                    evseId: sessionStore.evse.identifier
                },
            });
        } else {
            if (GlobalStore.sharedInstance().shouldIssueRecipe) {
                NavigationService.sharedInstance().navigate('EmailReceipt', {
                    intendedNavigation: {
                        evseId: sessionStore.evse.identifier,
                        evse: sessionStore.evse
                    }
                });
            } else {
                PaymentsStore.sharedInstance().newPaymentMethod(
                  NavigationService.sharedInstance().navigate.bind(NavigationService.sharedInstance()),
                  () => {},
                  {},
                  undefined,
                  {
                      screen: 'SessionStart',
                      evseId: sessionStore.evse.identifier
                  }
                ).then((paymentMethod?: PaymentMethod) => {
                    sessionStore.setLoading(null);
                    if (paymentMethod) {
                        startSession(sessionStore.evse);
                    }
                })
            }
        }
    }
    return <>
        <Button disabled={isDisabled}
                style={{marginTop: 10}}
                title={sessionStore.loading
                    ? __('session.start.button-loading')
                    : (sessionStore.waitingForCar
                            ? __('session.start.button-waiting')
                            : __('session.start.ad-hoc-charging')
                    )}
                icon={isDisabled
                    ? <ConfigurableActivityIndicator/>
                    : <ButtonIcon source={icons.adHoc} style={{width: 21, height: 21}}/>
                }
                iconRight={false}
                onPress={async () => {
                    sessionStore.setLoading({});
                    globalStore.unsetProfile();
                    await OAuthAPI.loginAsAdHoc();
                    await globalStore.statusUpdate();

                    ActiveTermStore.sharedInstance().setOnAfterConsentCallback(() => {
                        sessionStore.setLoading(null);
                        NavigationService.sharedInstance().navigate('SessionStart', {evseId: sessionStore.evse.identifier});
                        continueAfterTermsAreAccepted();
                    });

                    TermsStore.sharedInstance().load().then(result => {
                        sessionStore.setLoading(null);
                        if (result === null) { // No active term to consent to
                            continueAfterTermsAreAccepted();
                        }
                    })
                }}
        >
        </Button>

        {!sessionStore.loading && !sessionStore.waitingForCar && <View>
            <Text style={{textAlign: 'center', marginTop: 20}}>
                {__('session.start.ad-hoc-charging-explanation')}
            </Text>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 21, marginBottom: 21}}>
                <Divider style={{flex: 1}} />
                <Text
                    style={{flex: 0, fontSize: 14, paddingLeft: 12, paddingRight: 12}}
                >{__('session.start.or')}</Text>
                <Divider style={{flex: 1}} />
            </View>
        </View>}
    </>
}

export default observer(() => {
    const match = useRouteMatch<MatchState>();
    const locationStore = LocationStore.sharedInstance();
    const sessionStore = SessionStartStore.sharedInstance();
    const globalStore = GlobalStore.sharedInstance();
    const reservationStore = ReservationStore.sharedInstance();
    const activeTermStore = ActiveTermStore.sharedInstance();
    const paymentsStore = PaymentsStore.sharedInstance();

    const [loading, setLoading] = useState(true);
    const [supportsPaidCharging, setSupportsPaidCharging] = useState(false);
    const [boost, setBoost] = useState(false);
    const [optimised, setOptimised] = useState(false);

    useEffect(() => {
        setSupportsPaidCharging(settings.supportsPayments);
        sessionStore.setLoading(null);
    }, []);

    // add loader if fails to load redirect
    useEffect(() => {
        if (match.params.evseId && (!globalStore.booting || !globalStore.activeSession)) {
            setLoading(true);
            locationStore.resolveEVSE(match.params.evseId).then(result => {
                sessionStore.set(result.evse, result.location);
                sessionStore.canStart(result.evse.id, paymentsStore.lastPaymentMethod?.id)
            }).catch(() => {
                // Evse not found and we're going to redirect to home
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [match.params.evseId, globalStore.booting, globalStore.activeSession]);

    useEffect(() => {
        if (sessionStore.evse && paymentsStore.lastPaymentMethod) {
            sessionStore.canStart(sessionStore.evse.id, paymentsStore.lastPaymentMethod.id)
        }
    }, [paymentsStore.lastPaymentMethod, sessionStore.evse])

    if (!sessionStore.evse && !loading) {
        return <Redirect push to={`/`}/>;
    }

    let content;

    if (loading && sessionStore.evse) {
        content = <View style={{marginTop: '50%'}}><ConfigurableActivityIndicator/></View>;
    }

    if (!loading) {
        const tariff = locationStore.getTariffById(sessionStore.evse.tariffId);
        const currency = (tariff && locationStore.getCurrencyByCode(tariff.currencyCode)) || null;
        const isAvailable = reservationStore.evseIsAvailable(sessionStore.evse);

        const termsUpdatedTitle = activeTermStore.activeTerm !== null
            ? __('terms-change.updated').replace('{title}', activeTermStore.activeTerm.title)
            : null;
        const dynamicOptimisedLabel = tariff?.optimisedLabel ?? __('dynamic-optimised.session-start.default_dynamic_optimised_label');
        content =  <ThemeConsumer>{({theme}) =>
            <Observer>{() => {
                return (
                    <View style={{marginTop: 28}}>
                        <View>
                            <SessionHeader location={sessionStore.location} evse={sessionStore.evse}/>
                            <Pricing evse={sessionStore.evse} currency={currency} tariff={tariff}/>
                        </View>
                        <Divider style={{marginTop: 28, marginBottom: 21}}/>
                        {!supportsPaidCharging && tariff && (settings.appStores.googleStoreId || settings.appStores.appleStoreId) && <GetOurApp/>}
                        {supportsPaidCharging && globalStore.isLoggedIn && globalStore.managedByOperator(sessionStore.evse) && !sessionStore.isEvaluatingCanStartSession ?
                            <View>
                                {tariff && locationStore.tariffIsPaid(tariff) && !paymentsStore.shouldShowBraintreeAddBtn ? <>
                                    <CompositeInput
                                        style={[{marginVertical: 21, paddingBottom: 21}]}
                                        label={__('session.start.payment-method-label')}
                                    >
                                        {(
                                                paymentsStore.canUseServiceWithoutCard()
                                                || (paymentsStore.payments && paymentsStore.payments.methods.length > 0)
                                            ) &&
                                            <PaymentMethodSelect
                                                currency={currency || undefined}
                                                paymentAddedCallback={() => {
                                                    NavigationService.sharedInstance().goBack();
                                                }}
                                            /> ||
                                            <Button
                                                icon={<ButtonIcon source={icons.add} />}
                                                onPress={() => (
                                                    paymentsStore.newPaymentMethod(
                                                        NavigationService.sharedInstance().navigate.bind(NavigationService.sharedInstance()),
                                                        () => {},
                                                        {},
                                                        undefined,
                                                        {
                                                            screen: 'SessionStart',
                                                            evseId: sessionStore.evse.identifier
                                                        }),
                                                        PaymentsStore.sharedInstance().shouldShowBraintreeAddBtn = false
                                                )}
                                                title={__('payment_methods.add')}
                                                buttonStyle={{width: '100%'}}
                                                containerStyle={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}
                                            />
                                        }
                                    </CompositeInput>
                                    <PaymentFailureNotice style={{marginHorizontal: 25, marginTop: 10}} />
                                </> : null }
                                {PaymentsStore.sharedInstance().payments?.processor === PaymentsStore.PROCESSOR_BRAINTREE ? <BraintreeAddButton /> : null}
                                {(sessionStore.unableToChargeReason.length > 0)
                                    ? <Text style={{textAlign: 'center', marginBottom: 21}}>
                                        {sessionStore.unableToChargeReason}
                                    </Text>
                                    : null
                                }
                                {/** session start button */}
                                {tariff?.priceType === PriceTypes.OPTIMISED_DYNAMIC ? (
                                    <StartSessionButton
                                        loading={loading}
                                        icon={icons.agileStreetsOptimized}
                                        onPress={() => {
                                            setOptimised(true);
                                            startSession(sessionStore.evse, {
                                            smartCharging: {
                                                enabled: true,
                                                mode: 'optimised_dynamic_pricing',
                                            }})
                                        }}
                                        optimised={optimised}
                                        buttonColor={theme.AgileStreetSlider.ecoColor}
                                        disabledButtonColor={theme.AgileStreetSlider.ecoColorDisabled}
                                        disabledTitleStyle={theme.AgileStreetSlider.ecoTextDisabled}
                                        containerStyle={{marginBottom: 20}}
                                        buttonTitle={__('optimised-off-peak.start_charging_btn_lbl').replace('{label}', dynamicOptimisedLabel) }
                                    />) : null}
                                <StartSessionButton
                                    loading={loading}
                                    icon={icons.startSession}
                                    onPress={() => {
                                        setBoost(true);
                                        startSession(sessionStore.evse);
                                    }}
                                    boost={boost}
                                    buttonColor={undefined}
                                    disabledButtonColor={theme.Button.disabledStyle?.backgroundColor}
                                    disabledTitleStyle={theme.Button.disabledTitleStyle.color}
                                    buttonTitle={tariff?.priceType === PriceTypes.OPTIMISED_DYNAMIC ? __('optimised-off-peak.start_boost_charging') : undefined}
                                />

                                {/** session expired notice */}
                                {globalStore.isLoggedIn && globalStore.managedByOperator(sessionStore.evse) && sessionStore.expirationReason &&
                                    <View>
                                        <Text style={{textAlign: 'center', marginTop: 20}}>{sessionStore.expirationReason}</Text>
                                    </View>
                                }

                            </View> : null
                        }
                        {globalStore.isLoggedIn && sessionStore.isEvaluatingCanStartSession && <ConfigurableActivityIndicator/>}

                        {globalStore.profile && !globalStore.managedByOperator(sessionStore.evse)
                            && <Text style={{textAlign: 'center'}}>{sessionStore.evse.operatedBy}</Text>}

                        {globalStore.profile && !isAvailable && !globalStore.evseIsOccupiedByCurrentUser(sessionStore.evse) &&
                            <Text style={{textAlign: 'center'}}>
                                {sessionStore.evse.status === 'out of order'
                                    ? __('session.start.out-of-order-message')
                                    : __('session.start.occupied-message')
                                }
                            </Text>
                        }

                        {globalStore.profile && sessionStore.waitingForCar &&
                            <Text style={{textAlign: 'center'}}>
                                {__('session.start.waiting-for-car-message')}
                            </Text>
                        }

                        {globalStore.profile && globalStore.activeSession && globalStore.activeSession.status === 'failed' &&
                            <Text style={{textAlign: 'center'}}>
                                {__('session.start.failed-message')}
                            </Text>
                        }

                        {/* Ad-hoc session start button */}
                        {!globalStore.isLoggedIn && (tariff && tariff.isAdHoc) ? startAdHocSessionButton() : null}

                        {!globalStore.isLoggedIn && !sessionStore.loading && !sessionStore.waitingForCar && <>
                            {<View>
                                {(tariff && !tariff.isAdHoc || !tariff) && <Text style={{textAlign: 'center', marginBottom: 21}}>{__('session.start.please-login')}</Text> }
                                <Button
                                    title={__('login.button-login')}
                                    icon={<ButtonIcon
                                        source={icons.login}
                                        style={{width: 21, height: 21,}}
                                    />}
                                    iconRight={false}
                                    onPress={() => NavigationService.sharedInstance().navigate('Login')}
                                />
                            </View>}
                        </>}

                        {activeTermStore.activeTerm !== null && <>
                            <TouchableOpacity
                                style={{marginTop: 28, marginBottom: 21}}
                                activeOpacity={0.6}
                                onPress={() => NavigationService.sharedInstance().navigate('TermConsent', {
                                    title: termsUpdatedTitle
                                })}
                            >
                                <Text style={{textAlign: 'center'}}>{termsUpdatedTitle}</Text>
                            </TouchableOpacity>
                        </>}
                    </View>
                )
            }}</Observer>
        }
        </ThemeConsumer>;
    }

    return (
        <BasePage title={'session.start.title'} onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Location', {
                locationId: sessionStore.location.id,
            });
            PaymentsStore.sharedInstance().shouldShowBraintreeAddBtn = false;
        }}>
            {content}
        </BasePage>
    )
});
