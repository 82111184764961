import React from 'react';
import {observer} from 'mobx-react';
import {View} from 'react-native';
import {ReceiveNewsAndPromos} from '@ampeco/settings';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {Button} from "react-native-elements";
import settings from "../../settings";
import {BasePage, NoMarginOverrideStyle} from "../BasePage";

const Settings = observer(() => {
    const globalStore = GlobalStoreProxy.sharedInstance();

    return (
        <BasePage title={'settings.title'} overrideStyles={NoMarginOverrideStyle}>
            <View style={styles.container}>
                <ReceiveNewsAndPromos
                    shouldPersist={!!globalStore.profile}
                    profileValue={!!(globalStore.profile && globalStore.profile.receiveNewsAndPromotions)}
                    onValueChange={(value: boolean) => {
                        if (globalStore.profile) {
                            globalStore.profile.receiveNewsAndPromotions = value;
                        }
                    }}
                />
                {settings.bugsnag.releaseStage !== 'production' && <View>
                    <Button
                        onPress={() => {
                            throw new Error('Testing Bugsnag! ' + new Date().toISOString());
                        }} title={'Press me to throw an error'}
                    />
                </View>}
            </View>
        </BasePage>
    )
});

export default Settings;

const styles = {
    container: {
        marginTop: 20,
    },
};
