import Echo from 'laravel-echo';
import {autorun, observable} from 'mobx';
import ApplicationStateService from '@ampeco/appstate-service';
import {Platform} from 'react-native';

export interface BroadcastSettings {
    endpoint: string;
    channelPrefix: string;
}

export default class BroadcastService {

    private static instance?: BroadcastService;
    static sharedInstance(settings: () => BroadcastSettings): BroadcastService {
        if (BroadcastService.instance === undefined) {
            BroadcastService.instance = new BroadcastService(settings);
        }
        return BroadcastService.instance;
    }

    @observable public connected = false;

    private echo?: Echo;

    private promisedEcho?: Promise<Echo>;

    private settings: () => BroadcastSettings;

    public static shouldDisconnect: boolean = true;

    constructor(settings: () => BroadcastSettings) {
        this.settings = settings;
        autorun(() => {
            // For charge-web when user switches the tab of the browser, echo disconnects. I think we shouldn't do that
            if (ApplicationStateService.sharedInstance().appState === 'background' && Platform.OS !== 'web') {
                this.disconnect();
            }
        });
    }

    public channelPrefix(): string {
        return this.settings().channelPrefix;
    }

    async connect(): Promise<Echo> {

        if (this.echo !== undefined) {
            return this.echo;
        }
        if (this.promisedEcho !== undefined) {
            return this.promisedEcho;
        }

        this.promisedEcho = new Promise(async (resolve, fail) => {
            require('./broadcast').broadcast(this.settings().endpoint).then((echo: Echo) => {
                this.echo = echo;
                this.connected = true;
                console.log('Echo: Connected');
                resolve(this.echo);
            }).catch((e: any) => {
                fail(e);
            });
        });
        return this.promisedEcho;
    }

    disconnect() {
        if (!BroadcastService.shouldDisconnect) {
            return;
        }
        this.connected = false;
        this.echo && this.echo.disconnect();
        if (this.echo) {
            console.log('Echo: Disconnect');
        }
        this.echo = undefined;
        this.promisedEcho = undefined;
    }
}
