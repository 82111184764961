import {observer} from 'mobx-react';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__, LocaleStore, TimeFormat} from '@ampeco/i18n';
import moment from 'moment';
import {DateFormatTypes, Session} from '@ampeco/charge-models';

const TimeStats = observer((props: {session: Session}) => {

    const {session} = props;
    const localStore = LocaleStore.sharedInstance();
    const timeFormat = localStore.displayTimeFormat;
    const dateFormat = localStore.displayDateFormat;

    return <ThemeConsumer>{({theme}) => {
        return <View style={{justifyContent: 'space-between', flexDirection: 'column', marginBottom: 20}}>
            <View style={{justifyContent: 'space-between', flexDirection: 'row'}}>
                <Text style={StyleSheet.flatten([theme.TimeStats.labelStyle, styles.labelStyles])}>{__('personal-charging.session-summary.start')}:</Text>
                <Text style={StyleSheet.flatten([theme.TimeStats.labelStyle, styles.labelStyles])}>{__('personal-charging.session-summary.finish')}:</Text>
            </View>
            <View style={{justifyContent: 'space-between', flexDirection: 'row'}}>
                <View style={styles.row}>
                    <Text style={[theme.TimeStats.textStyle, styles.pmTimeStyles]}>
                        {moment(session.startedAt).format(timeFormat as TimeFormat)}
                    </Text>
                    <Text style={[theme.TimeStats.textStyle]}>
                        {moment(session.startedAt).format(dateFormat as DateFormatTypes)}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={[theme.TimeStats.textStyle, styles.pmTimeStyles]}>
                        {moment(session.stoppedAt || undefined).format(timeFormat as TimeFormat)}
                    </Text>
                    <Text style={[theme.TimeStats.textStyle]}>
                        {moment(session.stoppedAt || undefined).format(dateFormat as DateFormatTypes)}
                    </Text>
                </View>
            </View>
        </View >}}
    </ThemeConsumer>;
});

export default TimeStats;

const styles = StyleSheet.create({
    labelStyles: {
        marginBottom: 5,
        fontSize: 14,
    },
    pmTimeStyles: {
        marginRight: 5,
    },
    row: {
        flexDirection: 'row',
    },
});
