import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {ActivityIndicator, View, StyleSheet} from "react-native";
import GlobalStore from "../../stores/GlobalStore";
import {LocationStore, SessionHistoryStore, SessionSummaryStore} from '@ampeco/charge-stores';
import {SessionHeader} from "./SessionHeader";
import {SessionChargingPeriods, SessionSummaryMain, SummaryStatsComponent} from "@ampeco/charge-screens";
import {Divider, ThemeConsumer, Text, Button} from "react-native-elements";
import AmpecoTheme from "@ampeco/theme";
import palette from "../../res/palette";
import fonts, {FontWeight} from "../../res/fonts";
import {__} from "@ampeco/i18n";
import colors from "../../res/colors";
import SummaryGraphComponent from "./SummaryGraphComponent";
import {ButtonIcon} from "@ampeco/icons";
import {icons} from "../../res/images";
import NavigationService from "@ampeco/navigation";
import {useLocation} from 'react-router';
import {BasePage} from "../BasePage";
import {PayForSession, PaymentsStore} from '@ampeco/payment-methods';
import {Session as SessionAPI} from '@ampeco/charge-api';
import {Session, SessionHistory} from '@ampeco/charge-models';
import net from '../../../frontend_modules/net';
import {resetDeviceId} from '../../services/DeviceIdService';

const SessionSummary = observer(() => {
    const globalStore = GlobalStore.sharedInstance();
    const locationStore = LocationStore.sharedInstance();
    const sessionSummaryStore = SessionSummaryStore.sharedInstance();
    const paymentsStore = PaymentsStore.sharedInstance();
    const [session, setSession] = useState(undefined);
    const [location, setLocation] = useState(undefined);
    const [evse, setEvse] = useState(undefined);
    const [dismissButton, setDismissButton] = useState(false);
    const routeLocation = useLocation<{dismissButton?: boolean; sessionId?: string; session?: Session; item?: SessionHistory}>();

    useEffect(() => {
        const sessionId = routeLocation.state?.item?.session?.id || routeLocation.state?.session?.id || routeLocation.state?.sessionId || globalStore?.finishedSession?.id;
        if (sessionId) {
            sessionSummaryStore.loadSessionInfo(sessionId).then(() => {
                setSession(sessionSummaryStore.session);
            })
        }

        setDismissButton(routeLocation.state?.dismissButton || false);

        if (!globalStore.isMenuOpen) {
            globalStore.openMenu();
        }
    }, [routeLocation, globalStore.finishedSession]);

    useEffect(() => {
        if (session?.evseId) {
            locationStore.resolveEVSE(session.evseId).then(result => {
                setLocation(result.location);
                setEvse(result.evse);
            });
        }
    }, [session])

    useEffect(() => {
        if (globalStore.isAdHocUser && session?.paymentStatus === 'paid') {
            globalStore.unsetProfile();
            net.setDeviceId(resetDeviceId());
        }
    }, [globalStore.isAdHocUser, session]);

    if (!session || !location || !evse) {
        return <BasePage
            title={'session.summary.title'} onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home');
        }}
        >
            <View style={{flex: 1}}><ActivityIndicator/></View>
        </BasePage>
    }

    const isAdhocFailed = globalStore.isAdHocUser && ['failed', 'partially_paid'].includes(session.paymentStatus);

    let paymentMethodId = undefined;
    if (session.paymentDetails && ['card', 'mobilityService'].includes(session.paymentDetails.type)) {
        paymentMethodId = session.paymentDetails.methodId;
    } else if (!session.paymentDetails && session.paymentMethodId && session.paymentMethodType) {
        paymentMethodId = session.paymentMethodId;
    }

    let paymentMethod = paymentMethodId ? paymentsStore.getPaymentMethodById(paymentMethodId) : undefined;

    if (globalStore.isAdHocUser && !paymentMethod && session.paymentDetails && session.paymentStatus === 'paid') {
        paymentMethod = {
            id: session.paymentDetails.methodId,
            type: session.paymentDetails.card_type,
            name: session.paymentDetails.name,
            default: false,
        };
    }

    if (isAdhocFailed) {
        paymentsStore.removeLastPaymentMethod();
        paymentsStore.resetPaymentMethodsList();
    }

    return (
        <BasePage
            title={'session.summary.title'} onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home');
        }}
        >
            <ThemeConsumer<AmpecoTheme>>{({theme}) => {
                return <View style={{marginTop: 28}}>
                    <SessionHeader location={location} evse={evse}/>

                    {(session.paymentStatus === 'paid' || session.paymentStatus === 'pending') && dismissButton && session.stopReason !== 'balance_exceeded' &&
                        <Text
                            style={[theme.Container.byPaddingAndMarginTop, palette.header, {fontWeight: FontWeight.bold}]}
                        >{__('session.summary.thank_you')}</Text>}

                    {session.paymentStatus === 'paid' && dismissButton && session.stopReason === 'balance_exceeded' &&
                        <Text
                            style={[theme.Container.byPaddingAndMarginTop, palette.header, {fontWeight: FontWeight.bold}]}
                        >{__('session.summary.charging-stopped')}</Text>}

                    {session.paymentStatus === 'paid' && dismissButton && session.stopReason === 'balance_exceeded' &&
                        <View style={theme.Container.byPaddingAndMarginTop}>
                            <Text
                                style={{
                                    color: colors.textEmphasisColor,
                                    fontFamily: fonts.regular,
                                    fontWeight: FontWeight.light,
                                }}
                            >
                                {__('session.summary.balance_exceeded')}
                            </Text>
                        </View>}

                    {session && ['time_limit', 'authorization_amount_reached', 'energy_exceeded'].includes(session.stopReason) && dismissButton &&
                        <Text
                            style={[theme.Container.byPaddingAndMarginTop, {
                                color: colors.textEmphasisColor,
                                fontFamily: fonts.regular,
                                fontWeight: FontWeight.light,
                            }]}
                        >{__('session.summary.' + session.stopReason)}</Text>}

                    {session.paymentStatus === 'pending' &&
                        <Text
                            style={[theme.Container.byPaddingAndMarginTop, {marginTop: 10, fontSize: 14}]}
                        >{__('session.summary.pending-payment')}</Text>}

                    {(session.paymentStatus === 'paid' || session.paymentStatus === 'pending')
                        && <SessionSummaryMain session={session} paymentMethod={paymentMethod}/>}

                    {(session.paymentStatus === 'partially_paid' || session.paymentStatus === 'failed') &&
                        <View style={{marginTop: 20}}>
                            <PayForSession
                                session={session}
                                paymentMethod={paymentMethod}
                                onSessionChanged={async (session: Session) => {
                                    if (session) {
                                        await globalStore.statusUpdate();
                                        globalStore.setFinishedSession(session);
                                        SessionHistoryStore.sharedInstance().updateSession(session);
                                    }
                                }
                                }
                                payEndpoint={SessionAPI.pay}
                                addPaymentMethodNavigation={NavigationService.sharedInstance().navigate.bind(NavigationService.sharedInstance())}
                                addPaymentMethodIntendedNavigation={{screen: 'SessionSummary'}}
                                stopReasonText={session.stopReason === 'balance_exceeded' ? __('session.summary.' + session.stopReason) : null}
                                onTopUpPress={() => {
                                    // TODO: no top up implemented yet.
                                }}
                                isPayNowDisabled={isAdhocFailed}
                            />
                        </View>
                    }

                    <Divider style={[theme.Divider.style, {marginTop: 20}]}/>
                    <SummaryStatsComponent session={session}/>
                    <Divider style={[theme.Divider.style, {marginTop: 20}]}/>
                    <SummaryGraphComponent session={session}/>
                    {dismissButton && <View style={[styles.mapButtonStyles]}>
                        <Button
                            title={__('session.summary.button')}
                            icon={<ButtonIcon style={{width: 25, height: 20}} source={icons.mapTabBar}/>}
                            onPress={() => NavigationService.sharedInstance().navigate('Home')}
                        />
                    </View>}
                    {session.chargingPeriods &&
                        <SessionChargingPeriods chargingPeriods={session.chargingPeriods} currency={session.currency}/>}
                </View>
            }}</ThemeConsumer>
        </BasePage>
    )
});

export default SessionSummary;

const styles = StyleSheet.create({
    mapButtonStyles: {
        marginTop: -10,
    },
});
