import Home from './pages/Home/Home';
import Terms from './pages/Terms/Terms';
import Settings from './pages/Settings/Settings';
import SessionStart from './pages/Session/SessionStart';
import InfoCenter from './pages/InfoCenter/InfoCenter';
import LocationWrapper from './pages/Location/LocationWrapper';
import RegisterWrapper from './pages/Profile/RegisterWrapper';
import ContactDetailsWrapper from './pages/Profile/ContactDetailsWrapper';
import ViewProfile from './pages/Profile/ViewProfile';
import EditProfile from './pages/Profile/EditProfile';
import Favorites from './pages/Favorites';
import DisplayComponent from './components/Displays/DisplayComponent';
import SessionRunningWrapper from './pages/Profile/SessionRunningWrapper';
import SessionSummary from './pages/Session/SessionSummary';
import TermConsent from './pages/Terms/TermConsent';
import ForgottenPasswordWrapper from './pages/Profile/ForgottenPasswordWrapper';
import LoginWrapper from './pages/Profile/Login';
import PaymentMethodsScreen from './pages/PaymentMethods/PaymentMethodsScreen';
import InvoiceDetailsEditScreen from './pages/InvoiceDetails/InvoiceDetailsEditScreen';
import AddPaymentMethodScreen from './pages/PaymentMethods/AddPaymentMethodScreen';
import EmailReceiptScreen from './pages/InvoiceDetails/EmailReceiptScreen';
import HistoryScreen from './pages/History/HistoryScreen';
import BalanceScreen from "./pages/Balance/BalanceScreen";
import TopUpPackagesScreen from "./pages/Balance/TopUpPackagesScreen";
import RedeemVoucherScreen from "./pages/RedeemVoucher/RedeemVoucherScreen";
import AddPaymentMethodNativeScreen from "./pages/PaymentMethods/AddPaymentMethodNativeScreen";
import AddPaymentMethodNative3dsScreen from './pages/PaymentMethods/AddPaymentMethodNative3dsScreen';

interface RouteInterface {
    name: string;
    path: string;
    component: any;
}

const routesConfig = [
    {
        name: 'Home',
        path: '/',
        component: Home
    },
    {
        name: 'Settings',
        path: '/settings',
        component: Settings
    },
    {
        name: 'Terms',
        path: '/terms',
        component: Terms
    },
    {
        name: 'TermConsent',
        path: '/term-consent',
        component: TermConsent
    },
    {
        name: 'InfoCenter',
        path: '/info-center',
        component: InfoCenter,
    },
    {
        name: 'Location',
        path: '/location/:locationId',
        component: LocationWrapper,
    },
    {
        name: 'SessionStart',
        path: '/session-start/:evseId',
        component: SessionStart,
    },
    {
        name: 'Login',
        path: '/login',
        component: LoginWrapper
    },
    {
        name: 'Register',
        path: '/register',
        component: RegisterWrapper
    },
    {
        name: 'ContactDetails',
        path: '/contact-details',
        component: ContactDetailsWrapper
    },
    {
        name: 'ViewProfile',
        path: '/view-profile',
        component: ViewProfile
    },
    {
        name: 'EditProfile',
        path: '/edit-profile',
        component: EditProfile
    },
    {
        name: 'Favorites',
        path: '/favorites',
        component: Favorites
    },
    {
        name: 'SessionRunning',
        path: '/session-running',
        component: SessionRunningWrapper
    },
    {
        name: 'SessionSummary',
        path: '/session-summary',
        component: SessionSummary
    },
    {
        name: 'Forgotten',
        path: '/forgotten-password',
        component: ForgottenPasswordWrapper
    },
    {
        name: 'PaymentMethods',
        path: '/payment-methods',
        component: PaymentMethodsScreen
    },
    {
        name: 'AddPaymentMethod',
        path: '/payment-methods/add',
        component: AddPaymentMethodScreen
    },
    {
        name: 'InvoiceDetailsEdit',
        path: '/invoice-details/edit',
        component: InvoiceDetailsEditScreen
    },
    {
        name: 'EmailReceipt',
        path: '/email-receipt',
        component: EmailReceiptScreen
    },
    {
        name: 'History',
        path: '/history',
        component: HistoryScreen,
    },
    {
        name: 'HistoryDetails',
        path: '/history/:sessionId',
        component: SessionSummary,
    },
    {
        name: 'Balance',
        path: '/balance',
        component: BalanceScreen,
    },
    {
        name: 'Top-up',
        path: '/TopUpPackages',
        component: TopUpPackagesScreen,
    },
    {
        name: 'RedeemVoucher',
        path: '/RedeemVoucher',
        component: RedeemVoucherScreen,
    },
    {
        name: 'AddPaymentMethodNative',
        path: '/AddPaymentMethodNative',
        component: AddPaymentMethodNativeScreen,
    },
    {
        name: 'AddPaymentMethodNative3ds',
        path: '/payment-methods/3ds',
        component: AddPaymentMethodNative3dsScreen,
    },
];

export const getDisplayRoutes = (): RouteInterface[] => {
    return [
        {
            name: 'Displays',
            path: '/displays/:identifier?',
            component: DisplayComponent
        }
    ];
}

export const getRoutes = (): RouteInterface[] => routesConfig.filter(() => {
    // TODO some magic for language
    return true;
}).map((route: RouteInterface) => {
    // TODO wrap path for language or make it dynamic with no path variable
    return {...route, path: route.path};
});
