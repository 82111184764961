import React from 'react';
import {Image, StyleSheet, View, FlatList} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import {HomeStackParamList} from '@ampeco/navigation';
import {__, LocaleStore} from '@ampeco/i18n';
import {Observer, observer} from 'mobx-react';
import {Button, Text, ThemeConsumer} from 'react-native-elements';
import PaymentsStore from '../PaymentsStore';
import {PaymentMethod} from '@ampeco/models';
import AmpecoTheme from '@ampeco/theme';
import {ButtonIcon} from '@ampeco/icons';
import {IReactionDisposer, reaction} from 'mobx';
import PaymentFailureNotice from './PaymentMethods/PaymentFailureNotice';
import {RoundedTag} from '@ampeco/components';
import Alert from '@ampeco/alert'
import {icons} from "../../../frontend/res/images";
import {Action, FormStore, FormStoreProvider} from "@ampeco/forms";
import GlobalStore from '../../../frontend/stores/GlobalStore';
import BraintreeAddButton from '../components/BraintreeAddButton';


type AddPaymentMethodProps = StackScreenProps<
    HomeStackParamList,
    'PaymentMethods'
>;

@observer
export default class PaymentMethods extends React.Component<AddPaymentMethodProps> {

    static reaction: IReactionDisposer | null = null;

    private store: FormStore;

    constructor(props: AddPaymentMethodProps) {
        super(props);
        this.state = {
            deletingPaymentMethod: false,
        };

        this.store = new FormStore();

        this.store.actions.default = async (paymentMethod: PaymentMethod) => {
            this.setDefaultPaymentMethod(paymentMethod);
        }

        this.store.actions.delete = async (paymentMethod: PaymentMethod) => {
            this.deletePaymentMethod(paymentMethod);
        }
    }

    componentDidMount(): void {
        setTimeout(() => {
            GlobalStore.sharedInstance().openMenu();
        }, 500);

        PaymentsStore.sharedInstance().hideLastPaymentFailure();
        this.props.navigation.setOptions({title: __('payment_methods.title')});

        PaymentMethods.reaction = reaction(
            () => LocaleStore.sharedInstance().language,
            () => {
                this.props.navigation.setOptions({title: __('payment_methods.title')});
            },
        );
    }

    componentWillUnmount(): void {
        PaymentsStore.sharedInstance().hideLastPaymentFailure();
        PaymentMethods.reaction && PaymentMethods.reaction();
        PaymentMethods.reaction = null;
        PaymentsStore.sharedInstance().shouldShowBraintreeAddBtn = false;
    }

    setDefaultPaymentMethod(paymentMethod: PaymentMethod) {
        const paymentsStore = PaymentsStore.sharedInstance();
        paymentsStore.setDefaultPaymentMethod(paymentMethod);
    }

    deletePaymentMethod(paymentMethod: PaymentMethod) {
        const paymentsStore = PaymentsStore.sharedInstance();

        if (!paymentsStore.canRemovePaymentMethodCallback(paymentMethod)) {
            Alert.sharedInstance().show(__('payment_methods.remove_title'),
                __('payment_methods.remove_prompt_in_use').replace(':payment_name', paymentMethod.name),
                [
                    {text: __('message.ok')},
                ]);
        } else {
            Alert.sharedInstance().show(
                __('payment_methods.remove_title'),
                __('payment_methods.remove_prompt').replace(':payment_name', paymentMethod.name),
                [
                    {
                        text: __('payment_methods.remove_prompt_cancel') || __('message.cancel'),
                        style: 'cancel',
                    },
                    {
                        text: __('payment_methods.remove_prompt_confirm') || __('message.ok'),
                        style: 'destructive',
                        onPress: () => {
                            paymentsStore.removePaymentMethod(paymentMethod);
                        },
                    },
                ],
                {cancelable: false},
            );
        }
    }
    render() {
        const paymentsStore = PaymentsStore.sharedInstance();
        const paymentMethods: PaymentMethod[] = paymentsStore.payments && paymentsStore.payments.methods || [];
        const isUsingBraintree = PaymentsStore.sharedInstance().payments?.processor === PaymentsStore.PROCESSOR_BRAINTREE;

        // tslint:disable-next-line: no-unused-expression
        paymentMethods.length; // react to change in length
        paymentMethods.forEach(el => el.default); // react on default change

        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => (
              <Observer>{() => {
                  return  <FormStoreProvider store={this.store}>
                      <FlatList
                        scrollEnabled={true}
                        bounces={true}
                        style={[styles.container]}
                        data={paymentMethods.map(c => (
                          {
                              ...c,
                              key: c.id + '',
                              disableLeftSwipe: c.type === 'corporate',
                              rightOpenValue: -65 * (c.default ? 1 : 2),
                              leftOpenValue: 65 * (c.default ? 1 : 2),
                          }
                        ))}
                        renderItem={(data) => (
                          <View key={data.item.id} style={[styles.paymentMethod, theme.PaymentMethods.listingItemStyle, {textAlign: 'left'}]}>
                              {theme.PaymentMethods && theme.PaymentMethods.paymentMethodTypeImages &&
                                <Image source={theme.PaymentMethods.paymentMethodTypeImages[data.item.type]}
                                       style={styles.icon}/>}
                              <Text style={[styles.text, theme.PaymentMethods && theme.PaymentMethods.textStyle]}>
                                  {data.item.name}
                              </Text>
                              {data.item.default && <View>
                                  <RoundedTag title={__('payment_methods.default')} active={true}/>
                              </View>}
                              {!data.item.default && <View style={styles.actionButtonContainer}>
                                  <Action
                                    type={Button}
                                    name="default"
                                    key={'default' + data.item.id}
                                    actionAttributes={[data.item]}
                                    leftIcon
                                    icon={<ButtonIcon source={icons.favorites} style={{width: 21, height: 21}}/>}
                                    buttonStyle={theme.SwipeButton.activeStyle}
                                  />
                              </View>}
                              <View style={styles.actionButtonContainer}>
                                  <Action
                                    type={Button}
                                    name="delete"
                                    key={'delete' + data.item.id}
                                    actionAttributes={[data.item]}
                                    leftIcon
                                    icon={<ButtonIcon source={icons.del} style={{width: 21, height: 21}}/>}
                                    buttonStyle={theme.SwipeButton.deleteStyle}
                                  />
                              </View>
                          </View>
                        )}
                        ListHeaderComponent={
                            <>{paymentMethods && paymentMethods.length === 0 &&
                              <Text style={[styles.inset, theme.PaymentMethods.textStyle]}>{__('payment_methods.no-cards')}</Text>}
                            </>
                        }
                        ListFooterComponent={
                            <View style={styles.inset}>
                                {paymentsStore.canAddPaymentMethod() && isUsingBraintree ?
                                  <BraintreeAddButton />
                                  : null}
                                {paymentsStore.canAddPaymentMethod() && !paymentsStore.shouldShowBraintreeAddBtn && <Button
                                  icon={theme.PaymentMethods && theme.PaymentMethods.addIconSource &&
                                    <ButtonIcon style={styles.addPaymentMethodButtonIcon} source={theme.PaymentMethods.addIconSource}/>}
                                  onPress={() => {
                                      const paymentStore = PaymentsStore.sharedInstance();
                                      paymentStore.hideLastPaymentFailure();
                                      paymentStore.shouldShowBraintreeAddBtn = false;

                                      const addPaymentMethodCallback = this.props.route.params.addPaymentMethodCallback;
                                      if (!addPaymentMethodCallback) {
                                          this.props.navigation && paymentsStore.newPaymentMethod(this.props.navigation.navigate, undefined, undefined, undefined, {screen: 'PaymentMethods'});
                                      } else {
                                          addPaymentMethodCallback();
                                      }
                                  }}
                                  title={__('payment_methods.add')}
                                />}

                                <PaymentFailureNotice/>

                                {this.props.route.params.footerComponent ? (
                                  this.props.route.params.footerComponent()
                                ): null}
                            </View>}
                      />
                  </FormStoreProvider>
              }}</Observer>

            )}</ThemeConsumer>
        );
    }
}

const styles = StyleSheet.create({
    inset: {
        marginBottom: 20,
        marginTop: 20,
    },
    first: {
        marginTop: 40,
    },
    container: {
        flex: 1,
    },
    paymentMethod: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 15,
    },
    text: {
        flex: 4,
        fontSize: 18,
    },
    icon: {
        width: 54,
        height: 38,
        marginRight: 16,
        borderRadius: 3,
    },

    rowBack: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingLeft: 15,
    },
    actionButtonContainer: {
        paddingLeft: 5,
    },
    addPaymentMethodButtonIcon: {
        width: 20,
        height: 20,
    },
    addPaymentMethodModal: {
        padding: 20,
    }
});
