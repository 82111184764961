import color from 'color';

const theme = window.initialConfig.app?.theme;
const accentColor = theme?.accentColor || '#5AB46B';
const backgroundColor = theme?.backgroundColor || 'white';
const menuBarsColor = theme?.menuBarsColor || backgroundColor;
const headerBackgroundColor = theme?.headerBackgroundColor || accentColor;
const connectorsTintColor = theme?.connectorTintColor || 'black';
const chargingProgressGenericBorder = theme?.chargingProgressGenericBorderColor || accentColor;
const chargingProgressGenericSegment = theme?.chargingProgressGenericSegmentColor || accentColor;
const dividerColor = theme?.dividerColor || '#CCCCCC';
const locationInfoBoxColor = theme?.locationInfoBoxColor || '#CCCCCC';
const buttonPrimaryDisabledColor = accentColor ? color(accentColor).desaturate(0.7).hex() : 'grey';

export const colors = {
    backgroundColor,
    statusBarStyle: 'dark-content', // used for the status bar colors
    statusBarStyleInverted: 'light-content', // inverted statusBar, only on iOS
    menuBackgroundColor: 'white',
    textColor: '#333333',
    textLabelColor: '#666666',
    textEmphasisColor: '#000000',
    dividerColor,
    connectorsTintColor,
    headerBackgroundColor,
    menuBarsColor,
    locationInfoBoxColor,

    tabBarSelectedColor: 'white',
    tabBarUnselectedColor: 'rgba(255,255,255, 0.7)',

    buttonTextColor: '#FFF',
    buttonPrimaryColor: accentColor,
    buttonPrimaryDisabledColor,
    buttonSecondaryColor: '#999999',
    buttonDestructiveColor: '#CA512B',

    chargingProgressOnBar: '#CCCCCC', // '#CCCCCC',
    chargingProgressOffBar: '#fff',
    chargingProgressText: '#fff',

    chargingProgressGenericOffBar: '#F7F7F7',
    chargingProgressGenericOnBar: chargingProgressGenericSegment,
    chargingProgressGenericBorder,

    activeStep: accentColor,
    inactiveStep: '#B0B0B0',

    topUpPackageBackgroundColor: '#F0F0F0',
    switchTrackColor: '#CCCCCC',
    switchInactiveColor: '#888888',
};

export const chargingAnimation = 'generic';
