import React from 'react';
import {KeyboardAvoidingView, SafeAreaView, StyleSheet} from 'react-native';
import {observer} from 'mobx-react';
import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import ResetPasswordView from '../components/ResetPassword';

interface Props {
    onSuccess: () => void;
    resetCode: string;
}

interface State {
    clearStore: (() => void) | null;
}

@observer
export default class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {clearStore: null};
    }

    render() {
        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => (
                <SafeAreaView style={styles.container}>
                    <KeyboardAvoidingView behavior="padding">
                        <ResetPasswordView
                            style={{flex: 0}}
                            clearStore={(clear: any) => this.setState({clearStore: clear})}
                            onSuccess={this.props.onSuccess}
                            resetCode={this.props.resetCode}
                        />
                    </KeyboardAvoidingView>
                </SafeAreaView>
            )}</ThemeConsumer>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        alignContent: 'stretch',
    },
});
