import React, {Component} from 'react';
import {Location, Connector as ConnectorType, EVSE as EVSEType} from '@ampeco/charge-models';
import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-elements';
import {Address, Connector} from '@ampeco/charge-screens';
import colors from '../../res/colors';
import fonts, {FontWeight} from '../../res/fonts';
import {__, LocaleStore} from '@ampeco/i18n';
import palette from '../../res/palette';

export class SessionHeader extends Component<{ location: Location, evse: EVSEType; }> {

    render() {
        LocaleStore.observeLanguage();

        let connector_no = 0;
        return <View>
            <Address location={this.props.location}/>
            <View style={[styles.row, styles.container, {alignItems: 'center'}]}>

                {this.props.evse.connectors.map(
                    (connector: ConnectorType) => <View style={{paddingRight: 10}} key={++connector_no}><Connector
                        connector={connector}
                    /></View>,
                )}
                <View style={{paddingLeft: 9}}>
                    <Text
                        style={[styles.evseText, {fontWeight: FontWeight.bold}]}
                    >{Math.round(this.props.evse.maxPower / 1000)}kW {this.props.evse.currentType.toUpperCase()}</Text>
                    <View style={{flexDirection: 'row'}}><Text style={[styles.evseText]}>ID: </Text><Text
                        style={[styles.evseTextBold]}
                    >{this.props.evse.identifier}</Text></View>
                </View>
            </View>
            {this.props.evse.midMeterCertificationEndYear &&
                <View style={{flexDirection: 'row', marginTop: 10, marginBottom: 10, alignItems: 'center'}}>
                    <Text>{__('session.start.mid-meter-certification-end-year')} </Text>
                    <Text
                        style={[palette.emphasis, {fontSize: 16}]}
                    >{this.props.evse.midMeterCertificationEndYear}</Text>
                </View>}
        </View>;
    }
}

const styles = StyleSheet.create({

    row: {
        flexDirection: 'row',
    },
    evseText: {
        color: colors.textEmphasisColor,
        fontSize: 13,
        fontFamily: fonts.regular,
        fontWeight: FontWeight.regular,
    },
    evseTextBold: {
        color: colors.textEmphasisColor,
        fontSize: 13,
        fontFamily: fonts.bold,
        fontWeight: FontWeight.regular,
    },
    container: {
        marginTop: 20,
    },
    containerWithMargin: {
        marginTop: 28,
    },
});
