import {formatNumber} from './FormatNumber';

type units = 'K' | 'N' | 'M' | 'imperial' | 'metric'; /// Kilometers, Miles, Nautical miles,  imperial or metric

export function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: units = 'K') {
    // The Haversine Formula
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const radlon1 = Math.PI * lon1 / 180;
    const radlon2 = Math.PI * lon2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K' || unit === 'metric') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }

    return dist;
}

export function formatDistance(distance: number, unit: units = 'K') {
    let prefix = '';
    if (unit === 'K' || unit === 'metric') {
        prefix = ' km';
    }
    if (unit === 'M' || unit === 'imperial') {
        prefix = ' mi';
    }
    if (unit === 'N') {
        prefix = ' nm';
    }
    return formatNumber(distance, 1) + prefix;
}
