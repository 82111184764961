import {reaction} from 'mobx';
import BroadcastService, {BroadcastSettings} from '@ampeco/broadcast';
import GlobalStore from "../stores/GlobalStore";

export default class BroadcastConnectionMonitor {
    static init(settings: () => BroadcastSettings) {
        // This is a global reaction - not just for the private channel
        reaction(
            () => GlobalStore.sharedInstance().profile !== null,
            () => {

                const broadcast = BroadcastService.sharedInstance(settings);
                broadcast.disconnect();
                broadcast.connect();
            },
        );
    }
}
