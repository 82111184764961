import {PaymentTransaction as Transaction} from '@ampeco/models';

export type SubscriptionPlanRenewalCycle = 'monthly' | 'annually';
export type UserSubscriptionStatus = 'active' | 'cancelled' | 'expired' | 'suspended' | 'pending';
export type ChargingAllowanceType = 'none' | 'separate' | 'combined';
export enum PostPaidChargingSessionType {
    NONE = 'none',
    PERSONAL = 'personal',
    PERSONAL_COMMERCIAL = 'personal_commercial',
}
export type ChargingAllowanceRenewalCycle = 'as_renewal_cycle' | 'after_each_session' | 'after_24_hours' | 'after_each_week' |'after_each_month' |'after_each_year';
export enum SubscriptionType {
    PRE_PAID = 'pre-paid',
    POST_PAID = 'post-paid',
}

export interface SubscriptionAmount {
    paid: number;
    due: number;
    total: number;
}

export interface SubscriptionPlan {
    id: string;
    name: string;
    description: string;
    type: SubscriptionType;
    order: number;
    renewal_cycle: SubscriptionPlanRenewalCycle;
    price: number | null;
    currency_id: string;
    charging_allowance_type: ChargingAllowanceType;
    charging_allowance_renewal_cycle: ChargingAllowanceRenewalCycle;
    ac_charging_allowance_kwh : number;
    dc_charging_allowance_kwh : number;
    combined_charging_allowance_kwh : number;
    base_fee_per_each_home_charge_point: boolean;
    usage_threshold?: number;
    post_paid_charging_sessions_accumulation: PostPaidChargingSessionType;
}

export interface UserSubscription {
    plan: SubscriptionPlan;
    name: string;
    is_cancelled: boolean;
    status: UserSubscriptionStatus;
    end_date: string;
    price: number | null;
    currency_id: string;
    description: string;
    renewal_cycle: SubscriptionPlanRenewalCycle;
    charging_allowance_type: ChargingAllowanceType;
    charging_allowance_renewal_cycle: ChargingAllowanceRenewalCycle;
    charging_allowances: {ac: number, dc: number};
    charging_allowance_usage: {ac: number, dc: number};
    total_amount: SubscriptionAmount;
    base_fee_amount: SubscriptionAmount;
    personal_charge_point_fee_amount: SubscriptionAmount;
    charging_fee_amount: SubscriptionAmount;
    has_failed_payment: boolean;
    latest_transaction: Transaction;
}
