import {StyleSheet, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import React, {ReactChildren, ReactNode} from 'react';
import {ChargeTheme} from '@ampeco/theme';

export default (props: { label: string, children: ReactNode | ReactChildren | string, left: boolean }) => {
    return <ThemeConsumer>{({theme}) => { return <View style={[styles.statBox, props.left ? {marginRight: 6} : {marginLeft: 6}]}>
        <Text style={[theme.SessionStatsRow.statTitle, styles.statTitle]}>{props.label}</Text>
        {typeof props.children === 'string' && <Text style={[theme.SessionStatsRow.statValue, styles.statValue]}>{props.children}</Text>}
        {typeof props.children !== 'string' && <View style={styles.statValueContainer}>{props.children}</View>}
    </View>;}}</ThemeConsumer>;
};

const styles = StyleSheet.create({
    statBox: {
        alignItems: 'flex-start',
        flex: 1,
        minWidth: '50%',
    },
    statTitle: {
        marginBottom: 7,
    },
    statValue: {
        paddingTop: 6,
    },
    statValueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 6,
    },
});
