import {observer} from 'mobx-react';
import React from 'react';
import {__} from '@ampeco/i18n';
import {View} from 'react-native';
import ProfileFieldsStore, {availableProfileFields} from './ProfileFieldsStore';

interface Props {
    type: any;
    profile: any;
    useCountryDropdown?: boolean;
}

@observer
class ProfileDetailsFields extends React.Component<Props> {

    static defaultProps = {
        useCountryDropdown: true,
    };

    render() {
        const profileFields = ProfileFieldsStore.sharedInstance().profileFields;
        const fields: Element[] = [];

        availableProfileFields.forEach(fieldName => {
            if (
                !profileFields[fieldName]
                || profileFields[fieldName] === 'disabled'
            ) {
                return;
            }

            let value = this.props.profile[fieldName];
            if (fieldName === 'country' && this.props.useCountryDropdown) {
                value = '';
                ProfileFieldsStore.sharedInstance().countryList.forEach(element => {
                    if (element.key === this.props.profile[fieldName]) {
                        value = element.label;
                    }
                });
            }

            if (fieldName === 'state' && this.props.useCountryDropdown) {
                const countryCode = this.props.profile.country;
                const country = ProfileFieldsStore.sharedInstance().countryList.find(element => element.key === countryCode);

                if (country && country.states) {
                    country.states.forEach(elem => {
                        if (elem.key === this.props.profile[fieldName]) {
                            value = elem.label;
                        }
                    });
                }
            }

            fields.push(<this.props.type
                title={__('profile.label.' + fieldName)}
                value={value}
                key={fieldName}
            />);
        });

        return <View>
            {fields}
        </View>;
    }
}

export default ProfileDetailsFields;
