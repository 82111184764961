import {action, autorun, computed, observable} from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FAQ} from '../models/FAQ';
import {HelpCenter} from '../models/HelpCenter';

let instance: InfoCenterStore | null = null;

const LocalStorageKey = '@InfoCenter';

export default class InfoCenterStore {

    @observable help: HelpCenter | null = null;
    @observable faqs: FAQ[] | null = null;

    constructor() {
        this.restore().then(() => {
            autorun(() => {
                this.save();
            });
        });
    }

    @computed get numberedFaqs() {
        let i = 0;
        if (this.faqs === null) {
            return [];
        }
        if (typeof this.faqs !== 'object') {
            return [];
        }
        if (typeof this.faqs.map !== 'function') {
            return [];
        }
        return this.faqs.map((faq: FAQ) => ({...faq, number: i++}));
    }

    static sharedInstance(): InfoCenterStore {
        if (instance === null) {
            instance = new InfoCenterStore();
        }
        return instance;
    }

    async restore() {
        const stored = await AsyncStorage.getItem(LocalStorageKey);
        if (stored !== null) {
            const parsed = JSON.parse(stored);
            this.restored(parsed[0], parsed[1]);
        }
    }

    @action.bound
    restored(help: HelpCenter, faqs: FAQ[]) {
        this.help = help;
        this.faqs = faqs;
    }

    async save() {
        return AsyncStorage.setItem(
            LocalStorageKey,
            JSON.stringify([this.help, this.faqs]),
        );
    }

    loadFromBackend() {
        require('../api').getHelpCenter().then((help: HelpCenter) => this.help = help).catch(() => {});
        require('../api').getFaqs().then((faqs: FAQ[]) => this.faqs = faqs).catch(() => {});
    }
}
