import {PixelRatio, Platform} from 'react-native';

// Beyond this threshold it's not a matter of beauty, it's a matter of usage. 
// Sacrifice elements, reorder items in column. 
// This means that the user has enabled special accessibility sizes on iOS and XL+ on Android
export const blindThreshold = Platform.OS === 'ios' ? 1.354 : 1.333;

// Beyond this threshold it's OK to sacrifice some elements in order to gain space.
export const glassesThreshold = 1.2;

export const isBlind = () => {
    return PixelRatio.getFontScale() >= blindThreshold;
};

export const needsGlasses = () => {
    return PixelRatio.getFontScale() >= glassesThreshold;
};
