export default function skin() {
    let theme = {
        colors: {},
        chargingAnimation: 'generic', // 'car' | 'generic'
        disableLoginLogo: false,
    };

    if (window.initialConfig.app.theme.type) {

        switch (window.initialConfig.app.theme.type) {
            case 'light':
                theme = require('./skins/light');
                break;
        }

        if (!theme.colors) {
            theme.colors = {};
        }
        if (!theme.chargingAnimation) {
            theme.chargingAnimation = 'generic';
        }

        if (theme.disableLoginLogo) {
            theme.disableLoginLogo = true;
        }
    }
    return theme;
}
