import net from '@ampeco/net';
import {BalanceHistoryPaginatedResponse} from '../BalanceHistory';
import {Balance} from '../Balance';
import * as TopUpPackages from './topuppackages';

export async function getBalanceHistory(page: number, perPage: number): Promise<BalanceHistoryPaginatedResponse> {
    return (await net.getOrWarn(`/app/profile/balance_history?page=${page}&perPage=${perPage}`)) as BalanceHistoryPaginatedResponse;
}

export async function getBalance(): Promise<Balance> {
    return (await net.getOrWarn('/app/profile/balance')) as Balance;
}

export {TopUpPackages};
