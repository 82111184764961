import {action, computed, observable, reaction} from 'mobx';
import {Pin} from '@ampeco/charge-models';
import {Pins} from '@ampeco/charge-api';
import supercluster, { BBox, Points, Supercluster } from 'supercluster';
import ApplicationStateService from '@ampeco/appstate-service';
import {Dimensions} from 'react-native';
import UserLocationStore from './UserLocationStore';
import {LocationStore} from './index';
import PinImageDataStore from './PinImageDataStore';
import {Filters} from "@ampeco/charge-models/Filters";
import {LatLng} from 'react-native-maps';

export interface Range {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
}

export default class PinsStore {

    private static instance: PinsStore;

    @observable region: Range | null = null;
    @observable pins: Pin[] = [];
    @observable filteredPins: Pin[] = [];
    @observable pinsGroupingOnZoomOut = false;
    @observable showZoomInNotice = false;
    @observable dynamicClustering = false;
    @observable isLoading = false;
    @observable boundaries: { northEast: LatLng; southWest: LatLng } | undefined;
    public pinsLimit = 700;

    static sharedInstance(): PinsStore {
        if (PinsStore.instance === undefined) {
            PinsStore.instance = new PinsStore();
        }
        return PinsStore.instance;
    }

    async loadFromBackend(filters?: Filters) {
        if (this.dynamicClustering && !this.minMaxBoundaries) {
            return;
        }

        this.isLoading = true;

        const params = {
            ...(filters ? {...this.getFilterParams(filters)} : {}),
            ...(this.dynamicClustering ? {...this.minMaxBoundaries, limit: this.pinsLimit} : {}),
        }
        this.pins = await Pins.all(params).finally(() => this.isLoading = false);

        this.filteredPins = this.dynamicClustering ? this.pins : [];
        this.triggerClustering();

        //console.log('pins ' + this.pins.length)
    };

    async reloadPinFromBackend(location_id: string, filterStore: any) {
        let serverPin = null;

        // @TODO fix in src/services/LocationChangesMonitor.ts in mobile
        try {
            const params = this.getFilterParams(filterStore);
            serverPin = await Pins.byId(location_id, params);
        } catch (error) {
            console.warn(error);
        }
        const localPin = this.pins.find(pin => pin.id == location_id);
        if (serverPin) {
            if (serverPin.pin_image_id) {
                PinImageDataStore.findOrFetch(serverPin.pin_image_id);
            }

            if (localPin) {
                localPin.geo = serverPin.geo;
                localPin.av = serverPin.av;
            } else {
                this.pins.push(serverPin);
            }
        } else if (localPin) {
            const index = this.pins.findIndex(pin => pin.id === location_id);
            this.pins.splice(index, 1);
        }
    }

    initPinsReload(globalStore: any, filterStore?: any) {
        reaction(() =>
            ApplicationStateService.sharedInstance().appState === 'active' || globalStore.profile
            , () => {
                let filters: any;
                if (filterStore) {
                    filters = filterStore.filters;
                }
                if (ApplicationStateService.sharedInstance().appState === 'active') {
                    this.loadFromBackend(filters).then(() => {
                        // Force-load the index
                        // tslint:disable-next-line:no-unused-expression
                        this.superclusterIndex;
                        LocationStore.sharedInstance().invalidateCache();

                    }).catch((error: any) => {
                        console.warn(error);
                    });
                }
            }, {name: 'Reload Pins Reaction'});
    }

    getClusterExpansionZoom(clusterId: number, zoom: number) {
        return this.superclusterIndex.getClusterExpansionZoom(clusterId, zoom);
    }

    @action.bound
    updateRegion(region: Range) {
        if (region.longitudeDelta < 0) {
            region.longitudeDelta = 360 - region.longitudeDelta;
        }
        // console.log(region);
        this.region = region;
    }

    triggerClustering() {
        if (!this.dynamicClustering && this.minMaxBoundaries) {
            const {minLatitude, minLongitude, maxLatitude, maxLongitude} = this.minMaxBoundaries;

            const filteredPins = this.pins.filter((pin) => {
                const [latitude, longitude] = pin.geo.split(',').map(parseFloat);
                if (latitude >= minLatitude && latitude <= maxLatitude && longitude >= minLongitude && longitude <= maxLongitude) {
                    return pin;
                }
            })
            const CLUSTERING_PINS_MAX_CAP = 1000;
            this.showZoomInNotice = filteredPins.length > CLUSTERING_PINS_MAX_CAP;
            this.filteredPins = filteredPins.length > CLUSTERING_PINS_MAX_CAP ? filteredPins.slice(0, CLUSTERING_PINS_MAX_CAP / 2) : filteredPins;
        }
    }

    centerOnCoordinate(coordinate: {latitude: number; longitude: number}, latitudeDelta?: number) {

        const {width, height} = Dimensions.get('window');
        const ASPECT_RATIO = width / height;

        const LATITUDE_DELTA = 0.0922;
        const LONGITUDE_DELTA = (latitudeDelta || LATITUDE_DELTA) * ASPECT_RATIO;
        // @ts-ignore
        const region: Range = {...this.region};
        region.latitude = coordinate.latitude;
        region.longitude = coordinate.longitude;
        region.latitudeDelta = latitudeDelta || LATITUDE_DELTA;
        region.longitudeDelta = LONGITUDE_DELTA;
        return region;
    }

    getNearestObject() {
        if (!UserLocationStore.sharedInstance().userPosition || this.pins.length === 0) {
            return null;
        }

        return this.pins.reduce((previous: Pin, current: Pin) => {
            const previousDistance = UserLocationStore.distanceFromUser(previous.geo);
            const currentDistance = UserLocationStore.distanceFromUser(current.geo);

            if (previousDistance === null) {
                return current;
            } else if (currentDistance === null) {
                return previous;
            } else if (currentDistance < previousDistance) {
                return current;
            } else {
                return previous;
            }
        });
    }

    @action.bound zoomOnCoordinates(coordinates: number[], zoom: number | undefined) {
        const [longitude, latitude] = coordinates;
        // @ts-ignore
        const region: Range = {...this.region};
        region.latitude = latitude;
        region.longitude = longitude;
        if (zoom === undefined) { // +1
            region.latitudeDelta /= 2;
            region.longitudeDelta /= 2;
        } else {
            const distance = this.distanceDelta(zoom);
            region.latitudeDelta = distance;
            region.longitudeDelta = distance;
        }
        return region;

    }

    distanceDelta(zoom: number) {
        return Math.exp(Math.log(360) - (zoom * Math.LN2));
    }

    getFilterParams(filters: { connectors: string[], power: { min?: number, max?: number }, currents: string[], freeOnly: boolean, cableOnly: boolean }) {
        const params: {[index: string]: any} = {};

        if (filters.connectors) {
            params.plug_type = filters.connectors;
        }
        if (filters.power.min) {
            params.min_power = filters.power.min;
        }
        if (filters.currents.indexOf('ac') !== -1) {
            params.current_type = 'ac';
        }
        if (filters.currents.indexOf('dc') !== -1) {
            if (!params.current_type) {
                params.current_type = 'dc';
            } else {
                delete params.current_type;
            }
        }
        if (filters.freeOnly) {
            params.free_only = 'true';
        }
        if (filters.cableOnly) {
            params.cable_only = 'true';
        }

        return params;
    }

    @computed get clusteredLocations() {

        if (this.region === null) {
            return [];
        }

        const zoom = this.zoomLevel(this.region);

        const bbox: BBox = [
            this.region.longitude - this.region.longitudeDelta,
            this.region.latitude - this.region.latitudeDelta,
            this.region.longitude + this.region.longitudeDelta,
            this.region.latitude + this.region.latitudeDelta,
        ];

        return this.pinsGroupingOnZoomOut ? this.superclusterIndex.getClusters(bbox, zoom)
            : this.superclusterIndex.getClusters(bbox, zoom).map((item: any) => {
                if (item.properties.cluster_id) {
                    return this.superclusterIndex.getLeaves(item.properties.cluster_id, Infinity);
                }
                return item;
            }).flat();
    }

    zoomLevel(region: Range) {
        return Math.round(Math.log(Dimensions.get('window').width / region.longitudeDelta) / Math.LN2);
    }

    @computed
    private get superclusterIndex() {
      // @ts-ignore
      const sc: Supercluster = new supercluster({
        radius: 100,
        minZoom: 5,
        maxZoom: 16,
      });
      const points = this.locationsAsGeoJsonFeatures;
      sc.load(points);
      return sc;
    }


    @computed
    private get locationsAsGeoJsonFeatures(): Points {
        return this.filteredPins.map(pin => {
            const [latitude, longitude] = pin.geo.split(',').map(parseFloat);
            return {
                type: 'Feature',
                properties: pin,
                geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude],
                },
            };
        });
    }

    @computed
    private get minMaxBoundaries() {
        if (!this.boundaries) {
            return null;
        }

        const minLatitude = Math.min(this.boundaries.northEast.latitude, this.boundaries.southWest.latitude)
        const minLongitude = Math.min(this.boundaries.northEast.longitude, this.boundaries.southWest.longitude)
        const maxLatitude = Math.max(this.boundaries.northEast.latitude, this.boundaries.southWest.latitude)
        const maxLongitude = Math.max(this.boundaries.northEast.longitude, this.boundaries.southWest.longitude)

        return { minLatitude, minLongitude, maxLatitude, maxLongitude };
    }

}
