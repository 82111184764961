import {LocaleStore} from '@ampeco/i18n';
import {NumberSeparators} from '@ampeco/charge-models/UserLocalizationSettings';

export function formatNumber(number: number, decimalsCount?: number): string {
    const localizationSettings = LocaleStore.sharedInstance().localizationSettings;
    const decimals = decimalsCount ?? localizationSettings.decimals;
    const decimalSeparator = NumberSeparators[localizationSettings.decimal_separator];
    const thousandsSeparator = NumberSeparators[localizationSettings.thousands_separator];
    const fixedNumber = number.toFixed(decimals);
    const parts = fixedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    return parts.join(decimalSeparator);
}

export function countDecimals(input: string): number {
    const decimalIndex = input.indexOf('.');

    if (decimalIndex === -1 || decimalIndex === input.length - 1) {
        return 0;
    }

    const decimals = input.length - decimalIndex - 1;

    return decimals;
}
