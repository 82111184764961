export enum FeesFilter {
    PAID = 'paid',
    DUE = 'due',
}

export interface SubscriptionSessionHistory {
    sessionId: string;
    startDate: string;
    totalDuration: number;
    totalEnergy: number;
    locationName: string;
    totalAmount: number;
    currencyId: string;
    isPaid: boolean;
    isCommercialSession: boolean;
}
