import {Location} from '@ampeco/charge-models';

export class MapNavigateService {
  private static onNavigationPressCallback: (location: Location) => void | null;

  static init(onNavigationPressCallback: (location: Location) => void) {
      MapNavigateService.onNavigationPressCallback = onNavigationPressCallback;
  }

  static onNavigationPress(location: Location) {
      if (!MapNavigateService.onNavigationPressCallback) {
          return;
      }

      MapNavigateService.onNavigationPressCallback(location);
  }
}
