import React from 'react';
import {Platform, StyleSheet, TouchableWithoutFeedback, View} from 'react-native';
import {Location, EVSE, formatEVSEPriceLabel, formatEVSEPriceMode, EvseStatus} from '@ampeco/charge-models';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {countDecimals, formatPower} from '@ampeco/utils';
import {Observer} from 'mobx-react';
import {ReservationStore} from '@ampeco/charge-stores';
import {ConnectorList} from './ConnectorList';
import Navigation from '@ampeco/navigation';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {RTLImage} from '@ampeco/rtl-image';

export enum EvseStatusStyles {
    AVAILABLE = 'available',
    IN_USE = 'inUse',
    UNKNOWN = 'unknown',
}

export default (props: {evse: EVSE, location: Location}) => {
    const reservationStore = ReservationStore.sharedInstance();
    const globalStore = GlobalStoreProxy.sharedInstance();
    const navigationService = Navigation.sharedInstance();

    const evse = props.evse;

    const decimalsCount = evse.maxPower ? countDecimals((Math.trunc(evse.maxPower) / 1000).toString()) : undefined;

    const evseStatusMap = {
        [EvseStatus.AVAILABLE]: (evse: EVSE) => {
            if (evse?.reservationId && reservationStore.activeReservation?.evseId === evse.id) {
                return {statusLabel: __('location.reserved-label'), statusStyle: EvseStatusStyles.AVAILABLE}
            }
            if (!evse.isAvailable && !evse?.reservationId) {
                return {statusLabel: __('location.unavailable-label'), statusStyle: EvseStatusStyles.UNKNOWN}
            }
            if (!reservationStore.evseIsAvailable(evse)) {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE}
            }

            return {statusLabel: __('location.available-label'), statusStyle: EvseStatusStyles.AVAILABLE};
        },
        [EvseStatus.RESERVED]: (evse: EVSE) => {
            if (evse?.reservationId && reservationStore.activeReservation?.evseId === evse.id) {
                return {statusLabel: __('location.reserved-label'), statusStyle: EvseStatusStyles.AVAILABLE}
            } else  {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
            }
        },
        [EvseStatus.PREPARING]: (evse: EVSE) => {
            if (globalStore.evseIsOccupiedByCurrentUser(evse)) {
                return {statusLabel: 'Preparing', statusStyle: EvseStatusStyles.AVAILABLE}
            } else  {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
            }
        },
        [EvseStatus.CHARGING]: (evse: EVSE) => {
            if (globalStore.evseIsOccupiedByCurrentUser(evse)) {
                return {statusLabel: __('location.charging-label'), statusStyle: EvseStatusStyles.AVAILABLE}
            } else  {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
            }
        },
        [EvseStatus.SUSPENDED_EV]: (evse: EVSE) => {
            if (globalStore.evseIsOccupiedByCurrentUser(evse)) {
                return {statusLabel: __('location.suspended-ev-label'), statusStyle: EvseStatusStyles.IN_USE};

            } else  {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
            }
        },
        [EvseStatus.SUSPENDED_EVSE]: (evse: EVSE) => {
            if (globalStore.evseIsOccupiedByCurrentUser(evse)) {
                return {statusLabel: __('location.suspended-evse-label'), statusStyle: EvseStatusStyles.IN_USE};
            } else  {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
            }
        },
        [EvseStatus.FINISHING]: (evse: EVSE) => {
            if (globalStore.evseIsOccupiedByCurrentUser(evse)) {
                return {statusLabel: __('location.finishing-label'), statusStyle: EvseStatusStyles.AVAILABLE};
            } else  {
                return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
            }
        },
        [EvseStatus.OCCUPIED]: () => {
            return {statusLabel: __('location.in-use-label'), statusStyle: EvseStatusStyles.IN_USE};
        },
        [EvseStatus.FAULTED]: () => {
            return {statusLabel: __('location.unavailable-label'), statusStyle: EvseStatusStyles.UNKNOWN};
        },
        [EvseStatus.UNAVAILABLE]: () => {
            return {statusLabel: __('location.unavailable-label'), statusStyle: EvseStatusStyles.UNKNOWN};
        },
        [EvseStatus.OUT_OF_ORDER]: () => {
            return {statusLabel: __('location.out-of-order-label'), statusStyle: EvseStatusStyles.UNKNOWN};
        },
    }
    const renderEvseStatus = (evse: EVSE, theme: any) => {
        const statusHandler = evseStatusMap[evse.status] ?? null;

        if (!statusHandler) {
            return null;
        }

        const textStyle = {
            [EvseStatusStyles.AVAILABLE]: theme.EVSE.available as string,
            [EvseStatusStyles.IN_USE]:  theme.EVSE.inUse as string,
            [EvseStatusStyles.UNKNOWN]: theme.EVSE.unknown as string,
        }
        const circleStyle = {
            [EvseStatusStyles.AVAILABLE]: theme.EVSE.circleAvailable,
            [EvseStatusStyles.IN_USE]:  theme.EVSE.circleInUse,
            [EvseStatusStyles.UNKNOWN]: theme.EVSE.circleUnknown,
        }
        const status = statusHandler(evse);
        let statusStyle = status.statusStyle;
        let statusLabel = status.statusLabel;

        if (evse.isTemporarilyUnavailable) {
            statusStyle = EvseStatusStyles.IN_USE;
            statusLabel =  __('location.unknown-label');
        }
        if (evse.isLongTermUnavailable) {
            statusStyle = EvseStatusStyles.UNKNOWN;
            statusLabel =  __('location.unavailable-label');
        }

        return (
            <View style={Platform.OS === 'android' ? styles.availabilityAndroid : styles.availability}>
                <View style={[styles.circle, circleStyle[statusStyle as keyof typeof circleStyle]]} />
                <Text style={[theme.EVSE.textStyle, textStyle[statusStyle as keyof typeof textStyle]]} >
                    {statusLabel}
                </Text>
            </View>
        );
    }
    const unknown = evse.isTemporarilyUnavailable || evse.isLongTermUnavailable || evse.status === 'out of order' || evse.status === 'unavailable';

    return (
        <ThemeConsumer>{({theme}) => {
            return <Observer>
                {
                    () => {
                        reservationStore.evseIsAvailable(props.evse); // observe the availability of the evse

                        return <TouchableWithoutFeedback onPress={() => {
                            if (globalStore.evseIsOccupiedByCurrentUser(evse) && globalStore.sessionIsRunning) {
                                navigationService.navigate('ActiveSession');
                            } else {
                                navigationService.navigate('SessionStart', {
                                    evseId: evse.identifier,
                                });
                            }
                        }}
                        ><View style={styles.container}>
                            <View style={styles.info}>
                                { evse.maxPower !== null && <Text
                                    style={[theme.EVSE.textStyle, styles.bold]}>{formatPower(evse.maxPower, decimalsCount)} {evse.currentType.toUpperCase()}</Text> }
                                <View style={styles.row}><Text style={theme.EVSE.textStyle}>ID: </Text><Text
                                    style={[theme.EVSE.textStyle, styles.bold, styles.sixCharsWide]} numberOfLines={1} ellipsizeMode="head">{evse.identifier}</Text></View>
                                {globalStore.managedByOperator(evse) ? renderEvseStatus(evse, theme) : null}
                            </View>
                            <ConnectorList evse={evse} location={props.location} />
                            <View style={styles.priceAndDisclosure}>
                                <View style={styles.price}>
                                    <Text
                                        style={[theme.EVSE.textStyle, theme.EVSE.priceTopStyle]}>{formatEVSEPriceLabel(evse)}</Text>
                                    {formatEVSEPriceMode(evse) !== '' &&
                                        <Text
                                            style={[theme.EVSE.textStyle, theme.EVSE.priceBottomStyle]}>{formatEVSEPriceMode(evse)}</Text>
                                    }
                                </View>
                                <RTLImage style={[theme.EVSE.disclosureStyle, styles.disclosure]} source={theme.EVSE.disclosureIcon} />
                            </View>

                        </View>
                        </TouchableWithoutFeedback>
                    }
                }
            </Observer>;
        }}
        </ThemeConsumer>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    startSession: {
        paddingHorizontal: 10,
        margin: 0,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginRight: 2,
    },
    connectors: {
        flexDirection: 'row',
        flex: 1,
    },
    row: {
        flexDirection: 'row',
    },
    availability: {
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    availabilityAndroid: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    priceAndDisclosure: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    disclosure: {
        marginLeft: 4,
    },
    price: {alignItems: 'center'},
    bold: {fontWeight: 'bold'},
    sixCharsWide: {width: 60},
    evses: {
        flexDirection: 'row',
        flex: 1,
    },
    evse: {
        marginLeft: 10,
    },
    info: {
        marginRight: 10,
    },

});
