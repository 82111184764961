import {format} from '@ampeco/currency';
import {__} from '@ampeco/i18n';
import {LocationStore, PriceFieldsFormatterStore} from '@ampeco/charge-stores';
import {Cacheable, CacheStore} from '@ampeco/cache';
import {observable} from 'mobx';
import {Session} from './Session';

export type CurrentType = 'ac'|'dc';
export type EnergyUnit = 'kWh'|'Wh';

export interface Connector {
    name: string;
    icon: 'type2'|'chademo'|'ccs';
    status: 'active'|'disabled';
    format: 'socket'|'cable';
}
export interface Zone {
    title: string|null;
    description: string|null|undefined;
    evses: EVSE[];
}

export interface EVSE {
    id: string;
    maxPower: number | null;
    identifier: string;
    originalIdentifier: string;
    networkId: string;
    currentType: CurrentType;
    status: string; // todo: list options
    isAvailable: boolean;
    isTemporarilyUnavailable: boolean;
    isLongTermUnavailable: boolean;
    reservationMinutes: number|null;
    reservationId: string|null;
    managedByOperator: boolean;
    operatedBy?: string;
    currencyCode: string|null;
    tariffId: string|null;
    connectors: Connector[];
    roamingLocationId: string;
    qrUrl: string;
    capabilities: string;
    activeSession?: Session;
    hasParkingBarrier: boolean;
    enterTimeLimitMinutes?: number | null
    startChargingTimeLimitMinutes?: number | null
    exitTimeLimitMinutes?: number | null
    canReserve: boolean;
    midMeterCertificationEndYear?: number;
    corporateBillingAsDefaultPaymentMethod: string | null;
    roamingEvseId?: string | null;
}
export enum EvseStatus {
    AVAILABLE = 'available',
    PREPARING = 'preparing',
    CHARGING = 'charging',
    SUSPENDED_EV = 'suspendedEV',
    SUSPENDED_EVSE = 'suspendedEVSE',
    FINISHING = 'finishing',
    OCCUPIED = 'occupied',
    FAULTED = 'faulted',
    UNAVAILABLE = 'unavailable',
    OUT_OF_ORDER = 'out of order',
    RESERVED = 'reserved',
}
export enum WeekDays {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export type TimeRange = {
    start: string,
    end: string,
}

export type WorkingHours = Record<WeekDays, TimeRange[]>;

export interface Location extends Cacheable{
    name: string;
    address: string;
    description: string;
    detailed_description: string;
    additional_description: string;
    location: string;
    what3words_address: string | null;
    location_image: string|number;
    images: string[];
    zones: Zone[];
    updatedAt: string;
    workingHours: WorkingHours | null;
    timezone: string;
}

export class LocationObject implements Location{

    id: number;
    name: string;
    address: string;
    description: string;
    detailed_description: string;
    additional_description: string;
    location: string;
    what3words_address: string | null;
    location_image: string|number;
    images: string[];
    @observable zones: Zone[]; // Because we change the isAvailable of the EVSE dynamically
    updatedAt: string;
    workingHours: WorkingHours | null;
    timezone: string;

    constructor() {
        this.id = 0;
        this.name = '';
        this.address = '';
        this.description = '';
        this.detailed_description = '';
        this.additional_description = '';
        this.what3words_address = null;
        this.location = '';
        this.location_image = '';
        this.images = [];
        this.zones = [];
        this.updatedAt = '';
        this.workingHours = null;
        this.timezone = '';
    }

    static cacheName() {
        return 'locations';
    }

    static endpoint() {
        return '/app/locations';
    }

    static endpointVersion = 'v2';

    static cacheTime() {
        return 300;
    }

    static fetch(ids: string | string[], force: boolean = false, ifExists: boolean = false) {
        CacheStore.getInstance(LocationObject).fetch(ids, force, ifExists);
    }

    static where(id: string): LocationObject|undefined {
        return CacheStore.getInstance(LocationObject).where(id);
    }
    static whereOrEmpty(id: string): LocationObject {
        const item = LocationObject.where(id);
        return item || new LocationObject();
    }

    static clearCachedData(shouldReload: boolean = false): void {
        CacheStore.getInstance(LocationObject).clearCachedData(shouldReload);
    }

    static build(arg: any): LocationObject {
        const res = new LocationObject();

        res.id = arg.id;
        res.name = arg.name;
        res.address = arg.address;
        res.description = arg.description;
        res.detailed_description = arg.detailed_description;
        res.additional_description = arg.additional_description;
        res.what3words_address = arg.what3words_address;
        res.location = arg.location;
        res.location_image = arg.location_image;
        res.images = arg.images;
        res.zones = arg.zones;
        res.updatedAt = arg.updatedAt;
        res.workingHours = arg.workingHours;
        res.timezone = arg.timezone;

        return res;
    }

    static formatResponse(params: {[key: string]: {[key: string]: string | null}}, response: any): {[key: string]: boolean | LocationObject | null} {
        // Store currency and tariffs
        const locationStore = LocationStore.sharedInstance();
        response.currencies.map(locationStore.updateCurrency)
        response.tariffs.map(locationStore.updateTariff)

        // Format locations
        const locations: {[key: string]: boolean | LocationObject | null} = {};
        response.locations.forEach((location: Location) => {
            locations[location.id] = location;
        });

        Object.keys(params[LocationObject.cacheName()]).forEach(key => {
            if (typeof locations[key] === 'undefined') {
                locations[key] = true;
            }
        });
        return locations;
    }
}

export interface Pin {
    id: string;
    pin_image_id: string|null;
    geo: string;
    av: {
        ava: number;
        una: number;
        unk: number;
    }
}

export function formatEVSEPriceLabel(evse: EVSE) {
    const locationStore = LocationStore.sharedInstance();
    const priceFormatterStore = PriceFieldsFormatterStore.sharedInstance();

    if (evse.tariffId === null) {
        return __('location.price.free');
    }
    const tariff = locationStore.getTariffById(evse.tariffId + '');
    if (!tariff) {
        return '';
    }

    if (tariff.priceType === 'charging not allowed') {
        return '';
    }

    if (tariff.priceType === 'free') {
        return __('location.price.free');
    }

    if (tariff.priceType === 'flat rate' && locationStore.getCurrencyByCode(tariff.currencyCode) !== null) {
        // simple pricing
        return format(tariff.priceForSession || 0, locationStore.getCurrencyByCode(tariff.currencyCode), '');
    }

    if (tariff.priceType === 'duration+energy' && (tariff.priceForDuration === null || tariff.priceForEnergy === null || tariff.priceForDuration <= 0 || tariff.priceForEnergy <= 0) && locationStore.getCurrencyByCode(tariff.currencyCode) !== null) {
        // simple pricing
        const canUsePriceForDuration = (tariff.priceForEnergy === null || tariff.priceForEnergy <= 0);
        return format(canUsePriceForDuration ? tariff.priceForDuration || 0 : tariff.priceForEnergy || 0, locationStore.getCurrencyByCode(tariff.currencyCode), '');
    }

    return __('location.price.complex');
}

export function formatEVSEPriceMode(evse: EVSE) {
    const locationStore = LocationStore.sharedInstance();

    if (evse.tariffId === null) {
        return '';
    }
    const tariff = locationStore.getTariffById(evse.tariffId + '');
    if (tariff) {
        if (tariff.priceType === 'flat rate') {
            return __('location.price.session');
        }
        if (
            (tariff.priceType === 'duration+energy')
            && (tariff.priceForDuration === null || tariff.priceForEnergy === null || tariff.priceForDuration <= 0 || tariff.priceForEnergy <= 0)
            && locationStore.getCurrencyByCode(tariff.currencyCode) !== null
        ) {
            if (tariff.priceForEnergy === null || tariff.priceForEnergy <= 0) {
                return __('location.price.period_time').replace(':period', tariff?.pricingPeriodInMinutes?.toString() ?? '1');
            } else {
                return __('location.price.energy');
            }

        }
    }
    return '';
}

export type EvseErrorCode = 'ConnectorLockFailure' |
    'EVCommunicationError' |
    'GroundFailure' |
    'HighTemperature' |
    'InternalError' |
    'LocalListConflict' |
    'NoError' |
    'OtherError' |
    'OverCurrentFailure' |
    'OverVoltage' |
    'PowerMeterFailure' |
    'PowerSwitchFailure' |
    'ReaderFailure' |
    'ResetFailure' |
    'UnderVoltage' |
    'WeakSignal';
