import history from './LocalHistory';
import {getRoutes} from "../Routes";

type Delay = any;

export function delay(time: number, res?: any): Promise<Delay> {
    return new Promise(resolve => setTimeout(() => resolve(res), time));
}

export const navigate = (routeName: string, params?: any): Promise<Delay> => {
    const matchedRoute = matchRoutePath(routeName, params);

    if (history.location.pathname !== matchedRoute.path) {
        let state = {}

        if (typeof params === 'object') {
            state = Object.assign(state, params);
        }

        if (typeof matchedRoute.path === 'object') {
            state = Object.assign(state, matchedRoute.path);
        }

        history.push(matchedRoute.path, {
            ...JSON.parse(JSON.stringify(state))
        });
    }

    return delay(50);
};

export const goBack = (): Promise<Delay> => {
    history.goBack();
    return delay(50);
};

export const matchRoutePath = (routeName: string, params?: {[key:string]: any}) => {
    const matchedRoute = getRoutes().find(route => route.name === routeName);

    if (!matchedRoute) {
        return {path: routeName};
    }

    let path = matchedRoute.path;
    //Path may contain param placeholders(:id e.g), replace them with values
    if (params) {
        path = buildPathWithParams(path, params);
    }

    return {path};
};

function buildPathWithParams(path: string, params: {[key:string]: any}) {
    return path.replace(/:\w+(\?)?/g, (matchedParam) => {
        const param = matchedParam.replace(/[:?]/g, '');

        return params[param] ?? '';
    });
}
