import React from "react";
import {MarkerClusterer} from "@react-google-maps/api";
import {Clusterer, MarkerExtended} from "@react-google-maps/marker-clusterer";
import settings from "../../settings";
import MapClusterPinsComponent from "./MapClusterPinsComponent";
import {Pin} from "@ampeco/charge-models";
import colors from "../../res/colors";

const clustererOptions = {
    enableRetinaIcons: true,
    averageCenter: true,
    minimumClusterSize: 5,
    batchSize: 100,
    clusterClass: 'maps-clusterer',
    imagePath: `${settings.images}/pin-multiple-`,
    imageSizes: [42, 60],
    calculator: function CALCULATOR(
        markers: MarkerExtended[],
    ): ClusterIconInfo {
        const title = '';
        const count = markers.length.toString();
        const parsedCount = parseInt(count);
        let text = count;
        switch (true) {
            case parsedCount <= 10: {
                text = count;
                break;
            }
            case (parsedCount > 10 && parsedCount <= 50): {
                text = '10+';
                break;
            }
            case (parsedCount > 50 && parsedCount < 100): {
                text = '50+';
                break;
            }
            case (parsedCount >= 100): {
                text = '99+';
                break;
            }
        }

        return {
            text,
            index: 1,
            title
        };
    }
};

const clustererStyles = [
    {
        url: `${settings.images}/pin-multiple.png`,
        height: 53,
        width: 53,
        textColor: colors.pinTextColor,
        textSize: 16,
        anchorText: [-5, -5]
    }
];

interface Props {
    pins: Pin[]
}

const MapClusterComponent = ({pins}: Props): JSX.Element => {
    return (
        <MarkerClusterer
            options={clustererOptions}
            styles={clustererStyles}
        >
            {(clusterer: Clusterer) => {
                return <MapClusterPinsComponent clusterer={clusterer} pins={pins} />
            }}
        </MarkerClusterer>
    );
};

export default MapClusterComponent;
