import net, {setAuthenticationInfo} from '@ampeco/net';

export interface OAuthSettings {
    client_id: string;
    client_secret: string;
    oauth_token_url: string;
}

export class OAuthAPI {

    public static settings: () => OAuthSettings;

    public static init(oauthSettings: () => OAuthSettings) {
        this.settings = oauthSettings;
    }

    public static async logout(): Promise<boolean> {
        try {
            await net.post('/app/logout');
        } finally {
            await setAuthenticationInfo(null);
        }
        return true;
    }

    public static async login(email: string, password: string): Promise<boolean> {
        const issued_on = new Date();
        const res = await net.post(this.settings().oauth_token_url, {
            username: email,
            password,
            grant_type: 'password',
            client_id: this.settings().client_id,
            client_secret: this.settings().client_secret,
        });
    
        await setAuthenticationInfo({...res, issued_on});
        return true;
    }

    public static async loginAsAdHoc(): Promise<boolean> {
        const issued_on = new Date();
        const res = await net.post(this.settings().oauth_token_url, {
            grant_type: 'ad-hoc',
            unique_for_device: false, // Added to achieve backward compatibility. By default if this is not passed, the backend will re-use the ad-hoc profiles based on the device-id provided in the headers
            client_id: this.settings().client_id,
            client_secret: this.settings().client_secret,
        });

        await setAuthenticationInfo({...res, issued_on});
        return true;
    }

    public static async resetPassword(
        email: string,
        password: string,
        confirmPassword: string,
        token: string,
    ): Promise<boolean> {
        await net.post('/app/password/reset', {
            email,
            password,
            password_confirmation: confirmPassword,
            token,
        });
        return true;
    }
}
