import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {FormStore} from './FormStore';
import {ProviderContext} from './FormStoreProvider';
import {ActivityIndicator} from 'react-native';
import {ButtonProps, InputProps, ThemeProps, withTheme} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import AmpecoTheme from '@ampeco/theme';
import Alert from '@ampeco/alert';

interface Props {
    name: string;
    actionAttributes?: any[];
    type: any;
    disableIfInvalid?: boolean;
    validateOnPress?: boolean;
    onPress?: () => {};
    confirmationPrompt?: string;
    confirmationPromptMessage?: string;
    confirmationPromptCancel?: string;
    confirmationPromptOK?: string;
    confirmationPromptDestructive?: boolean;
}

@observer
class Action extends React.Component<Props & ButtonProps & InputProps & ThemeProps<AmpecoTheme>, ProviderContext> {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    get store(): FormStore {

        if (this.context.store) {
            return this.context.store;
        } else {
            throw new Error('Store not defined for element');
        }
    }

    render() {
        const {
            name,
            actionAttributes,
            disableIfInvalid,
            validateOnPress,
            onPress,
            confirmationPrompt,
            confirmationPromptMessage,
            confirmationPromptCancel,
            confirmationPromptOK,
            confirmationPromptDestructive,
        } = this.props;

        const store = this.store;
        let props = {...this.props};
        delete props.type;
        if (store.processing === name) {
            props = {...props, icon: <ActivityIndicator color={(this.props.theme.Button && this.props.theme.Button.titleStyle && this.props.theme.Button.titleStyle.color) || 'white'}/>};
            if (store.processingItem) {
                props = {...props, title: store.processingItem};
            }
        }

        return <this.props.type {...props}
                                value={store.form[name] as string}
                                onPress={async () => {
                                    const completion = async () => {
                                        await store.action(name, actionAttributes);
                                        onPress && onPress();
                                    };

                                    if (validateOnPress) {
                                        store.validateAll();
                                        if (!store.isValid) {
                                            return;
                                        }
                                    }
                                    if (store.processing === name) {
                                        return; // disable double-presing
                                    }
                                    if (confirmationPrompt) {
                                        Alert.sharedInstance().show(confirmationPrompt, confirmationPromptMessage,
                                            [
                                                {
                                                    text: confirmationPromptCancel || __('message.cancel'),
                                                    style: 'cancel',
                                                },
                                                {
                                                    text: confirmationPromptOK || __('message.ok'),
                                                    style: (confirmationPromptDestructive === true ? 'destructive' : 'default'),
                                                    onPress: await completion,
                                                },
                                            ],
                                            {cancelable: false},
                                        );
                                    } else {
                                        await completion();
                                    }
                                }}
                                disabled={(disableIfInvalid && !store.isValid) || props.disabled}
        />;
    }
}

export default withTheme<Props & ButtonProps & InputProps, AmpecoTheme>(Action);
