import {action, observable} from 'mobx';
import net from '@ampeco/net';
import {MenuItem} from '@ampeco/charge-models';

export default class MenuItemsStore {
    private static instance: MenuItemsStore;

    @observable items: MenuItem[] = [];
    @observable hiddenItems: string[] = [];

    static sharedInstance(): MenuItemsStore {
        if (MenuItemsStore.instance === undefined) {
            MenuItemsStore.instance = new MenuItemsStore();
        }
        return MenuItemsStore.instance;
    }

    async loadFromBackend() {
        const res = await net.get('/app/custom-menu-items');
        this.items = res.data ?? [];
    }

    @action.bound
    addHiddenItem(item: string) {
        this.hiddenItems.push(item);
    }

    isItemHidden(item: string): boolean {
        return this.hiddenItems.includes(item);
    }

    isItemVisible(item: string): boolean {
        return !this.isItemHidden(item);
    }
}
