import {computed, observable, toJS} from 'mobx';
import {Location, LocationObject, Pin} from '@ampeco/charge-models';
import LocationStore from './LocationStore';
import {ToasterStore} from '@ampeco/toaster';
import {__} from '@ampeco/i18n';
import {CacheStore} from '@ampeco/cache';
import {Favorites} from '@ampeco/charge-api'

type LocationWithKey = Location & {key: string;};

export default class FavoritesStore {

    private static instance: FavoritesStore;

    @observable favoritesList: string[] = [];

    static sharedInstance(): FavoritesStore {
        if (FavoritesStore.instance === undefined) {
            FavoritesStore.instance = new FavoritesStore();
        }
        return FavoritesStore.instance;
    }

    @computed get favorites(): LocationWithKey[] {
        const store = LocationStore.sharedInstance();

        const res: LocationWithKey[] = [];

        this.favoritesList.forEach(locationId => {
            const location = LocationObject.where(locationId);

            if (!location) {
                return;
            }
            const locationCopy: LocationWithKey = toJS(location) as any;

            locationCopy.key = String(location.id);
            res.push(locationCopy as LocationWithKey);
        });
        return res;
    }

    async toggleFavorite(location: Location | null, setLoading?: (loading: boolean) => void): Promise<void> {
        if (!location) {
            return;
        }

        if (this.favoritesList.indexOf(location.id + '') > -1) {
            let index: number = 0;
            try {
                index = this.favoritesList.indexOf(location.id + '');
                this.favoritesList.splice(index, 1);
                setLoading && setLoading(true);
                await Favorites.remove(location);
            } catch (e) {
                // insert back into the position where we got it from
                this.favoritesList.splice(index, 0, location.id + '');
                ToasterStore.sharedInstance().setMessage(__('favorites.failed-to-remove'));
            } finally {
                setLoading && setLoading(false);
            }
        } else {
            try {
                // push to the beginnig of the array, so that it appears on the top of the list
                this.favoritesList.unshift(location.id + '');
                setLoading && setLoading(true);
                await Favorites.add(location);
            } catch (e) {
                this.favoritesList.splice(this.favoritesList.indexOf(location.id + ''), 1);
                ToasterStore.sharedInstance().setMessage(__('favorites.failed-to-add'));
            } finally {
                setLoading && setLoading(false);
            }
        }
    }

    loadFromBackend() {
        CacheStore.getInstance(LocationObject).fetch(this.favoritesList);
    }
}
