import React from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {StyleSheet} from 'react-native';
import { ReservationStore } from '@ampeco/charge-stores';
import {observer} from 'mobx-react';
import { humanizeDuration } from '@ampeco/humanize-duration';
import { StandaloneIcon } from '@ampeco/icons';
import Notice from '@ampeco/notice';
import {ChargeTheme} from '@ampeco/theme';
import Navigation from '@ampeco/navigation';

export default observer(function ActiveReservationHint() {
    const reservationStore = ReservationStore.sharedInstance();
    const remaining = reservationStore.activeReservationSeconds;
    const navigationService = Navigation.sharedInstance();

    const reservation = reservationStore.activeReservation;
    if (!reservation || remaining === 0) {
        return null;
    }

    return <ThemeConsumer>{({theme}) =>
        {
            return <Notice onPress={() => {navigationService.navigate('SessionStart', {evseId: reservation?.evseIdentifier}); }}>
                <StandaloneIcon source={theme.ActiveReservationHint.iconSource} style={styles.icon}/>
                {remaining &&
                    <>
                        <Text style={theme.ActiveReservationHint.textStyle}>
                            {__('reservation.reservation-message').replace(':remaining', '')}
                        </Text>
                        <Text style={StyleSheet.flatten([theme.ActiveReservationHint.textStyle, theme.ActiveReservationHint.timerStyle])}>
                            {humanizeDuration(remaining)}
                        </Text>
                    </>
                }
                </Notice>;
        }
    }</ThemeConsumer>;
});

const styles = StyleSheet.create({
    icon: {
        marginHorizontal: 8,
    },
});
