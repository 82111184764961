import { Cacheable, CacheStore } from '@ampeco/cache';
import {PixelRatio} from 'react-native';

export interface PinImage extends Cacheable {
    url: string;
    name: string;
    offset: {
        x: number;
        y: number;
    };
    size: {
        width: number;
        height: number;
    };
}

export default class PinImageDataStore {
    id = 0;
    name = '';
    updatedAt = '';
    url = '';
    offset = { x: 0, y: -30 }
    size = {width: 42, height: 60}

    static async load() {
        CacheStore.getInstance(PinImageDataStore).request({[this.cacheName()]: {}});
    }

    static async fetch(ids: string | string[], force: boolean = false) {
        CacheStore.getInstance(PinImageDataStore).fetch(ids, force);
    }

    static build(args: any): PinImage {
        return {
            id: parseInt(args.id),
            name: args.name,
            url: args.url,
            offset: args.offset,
            size: args.size,
            updatedAt: args.updatedAt,
        };
    }

    static cacheName() {
        return 'pinImages';
    }

    static endpoint() {
        return `/app/pins/images?pixelDensity=${Math.round(PixelRatio.get())}`;
    }

    static cacheTime() {
        return 600;
    }

    static findOrFetch(id: string): PinImage | undefined {
        return CacheStore.getInstance(PinImageDataStore).findOrFetch(id);
    }

    static endpointVersion = 'v2'
}
