import React from 'react';
import {View, SafeAreaView, StyleSheet, KeyboardAvoidingView, Image, ActivityIndicator, TouchableWithoutFeedback} from 'react-native';
import {observer} from 'mobx-react';
import {__, LocaleStore} from '@ampeco/i18n';
import {Divider, Text, Button, ThemeConsumer} from 'react-native-elements';
import LoginView from '../components/Login';
import {TextButton} from '@ampeco/icons';
import {LanguageSelect} from '@ampeco/settings';
import {EndpointSelect} from '@ampeco/dev-endpoint';
import AmpecoTheme from '@ampeco/theme';

type Props = {
    onLocaleChange?: (locale: string) => void,
    onSkip?: () => void,
    onLogin?: () => Promise<void>,
    onForgotten?: (email?: string) => void,
    onRegister?: () => void,
    onFacebookLogin?: () => Promise<void>,
    onGoogleLogin?: () => Promise<void>,
    onEmptySpaceTap?: () => void,
    discoverEnabled?: boolean,
    canSkip?: boolean,
};

interface State {
    languageCollapsed: boolean;
    thirdPartyLoginWorking: string[];
    clearStore: (() => void) | null;
}

@observer
class Login extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {languageCollapsed: true, thirdPartyLoginWorking: [], clearStore: null};
    }

    private hasSocialLogin(): boolean {
        return this.props.onFacebookLogin !== undefined || this.props.onGoogleLogin !== undefined;
    }

    render() {
        const localeStore = LocaleStore.sharedInstance();
        LocaleStore.observeLanguage();
 
        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => (
                <SafeAreaView style={styles.container}>
                    <View style={styles.languageBox}>
                        {localeStore.hasMoreThanOneLanguage && <LanguageSelect callback={this.props.onLocaleChange} />}
                        {this.props.discoverEnabled && <EndpointSelect/>}
                    </View>

                    <View style={{flex: 4}} />

                    <KeyboardAvoidingView behavior="padding">
                        <LoginView
                            style={{flex: 0}}
                            clearStore={(clear: any) => this.setState({clearStore: clear})}
                            onLogin={this.props.onLogin}
                            onForgotten={this.props.onForgotten}
                            onRegister={this.props.onRegister}
                        />
                    </KeyboardAvoidingView>

                    {this.hasSocialLogin() && <View style={{flex: 0.5, minHeight: 0}} />}

                    {this.hasSocialLogin() && <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        maxHeight: 20,
                        minHeight: 16,
                        marginHorizontal: 25,
                    }}>

                        <Divider style={{flex: 1}}/>
                        <Text style={[theme.Login.loginWith, {
                            marginTop: 0,
                            marginBottom: 0,
                            margin: 10,
                        }]}>{__('login.label.login-with')}</Text>
                        <Divider style={{flex: 1}}/>
                    </View>}

                    {this.hasSocialLogin() && <View style={{flex: 0, flexDirection: 'row', justifyContent: 'center'}}>
                        {this.props.onFacebookLogin && <Button
                            title=""
                            containerStyle={{width: 44, margin: 15}}
                            onPress={async () => {
                                this.setState({thirdPartyLoginWorking: ['facebook']});
                                try {
                                    this.props.onFacebookLogin && await this.props.onFacebookLogin();
                                } catch (e) {
                                    console.info(e);
                                } finally {
                                    this.setState({thirdPartyLoginWorking: []});
                                }
                            }}
                            buttonStyle={styles.socialButton}
                            icon={this.state.thirdPartyLoginWorking.includes('facebook') ? <ActivityIndicator/> : <Image source={theme.Login.images.facebook}/>}
                        />}
                        {this.props.onGoogleLogin && <Button
                            title=""
                            containerStyle={{width: 44, margin: 15}}
                            buttonStyle={styles.socialButton}
                            onPress={async () => {
                                this.setState({thirdPartyLoginWorking: ['google']});
                                try {
                                    this.props.onGoogleLogin && await this.props.onGoogleLogin();
                                } catch (e) {
                                    console.info(e);
                                } finally {
                                    this.setState({thirdPartyLoginWorking: []});
                                }
                            }}
                            icon={this.state.thirdPartyLoginWorking.includes('google') ? <ActivityIndicator/> : <Image source={theme.Login.images.google}/>}/>}
                    </View>}

                    <TouchableWithoutFeedback onPress={this.props.onEmptySpaceTap}>
                        <View style={{ flex: this.hasSocialLogin() ? 1.5 : 3, minHeight: 0}} />
                    </TouchableWithoutFeedback>

                    {this.props.canSkip || typeof this.props.canSkip === 'undefined' && <View style={styles.skipBox}>
                        <TextButton textStyle={[styles.textButton, theme.Login.textButtons]} onPress={() => {
                            this.props.onSkip && this.props.onSkip();
                            this.state.clearStore && this.state.clearStore();
                        }}>
                            {__('login.button-skip')}
                        </TextButton>
                    </View>}
                </SafeAreaView>
            )}</ThemeConsumer>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        ...{
            flex: 1,
            flexDirection: 'column',
            padding: 0,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            alignContent: 'stretch',
        },
    },
    languageBox: {
        flex: 0,
        marginLeft: 25,
        marginRight: 25,
        marginTop: 10,
        alignItems: 'flex-end',
    },
    registerBox: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        maxHeight: 50,
        marginTop: 10,
    },
    skipBox: {
        flex: 0,
        marginLeft: 25,
        marginRight: 25,
        paddingBottom: 15,
        alignSelf: 'stretch',
        alignContent: 'flex-end',
    },
    formBox: {
        paddingHorizontal: 25,
        flex: 0,
        width: '100%',
        justifyContent: 'center',
    },
    loginButton: {
        alignSelf: 'stretch',
    },
    textButton: {
        ...{
            fontSize: 14,
        },
    },
    socialButton: {
        height: 43,
        width: 43,
        margin: 0,
        backgroundColor: '#333333',
    },
});

export default Login;
