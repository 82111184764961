import {Theme} from 'react-native-elements';
import colors from './res/colors';
import palette from './res/palette';
import fonts, {FontWeight} from './res/fonts';
import {icons, miscImages, paymentMethodImages, connectorImages, filterCheckboxImages} from './res/images';
import {ChargeTheme} from '@ampeco/theme';
import {StyleSheet} from 'react-native';
import skin from './res/skin';
import images from "@ampeco/balance/images";

export const theme: Theme<ChargeTheme> = {
    ToasterError: {
        containerStyle: {
            backgroundColor: colors.errorBackgroundColor,
        },
        textStyle: {
            color: colors.errorTextColor,
        },
    },
    PaymentMethods: {
        textStyle: {
            ...palette.light,
            color: colors.textColor,
        },
        listingItemStyle: {
            backgroundColor: colors.backgroundColor,
        },
        addIconSource: icons.add,
        removeIconSource: icons.del,
        setDefaultIconSource: icons.favorites,
        paymentMethodTypeImages: paymentMethodImages,
        failNoticeBackgroundColor: colors.noticeBackgroundColor,
        failNoticeBoldText: {
            color: colors.noticeTextColor,
            fontFamily: fonts.medium,
        },
        failNoticeRegularText: {
            color: colors.noticeTextColor,
            fontFamily: fonts.light,
        },
        failNoticeIcon: icons.warningBlack,
        failNoticeIconStyle: {
            width: 21,
            height: 21
        }
    },
    AddPaymentMethod: {

        native: {
            container: {
                ...palette.containerLayout,
            },
            labels: {
                ...palette.label,
            },
            inputs: {
                color: colors.textColor,
                fontFamily: 'Roboto-Regular',
                fontSize: 14,
                borderBottomColor: colors.dividerColor,
                borderBottomWidth: 1,
            },
            inputContainers: {
            },
            invalidColor: colors.errorBackgroundColor,
            frontCardImage: miscImages.cardFront,
            backCardImage: miscImages.cardBack,
        },
    },
    PayForSession: {
        payNowIcon: icons.payNow,
        titleStyle: {
            color: colors.textEmphasisColor,
            fontFamily: fonts.bold,
        },
        descriptionStyle: {
            color: colors.textEmphasisColor,
            fontFamily: fonts.regular,
            fontWeight: FontWeight.light,
            marginBottom: 20,
        },
        infoLabelStyle: {
            color: colors.textLabelColor,
        },
        infoValueStyle: {
            color: colors.textEmphasisColor,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Light',
        },
        buttonContainer: {
            marginTop: 20
        },
        buttonStyle: {
            marginTop: 20,
        }
    },
    Select: {
        dropdownStyle: {
            backgroundColor: colors.backgroundColor,
            color: colors.textEmphasisColor,
            borderRadius: 5,
        },
        textStyle: {
            color: colors.textEmphasisColor,
        },
        dropdownTextStyle: {
            color: colors.textColor,
            backgroundColor: colors.backgroundColor,
        },
        dropdownTextHighlightStyle: {
            color: colors.textEmphasisColor,
            backgroundColor: colors.backgroundColor,
        },
        selectedStyle: {
            color: colors.textEmphasisColor,
        },
        unselectedStyle: {
            color: colors.textColor,
        },
        selectIconSource: icons.dropDown,
        selectPlaceholderStyle: {
            color: colors.textEmphasisColor,
            fontSize: 14,
            fontFamily: palette.regular.fontFamily,
            fontWeight: palette.regular.fontWeight,
        },
        selectWithBottomBorder: {
            borderBottomColor: colors.dividerColor,
            borderBottomWidth: StyleSheet.hairlineWidth,
        },
    },
    Button: {
        titleStyle: {
            fontFamily: 'Roboto-Regular',
            fontSize: 16,
            paddingTop: 0,
            paddingBottom: 0,
            color: colors.buttonTextColor,
            paddingLeft: 6,
            paddingRight: 6,
        },
        buttonStyle: {
            // backgroundColor: colors.buttonPrimaryColor,
            paddingTop: 10,
            paddingBottom: 10,
            elevation: 0,
            borderRadius: 5,
        },
        containerStyle: {
            marginBottom: 0,
            marginTop: 0,
            borderWidth: 0,
            borderRadius: 5,
        },
        disabledStyle: {
            backgroundColor: colors.buttonPrimaryDisabledColor,
        },
        disabledTitleStyle: {
            color: colors.buttonTextColor,
        },
        raised: false,
    },
    colors: {
        primary: colors.buttonPrimaryColor,
    },
    Text: {
        style: {
            fontSize: 16,
            color: colors.textColor,
        },
        labelStyle: {...palette.label},
        labelColorStyle: {color: colors.textLabelColor},
        labelFontStyle: {
            fontFamily: fonts.regular,
            fontWeight: FontWeight.regular,
            fontSize: 12,
        },
    },
    Input: {
        containerStyle: {
            width: '100%',
            marginTop: 4,
            paddingHorizontal: 0,
        },
        placeholderTextColor: colors.textLabelColor,
        inputStyle: {
            color: colors.textColor,
            fontFamily: 'Roboto-Regular',
            fontSize: 14,
        },
        inputContainerStyle: {
            height: 30,
            borderBottomColor: colors.dividerColor,
            borderBottomWidth: StyleSheet.hairlineWidth,
        },
        errorStyle: {
            color: colors.textErrorColor,
            fontSize: 12,
            fontFamily: 'Roboto-Light',
            fontWeight: FontWeight.light,
            marginLeft: 0,
        },
    },
    CheckBox: {
        fontFamily: palette.text.fontFamily,
        containerStyle: {backgroundColor: 'transparent', borderWidth: 0},
        textStyle: {fontWeight: FontWeight.regular, color: colors.textColor, fontSize: 16},
        checkedColor: colors.textEmphasisColor,
        uncheckedColor: colors.textEmphasisColor,
        checkedIcon: filterCheckboxImages.on,
        uncheckedIcon: filterCheckboxImages.off,
    },
    Forms: {
        labelStyle: {...palette.label},
    },
    Divider: {
        style: {
            backgroundColor: colors.dividerColor,
            height: StyleSheet.hairlineWidth,
        },
    },
    Vouchers: {
        codeContainer: {
            height: 80,
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: colors.dividerColor,
            marginBottom: 6,
        },
        codeInputContainer: {
            borderBottomWidth: 0,
        },
        codeInput: {
            ...palette.light, ...{
                height: 80,
                fontSize: 30,
                justifyContent: 'flex-end',
                color: colors.textEmphasisColor,
            },
        },
        notice: {
            fontSize: 12,
            fontFamily: fonts.italic,
            textAlign: 'center',
            color: colors.textLabelColor,
        },
        textLabel: {
            ...palette.label, ...{
                flex: 1,
            },
        },
        button: {
            marginVertical: 15,
        }
    },
    Balance: {
        container: {
            flex: 1,
            marginTop: 24,
        },
        balanceContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
        },
        autoTopUpContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        autoTopUpAmountButton: {
            color: colors.buttonPrimaryColor,
        },
        listStyle: {
            borderTopColor: colors.dividerColor,
            borderBottomColor: colors.dividerColor,
            paddingLeft: 0,
            paddingRight: 0,
        },
        balance: {
            ...palette.light, ...{
                fontSize: 36,
                color: colors.textEmphasisColor,
            },
        },
        space: {
            marginVertical: 31,
        },
        historyHeader: {
            ...palette.light, ...{
                fontSize: 20,
                color: colors.textEmphasisColor,
                marginBottom: 10,
            },
        },
        textLabel: {
            ...palette.label,
        },
        saveIcon: icons.save,
        borderedList: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        addIconStyle: {
            width: 14,
            height: 14,
        }
    },
    History: {

        plusStyle: {
            color: colors.buttonActiveColor,
        },
        minusStyle: {
            color: colors.buttonDestructiveColor,
        },
        textStyle: {
            color: colors.textEmphasisColor,
        },

        disclosureIcon: icons.disclosure,
    },
    HistoryLine: {
        downloadIcon: icons.downloadPDF,
        downloadIconStyle: {
            width: 20,
            height: 25,
        },
        timerIcon: icons.timer,
        timerIconStyle: {
            width: 10,
            height: 10,
            marginRight: 5,
            tintColor: colors.textColor,
        },
    },
    Settings: {
        label: palette.label,
        view: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 25,
            marginTop: 35,
            borderBottomWidth: 1,
            borderBottomColor: colors.dividerColor,
        },
        viewNoBottom: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 25,
            marginTop: 35,
            borderBottomWidth: 0,
            borderBottomColor: colors.dividerColor,
        },
    },
    Notice: {
        containerStyle: {
            backgroundColor: colors.mapNoticeBackgroundColor,
        },
        textStyle: {
            fontSize: 14,
            color: colors.noticeTextColor,
            backgroundColor: colors.noticeBackgroundColor,
        },
        mapTextStyle: {
            fontSize: 14,
            backgroundColor: colors.mapNoticeBackgroundColor,
            color: colors.mapNoticeTextColor,
        },
        boldTextStyle: {
            fontFamily: fonts.bold,
        },
        iconStyle: {
            tintColor: colors.noticeTextColor,
        },
        mapIconStyle: {
            tintColor: colors.mapNoticeTextColor,
            width: 21,
            height: 21,
        },
        buttonIcon: {
            source: icons.warningWhite,
        },
    },
    InfoCenter: {
        header: palette.header,
        iconEnvelope: icons.envelope,
        iconHeadset: icons.headset,
        dividerColor: colors.dividerColor,
        textEmphasisColor: colors.textEmphasisColor,
        containerStyles: {
            flex: 1,
            paddingHorizontal: 0,
        },
        iconStyles: {
            height: 25,
            width: '100%',
            backgroundColor: 'none',
            resizeMode: 'center'
        },
        contactStyles: {
            marginLeft: 10
        }
    },
    NetworkError: {
        containerStyle: {backgroundColor: colors.backgroundColor},
        connectionErrorIcon: miscImages.connectionError,
        updateIcon: icons.update,
        titleStyle: palette.header,
    },
    UpdateRequired: {
        containerStyle: {backgroundColor: colors.backgroundColor},
        titleStyle: palette.header,

    },
    ScanQR: {
        separator: {
            backgroundColor: colors.backgroundColor,
        },
        overlayTarget: {
            borderColor: colors.backgroundColor,
        },
        torchIconActive: null,
        torchIconInactive: null,
    },
    PullUpPanel: {
        contentStyle: {backgroundColor: colors.backgroundColor},
        indicatorImageSource: icons.moreDetailsBelow,
    },
    Login: {
        loginWith: palette.label,
        textButtons: palette.text,
        images: {
            facebook: icons.facebook,
            google: icons.google,
            emailSmall: icons.emailSmall,
            passwordSmall: icons.passwordSmall,
            login: icons.login,
        },
        iconsStyle: {
            resizeMode: 'center',
            backgroundColor: 'none',
            width: 20,
            height: 20
        }
    },
    Logout: {
        iconSource: icons.login
    },
    TextButton: {
        style: {
        },
        textStyle: {
            ...palette.text,
            color: colors.textColor,
        },
    },
    Global: { // TODO: use it
        statusBarStyle: 'light-content',
    },
    Popup: { // TODO: use it
        containerStyle: {
            backgroundColor: colors.backgroundColor,
            shadowColor: colors.backgroundColor,
        },
        backgroundStyle: {
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
        titleStyle: {
            fontSize: 18,
            fontFamily: fonts.light,
        },
        closeImageSource: icons.close,
    },
    Loading: { // TODO: use it
        statusBarStyle: 'light-content',
        containerStyle: {
            backgroundColor: colors.backgroundColor,
        },
        activityIndicatorColor: 'string',
    },
    Datepicker: { // unused in this project.
        dateText: {
            color: colors.textColor,
            fontSize: palette.text.fontSize,
            fontFamily: palette.text.fontFamily,
        },
        dateInput: {
            borderBottomColor: colors.dividerColor,
        },
        btnTextConfirm: {
            color: colors.textEmphasisColor,
            fontFamily: fonts.medium,
            fontSize: 16,
        },
    },
    VerifyScreen: {
        containerStyle: {backgroundColor: colors.backgroundColor},
        titleStyle: palette.header,
        codeInputContainer: {
            borderBottomWidth: 0,
        },
        codeInput: {
            ...palette.light, ...{
                height: 100,
                fontSize: 30,
                justifyContent: 'flex-end',
                color: colors.textEmphasisColor,
            },
        },
    },

    PasswordRevealInput: {
        showPasswordIcon: icons.showPassword,
        hidePasswordIcon: icons.hidePassword,
        iconStyle:{
            height: 15,
            width: 20,
            backgroundColor: colors.backgroundColor
        }
    },

    TopUpBalance: {
        price: {
            fontSize: 36,
            ...palette.light,
            color: colors.textEmphasisColor,
            paddingVertical: 10,
        },
        bonus: {
            fontSize: 12,
            ...palette.regular,
            color: colors.backgroundColor,
        },
        bonusContainerStyle: {
            backgroundColor: colors.textEmphasisColor,
            borderWidth: 1,
            borderColor: colors.backgroundColor,
            paddingHorizontal: 10,
            width: 'auto',
            left: 'auto',
            right: 'auto',
        },
        topUpPackage: {
            backgroundColor: colors.topUpPackageBackgroundColor,
        },
        infoTextStyle: {
            color: colors.textColor
        }
    },
    Switch: {
        thumbColor: {
            true: colors.buttonPrimaryColor,
            false: colors.switchInactiveColor,
        },
        trackColor: {
            true: colors.switchTrackColor,
            false: colors.switchTrackColor,
        },
    },

    Code: {
        cellStyle: {
            color: palette.text.color,
            fontSize: palette.extraLarge.fontSize,
        },
        cellContainerStyle: {
            borderBottomColor: palette.text.color,
            borderBottomWidth: 1,
        },
    },

    AddButton: {
        containerStyle: {
            backgroundColor: colors.buttonPrimaryColor,
        },
        addIcon: icons.plus,
        iconStyle: {
            width: 21,
            height: 21,
        }
    },
    Invoices: {
        headerStyle: {...palette.header},
        labelStyle: {...palette.label},
        valueStyle: {...palette.text},
        contentStyle: {},
        editButtonIcon: icons.edit,
        saveButtonIcon: icons.save,
        addButtonIcon: icons.add,
    },
    Container: {
        byMargin: {...palette.containerMargin},
        byPadding: {...palette.containerPadding},
        layout: {...palette.containerLayout},
        byPaddingAndMarginTop: {
            paddingHorizontal: 25,
            marginTop: 28,
        },
    },
    Fonts: {
        light: palette.light,
        regular: palette.regular,
        bold: palette.bold,
        condensedLight: palette.condensedLight,
        condensedBold: palette.condensedBold,
        condensed: palette.condensed,

    },
    SwipeToShowActionsText: {
        style: {
            ...palette.medium,
        },
    },
    SwipeButton: {
        editStyle: {
            backgroundColor: colors.buttonPrimaryColor,
        },
        deleteStyle: {
            backgroundColor: colors.buttonDestructiveColor,
        },
        activeStyle: {
            backgroundColor: colors.buttonActiveColor,
        },
        textStyle: {
            color: colors.buttonTextColor,
        },
    },
    RoundedTag: {
        activeStyle: {
            backgroundColor: colors.buttonActiveColor,
        },
        inactiveStile: {
            backgroundColor: colors.buttonInactiveColor,
        },
        activeTextStyle: {
            color: colors.buttonActiveTextColor,
        },
        inactiveTextStyle: {
            color: colors.buttonInactiveTextColor,
        },
    },
    LanguageSelector: {
        style: palette.header,
    },
    Spinner: {
        color: colors.splashScreenSpinnerColor,
    },
    HTMLRenderer: {
        color: palette.text.color,
        fontSize: palette.text.fontSize,
    },
    PersonalAsset: {
        addButtonIcon: icons.add,
        rebootButtonIcon: icons.update,
        addIconSource: icons.add,
        saveButtonIcon: icons.save,
        startChargingIconSource: icons.power,
        header: palette.header,
        iconEnvelope: icons.envelope,
        iconHeadset: icons.headset,
        dividerColor: colors.dividerColor,
        textEmphasisColor: colors.textEmphasisColor,
        errorIcon: {
            icon: icons.warningBlack,
            tintColor: colors.textErrorColor,
        },
        backIcon: icons.back,
        numpadButton: {
            borderColor: colors.dividerColor,
        },
        noBorder: {
            backgroundColor: colors.backgroundColor,
        },
        numpadText: {
            color: colors.buttonPrimaryColor,
        },
        line: {
            borderColor: colors.dividerColor,
        },
        wrapper: {
            backgroundColor: colors.backgroundColor,
        },
        containerNumpad: {
            backgroundColor: colors.backgroundColor,
        },
        PersonalAssetAddStepView: {
            containerLayout: {
                ...palette.containerLayout,
            },
        },
        UnknownAsset: {
            header: palette.header,
            unknownIcon: miscImages.unknown,
            container: {
                backgroundColor: colors.backgroundColor,
            },
        },
        personalAssetList: {
            containerLayout: {
                paddingTop: 20,
                marginLeft: 10,
                marginRight: 10,
            },
        },
        personalChargingConnectorView: {
            containerLayout: {
                paddingTop: 20,
            },
            textLabel: {
                ...palette.label,
                fontFamily: palette.light.fontFamily,
                fontWeight: palette.light.fontWeight,
                fontSize: 11,
                color: colors.textEmphasisColor,
            },
            textLabelDescription: {
                ...palette.label,
                fontFamily: fonts.italic,
                fontWeight: palette.light.fontWeight,
                fontSize: 11,
                color: colors.textEmphasisColor,
            },
            selectPhotoDescription: {
                ...palette.label,
                fontFamily: palette.regular.fontFamily,
                fontWeight: palette.light.fontWeight,
                fontSize: 14,
                color: colors.textEmphasisColor,
            },
        },
        configuration: {
            nameInput: {
                ...palette.regular, ...{
                    color: colors.textEmphasisColor,
                },
                fontSize: 14,
            },
        },
        statusBackgroundStyles: {
            chargingStyle: {
                backgroundColor: colors.buttonActiveColor,
            },
            readyStyle: {
                backgroundColor: colors.buttonActiveTextColor,
            },
            faultStyle: {
                backgroundColor: colors.errorBackgroundColor,
            },
            noConnection: {
                backgroundColor: colors.dividerColor,
            },
        },
        lightText: {
            paddingLeft: 3,
            color: colors.buttonActiveTextColor,
        },
        darkText: {
            paddingLeft: 3,
            color: colors.buttonInactiveTextColor,
        },
        borderButton: {
            borderColor: colors.dividerColor,
            borderWidth: 1,
            alignItems: 'center',
        },
        defaultImage: miscImages.personalCharger,
    },
    Components: {
        roundNumber: {
            backgroundColor: colors.dividerColor,
        },
        roundNumberText: {
            color: colors.backgroundColor,
        },
        stepTitle: {
            color: colors.textEmphasisColor,
        },
        stepDescr: {
            color: colors.textEmphasisColor,
        },
        FailImage: {
            icon: miscImages.unknown,
        },
    },
    Distance: {
        flagStyle: {
            tintColor: colors.textEmphasisColor,
        },
        textStyle: {
            color: colors.textEmphasisColor,
        },
        flagIcon: icons.distanceFlag,
    },
    Connector: {
        iconStyle: {
            tintColor: colors.connectorsTintColor,
        },
        titleStyle: {
            color: colors.textEmphasisColor,
            fontWeight: FontWeight.bold,
        },
        subtitleStyle: {
            color: colors.textEmphasisColor,
            fontWeight: FontWeight.regular,
        },
        connectorImages,
    },
    EVSE: {
        textStyle: palette.emphasis,
        disclosureIcon: icons.disclosure,
        priceTopStyle: {
            fontSize: 24,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Light',
        },
        priceBottomStyle: {
            textAlign: 'center',
            fontSize: 13,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Light',
        },
        disclosureStyle: {
            tintColor: palette.text.color,
        },
        inUse: {
            color: colors.inUse,
        },
        available: {
            color: colors.available,
        },
        circleInUse: {
            backgroundColor: colors.inUse,
        },
        circleAvailable: {
            backgroundColor: colors.available,
        },
    },
    Zone: {
        iconStyle: {
            tintColor: colors.textEmphasisColor,
        },
        iconSource: icons.info,
        textStyle: {
            color: colors.textColor
        },
    },
    What3words: {
        textStyle: {
            ...palette.label,
            color: colors.textEmphasisColor,
        },
        learnMore: {
            fontSize: 12,
            color: colors.buttonPrimaryColor,
            fontWeight: FontWeight.medium,
        },
    },
    PullUpPanelContent: {
        containerStyle: {
            backgroundColor: colors.backgroundColor,
        },
        fragmentStyle: {
            backgroundColor: colors.textEmphasisColor,
        },
        offset: {
            marginHorizontal: 0,
        }
    },
    Pricing: {
        containerStyle: {
            marginTop:10,
        },
        labelStyle: {
            ...palette.text,
            ...palette.light,
        },
        valueStyle: {
            ...palette.emphasis,
        },
        infoIcon: icons.info,
        iconStyle: {
            tintColor: colors.textEmphasisColor,
        },
        descriptionStyle: {
            ...palette.text,
        },
        titleStyle: {
            ...palette.emphasis,
        },
        dayNightLabel: {
            ...palette.emphasis,
        },
    },
    FlowWizard: {
        steps: {
            style: {
                backgroundColor: '#12A4C8',
            },
            active: {
                backgroundColor: palette.steps.active,
            },
            inactive: {
                backgroundColor: palette.steps.inactive,
            },
        },
    },
    Register: {
        actionIconSource: icons.forward,
    },
    Disclosure: {
        textStyle: {
            ...palette.emphasis,
            ...palette.light,
        },
        iconSource: icons.disclosure,
    },
    DetailsLine: {
        titleStyle: {
            fontFamily: 'Roboto-Light',
            fontWeight: FontWeight.light,
            fontSize: 12,
            color: colors.textColor,
        },
        valueStyle: {
            fontFamily: 'Roboto-Light',
            fontWeight: FontWeight.light,
            fontSize: 12,
            color: colors.textEmphasisColor,
        },
    },
    TextHeader: {
        style: {
            fontSize: 20,
            fontFamily: 'Roboto-Light',
            fontWeight: FontWeight.light,
            color: colors.textEmphasisColor,
        },
    },
    TextSubTitle: {
        style: {
            ...palette.label,
        },
    },
    SessionRunning: {
        endSessionIcon: icons.endSession,
        startSessionIcon: icons.startSession,
        agileStreetsOptimized: icons.agileStreetsOptimized,
        errorTextStyle: {
            color: colors.textErrorColor,
        },
        activityIndicatorColor: colors.buttonTextColor,
        chargingAnimation: skin().chargingAnimation,
        agileStreetsEndTimeIcon: icons.agileStreetsEndTimeIcon,
        agileStreetsTargetChargeIcon: icons.agileStreetsTargetChargeIcon,
        agileStreetsIcon: icons.agileStreetsIcon,
    },
    AgileStreetSlider: {
        toolTipPriceTextStyle: {
            ...palette.text,
            ...palette.large,
            ...palette.bold,
            color: 'black',
            textAlign: 'center',
        },
        toolTipAdditionalInfoTextStyle: {
            ...palette.text,
            color: 'black',
        },
        ecoColor: colors.agileStreetsEcoColor,
        ecoColorDisabled: colors.agileStreetsEcoDisabledColor,
        ecoTextDisabled: colors.agileStreetsEcoDisabledTextColor,
    },
    CarChargeAnimation: {
        chargingBackgroundImage: miscImages.chargingBackground,
        chargingProgressOffBar: colors.chargingProgressOffBar,
        chargingProgressOnBar: colors.chargingProgressOnBar,
        percentStyle: {
            color: colors.chargingProgressText,
        },
    },
    GenericChargeAnimation: {
        statusStyle: {
            color: colors.textEmphasisColor,
        },
        cellStyle: {
            borderColor: colors.chargingProgressGenericBorder,
        },
        cellPlusStyle: {
            backgroundColor: colors.chargingProgressGenericBorder,
        },
        chargingProgressGenericOffBar: colors.chargingProgressGenericOffBar,
        chargingProgressGenericOnBar: colors.chargingProgressGenericOnBar,
    },
    Address: {
        locationTitleStyle: {
            fontSize: 18,
            fontWeight: FontWeight.regular,
            color: colors.textEmphasisColor,
            textAlign: 'left',
        },
        locationSubtitleStyle: {
            fontSize: 12,
            fontWeight: FontWeight.light,
            color: colors.textEmphasisColor,
            textAlign: 'left',
        },
        locationIconStyle: {
            height: 20,
            width:20,
        },
        locationIconSource: miscImages.buttonLocation,
        locationIconContainerStyle: {
            backgroundColor: colors.buttonPrimaryColor,
        },
    },
    PersonalChargingSessionStatsComponent: {
        statTitleStyle: {
            color: colors.textLabelColor,
            fontSize: 12,
        },
        statValueStyle: {
            color: colors.textEmphasisColor,
            fontSize: 18,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Light',
        },
        textStyle: {
            fontSize: 22,
            color: colors.textEmphasisColor,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Regular',
        },
        labelStyle: {
            fontSize: 12,
            fontWeight: FontWeight.regular,
            fontFamily: 'RobotoCondensed-Regular',
            color: colors.textEmphasisColor,
        },
    },
    SessionSummaryStatsComponent: {
        statTitle: {
            color: colors.textLabelColor,
            fontSize: 10,
        },
        statValue: {
            color: colors.textEmphasisColor,
            fontSize: 18,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Light',
        },
        textStyles: {
            fontSize: 18,
            color: colors.textEmphasisColor,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Regular',
        },
        labelStyle: {
            fontSize: 10,
            fontWeight: FontWeight.regular,
            fontFamily: 'RobotoCondensed-Regular',
            color: colors.textEmphasisColor,
        },
    },
    SessionSummary: {
        paymentImages: paymentMethodImages,
        paymentMethodLabel: {
            color: colors.textEmphasisColor,
            fontSize: 20,
            fontWeight: FontWeight.light,
            fontFamily: 'Roboto-Light',
        },
    },
    SessionStatsRow: {
        statTitle: {
            color: colors.textLabelColor,
            fontSize: 12,
        },
        statValue: {
            color: colors.textEmphasisColor,
            fontSize: 36,
            lineHeight: 36,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Light',
        },
    },
    TimeStats: {
        labelStyle: {
            fontSize: 10,
            fontWeight: FontWeight.regular,
            fontFamily: 'RobotoCondensed-Regular',
            color: colors.textEmphasisColor,
        },
        textStyle: {
            fontSize: 12,
            color: colors.textEmphasisColor,
            fontWeight: FontWeight.light,
            fontFamily: 'RobotoCondensed-Regular',
        },
        pmTimeStyle: {
            fontWeight: FontWeight.bold,
        },
    },
    SessionHistory: {
        historyTitleStyle: {
            ...palette.light,
            fontSize: 20,
            color: colors.textEmphasisColor,
            marginTop: 20,
            marginBottom: 14,
        },
        statPercentStyle: {
            ...palette.emphasis,
            fontSize: 18
        },
        statValueStyle: {
            ...palette.emphasis,
            ...palette.light,
            fontSize: 36,
        },
        noteIcons: {
            public: icons.publicPlace,
            private: icons.privatePlace,
        },
        statIcons: {
            trendingUp: icons.trendingUp,
            trendingDown: icons.trendingDown
        },
    },
    Notices: {
        containerStyle: {
            marginBottom: 5,
        },
        textStyle: {
            fontSize: 14,
            color: colors.noticeTextColor,
        },
        inverseTextStyle: {
            fontSize: 14,
            backgroundColor: colors.textEmphasisColor,
        },
        boldTextStyle: {
            fontFamily: fonts.bold,
        },
        iconStyle: {
            marginLeft: 8,
            marginRight: 8,
            tintColor: colors.noticeTextColor,
        },
        inverseIconStyle: {
            tintColor: colors.backgroundColor
        },
        buttonIcon: {
            source: icons.warningWhite
        },
    },
    CompositeInput: {
        containerStyle: {
            marginTop: 28,
            paddingHorizontal: 0,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottomColor: colors.dividerColor,
            borderBottomWidth: StyleSheet.hairlineWidth,
        },
        textStyle: {
            ...palette.label,
        },
    },
    SessionChargingPeriods: {
        titleStyle: {
            fontFamily: fonts.regular,
            fontSize: 12,
        },
        textStyle: {
            fontFamily: fonts.light,
            fontSize: 12,
        },
        containerStyle: {
            paddingTop: 25,
            flex: 0,
        },
    },
    TermChange: {
        containerStyle: {
            flex: 1,
        },
        linkContainerStyle: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginLeft: 14,
            marginRight: 14,
            paddingBottom: 24,
            paddingTop: 24,
        },
        linkTextStyle: {
            ...palette.emphasis,
            ...palette.light,
            fontSize: 16,
            flex: 5,
            alignItems: 'flex-start',
            textDecorationLine: 'none',
        },
        linkIconSource: icons.disclosure,
    },
    EmailReceipt: {
        clearButtonTextStyle: {
            color: colors.textColor,
            fontSize: 14,
        },
    },
};
