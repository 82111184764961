import {v4 as uuidv4} from 'uuid';

const key = '@deviceUUID';

export function getDeviceId() {
    let id = localStorage.getItem(key);

    if (!id) {
        id = uuidv4();
        localStorage.setItem(key, id);
    }

    return id;
}

export function resetDeviceId() {
    localStorage.removeItem(key);

    return getDeviceId();
}
