export enum NumberSeparatorTypes {
    apostrophe = 'apostrophe',
    comma =  'comma',
    period = 'period',
    space = 'space',
}

export const NumberSeparators = {
    [NumberSeparatorTypes.apostrophe] : '\'',
    [NumberSeparatorTypes.comma] : ',',
    [NumberSeparatorTypes.period] : '.',
    [NumberSeparatorTypes.space] : ' ',
}

export enum DateFormatTypes {
    DATE_FORMAT_Y_M_D_DASH = 'Y-m-d',
    DATE_FORMAT_D_M_Y_SLASH = 'd/m/Y',
    DATE_FORMAT_D_M_Y_PERIOD = 'd.m.Y',
    DATE_FORMAT_M_D_Y_SLASH = 'm/d/Y',
    DATE_FORMAT_D_M_Y_DASH = 'd-m-Y',
    DATE_FORMAT_Y_M_D_PERIOD = 'Y.m.d',
    DATE_FORMAT_Y_D_M_PERIOD = 'Y.d.m',
    DATE_FORMAT_Y_M_D_SLASH = 'Y/m/d',
}

export const DateFormats = {
    [DateFormatTypes.DATE_FORMAT_Y_M_D_DASH] : 'yyyy-mm-dd',
    [DateFormatTypes.DATE_FORMAT_D_M_Y_SLASH] : 'dd/mm/yyyy',
    [DateFormatTypes.DATE_FORMAT_D_M_Y_PERIOD] : 'dd.mm.yyyy',
    [DateFormatTypes.DATE_FORMAT_M_D_Y_SLASH] : 'mm/dd/yyyy',
    [DateFormatTypes.DATE_FORMAT_D_M_Y_DASH] : 'dd-mm-yyyy',
    [DateFormatTypes.DATE_FORMAT_Y_M_D_PERIOD] : 'yyyy.mm.dd',
    [DateFormatTypes.DATE_FORMAT_Y_D_M_PERIOD] : 'yyyy.dd.mm',
    [DateFormatTypes.DATE_FORMAT_Y_M_D_SLASH] : 'yyyy/mm/dd',
}
export interface UserLocalizationSettings {
    decimals: number;
    decimal_separator: NumberSeparatorTypes;
    thousands_separator: NumberSeparatorTypes;
    default_time_format: TimeFormatTypes | null;
    date_format: DateFormatTypes | null;
    default_units: UnitFormat | null;
}

export enum TimeFormatTypes {
    TIME_FORMAT_AM_PM = 'AM-PM',
    TIME_FORMAT_24_hour = '24-hour',
}

export const TimeFormats = {
    [TimeFormatTypes.TIME_FORMAT_AM_PM] : 'hh:mm A',
    [TimeFormatTypes.TIME_FORMAT_24_hour] : 'HH:mm',
}

export enum UnitFormat {
    UNIT_FORMAT_METRIC = 'metric',
    UNIT_FORMAT_IMPERIAL = 'imperial',
}
