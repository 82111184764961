import React from 'react';
import {observer} from 'mobx-react';
import {Action, Error, FormStore, FormStoreProvider} from '@ampeco/forms';
import {ButtonIcon} from '@ampeco/icons';
import {icons} from '../../res/images';
import {__} from '@ampeco/i18n';
import {ProfileFormFields} from '@ampeco/profile-fields';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {Profile} from '@ampeco/charge-api';
import {UpdateProfile} from '@ampeco/charge-models';
import NavigationService from '@ampeco/navigation';
import {Button} from 'react-native-elements';
import {BasePage} from "../BasePage";

const store = new FormStore();

store.actions.save = async () => {
    GlobalStoreProxy.sharedInstance().profile = await Profile.patch(store.form as unknown as UpdateProfile);
    return NavigationService.sharedInstance().goBack();
}

const EditProfile = observer(() => {
    const profile = GlobalStoreProxy.sharedInstance().profile;

    return (
        <BasePage title={'profile.title'}>
            <FormStoreProvider store={store}>
                <ProfileFormFields
                    formStore={store}
                    profile={profile}
                    showEmail
                />

                <Error key="error"/>

                <Action
                    disableIfInvalid type={Button}
                    name="save"
                    key="save"
                    title={__('profile.button.submit')}
                    leftIcon
                    icon={<ButtonIcon source={icons.save} style={{width: 21, height: 21}}/>}
                    validateOnPress
                />
            </FormStoreProvider>
        </BasePage>
    )
});

export default EditProfile;
