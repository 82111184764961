import { action, computed, observable } from 'mobx';
import { __ } from '@ampeco/i18n';
import { AddPaymentMethodResponse, PaymentMethod } from '@ampeco/models';
// @ts-ignore
import BraintreeDropInMobile from 'react-native-braintree-dropin-ui';
import net from '@ampeco/net';
import * as Api from './api/payments';
import Navigation from '@ampeco/navigation';
import { Platform } from 'react-native';
import { confirmSetupIntent, initStripe } from '@stripe/stripe-react-native';
import DeviceInfo from 'react-native-device-info'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { bugsnag } from '@ampeco/logging';
import {InvoiceDetailsStore} from '@ampeco/invoices';
import AdditionalAddCardsWebViewEventsService from './services/AdditionalAddCardsWebViewEventsService';
import {SessionStartStore} from "@ampeco/charge-stores";
import GlobalStore from "../../frontend/stores/GlobalStore";

let instance: PaymentsStore | null = null;

export enum BillingStrategy {
    BILL_AFTER_SESSION = 'bill_after_session',
    AUTHORIZE_BEFORE_SESSION = 'authorize_before_session',
    MINIMUM_BALANCE = 'minimum_balance',
    NO_REQUIREMENT = 'no_requirement',
}

interface Card {
    name: string,
    expiryMonth: string,
    expiryYear: string,
    brand: string,
    last4: string,
}
export default class PaymentsStore {

    static PROCESSOR_BRAINTREE = 'Braintree';
    static PROCESSOR_STRIPE = 'Ampeco\\Modules\\Payments\\Managers\\StripeManager';
    static PROCESSOR_QORPAY = 'Ampeco\\Modules\\Payments\\Managers\\QorPayManager';
    static PROCESSOR_TRANSBANK = 'Ampeco\\Modules\\Payments\\Managers\\TransbankManager';
    static PROCESSOR_HYPERPAY = 'Ampeco\\Modules\\Payments\\Managers\\HyperPayManager';

    private dropInInstance: BraintreeDropInWeb.Dropin | null = null

    @observable payments: {
        clientToken: string,
        processor: string,
        methods: PaymentMethod[],
        defaultPaymentMethod: PaymentMethod,
    } | null = null;
    @observable shouldShowPaymentFailureNotice: boolean = false;
    @observable shouldShowBraintreeAddBtn: boolean = false;
    @observable lastPaymentFailure: string | null = null;
    @observable paymentFailureTitle: string | null = null;
    private canAddPaymentMethodCallback: (() => boolean) | null = null;
    private canUseServiceWithoutCardCallback: (() => boolean) | null = null;
    private canUseBalanceCallback: (() => boolean) | null = null;

    onAddPaymentMethodCloseHandler: ((success: boolean, shouldNavigateBack?: boolean) => Promise<void>) | null = null;
    paymentMethodId: string | undefined | null = null;

    @observable bankLogo: string | null | undefined;
    @observable bankLogoLink: string | null | undefined;

    @observable qorpayTokenData: string | null | undefined;

    @observable strategy: BillingStrategy = BillingStrategy.BILL_AFTER_SESSION;

    @observable allowPaymentMethod: boolean = true;

    @observable private _paymentMethodProcessor: Stripe | null | undefined = undefined;

    isWeb = Platform.OS === 'web';

    @computed get paymentMethodProcessor(): Stripe | null {
        return this._paymentMethodProcessor || null;
    }

    set paymentMethodProcessor(paymentMethodProcessor: Stripe | null) {
        this._paymentMethodProcessor = paymentMethodProcessor;
    }

    @observable private _lastPaymentMethod: PaymentMethod | null | undefined = undefined;

    @observable isPaymentTransactionScaSucceeded: boolean | null = null;

    @computed get lastPaymentMethod(): PaymentMethod | null {
        return this._lastPaymentMethod || null;
    }

    set lastPaymentMethod(lastPaymentMethod: PaymentMethod | null) {
        this._lastPaymentMethod = lastPaymentMethod;
    }

    /**
     * This returns the credit/debit card marked as default
     */
    @computed get defaultPaymentMethod(): PaymentMethod | null {
        if (this.payments && this.payments.methods) {
            return this.payments.methods.find(el => el.default) || null;
        } else {
            return null;
        }
    }
    @computed get hasDefaultPaymentMethod(): boolean {
        return Boolean(this.defaultPaymentMethod);
    }

    @computed get paymentsEnabled(): Boolean {
        return Boolean(this.payments && this.payments.processor && this.payments.clientToken);
    }

    @computed get hasAtLeastOnePaymentMethod(): boolean {
        return Boolean(this.payments && this.payments.methods.length > 0);
    }

    @computed get hasCorporate(): boolean {
        return Boolean (this.payments?.methods.some(el => el.type === 'corporate'));
    }

    static sharedInstance(): PaymentsStore {
        if (instance === null) {
            instance = new PaymentsStore();
        }
        return instance;
    }

    public setCanAddPaymentMethodCallback(callback: () => boolean) {
        this.canAddPaymentMethodCallback = callback;
    }

    public setCanUseServiceWithoutCardCallback(callback: () => boolean) {
        this.canUseServiceWithoutCardCallback = callback;
    }

    public setCanUseBalanceCallback(callback: () => boolean) {
        this.canUseBalanceCallback = callback;
    }

    public canAddPaymentMethod() {
        if (this.canAddPaymentMethodCallback) {
            return this.canAddPaymentMethodCallback();
        }

        return false;
    }

    private loadBillingRetries = 0;
    public async loadBillingSettings() {
        try {
            const res = await net.get('/app/billing_settings');
            this.loadBillingRetries = 0;
            this.strategy = res.data.billing_strategy;
            if (res.data.billing_strategy === BillingStrategy.MINIMUM_BALANCE) {
                this.allowPaymentMethod = !res.data.do_not_allow_payment_method;

                if (!this.allowPaymentMethod && this._lastPaymentMethod && this._lastPaymentMethod.type !== 'corporate') {
                    this.removeLastPaymentMethod();
                }
            } else {
                this.allowPaymentMethod = true;
            }
        } catch (e) {
            if (this.loadBillingRetries < 30) {
                setTimeout(() => this.loadBillingSettings(), this.loadBillingRetries++ * 1000);
            }
        }
    }

    @action.bound
    hideLastPaymentFailure() {
        this.shouldShowPaymentFailureNotice = false;
        this.lastPaymentFailure = null;
        this.paymentFailureTitle = null;
    }

    @action.bound
    showLastPaymentFailure(message: string, title: string | null = null) {
        this.shouldShowPaymentFailureNotice = true;
        this.lastPaymentFailure = message;
        this.paymentFailureTitle = title;
    }

    getPaymentMethodById(paymentMethodId: string): PaymentMethod | undefined {
        if (this.payments === null) {
            return undefined;
        }
        return this.payments.methods.find(method => method.id === paymentMethodId);
    }

    async finishAddingBraintreePaymentMethod() {
        if (!this.dropInInstance) {
            return Promise.reject();
        }

        const result = await this.dropInInstance.requestPaymentMethod();
        const paymentMethod = await Api.postPayment({ payment_method_nonce: result.nonce });

        this.payments && paymentMethod && this.payments.methods && this.payments.methods.push(paymentMethod);
        if (this.payments && paymentMethod && this.payments.methods && this.allowPaymentMethod) {
            this.selectPaymentMethod(paymentMethod);
        }
        this.shouldShowBraintreeAddBtn = false;

        this.dropInInstance.teardown();

        return paymentMethod;
    }

    @action.bound
    async addPaymentMethod(addResponse: AddPaymentMethodResponse): Promise<PaymentMethod | undefined> {
        if (!this.payments) {
            return;
        }
        try {
            let paymentMethod: PaymentMethod | null = null;
            let setupIntentResult: object | null = null;
            let error: object | null = null;

            switch (this.payments.processor) {
                case PaymentsStore.PROCESSOR_BRAINTREE:
                    try {
                        if (Platform.OS === 'web') {
                            const braintreeDropInWeb = await require('braintree-web-drop-in');
                            this.dropInInstance = await braintreeDropInWeb.create({
                                authorization: this.payments.clientToken,
                                container: '[data-testid=braintree-web-id]',
                            });
                            this.shouldShowBraintreeAddBtn = true;
                        } else {
                            const result = await BraintreeDropInMobile.show({
                                clientToken: this.payments && this.payments.clientToken,
                                googlePay: false,
                                applePay: false,
                                payPal: true,
                            });

                            paymentMethod = await Api.postPayment({ payment_method_nonce: result.nonce });
                        }
                    } catch (e) {
                        if (e.code !== 'USER_CANCELLATION') {
                            throw e;
                        }
                    }
                    break;
                case PaymentsStore.PROCESSOR_STRIPE:
                    this.hideLastPaymentFailure();
                    if (this.isWeb) {

                        const paymentMethodResponse = await Api.putPayment();
                        const sessionStore = SessionStartStore.sharedInstance().evse;
                        let  augmentUrl = '';
                        if(GlobalStore.sharedInstance().isAdHocUser && sessionStore?.identifier) {
                            augmentUrl = '?evseIdentifier=' + sessionStore?.identifier;
                        }

                        const result = await this.paymentMethodProcessor?.confirmSetupIntent(paymentMethodResponse.client_secret, {
                            payment_method: this.paymentMethodId,
                            return_url: window.location.protocol + "//" + window.location.host + '/payment-methods/3ds' + augmentUrl,
                        });

                        if (result.setupIntent.status == "requires_action") {
                            window.location.replace(result.setupIntent.next_action.redirect_to_url.url);
                            return;
                        } else if (result.setupIntent?.status == 'succeeded') {
                            setupIntentResult = {
                                'client_secret': result.setupIntent.client_secret,
                                'setup_intent_reference': result.setupIntent.id,
                            };
                        }
                    } else {
                        await confirmSetupIntent(!!addResponse && addResponse.client_secret, {
                            paymentMethodType: 'Card',
                        });
                    }

                    const postPaymentParams = setupIntentResult ?? addResponse;

                    paymentMethod = await Api.postPayment(postPaymentParams);
                    break;
                case PaymentsStore.PROCESSOR_QORPAY:
                    this.hideLastPaymentFailure();
                    const paymentStore = PaymentsStore.sharedInstance();

                    try {
                        const tokenData = JSON.parse(paymentStore.qorpayTokenData);

                        paymentMethod = await Api.postPayment({
                            request_id: tokenData.request_id,
                            client_id: tokenData.profile_id,
                            brand: tokenData.brand,
                        });
                    }  catch (e) {
                        bugsnag.notify('QorPay', 'Add payment method failed', {
                            message: e.message,
                        });

                        throw new Error(__('addPaymentMethod.error'));
                    }
                    break;
                default:
                    paymentMethod = await Api.postPayment(addResponse);
                    break;
            }

            this.payments && paymentMethod && this.payments.methods && this.payments.methods.push(paymentMethod);
            if (this.payments && paymentMethod && this.payments.methods && this.allowPaymentMethod) {
                this.selectPaymentMethod(paymentMethod);
            }

            return paymentMethod || undefined;
        } catch (e) {
            if (e.response && e.response.status === 404) {
                // Reload the payments list
                this.payments.methods = await Api.getPayment();
            } else if (e.response && e.response.status === 422) {
                this.showLastPaymentFailure(e.response.data.message);
            } else if (e.message) {
                this.showLastPaymentFailure(e.message, __('addPaymentMethod.adding-failed'));
            } else {
                this.showLastPaymentFailure(__('addPaymentMethod.error'));
            }
        }

    }

    nativePaymentProcessors = [PaymentsStore.PROCESSOR_STRIPE];

    @action.bound
    async newPaymentMethod(
        navigate: (screen: string, params: any) => void,
        beforeNavigateCallback?: () => void,
        options?: {},
        paymentMethod?: Card,
        intendedNavigation?: {
            screen: string;
            evseId?: string;
            sessionId?: string;
        },
    ): Promise<PaymentMethod | undefined> {
        try {
            if (!InvoiceDetailsStore.canAddPaymentMethod) {
                navigate('InvoiceDetailsEdit', {message: __('invoices.message')});
                return undefined;
            }
            if (this.nativePaymentProcessors.includes(this.payments && this.payments.processor || '')) {
                return new Promise((resolve) => {
                    if (this.isWeb) {
                        this.onAddPaymentMethodCloseHandler = async (success: boolean, shouldNavigateBack: boolean = true) => {
                            try {
                                if (success) {
                                    const paymentMethodResponse = await Api.putPayment(options);
                                    const paymentMethod = await this.addPaymentMethod(paymentMethodResponse);
                                    resolve(paymentMethod);
                                } else {
                                    resolve(undefined);
                                }
                                if (shouldNavigateBack) {
                                    if (intendedNavigation) {
                                        const params: any = {
                                            ...intendedNavigation,
                                        };
                                        delete params.screen;
                                        Navigation.sharedInstance().navigate(
                                            intendedNavigation.screen,
                                            params
                                        );
                                    } else {
                                        Navigation.sharedInstance().goBack();
                                    }
                                }
                            } catch (e) {
                                this.resolveError(e)
                                throw e
                            }
                            this.onAddPaymentMethodCloseHandler = null;
                        };
                    }

                    navigate('AddPaymentMethodNative', {
                        onClose: async (
                            shouldNavigateBack: boolean = true,
                        ) => {
                            try {
                                const paymentMethodResponse = await Api.putPayment(options);
                                const paymentMethod = await this.addPaymentMethod(paymentMethodResponse);

                                resolve(paymentMethod);

                                if (shouldNavigateBack) {
                                    Navigation.sharedInstance().goBack()
                                }
                            } catch (e) {
                                this.resolveError(e)
                                throw e
                            }
                        },
                    });
                });
            }
            const paymentMethodResponse = await Api.putPayment(options);
            if (paymentMethodResponse.redirectUrl) {
                if(this.isWeb) {
                    return new Promise((resolve) => {
                        this.onAddPaymentMethodCloseHandler = async (success: boolean, shouldNavigateBack: boolean = true) => {
                            try {
                                if (success) {
                                    this.addPaymentMethod(paymentMethodResponse).then(async (paymentMethod) => {
                                        resolve(paymentMethod);
                                    });
                                } else {
                                    resolve(undefined);
                                }
                                if (shouldNavigateBack) {
                                    if (intendedNavigation) {
                                        const params: any = {
                                            ...intendedNavigation,
                                        };
                                        delete params.screen;
                                        Navigation.sharedInstance().navigate(
                                            intendedNavigation.screen,
                                            params,
                                        );
                                    } else {
                                        Navigation.sharedInstance().goBack();
                                    }
                                }
                            } catch (e) {
                                this.resolveError(e)
                                throw e
                            }
                            this.onAddPaymentMethodCloseHandler = null;
                        };
                        beforeNavigateCallback && beforeNavigateCallback();
                        navigate('AddPaymentMethod', {
                            url: paymentMethodResponse.redirectUrl,
                            successUrl: paymentMethodResponse.successUrl,
                            waitForSuccessUrl: paymentMethodResponse.waitForSuccessUrl || false,
                            sourceType: (this.payments?.processor === PaymentsStore.PROCESSOR_TRANSBANK) ? 'html' : 'uri',
                        });
                    });
                } else {
                    return new Promise((resolve) => {
                        beforeNavigateCallback && beforeNavigateCallback();
                        navigate('AddPaymentMethod', {
                            url: paymentMethodResponse.redirectUrl,
                            successUrl: paymentMethodResponse.successUrl,
                            waitForSuccessUrl: paymentMethodResponse.waitForSuccessUrl || false,
                            sourceType: (
                              this.payments?.processor === PaymentsStore.PROCESSOR_TRANSBANK
                              || this.payments?.processor === PaymentsStore.PROCESSOR_HYPERPAY
                            ) ? 'html' : 'uri',
                            onClose: async (success: boolean, shouldNavigateBack: boolean = true) => {
                                try {
                                    if (success) {
                                        this.addPaymentMethod(paymentMethodResponse).then(async (paymentMethod) => {
                                            resolve(paymentMethod)
                                        });
                                    } else {
                                        resolve(undefined);
                                    }
                                    if (shouldNavigateBack) {
                                        Navigation.sharedInstance().goBack()
                                    }
                                } catch (e) {
                                    this.resolveError(e)
                                    throw e
                                }
                            },
                            injectedJavaScript: this.payments ? AdditionalAddCardsWebViewEventsService.injectedJavaScript(this.payments.processor) : null,
                            onMessage: this.payments ? AdditionalAddCardsWebViewEventsService.onMessage(this.payments.processor) : null,
                        });
                    });
                }

            } else {
                return this.addPaymentMethod(paymentMethodResponse);
            }
        } catch (e) {
            this.resolveError(e)
        }
    }

    @action.bound
    async removePaymentMethod(paymentMethod: PaymentMethod) {
        try {
            const paymentMethods = await Api.deletePayment(paymentMethod);

            if (this._lastPaymentMethod && this._lastPaymentMethod.id === paymentMethod.id) {
                this._lastPaymentMethod = paymentMethods[0] || null;
            }

            this.hideLastPaymentFailure();

            if (this.payments && this.payments.methods) {
                this.payments.methods = paymentMethods;
            }
        } catch (e) {
            let errorMessage = __('deletePaymentMethod.deleting-failed');

            if (e.response && e.response.data && e.response.data.message) {
                errorMessage = e.response.data.message;
            }

            this.showLastPaymentFailure(errorMessage, __('deletePaymentMethod.deleting-error'));
        }
    }

    @action.bound
    async setDefaultPaymentMethod(paymentMethod: PaymentMethod) {
        await Api.setDefault(paymentMethod);

        if (this.payments && this.payments.methods) {
            const index = this.payments.methods.findIndex(val => val.id === paymentMethod.id);
            if (index !== -1) {
                this.payments.methods[index].default = true;
            }
            this.payments.methods.forEach((el, id) => {
                if (id !== index) {
                    el.default = false;
                }
            });
        }
    }

    public canRemovePaymentMethodCallback = (paymentMethod: PaymentMethod) => {
        return true;
    }

    @action.bound
    selectPaymentMethod(method: PaymentMethod | null) {
        this._lastPaymentMethod = method;
    }

    @action.bound removeLastPaymentMethod() {
        this._lastPaymentMethod = null;
    }

    resolveError(e: any) {
        if (e.response && e.response.status === 422) {
            this.showLastPaymentFailure(e.response.data.message);
        } else if (e.response && e.response.status === 409) {

            this.showLastPaymentFailure(
                __('addPaymentMethod.limit-reached-error'),
                __('addPaymentMethod.limit-reached-title'),
            );
        } else {
            this.showLastPaymentFailure(__('addPaymentMethod.error'));
        }
    }

    describe(paymentMethod?: PaymentMethod): string {
        if (!paymentMethod) {
            return __('payment_methods.last_used');
        }
        if (paymentMethod.type === 'paypal') {
            return __('payment_methods.paypal_account').replace('{number}', paymentMethod.name);
        }
        return __('payment_methods.credit_card_number').replace('{number}', paymentMethod.name);
    }

    canUseServiceWithoutCard() {
        const strategy = this.strategy;
        if (this.canUseServiceWithoutCardCallback) {
            return (strategy === BillingStrategy.MINIMUM_BALANCE || strategy === BillingStrategy.NO_REQUIREMENT) && this.canUseServiceWithoutCardCallback();
        }

        return strategy === BillingStrategy.MINIMUM_BALANCE || strategy === BillingStrategy.NO_REQUIREMENT;
    }

    canUseBalance() {
        if (this.canUseBalanceCallback) {
            return this.canUseBalanceCallback();
        }
        return false;
    }

    shouldShowPaymentMethods(): boolean {
        return Boolean((this.canUseServiceWithoutCard() && this.canUseBalance()) || ((this.hasCorporate || this.paymentsEnabled) && this.hasAtLeastOnePaymentMethod));
    }

    @action.bound
    setPayments(payments: {
        clientToken: string;
        processor: string;
        methods: PaymentMethod[];
    } | null) {
        this.payments = payments;

        if (this._lastPaymentMethod === undefined && this.payments !== null && this.payments.methods !== null && this.payments.methods.length > 0) {
            this._lastPaymentMethod = this.payments.methods[0];
        }
    }

    @action.bound
    async initPaymentProcessor(payments: {
        processor: string;
        publishableKey?: string;
    } | null) {
        if (!payments) {
            return;
        }
        if (payments.processor === PaymentsStore.PROCESSOR_STRIPE && payments?.publishableKey) {
            if (this.isWeb) {
                this.paymentMethodProcessor = await loadStripe(payments.publishableKey);
            } else {
                await initStripe({
                    publishableKey: payments.publishableKey,
                    urlScheme: DeviceInfo.getBundleId() + '.stripe',
                })
            }
        }
    }

    @action.bound
    unsetPayments() {
        this.lastPaymentMethod = null;
        this.lastPaymentFailure = null;
        this.payments = null;
    }

    @action.bound
    setBankLogoData(bankLogo?: string | null, bankLogoLink?: string | null) {
        this.bankLogo = bankLogo;
        this.bankLogoLink = bankLogoLink;
    }

    @action.bound
    setQorPayTokenData(tokenData: string | null) {
        this.qorpayTokenData = tokenData;
    }

    @action.bound
    setPaymentTransactionScaSucceeded(succeeded: boolean|null) {
        this.isPaymentTransactionScaSucceeded = succeeded;
    }
}
