import React from 'react';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {__} from "@ampeco/i18n";
import './search.scss';
import {Image} from "react-native";
import {icons} from "../../res/images";

type Props = {
    map: any;
}

const GooglePlacesInput = (props: Props) => {

    const defaultZoom = 16;
    let markers: google.maps.Marker[] = [];
    return (
        <div className="searchContainer">
            <GooglePlacesAutocomplete
                selectProps={{
                    placeholder: __('nav.places-search.search-placeholder'),
                    onChange: (result: any) => {
                        geocodeByAddress(result.label)
                            .then(results => {
                                return getLatLng(results[0])
                            })
                            .then(({lat, lng}) =>{
                                props.map.setCenter({lat, lng});
                                props.map.setZoom(defaultZoom);

                                deleteMarkers();
                                const marker = new google.maps.Marker({
                                    position: new google.maps.LatLng(lat, lng),
                                    map: props.map,
                                    animation: google.maps.Animation.DROP,
                                });
                                markers.push(marker);
                                props.map.setCenter(new google.maps.LatLng(lat, lng));

                            });

                    },
                    styles: {
                        control: (base: any) => ({
                            ...base,
                            border: 0,
                            // This line disable the blue border
                            boxShadow: 'none'
                        })
                    },
                    openMenuOnClick: false,
                    noOptionsMessage: function noOptionsMessage() {
                        return null;
                    },
                }}></GooglePlacesAutocomplete>
            <div className="searchIconContainer">
                <Image source={icons.search} style={{width: 16, height: 16}} resizeMode={'contain'}/>
            </div>
        </div>
    );

    function clearMarkers() {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
    }

    function deleteMarkers() {
        clearMarkers();
        markers = [];
    }

};

export default GooglePlacesInput;
