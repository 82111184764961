import './polyfills/functions';
import React from 'react';
import {render} from 'react-dom';
import {ThemeProvider} from 'react-native-elements';
import {Router} from "react-router";
import {theme} from './Theme';
import history from './services/LocalHistory';
import init from "./init";
import Analytics from './Analytics';
import GlobalStore from "./stores/GlobalStore";
import AppComponent from './App';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

import './styles/default.scss';

function App() {
    return (
        <ThemeProvider theme={theme} >
            <Router history={history}>
                <Analytics>
                    <div className="wrapper app" id="outer-container">
                        <AppComponent/>
                        <ToastContainer/>
                    </div>
                </Analytics>
            </Router>
        </ThemeProvider >
    );
}

init();
GlobalStore.sharedInstance().bootstrap();

render(<App />, document.querySelector('#app-root'));
