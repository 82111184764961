import {action, computed, observable} from 'mobx';
import {FeesFilter, SubscriptionSessionHistory} from '@ampeco/charge-models';
import {SubscriptionPlans as API} from '@ampeco/charge-api';

export default class SubscriptionSessionHistoryStore {
    private static instance: SubscriptionSessionHistoryStore;

    @observable records: SubscriptionSessionHistory[] = [];
    @observable filter: FeesFilter | null = null;
    @observable isLoading: boolean = false;

    static sharedInstance(): SubscriptionSessionHistoryStore {
        if (SubscriptionSessionHistoryStore.instance === undefined) {
            SubscriptionSessionHistoryStore.instance = new SubscriptionSessionHistoryStore();
        }
        return SubscriptionSessionHistoryStore.instance;
    }

    @computed get list() {
        const list = this.records.map(record => ({...record, key: record.sessionId}));

        if (this.filter === null) {
            return list;
        }

        return list.filter(sessionHistoryItem => sessionHistoryItem.isPaid === (this.filter === FeesFilter.PAID));
    }

    @action.bound
    setFilter(filter: FeesFilter | null) {
        this.filter = filter;
    }

    async load() {
        try {
            this.isLoading = true;
            this.records = await API.sessionHistory();
        } catch (e) {
            this.records = [];
        } finally {
            this.isLoading = false;
        }
    }
}
