import React from 'react';
import {StyleSheet, View, ViewStyle, TouchableOpacity, PixelRatio} from 'react-native';
import AmpecoTheme from '@ampeco/theme';
import {ThemeConsumer} from 'react-native-elements';

interface Props {
    onPress?: () => void;
    style?: Partial<ViewStyle>;
}

export default class Notice extends React.Component<Props> {

    renderContent(theme: AmpecoTheme) {
        return <View style={[theme.Notice && theme.Notice.containerStyle, styles.container, this.props.style]}>
            {this.props.children}
        </View>;
    }

    render() {
        return <ThemeConsumer<AmpecoTheme>>{({theme}) => {
            if (!this.props.onPress) {
                return this.renderContent(theme);
            }
            return <TouchableOpacity activeOpacity={0.6} onPress={this.props.onPress}>
                {this.renderContent(theme)}
            </TouchableOpacity>;
        }}
        </ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 0,
        minHeight: 35,
        borderRadius: 35 / 2 * PixelRatio.getFontScale(),
        flexDirection: 'row',
        alignItems: 'center',
    },
});
