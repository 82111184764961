function round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function formatEnergy(energy: string, precision?: number): string {
    let value = (Math.round(parseInt(energy, 10) / 1000 * 100) / 100);

    if (precision) {
        value = round(value, 1);
    }

    return value + ' kWh';
}

export function formatPower(power: string, precision?: number): string {
    let value = (parseInt(power, 10) / 1000);
    if (precision) {
        value = round(value, 1);
    }
    return value + ' kW';
}
