import {Session} from '@ampeco/charge-models';
import {action} from 'mobx';
import {ReservationStore} from '@ampeco/charge-stores';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';

export default class SessionMock {

    private static instance?: SessionMock;

    static sharedInstance(): SessionMock {
        if (SessionMock.instance === undefined) {
            SessionMock.instance = new SessionMock();
        }
        return SessionMock.instance;
    }

    incrementInterval: number | null = null;

    private chargeAmount(activeSession: Session) {
        return Math.round((activeSession.energy / 1000) * 0.45 * 100) / 100;
    }

    start(evseId: string, paymentMethod: any): Promise<Session> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const session: Session = {
                    id: '1',
                    startedAt: new Date(),
                    status: 'starting',
                    power: 7100,
                    energy: 0,
                    locationId: '1',
                    evseId,
                    paymentMethodId: paymentMethod ? parseInt(paymentMethod.id, 10) : undefined,
                    totalDuration: 0,
                    totalAmount: 0.00,
                    carBatteryPercent: 23,
                    currency: {
                        name: 'Euro',
                        symbol: '€',
                        formatter: '€%.02f',
                        code: 'EUR',
                    },
                };

                ReservationStore.sharedInstance().updateReservation(null); // clear the mock reservation
                this.incrementInterval = setInterval(this.increment.bind(this), 1000);
                resolve(session);
            }, 1000);
        });
    }

    stop(evseId: string): Promise<Session> {

        return new Promise((resolve, reject) => {

            setTimeout(() => {
                const session = GlobalStoreProxy.sharedInstance().activeSession;
                if (session === null) {
                    reject();
                    return;
                }
                if (this.incrementInterval) {
                    clearInterval(this.incrementInterval);
                }
                this.incrementInterval = null;
                GlobalStoreProxy.sharedInstance().setActiveSession(null);
                resolve(session);
            }, 1000);
        });
    }

    @action
    increment() {
        const activeSession = GlobalStoreProxy.sharedInstance().activeSession;
        if (activeSession === null) {
            return;
        }

        activeSession.totalDuration += 1;

        if (activeSession.carBatteryPercent !== undefined && activeSession.carBatteryPercent < 100) {
            if (activeSession.carBatteryPercent + 2 > 100) {
                activeSession.carBatteryPercent = 100;
            } else {
                activeSession.carBatteryPercent += 2;
            }
        }

        // if (activeSession.carBatteryPercent === undefined || activeSession.carBatteryPercent < 100) {
        activeSession.totalDuration += 1;
        activeSession.energy = '' + (parseInt(activeSession.energy, 10) + Math.round(Math.random() * 50 + 5));
        activeSession.totalAmount = this.chargeAmount(activeSession);
        // } else {
        //     if (activeSession.totalDuration > activeSession.duration + 10) {
        //         activeSession.parkingDuration += 1;
        //         activeSession.parkingAmount = this.parkingAmount(activeSession);
        //     }
        // }

        // activeSession.totalAmount = activeSession.amount + activeSession.parkingAmount;
    }
}
