import React from 'react';
import {ChargingPeriod, TimeFormatTypes} from '@ampeco/charge-models';
import {View, StyleSheet} from 'react-native';
import {Divider, Text, ThemeConsumer} from 'react-native-elements';
import moment from 'moment';
import {humanizeDuration} from '@ampeco/humanize-duration';
import {format, default as Currency} from '@ampeco/currency';
import {__, LocaleStore} from '@ampeco/i18n';
import {PriceFieldsFormatterStore} from '@ampeco/charge-stores';

const getState = (currentState: string) => {
    if (currentState.includes('charging')) {
        return __('session.summary.state-periods.charging')
    }

    if (currentState.includes('idle')) {
        return __('session.summary.state-periods.idle')
    }

    if (currentState === 'waiting_for_start') {
        return __('session.summary.state-periods.waiting-for-start')
    }
    if (currentState === 'waiting_for_lock') {
        return __('session.summary.state-periods.waiting-for-lock')
    }

    return __('session.summary.state-periods.unknown')
};

const SessionChargingPeriods = (props: {chargingPeriods: ChargingPeriod[], currency: Currency}) => {
    const priceFieldsFormatterStore = PriceFieldsFormatterStore.sharedInstance();

    if (!props.chargingPeriods) {
        return null;
    }

    return <ThemeConsumer>{({theme}) => { return <View style={theme.SessionChargingPeriods.containerStyle}>
        <Text>{__('session.summary.state-periods.title')}</Text>
        <View style={{flexDirection: 'row', marginTop: 25, flex: 0, justifyContent: 'space-between'}}>
            <View style={{flexDirection: 'column'}}>
                <Text style={StyleSheet.flatten([theme.SessionChargingPeriods.titleStyle, styles.title, {paddingLeft: 0}])}>{__('session.summary.state-periods.start')}</Text>
                <Divider/>
                {props.chargingPeriods.map((period: ChargingPeriod, i: number) => {
                    return <Text key={i} style={StyleSheet.flatten([theme.SessionChargingPeriods.textStyle, styles.text, {paddingLeft: 0}])}>{moment(period.startedAt).format(LocaleStore.sharedInstance().displayTimeFormat as TimeFormatTypes)}</Text>;
                })}
            </View>
            <View style={{flexDirection: 'column'}}>
                <Text style={StyleSheet.flatten([theme.SessionChargingPeriods.titleStyle, styles.title])}>{__('session.summary.state-periods.state')}</Text>
                <Divider/>
                {props.chargingPeriods.map((period: ChargingPeriod, i: number) => {
                    return <Text key={i} style={StyleSheet.flatten([theme.SessionChargingPeriods.textStyle, styles.text])}>{getState(period.state)}</Text>;
                })}
            </View>
            <View style={{flexDirection: 'column'}}>
                <Text style={StyleSheet.flatten([theme.SessionChargingPeriods.titleStyle, styles.title])}>{__('session.summary.state-periods.duration')}</Text>
                <Divider/>
                {props.chargingPeriods.map((period: ChargingPeriod, i: number) => {
                    return <Text key={i} style={StyleSheet.flatten([theme.SessionChargingPeriods.textStyle, styles.text])}>{humanizeDuration(parseInt(period.durationInSeconds, 10), true)}</Text>;
                })}
            </View>
            <View style={{flexGrow: 1}}>
                <Text style={StyleSheet.flatten([theme.SessionChargingPeriods.titleStyle, styles.title, {padding: 0}])}> </Text>
                <Divider/>
                <Text style={StyleSheet.flatten([theme.SessionChargingPeriods.textStyle, styles.text, {padding: 0}])}> </Text>
            </View>
            <View style={{flexDirection: 'column'}}>
                <Text style={StyleSheet.flatten([theme.SessionChargingPeriods.titleStyle, styles.title, {paddingRight: 0, textAlign: 'right'}])}>{__('session.summary.state-periods.amount')}</Text>
                <Divider/>
                {props.chargingPeriods.map((period: ChargingPeriod, i: number) => {
                    return <Text key={i} style={StyleSheet.flatten([theme.SessionChargingPeriods.textStyle, styles.text, {paddingRight: 0, textAlign: 'right'}])}>
                        {format(period.amount, props.currency) + (period.freeEnergyWh > 0 ? '*' : '')}
                    </Text>;
                })}
            </View>
        </View>
    </View>;
    }}</ThemeConsumer>;
};

export default SessionChargingPeriods;

const styles = StyleSheet.create({
    text: {paddingHorizontal: 4, paddingBottom: 20},
    title: {paddingHorizontal: 4, paddingVertical: 8},
    divider: {marginBottom: 20},
});
