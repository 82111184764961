import {action, computed, observable} from 'mobx';
import {BalanceHistory} from '../BalanceHistory';
import {bugsnag} from '@ampeco/logging';
import {getBalanceHistory} from '../api';

let historyStoreInstance: BalanceHistoryStore;

export default class BalanceHistoryStore {
    @observable records: BalanceHistory[] = [];
    @observable isLoading = false;

    page= 1;
    lastPage = 2;
    perPage = 10;

    @computed get list() {
        if (this.records === undefined) {
            return [];
        }
        let key = 1;
        return this.records.map(record => ({...record, key: (key++) + ''}));
    }

    static sharedInstance() {
        if (historyStoreInstance === undefined) {
            historyStoreInstance = new BalanceHistoryStore();
        }
        return historyStoreInstance;
    }

    @action.bound
    async loadMoreRecords() {
        if (!this.hasMorePages()) {
            return Promise.resolve();
        }

        try {
            this.isLoading = true;
            const balanceHistoryResponse = await getBalanceHistory(this.page, this.perPage);
            this.records = this.records.concat(balanceHistoryResponse.data);
            this.page++;
            this.lastPage = balanceHistoryResponse.lastPage;
        } catch (error: any) {
            this.page--;
            if (!['ECONNABORTED', 401].includes(error?.code) || error instanceof Error) {
                bugsnag.notify(error, 'Failed loading balance history');
            }
        } finally {
            this.isLoading = false;
        }
    }

    @action.bound
    resetPagination() {
        this.records = [];
        this.page = 1;
    }

    hasMorePages() {
        return this.page - 1 < this.lastPage;
    }
}
