import React from 'react';
import {StyleSheet, View, Image, Text, TouchableOpacity, GestureResponderEvent} from 'react-native';
import {ThemeConsumer} from 'react-native-elements';
import {ChargeTheme} from '@ampeco/theme';
import {RTLImage} from '@ampeco/rtl-image';

export default function (props: {text: string, icon?: number, onPress?: (event: GestureResponderEvent) => void}) {
    return (<ThemeConsumer>{({theme}) => {
        if (props.onPress) {
            return <TouchableOpacity activeOpacity={0.6} onPress={props.onPress}><View style={styles.container}>
                {props.icon ? <View style={styles.image}><Image source={props.icon} /></View> : null}
                <Text style={[theme.Disclosure.textStyle, styles.text]}>{props.text}</Text>
                <View style={styles.disclosure}>
                    <RTLImage  style={{tintColor: theme.Disclosure.tintColor}} source={theme.Disclosure.iconSource} />
                </View>
            </View></TouchableOpacity>
        } else {
            return <View style={styles.container}>
                {props.icon ? <View style={styles.image}><Image source={props.icon} /></View> : null}
                <Text style={[theme.Disclosure.textStyle, styles.text]}>{props.text}</Text>
            </View>
        }
    }}</ThemeConsumer>);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginLeft: 14,
        marginRight: 14,
        paddingBottom: 14,
        paddingTop: 14,
    },
    image: {
        flex: 1,

    },
    text: {
        fontSize: 16,
        flex: 5,
        alignItems: 'flex-start',
        textAlign: 'left'
    },
    disclosure: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: 10,
        marginStart: 14,
    },
});
