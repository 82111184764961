import React from 'react';
import CustomHeader from './header/CustomHeader';
import Menu from './components/Menu/Menu';
import Map from './pages/Map/Map';
import {Route, Switch} from 'react-router-dom';
import {getDisplayRoutes} from './Routes';
import WebsocketMonitor from './services/WebsocketMonitor';
import settings from './settings';
import {Notices} from '@ampeco/charge-components';

const AppComponent: React.FC = () => {
    const renderMapDrivenApp = () => {
        WebsocketMonitor.init(() => settings.broadcast);

        return (<>
            <CustomHeader/>
            <Menu/>
            <div id="page-wrap" className="content">
                <div className="mapComponent">
                    <Map/>
                </div>
            </div>
            {/* Notices */}
            <Notices hasUserPosition={false}
                     filterCallback={(notice) => {
                         return notice.key === 'PaymentFailedNotice';
                     }}
                     overrideContainerStyles={{zIndex: 1111, width: 'fit-content', margin: 'auto'}}
            />
        </>);
    }

    return (
        <>
            <Switch>
                {getDisplayRoutes().map((route) => {
                    return <Route key={route.name} {...route}/>;
                })}

                <Route
                    path="/" render={renderMapDrivenApp}
                />
            </Switch>
        </>
    )
}

export default AppComponent;
