import React, {ReactNode} from 'react';
import {StyleSheet, View, TextStyle} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import {Interface} from 'readline';

interface Props {
    label: string;
    children: ReactNode;
    styleProps?: TextStyle;
}
const WithLabel: React.FC<Props> = ({label, children, styleProps}) => {
    return <ThemeConsumer<AmpecoTheme>>{
        ({theme}) => (<View>
            <Text style={StyleSheet.flatten([theme.Forms.labelStyle, styles.label, styleProps])}>{label}</Text>
            {children}
        </View>)}</ThemeConsumer>;
};

const styles = StyleSheet.create({
    label: {fontSize: 10, marginBottom: 4},
});

export default WithLabel;
