import React, {Component} from 'react';
import {StyleSheet, View} from 'react-native';
import SelectedConnectors from './SelectedConnectors';
import {Location} from '@ampeco/charge-models';
import Connector from './Connector';

interface Props {
    location?: Location | null;
    limit: number;
}

export class ConnectorTypesList extends Component<Props> {
    render() {
        const props = this.props;
        const selector = new SelectedConnectors();
        const selected = props.location ? selector.selected(props.location, props.limit) : [];

        return <View style={[styles.container, {flexGrow: selected.length > 3 ? 2 : 1}]}>{selected.map((connector, connectorid) => {
            return <Connector
                key={connector.evse.identifier + '-' + connectorid}
                connector={connector.connector}
                evse={connector.evse}
            />;

        })}</View>;
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
});
