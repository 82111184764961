import AdapterInterface from './AdapterInterface';
import {ReservationStore} from '@ampeco/charge-stores';
import {Reservation} from '@ampeco/charge-models';
import {Reservation as ReservationAPI} from '@ampeco/charge-api';

export default class LiveAdapter implements AdapterInterface {
    private store: ReservationStore;
    constructor() {
        this.store = ReservationStore.sharedInstance();
    }
    async reserve(evseId: string) {
        const reservation = await ReservationAPI.reserve(evseId);
        this.store.updateReservation(reservation);
        return reservation;
    }

    async cancel(reservationId: string) {
        await ReservationAPI.cancel(reservationId);
        this.store.updateReservation(null);
        return {};
    }

    async active(): Promise<Reservation | null> {
        return ReservationAPI.info().then((reservation: any) => {
            this.store.updateReservation(reservation);
            return reservation;
        }).catch(() => {  return null; });
    }
    async restriction() {
        const restriction = await ReservationAPI.restriction();
        this.store.updateReservationRestriction(restriction);
    }
}
