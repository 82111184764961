import React from 'react';
import {Header} from 'react-native-elements';
import {LocaleStore} from "../../frontend_modules/i18n/index";
import {observer} from 'mobx-react';
import Logo from './Logo';
import GlobalStore from "../stores/GlobalStore";
import colors from "../res/colors";
import SelectLanguage from "./SelectLanguage";

export default observer(() => {
    LocaleStore.observeLanguage();

    return <Header
        statusBarProps={{barStyle: 'light-content'}}
        barStyle="light-content" // or directly
        centerComponent={<Logo/>}
        rightComponent={
            <SelectLanguage
                shouldPersist={GlobalStore.sharedInstance().isLoggedIn}
                callback={() => GlobalStore.sharedInstance().reloadTranslatableResources()}
            />
        }
        containerStyle={{
            backgroundColor: colors.headerBackgroundColor,
            justifyContent: 'space-around',
            borderBottomColor: colors.headerBackgroundColor,
            borderWidth: 0,
            zIndex: 2,
        }}
    />;
});
