import {__} from '@ampeco/i18n';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import TextHeader from './TextHeader';
import DetailsLine from './DetailsLine';
import {observer} from 'mobx-react';
import {ProfileDetailsFields} from '@ampeco/profile-fields';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';

export default observer(function Details() {
    const globalStore = GlobalStoreProxy.sharedInstance();

    if (!globalStore.profile) {
        return <></>;
    }
    return <View style={styles.container}>
        <TextHeader text={__('profile.details')}/>

        <View style={styles.details}>
            <ProfileDetailsFields
                type={DetailsLine}
                profile={globalStore.profile}
            />
        </View>

    </View>;
});

const styles = StyleSheet.create({
    details: {
        marginTop: 10,
    },
    container: {
        marginTop: 20,
    },
});
