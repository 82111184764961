import net from '@ampeco/net';
import {AddPaymentMethodResponse, PaymentMethod} from '@ampeco/models';

export async function postPayment(options: AddPaymentMethodResponse): Promise<PaymentMethod | null> {
    return (await net.post('/app/profile/payment_methods', options)) as PaymentMethod | null;
}

export async function getPayment(): Promise<PaymentMethod[]> {
    return (await net.get('/app/profile/payment_methods')).data as PaymentMethod[];
}

export async function putPayment(options: {} = {}): Promise<AddPaymentMethodResponse> {
    return (await net.put('/app/profile/payment_methods', options)) as AddPaymentMethodResponse;
}

export async function deletePayment(paymentMethod: PaymentMethod): Promise<PaymentMethod[]> {
    return (await net.delete('/app/profile/payment_methods/' + paymentMethod.id)).data as PaymentMethod[];
}

export async function setDefault(paymentMethod: PaymentMethod): Promise<PaymentMethod> {
    return (await net.patch('/app/profile/payment_methods/' + paymentMethod.id, {is_default: true})) as PaymentMethod;
}
