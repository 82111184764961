import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {BasePage} from '../BasePage';
import NavigationService from '@ampeco/navigation';
import {History} from '@ampeco/charge-screens';

function HistoryScreen(): JSX.Element {
    const navigationService = NavigationService.sharedInstance();
    const navigation: any = {
        setOptions: () => {/**/},
        navigate: navigationService.navigate.bind(navigationService),
    };

    return <BasePage
        title={'sessions-history.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home')
        }}
    >
        <SafeAreaProvider>
            <History
                navigation={navigation}
                route={{
                    params: {
                        isPersonalChargingEnabled: () => false,
                    },
                }}
            />
        </SafeAreaProvider>
    </BasePage>;
}

export default HistoryScreen;
