import Currency from '@ampeco/currency';
import {Tariff, Location, Pin, PricePeriod, ToUPricePeriods} from '@ampeco/charge-models';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import net from '@ampeco/net';

interface LocationsResourceResponse {
    locations: Location[];
    currencies: Currency[];
    tariffs: Tariff[];
    evseIdentifier?: string;
}

interface LocationsResource {
    tariffs: Tariff[];
    currencies: Currency[];
    location?: Location;
}

interface LocationsResourceWithIdentifier extends LocationsResource {
    evseIdentifier: string | undefined;
}

const formatSingleResponse = (response: LocationsResourceResponse) => {
    if (response.locations.length === 0) {
        return {currencies: [], tariffs: []};
    }

    return {location: response.locations[0], tariffs: response.tariffs, currencies: response.currencies};
};

// Deprecated: Use getPins
export async function getLocations(): Promise<Location[]> {
    return (net.getOrWarn('/app/locations', undefined, 'v2'));
    // , () => {LocationStore.sharedInstance().loadFromBackend(); ToasterStore.sharedInstance().message = null; }
}

export async function getPins(params = {}): Promise<Pin[]> {
    const result = await net.getOrWarn('/app/pins', undefined, 'v2', params);

    if (!result || !Array.isArray(result.pins)) {
        GlobalStoreProxy.sharedInstance().error = new Error('Network error. Unable to load pins from server.');
        return [];
    }

    return result.pins;
}

export async function getPin(id: string, params?: {}): Promise<Pin|undefined> {
    return (await net.getOrWarn('/app/pins/' + id, undefined, 'v2', params))?.pins?.shift();
}

export async function getByEVSE(evse_id: string): Promise<LocationsResource> {
    const response = (await net.get('/app/locations/withEVSE/' + evse_id, 'v2'));

    return formatSingleResponse(response);
}

export async function getByEVSEIdentifier(evse_id: string): Promise<LocationsResourceWithIdentifier> {
    const response = (await net.get('/app/locations/withEVSEIdentifier/' + evse_id, 'v2')) as LocationsResourceResponse;

    return {...formatSingleResponse(response), evseIdentifier: response.evseIdentifier};
}

export async function getById(location_id: string): Promise<{location?: Location, tariffs: Tariff[], currencies: Currency[]}> {
    const response = (await net.get('/app/locations/' + location_id, 'v2'));

    return formatSingleResponse(response);
}

export async function getPricePeriod(tariffId: string, date?: string): Promise<PricePeriod[]> {
    return (await net.get('/app/tariffs/' + tariffId + '/price-periods?date=' + date)) as PricePeriod[];
}

export async function getTOUPricePeriods(evseId: string): Promise<ToUPricePeriods> {
    return (await net.get('/app/tariffs/tou/' + evseId + '/price-periods')) as ToUPricePeriods;
}
