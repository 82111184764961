import {action, autorun, observable} from 'mobx';
import Navigation from '@ampeco/navigation';
import {LocaleStore} from '@ampeco/i18n';
import {OAuthAPI} from '@ampeco/auth';
import {Term} from './Term';
import {TermsStore} from './TermsStore';

type onTermSetFn = (activeTerm: Term) => void;
type onTermLinkFn = (activeTerm: Term) => void;
type onConsentFn = (activeTerm: Term) => Promise<Term | null>;
type onCancelFn = (activeTerm: Term) => void;
type onAfterConsentFn = (activeTerm: Term) => void;
type onAfterCancelFn = (activeTerm: Term) => void;

export interface ActiveTermStoreConfiguration {
    onTermSet?: onTermSetFn,
    onTermLink?: onTermLinkFn,
    onConsent?: onConsentFn,
    onCancel?: onCancelFn,
    globalStore?: any | undefined,
}

export default class ActiveTermStore {
    private static instance: ActiveTermStore;
    private readonly onTermSet: onTermSetFn | undefined;
    private readonly onTermLink: onTermLinkFn | undefined;
    private readonly onConsent: onConsentFn | undefined;
    private readonly onCancel: onCancelFn | undefined;
    private onAfterConsent: onAfterConsentFn | undefined;
    private onAfterCancel: onAfterCancelFn | undefined;
    private static globalStore: any | undefined;

    @observable activeTerm: Term | null = null;

    constructor(configuration: ActiveTermStoreConfiguration) {
        this.onTermSet = configuration.onTermSet || ActiveTermStore._onTermSet;
        this.onTermLink = configuration.onTermLink || ActiveTermStore._onTermLink;
        this.onConsent = configuration.onConsent || ActiveTermStore._onConsent;
        this.onCancel = configuration.onCancel || ActiveTermStore._onCancel;
        ActiveTermStore.globalStore = configuration.globalStore || undefined;

        autorun(() => {
            if (this.activeTerm && this.onTermSet) {
                this.onTermSet(this.activeTerm);
            }
        }, {delay: 50})
    }

    static init(configuration: ActiveTermStoreConfiguration = {}) {
        this.instance = new ActiveTermStore(configuration);
    }

    static sharedInstance(): ActiveTermStore {
        if (!ActiveTermStore.instance) {
            throw new Error('ActiveTermStore is not configured.');
        }

        return ActiveTermStore.instance;
    }

    @action.bound
    setActiveTerm(term: Term | null) {
        if (this.activeTerm?.version_id !== term?.version_id) {
            this.activeTerm = term;
        }
    }

    async handleTermLinkClick () {
        if (this.activeTerm && this.onTermLink) {
            this.onTermLink(this.activeTerm);
        }
    }

    async handleConsent() {
        const term = this.activeTerm;
        if (term) {
            this.setActiveTerm(null);
            let isAfterConsent = true;
            if (this.onConsent) {
                const nextTerm = await this.onConsent(term);
                if (nextTerm) {
                    isAfterConsent = false;
                }
            }
            isAfterConsent && this.onAfterConsent && this.onAfterConsent(term);
        }
    }

    async handleCancel() {
        const term = this.activeTerm;
        if (term) {
            this.setActiveTerm(null);
            if (this.onCancel) {
                await this.onCancel(term);
            }
            this.onAfterCancel && this.onAfterCancel(term);
        }
    }

    setOnAfterConsentCallback(cb: onAfterConsentFn) {
        this.onAfterConsent = cb
    }

    setOnAfterCancelCallback(cb: onAfterCancelFn) {
        this.onAfterCancel = cb;
    }

    private static async _onTermSet(term: Term): Promise<void> {
        await Navigation.sharedInstance().navigate('TermChangeContainer', {
            activeTerm: term,
            isAdHock: ActiveTermStore?.globalStore?.isAdHocUser,
        });
    }

    private static async _onTermLink(term: Term): Promise<void> {
        if (term.urls) {
            await Navigation.sharedInstance().navigate('TermsContent',
                {
                    title: term.title,
                    url: term.urls[LocaleStore.sharedInstance().language],
                });
        }
    }

    private static async _onConsent(term: Term): Promise<Term | null> {
        // Re-load Terms in case there are multiple Terms with updated versions.
        return TermsStore.sharedInstance().load();
    }

    private static async _onCancel(term: Term): Promise<void> {
        try {
            await OAuthAPI.logout();
        } catch (e) {
            //
        } finally {
            if (ActiveTermStore.globalStore) {
                ActiveTermStore.globalStore.unsetProfile && ActiveTermStore.globalStore.unsetProfile();
                ActiveTermStore.globalStore.reloadTranslatableResources && ActiveTermStore.globalStore.reloadTranslatableResources();
            }
        }
    }
}
