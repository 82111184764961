import {action, computed, observable} from 'mobx';
import {Session} from '@ampeco/charge-models';
import {ProfileFieldsStore, ProfileFieldState} from '@ampeco/profile-fields';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';

export enum LimitChargingWhenProfileIncomplete {
    DISABLED = 'disabled',
    PUBLIC_CHARGING_ONLY = 'public_charging_only',
    HOME_CHARGING_ONLY = 'home_charging_only',
    ALL_CHARGING = 'all_charging',
}

export default class ProfileStore {
    private static instance: ProfileStore;

    private static PROFILE_FIELD_LIMIT_CHARGING_WHEN_PROFILE_INCOMPLETE = 'limitChargingWhenProfileIncomplete';

    @observable unpaidSession: Session | null = null;

    static sharedInstance(): ProfileStore {
        if (ProfileStore.instance === undefined) {
            ProfileStore.instance = new ProfileStore();
        }
        return ProfileStore.instance;
    }

    @action.bound
    setUnpaidSession(session: Session|null) {
        this.unpaidSession = session;
    }

    @computed get isLoggedIn() {
        const globalStore = GlobalStoreProxy.sharedInstance();

        if (!(globalStore.isAdHocUser || globalStore.isGuestUser)) {
            return true;
        }

        return false;
    }

    @computed get emailVerified() {
        const globalStore = GlobalStoreProxy.sharedInstance();

        const profileFields = ProfileFieldsStore.sharedInstance().profileFields;
        if (profileFields[ProfileFieldsStore.PROFILE_FIELD_EMAIL] === 'verified') {
            if (globalStore.profile && !globalStore.profile.email_verified_at) {
                return false;
            }
        }
        return true;
    }

    @computed get phoneVerified() {
        const globalStore = GlobalStoreProxy.sharedInstance();

        if (ProfileFieldsStore.sharedInstance().profileFields[ProfileFieldsStore.PROFILE_FIELD_PHONE] === 'verified') {
            if (globalStore.profile && !globalStore.profile.phone_verified_at) {
                return false;
            }
        }
        return true;
    }

    @computed get isPublicChargingDisabledWhenProfileIsIncomplete(): boolean {
        const publicChargingAllowedStates = [
            LimitChargingWhenProfileIncomplete.PUBLIC_CHARGING_ONLY,
            LimitChargingWhenProfileIncomplete.ALL_CHARGING,
        ];

        return this.isChargingDisabledWhenProfileIsIncomplete(publicChargingAllowedStates);
    }

    @computed get isHomeChargingDisabledWhenProfileIsIncomplete(): boolean {
        const homeChargingAllowedStates = [
            LimitChargingWhenProfileIncomplete.HOME_CHARGING_ONLY,
            LimitChargingWhenProfileIncomplete.ALL_CHARGING,
        ];

        return this.isChargingDisabledWhenProfileIsIncomplete(homeChargingAllowedStates);
    }

    private isChargingDisabledWhenProfileIsIncomplete(allowedStates: string[]): boolean {
        const profileFields = ProfileFieldsStore.sharedInstance().profileFields;
        if (!(ProfileStore.PROFILE_FIELD_LIMIT_CHARGING_WHEN_PROFILE_INCOMPLETE in profileFields)) {
            return true;
        }
        const limitChargingWhenProfileIncompleteValue = profileFields[ProfileStore.PROFILE_FIELD_LIMIT_CHARGING_WHEN_PROFILE_INCOMPLETE] as ProfileFieldState|LimitChargingWhenProfileIncomplete;

        return allowedStates.includes(limitChargingWhenProfileIncompleteValue);
    }
}
