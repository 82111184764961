import AsyncStorage from '@react-native-async-storage/async-storage';

const storageKey = '@Oauth.token';

export interface AuthenticationInfo {
    token_type: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    issued_on: Date;
}

export async function getAuthenticationInfo(): Promise<AuthenticationInfo | null>  {
    const value = await AsyncStorage.getItem(storageKey);
    if (value === null) {
        return null;
    } else {
        return JSON.parse(value) as AuthenticationInfo;
    }
}

export async function setAuthenticationInfo(token: AuthenticationInfo | null) {
    if (token === null) {
        await AsyncStorage.removeItem(storageKey);
    } else {
        await AsyncStorage.setItem(storageKey, JSON.stringify(token));
    }
}
