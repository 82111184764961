export enum EmbeddedUrlTrigger {
  LOGIN = 'login',
}

export interface EmbeddedUrls {
  url: string;
  closeUrl: string | null;
  trigger: EmbeddedUrlTrigger | null;
  userGroupIds?: number[];
}
