import React from 'react';
import {observer} from 'mobx-react';
import {SessionRunning} from '@ampeco/charge-screens';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Redirect} from 'react-router';
import NavigationService from '@ampeco/navigation';
import GlobalStore from '../../stores/GlobalStore';
import {BasePage} from "../BasePage";

const SessionRunningWrapper = observer(() => {
    const activeSession = GlobalStore.sharedInstance().activeSession;

    if (!activeSession) {
        return <Redirect push to={`/`} />;
    }

    return (
        <BasePage title={'session.running.title_screen'} onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Location', {
                locationId: activeSession.locationId
            })
        }}>
            <SafeAreaProvider>
                <SessionRunning/>
            </SafeAreaProvider>
        </BasePage>
    )
});

export default SessionRunningWrapper;
