import React from 'react';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import PropTypes from 'prop-types';
import {FormStore} from './FormStore';
import {ProviderContext} from './FormStoreProvider';
import {CheckBox, CheckBoxProps, Input, InputProps} from 'react-native-elements';
import {CheckBoxAdapter} from './CheckBoxAdapter';
import {FieldInput} from './FieldInput';
import {InputAdapter} from './InputAdapter';
import {Switch} from './Switch';
import {PasswordRevealInput} from './PasswordRevealInput';
import Select, {ElementDropdown, SelectProps, SelectElementDropdownProps} from '@ampeco/select';
import {PhoneInput} from './index';
import {PhoneInputProps} from './PhoneInputProps';

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

type Props = {
    name: string,
    type: FieldInput | typeof Input | typeof CheckBox | typeof PasswordRevealInput | typeof Switch | typeof Select | string | typeof ElementDropdown | typeof PhoneInput
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify',
    checked?: boolean;
    onChanged?: (text?: string) => any;
    noError?: boolean;
    disabled?: boolean;
} & Omit<CheckBoxProps, 'checked'> & Partial<InputProps> & Partial<SelectProps> & Partial<SelectElementDropdownProps> & Partial<PhoneInputProps>;

@observer
export class Field extends React.Component<Props, ProviderContext> {

    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    get store(): FormStore {

        if (this.context.store) {
            return this.context.store;
        } else {
            throw new Error('Store not defined for element');
        }
    }

    static adapters = (type: any) => {
        switch (type) {
            case Input:
                return InputAdapter;
            case CheckBox:
                return CheckBoxAdapter;
            default:
                return Field.additionalAdapters(type);
        }
    };

    static additionalAdapters = (type: any) => {
        return type;
    };

    getErrorMessage() {
        const {
            name,
        } = this.props;
        const store = this.store;
        let errorMessage;

        if (store.errors !== undefined) {
            if (store.errors[name] !== undefined && store.errors[name]) {
                errorMessage = store.errors[name];
            }
        }

        if (!errorMessage) {
            // Fix flash when error appear and re-appears
            errorMessage = ' ';
        }
        return errorMessage;
    }

    render() {
        const {name} = this.props;
        const store = this.store;
        const value = store.form[name] || '';

        const Type = Field.adapters(this.props.type);
        const errorMessage = this.getErrorMessage();
        return <Type
          {...this.props}
          value={value}
          onChange={this.onChanged}
          setError={(error: string|Error) => {
              this.store.errors[name] = error instanceof Error ? error.message : error;
          }}
          errorMessage={errorMessage}
          editable={!this.props.disabled && store.processing === null}
          disabled={this.props.disabled}
        />;
    }

    onChanged = (text: string) => {
        const {onChanged, name} = this.props;
        this.storeState(text);
        this.store.validate(name);
        if (onChanged) {
            onChanged(text);
        }
    };

    @action.bound
    storeState(text: any) {
        this.store.form[this.props.name] = text;
    }
}
