import {
    getByEVSE,
    getByEVSEIdentifier,
    getById,
    getLocations,
    getPin,
    getPins,
    getPricePeriod,
    getTOUPricePeriods
} from './locations';
import {
    updateLocale,
    updateNewsAndPromotionsPreferences,
    patchProfile,
    getSessionHistory,
    getSessionStats,
    patchEmail,
} from './profile';
import {addFavorite, removeFavorite} from './favorites';
import {emailIsAvailable, forgottenPassword, register} from './authentication';
import {
    checkCanStart,
    getActiveSessionInfo,
    getSessionInfo, paySession, sendRemoteStart,
    startSession,
    stopSession,
    updateSessionInfo,
    getThreshold,
    boostSession,
    boostRandomisedDelay,
    getLatestUnknown,
} from './session';
import {cancelReservation, getReservationInfo, getReservationRestriction, reserveNow} from './reservation';
import {
    getList as getSubscriptionPlanList,
    activate as activateSubscription,
    cancel as cancelSubscription,
    canUseChargingAllowance,
    billingHistory,
    sessionHistory,
    pay,
    hasObligationsMessage,
    makeObligationsMessageSeen,
} from './subscription_plans';
import {
    getPartnersList,
    addPartner,
} from './partners';
import {getOffPeakSettingsByEvseId, getTariffGroupId} from './charge_point';

const Signup = {
    register,
    emailIsAvailable,
    forgottenPassword,
};

const Location = {
    all: getLocations,
    byEVSE: getByEVSE,
    byId: getById,
    byEVSEIdentifier: getByEVSEIdentifier,
    getTOUPricePeriods,
};

const Pins = {
    all: getPins,
    byId: getPin,
};

const Profile = {
    updateLocale,
    updateNewsAndPromotionsPreferences,
    patch: patchProfile,
    sessionHistory: getSessionHistory,
    sessionStats: getSessionStats,
    addEmailForAdHocCharging: patchEmail,
};

const Session = {
    start: startSession,
    stop: stopSession,
    info: getSessionInfo,
    active: getActiveSessionInfo,
    update: updateSessionInfo,
    boost: boostSession,
    boostRandDelay: boostRandomisedDelay,
    pay: paySession,
    checkCanStart,
    sendRemoteStart,
    getThreshold,
    getLatestUnknown,
};

const Favorites = {
    add: addFavorite,
    remove: removeFavorite,
};

const Reservation = {
    reserve: reserveNow,
    cancel: cancelReservation,
    info: getReservationInfo,
    restriction: getReservationRestriction,
};

const SubscriptionPlans = {
    getList: getSubscriptionPlanList,
    activate: activateSubscription,
    cancel: cancelSubscription,
    canUseChargingAllowance,
    billingHistory,
    sessionHistory,
    pay,
    hasObligationsMessage,
    makeObligationsMessageSeen,
};

const ChargePoint = {
    getOffPeakSettingsByEvseId,
    getTariffGroupId,
}

const Partners = {
    getPartnersList,
    addPartner,
}

export {Location, Pins, Profile, Favorites, Signup, Session, Reservation, SubscriptionPlans, ChargePoint, Partners};
