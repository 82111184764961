import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ThemeConsumer, Text} from 'react-native-elements';
import {ChargeTheme} from '@ampeco/theme';

export default (props: {formattedPrice: string; kwh: number; }) => {
    return (
        <ThemeConsumer>
            {({theme}) => (
                <View>
                    <View style={styles.marker}>
                        <View style={{
                            position: 'absolute',
                            width: 100,
                            height: 50,
                            backgroundColor: 'white',
                            top: -60,
                        }}>
                            <Text style={theme.AgileStreetSlider.toolTipPriceTextStyle}>
                                {props.formattedPrice}
                            </Text>
                            <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                                <Text style={theme.AgileStreetSlider.toolTipAdditionalInfoTextStyle}>{props.kwh} kWh</Text>
                                <Text style={theme.AgileStreetSlider.toolTipAdditionalInfoTextStyle}>{props.kwh * 3} mi</Text>
                            </View>
                        </View>
                    </View>
                </View>
            )}
        </ThemeConsumer>
    );
};

const styles = StyleSheet.create({
    marker: {
        color: 'white',
        width: 32,
        height: 32,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#707070',
        borderRadius: 16,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
});
