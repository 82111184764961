import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {observer} from "mobx-react";
import net from '@ampeco/net';
import {HTMLRenderer} from '@ampeco/html-renderer';
import ConfigurableActivityIndicator from "@ampeco/configurable-activity-indicator";
import colors from "../../res/colors";

interface Props {
    navigation: {
        title: string,
        url: string,
        getParam: (query: string) => string,
    },
}

const TermsScreen = observer(({navigation}: Props): JSX.Element => {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (navigation.url) {
            net.get(navigation.url)
                .then((response) => {
                    const parsed = new DOMParser().parseFromString(response.toString(), 'text/html');
                    setContent(parsed.getElementById('container').innerHTML);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    if (loading) {
        return <View style={styles.loading}>
            <ConfigurableActivityIndicator/>
        </View>;
    }

    return (
        <View style={styles.container}>
            <HTMLRenderer text={content}/>
        </View>
    );
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderRadius: 10,
        height: '90vh',
        backgroundColor: colors.backgroundColor,
        paddingVertical: 10,
        paddingHorizontal: 25,
        color: colors.textColor,
    },
    loading: {
        flex: 1,
        marginTop: 25,
    }
});

export default TermsScreen;

