import { I18n, LocaleResolver, Scope, TranslateOptions } from 'i18n-js';
import * as RNLocalize from 'react-native-localize';

export interface I18NSettings {
    default: string;
    available: { [index: string]: string };
}

/**
 * Contains translations for various locales
 *
 * @interface TranslationPack
 */
export interface TranslationPack {
    [locale: string]: object;
}

/**
 * This is the same i18n-js resolver, with the addition to always add english as fallback.
 * The way it works by default is if the default language is "mk" and the current is "bg", will return
 * a list of ["bg", "mk"], so if the string is not found it won't fallback to english, this requires baking all translation files with english strings as well.
 *
 * @see https://github.com/fnando/i18n/blob/main/src/Locales.ts#L27
 *
 * @param i18n
 * @param locale
 * @returns
 */
const alwaysEnglishFallbackResolver: LocaleResolver = (
    i18n: I18n,
    locale: string,
): string[] => {
    const locales = [];
    const list: string[] = [];

    // Handle the inline locale option that can be provided to
    // the `I18n.t` options.
    locales.push(locale);

    // Add the current locale to the list.
    if (!locale) {
        locales.push(i18n.locale);
    }

    // Add the default locale if fallback strategy is enabled.
    if (i18n.enableFallback) {
        locales.push(i18n.defaultLocale);
    }

    // Compute each locale with its country code.
    // So this will return an array containing both
    // `de-DE` and `de` locales.
    //
    // We also need to support locales with region code (e.g. zh-Hant-TW).
    // In that case, the list should be `["zh-hant-tw", "zh-hant", "zh"]`.
    locales
        .filter(Boolean)
        .map((entry) => entry.toString())
        .forEach(function (currentLocale: string) {
            if (!list.includes(currentLocale)) {
                list.push(currentLocale);
            }

            if (!i18n.enableFallback) {
                return;
            }

            const codes = currentLocale.split("-");

            if (codes.length === 3) {
                list.push(`${codes[0]}-${codes[1]}`);
            }

            list.push(codes[0]);
        });

    list.push('en');
    return [... new Set(list)];
};

export class I18N {
    static translator: I18n;
    /**
     * Initializes and loads translations
     *
     * @static
     * @param {TranslationPack} translations
     * @param {I18NSettings} settings
     * @memberof I18N
     */
    static init(translations: TranslationPack, settings: I18NSettings) {
        I18N.translator = new I18n();
        I18N.translator.enableFallback = true;
        I18N.translator.defaultLocale = settings.default;
        I18N.translator.locales.register('default', alwaysEnglishFallbackResolver);

        const locales: {[index: string]: any} = {};
        for (const locale of Object.keys(settings.available)) {
            if (translations[locale]) {
                locales[locale] = translations[locale];
            }
        }
        I18N.translator.store(locales);

        const availableLocales: string[] = Object.keys(settings.available);
        const bestAvailable = RNLocalize.findBestAvailableLanguage?.(availableLocales);
        if (bestAvailable) {
            I18N.setLocale(bestAvailable.languageTag); // Init the locale
        }

    }

    public static setLocale(locale: string) {
        I18N.translator.locale = locale;
    }

    public static getLocale() {
        return I18N.translator.locale;
    }

    public static setDefaultLocale(defaultLocale: string): void {
        I18N.translator.defaultLocale = defaultLocale;
    }

    public static getDefaultLocale(): string {
        return I18N.translator.defaultLocale;
    }

    public static translate(name: Scope, params: TranslateOptions = {})
    {
        return I18N.translator.t(name, params);
    }

    public static isRTL(locale: string): boolean {
        return locale.indexOf('he') === 0 || locale.indexOf('ar') === 0;
    }
}
