import React from 'react';
import {
    StyleSheet,
    View,
} from 'react-native';
import {Text, ThemeConsumer, withTheme} from 'react-native-elements';
import InfoCenterStore from '../../stores/InfoCenterStore';
// @ts-ignore
import {AccordionList} from 'accordion-collapse-react-native';
import {FAQ} from '../../models/FAQ';
import AmpecoTheme from '@ampeco/theme';
import {HTMLRenderer} from '@ampeco/html-renderer';

class Faqs extends React.Component {

    render() {
        const store = InfoCenterStore.sharedInstance();

        return <ThemeConsumer<AmpecoTheme>>{({theme}) => (
            <AccordionList
                list={store.numberedFaqs}
                header={(item: FAQ & {number: number}) =>
                    <View style={item.number > 0 ? [style.faqSection, {borderTopColor: theme.InfoCenter.dividerColor}] : style.firstFaqSection}>
                        <Text style={StyleSheet.flatten([theme.InfoCenter.question, {color: theme.InfoCenter.textEmphasisColor, textAlign: 'left'}])}>{item.title}</Text>
                    </View>}
                body={(item: FAQ) =>
                    <View style={{marginBottom: 20}}>
                        <HTMLRenderer text={item.text}/>
                    </View>
                }
            />
        )}</ThemeConsumer>;
    }
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 25,
    },
    helpCenterSection: {
        flexDirection: 'row',
    },
    firstFaqSection: {
        paddingVertical: 20,
    },
    faqSection: {
        paddingVertical: 20,
        borderTopWidth: 1,
    },
    faqText: {
        paddingBottom: 15,
    },
});

export default Faqs;
