import React, {useState, useEffect} from 'react';
import {ActiveTermStore} from '@ampeco/terms';
import TermConsentScreen from './TermConsentScreen';
import {BasePage} from "../BasePage";
import {observer} from "mobx-react";

const TermConsent = (): JSX.Element => {
    const [term, setTerm] = useState(null);
    const activeTermStore = ActiveTermStore.sharedInstance();

    useEffect(() => {
        if (activeTermStore.activeTerm) {
            setTerm(activeTermStore.activeTerm);
        }
    }, [activeTermStore.activeTerm]);

    if (!term) {
        return null;
    }

    return (
        <BasePage isGoBackLinkDisabled>
            <TermConsentScreen
                term={term}
                onConsentClick={() => {
                    setTerm(null);
                    activeTermStore.handleConsent();
                }}
                onCancelClick={() => {
                    setTerm(null);
                    activeTermStore.handleCancel();
                }}
                onTermLinkClick={async () => {
                    await activeTermStore.handleTermLinkClick();
                }}
            />
        </BasePage>
    );
}
export default observer(TermConsent)
