import {sprintf} from 'sprintf-js';
import {Cacheable, CacheStore} from '@ampeco/cache';
import {action} from 'mobx';
import {LocaleStore} from '@ampeco/i18n';
import {formatNumber} from '@ampeco/utils';

export class Currency implements Cacheable {
    public id: number = 0;
    public name: string = '';
    public sign: string = '';
    public code: string = '';
    public formatter: string = '';
    public unitPriceFormatter: string = '';
    public minorUnitSign?: string;
    public minorUnitPrecision?: number;
    public minorUnitDecimal: number = 0;
    public updatedAt: string = '';
    public hasPrefix: boolean = false;
    public hasSuffix: boolean = false;

    static cacheName() {
        return 'currencies';
    }

    static endpoint() {
        return '/app/currencies';
    }

    static cacheTime() {
        return 3600;
    }

    static fetch(ids: string | string[]) {
        CacheStore.getInstance(Currency).fetch(ids);
    }

    static where(id: string): Currency | undefined {
        return CacheStore.getInstance(Currency).cache[id];
    }

    static clearCachedData(shouldReload: boolean = false): void {
        CacheStore.getInstance(Currency).clearCachedData(shouldReload);
    }

    static build(arg: any): Currency {
        const res = new Currency();

        res.id = arg.id;
        res.name = arg.name;
        res.sign = arg.sign;
        res.code = arg.code;
        res.formatter = arg.formatter;
        res.unitPriceFormatter = arg.unitPriceFormatter;
        res.minorUnitSign = arg.minorUnitSign;
        res.minorUnitPrecision = arg.minorUnitPrecision;
        res.minorUnitDecimal = arg.minorUnitDecimal;
        res.updatedAt = arg.updated_at;
        res.hasPrefix = arg.hasPrefix;
        res.hasSuffix = arg.hasSuffix;

        return res;
    }
}

export const supportsMinors = (currency: Currency | null): boolean => {
    return !!currency && currency.minorUnitPrecision !== undefined && !!currency.minorUnitSign;
};

export const format = (number: number, currency: Currency | null | undefined, additionalText: string = '', inMinors: boolean = false) => {
    const localizationSettings = LocaleStore.sharedInstance().localizationSettings;
    const decimals = localizationSettings.decimals;
    let formattedNumber = formatNumber(Number(number), decimals);

    if (!currency) {
        if (isNaN(Number(formattedNumber))) {
            return '-';
        }
        return formattedNumber + '';
    } else {
        formattedNumber = addCurrencySign(formattedNumber, currency);
        // @ts-ignore
        if (inMinors && supportsMinors(currency)) {
            // tslint:disable-next-line:no-non-null-assertion
            return sprintf(`%0.0${currency.minorUnitPrecision}f ${currency.minorUnitSign}`, number * Math.pow(10, currency.minorUnitDecimal!));
        } else {
            // @ts-ignore
            return formattedNumber + additionalText;
        }
    }
};

export const addCurrencySign = (formattedNumber: any, currency: Currency) => {
    let result = '';
    if (currency.hasPrefix) {
        result += currency.sign;
    }

    result += formattedNumber;

    if (currency.hasSuffix) {
        result += currency.sign;
    }

    return result;
}

export const withNoDecimals = action((number: number, currency: Currency | null | undefined, additionalText: string = '') => {
    const numberString: string = '';
    if (!currency) {
        if (isNaN(number)) {
            return '-';
        }
        return number + '';
    }

    let formatter = currency.formatter;
    if (Number.isInteger(number)) {
        formatter = currency?.formatter?.replace('0.02', '0');
    }

    return sprintf(formatter + additionalText, numberString || number);
});
