import Echo from 'laravel-echo';
import BroadcastService, {BroadcastSettings} from "../../frontend_modules/broadcast/BroadcastService";
import {GlobalStoreProxy} from "@ampeco/charge-globalstore";
import GlobalStore from "../stores/GlobalStore";
import {Session} from "@ampeco/charge-models";
import Navigation from "@ampeco/navigation";
import {reaction} from "mobx";

class SessionWebsocketMonitor {
    private echo?: Echo;

    private subscribed?: string;
    private settings: () => BroadcastSettings;
    private globalStore: any;

    init(settings: () => BroadcastSettings) {
        this.globalStore = GlobalStoreProxy.sharedInstance();
        this.settings = settings;
        this.subscribe();

        reaction(() => BroadcastService.sharedInstance(this.settings).connected && GlobalStore.sharedInstance().profile !== null, connected => {

            if (connected) {
                this.subscribe()

            } else {
                this.unsubscribe();
            }

        }, {name: 'Session Websocket Monitor'});
    }

    async subscribe() {
        if (this.subscribed) {
            return;
        }

        if (GlobalStore.sharedInstance().isGuestUser) {
            return;
        }

        const service = BroadcastService.sharedInstance(this.settings);
        const channelName = SessionWebsocketMonitor.channelName(service);
        this.subscribed = channelName;

        this.echo = await service.connect();

        const channel = this.echo.private(channelName);

        channel.listen('SessionChanged', this.onSessionChanged.bind(this));
        channel.listen('SessionEnded', this.onSessionEnded.bind(this));

        console.log('Echo: Subscribed to ' + channelName);
    }

    unsubscribe() {
        if (!this.subscribed) {
            return;
        }
        this.echo && this.echo.leave(this.subscribed);
        this.echo && console.log('Echo: Unsubscribed from ' + this.subscribed);
        this.subscribed = undefined;
    }

    private onSessionChanged = (payload: any) => {
        console.log('Change session:' + JSON.stringify(payload));
        GlobalStore.sharedInstance().setActiveSession(payload.session)
    };

    private onSessionEnded = (payload: any) => {
        console.log('End session:' + JSON.stringify(payload));
        GlobalStore.sharedInstance().setActiveSession(null);

        GlobalStore.sharedInstance().reloadProfile();
        SessionWebsocketMonitor.sessionHasEnded(payload.session)
    };

    static sessionHasEnded(session: Session) {
        if (session.status !== 'failed') {
            const lastSession = GlobalStore.sharedInstance().finishedSession;
            if (lastSession && lastSession.id === session.id && lastSession.paymentStatus !== 'pending' && session.paymentStatus === 'pending') {
                // Ignore the as the notifications are coming in the wrong order
                return;
            }

            GlobalStore.sharedInstance().setActiveSession(null);
            GlobalStore.sharedInstance().setFinishedSession(session);
            if (!session.summarySeen) {
                Navigation.sharedInstance().navigate('SessionSummary', {dismissButton: true});
            }
        }
    }

    private static channelName(service: BroadcastService) {
        const globalStore = GlobalStoreProxy.sharedInstance();

        if (globalStore.profile === null || globalStore.profile.id === null) {
            throw new Error('Cannot resolve channel name - no profile logged in');
        }
        return `${service.channelPrefix()}.user.${globalStore.profile.id}`;
    }
}

export default new SessionWebsocketMonitor();
