import {action, computed, observable} from 'mobx';
import {Session, SessionHistory} from '@ampeco/charge-models';
import {__, LocaleStore} from '@ampeco/i18n';
import {Profile} from '@ampeco/charge-api';
import moment from 'moment';
import {DEFAULT_DATE_FORMAT} from '@ampeco/i18n';

let historyStoreInstance: SessionHistoryStore;

export default class SessionHistoryStore {
    static CUSTOM_PERIOD_INDEX = 3;
    static THIS_MONTH_INDEX = 0;
    static LAST_MONTH_INDEX = 1;

    @observable records?: SessionHistory[];
    @observable selectedPeriodIndex: number = 0;
    @observable customPeriodStart  ?: string | undefined;
    @observable customPeriodEnd ?: string | undefined;
    @observable filter ?: string | null = null;
    @observable customPeriod ?: boolean;
    @computed get list() {

        if (this.records === undefined) {
            return [];
        }
        let key = 1;
        const result = this.records.map(record => ({...record, key: (key++) + ''}));

        if (this.filter === null) {
            return result;
        }

        return result.filter(entry => entry.type === this.filter);
    }

    @action.bound
    setFilter(filter: string | null) {
        this.filter = filter
    }

    @action.bound
    setCustomPeriod(customPeriod: boolean) {
        this.customPeriod = customPeriod
    }
    @action.bound
    setCustomPeriodStart(customPeriodStart: string | undefined) {
        this.customPeriodStart = customPeriodStart;
    }
    @action.bound
    setCustomPeriodEnd(customPeriodEnd: string | undefined) {
        this.customPeriodEnd = customPeriodEnd;
    }

    static sharedInstance() {
        if (historyStoreInstance === undefined) {
            historyStoreInstance = new SessionHistoryStore();
        }
        return historyStoreInstance;
    }

    async load() {
        try {
            this.records = await Profile.sessionHistory(
                this.customPeriodStart ? moment(this.customPeriodStart, LocaleStore.sharedInstance().displayDateFormat).format(DEFAULT_DATE_FORMAT) : undefined,
                this.customPeriodEnd ? moment(this.customPeriodEnd, LocaleStore.sharedInstance().displayDateFormat).format(DEFAULT_DATE_FORMAT) : undefined,
            );
        } catch (e) {
            // if (e.code !== 'ECONNABORTED') {
            //     bugsnag.notify(e, 'Failed loading session history');
            // }
        }
    }

    updateSession(session: Session) {
        if (this.records === undefined) {
            return;
        }

        const result = this.records.find(r => r.session.id === session.id);

        if (result === undefined) {
            return;
        }

        result.session = session;
    }

    getPeriods() {
        return [
            __('sessions-history.this-month'),
            __('sessions-history.last-month'),
            __('sessions-history.all-time'),
            __('sessions-history.custom-period'),
        ];
    }
}
