import React from 'react';
import {StyleSheet, ViewStyle, TextStyle} from 'react-native';
import {Button, ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

interface Props {
    active?: boolean;
    title: string;
    children?: React.ReactChildren;
    customColorStyle?: ViewStyle;
    customTextStyle?: TextStyle;
    disabled?: boolean;
    onPress?: () => void | Promise<void>;
}

const RoundedTag: React.FC<Props> = ({title, active, children, customColorStyle, customTextStyle, disabled, onPress}) => {
    const styleCenterText = (style: ViewStyle) => {
        if (!!customColorStyle) return [style, styles.textCenter]
        return style
    }

    return <ThemeConsumer<AmpecoTheme>>{({theme}) => {

        let style = StyleSheet.flatten([styles.button, active ? theme.RoundedTag.activeStyle : theme.RoundedTag.inactiveStile]);

        if (!!customColorStyle) style = StyleSheet.flatten([customColorStyle, styles.button])

        let titleStyle = StyleSheet.flatten([{
            fontSize: 12,
            paddingLeft: 0,
        }, active ? theme.RoundedTag.activeTextStyle : theme.RoundedTag.inactiveTextStyle]);

        if (!!customTextStyle) {
            titleStyle = StyleSheet.flatten([{fontSize: 12, paddingLeft: 0}, customTextStyle])
        }

        return <Button
            title={title}
            disabled={!!disabled}
            onPress={onPress}
            titleStyle={titleStyle}
            disabledTitleStyle={titleStyle}
            buttonStyle={styleCenterText(style)}
            disabledStyle={style}
            containerStyle={[styles.container]}>
                {children}
            </Button>;
    }}</ThemeConsumer>;

};
const styles = StyleSheet.create({
    container: {
        marginTop: 2,
        marginBottom: 0,
    },
    button: {
        borderRadius: 18,
        height: 18 * 2,
        paddingHorizontal: 20,
    },
    text: {
        fontSize: 12,
        paddingLeft: 0,
    },
    textCenter: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default RoundedTag;
