import React from 'react';
import {ActivityIndicator, StyleProp, StyleSheet, TextStyle, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import {observer} from 'mobx-react';
import {__} from '@ampeco/i18n';
import {humanizeDuration} from '@ampeco/humanize-duration';
import {formatEnergy, formatPower} from '@ampeco/utils';
import {Session} from '@ampeco/charge-models';
import {format} from '@ampeco/currency';
import {ChargeTheme} from '@ampeco/theme';
import {SessionStatsStore} from '@ampeco/charge-stores';

interface PropsStats {
    label: string,
    value: string,
    valueStyle?: StyleProp<TextStyle>,
    labelStyles?: StyleProp<TextStyle>;
    hasAsterisk?: boolean;
}
function Stat(props: PropsStats) {
    return <ThemeConsumer>{({theme}) => { return <View style={styles.statBox}>
        <Text style={[theme.PersonalChargingSessionStatsComponent.statTitleStyle, props.labelStyles]}>{props.label}</Text>
        <Text
            style={[theme.PersonalChargingSessionStatsComponent.statValueStyle, props.valueStyle]}
        >{props.value + (props.hasAsterisk ? '*' : '')}</Text>
    </View>; }}</ThemeConsumer>;
}
interface Props {
    activeSession: Session | null;
    hideDurationAndAmount?: boolean
    hideEnergyAndPower?: boolean
}
@observer
export default class SessionStatsComponent extends React.Component<Props> {
    render() {
        const {activeSession} = this.props;
        if (activeSession === null) {
            return this.renderLoading();
        }

        return <ThemeConsumer>{({theme}) => {
            const textStyle = [theme.PersonalChargingSessionStatsComponent.textStyle, styles.textStyles];
            const labelStyle = [theme.PersonalChargingSessionStatsComponent.labelStyle, styles.labelStyles];
            const amountTextStyle = activeSession.isOptimized ?
                [theme.PersonalChargingSessionStatsComponent.textStyle, styles.textStyles, styles.bold] :
                [theme.PersonalChargingSessionStatsComponent.textStyle, styles.textStyles];
            const statsStore = SessionStatsStore.sharedInstance();

            return <View style={styles.container}>
                {statsStore.isShowPowerEnabled ? <Stat
                    labelStyles={labelStyle}
                    valueStyle={textStyle}
                    label={__('session.running.power')}
                    value={this.props.hideEnergyAndPower ? '-' : formatPower(activeSession.power)}
                /> : null}
                {statsStore.isShowEnergyEnabled ? <Stat
                    labelStyles={labelStyle}
                    valueStyle={textStyle}
                    label={__('session.running.energy')}
                    value={this.props.hideEnergyAndPower ? '-' : formatEnergy(activeSession.energy)}
                /> : null}
                {statsStore.isShowDurationEnabled ? <Stat
                    labelStyles={labelStyle}
                    valueStyle={textStyle}
                    label={__('session.running.duration')}
                    value={this.props.hideDurationAndAmount ? '-' : humanizeDuration(activeSession.totalDuration, true)}
                /> : null}
                {!activeSession.isPersonal && statsStore.isShowAmountEnabled ? <Stat
                    labelStyles={labelStyle}
                    valueStyle={amountTextStyle}
                    label={__('session.running.amount')}
                    value={this.props.hideDurationAndAmount ? '-' : activeSession.currency ? format(activeSession.totalAmount, activeSession.currency) : '-'}
                    hasAsterisk={!this.props.hideDurationAndAmount && activeSession.currency && (activeSession.isOptimized || activeSession.isUsingChargingAllowance)}
                /> : null}

        </View>; }}</ThemeConsumer>;
    }

    renderLoading() {
        return <View style={{flex: 1}}><ActivityIndicator /></View>;
    }
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 25,
        marginTop: 28,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        flexWrap: 'wrap',
    },
    statBox: {
        alignItems: 'center',
        marginHorizontal: 3,
    },
    textStyles: {
        marginBottom: 10,
    },
    labelStyles: {
        marginBottom: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
});
