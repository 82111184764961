import React from 'react';
import {ToUPricePeriod, ToUPricePeriods} from '@ampeco/charge-models';
import Currency from '@ampeco/currency';
import { EnergyToUContext } from './EnergyToUContext';
import {EnergyToUTariff} from './EnergyToUTariff';
import {findCurrentPeriodIndex, groupPeriods} from './utils';
import {Location as LocationAPI} from '@ampeco/charge-api';

interface EnergyToUProviderProps {
  currency: Currency;
  isPersonalCP: boolean;
  evseId: string;
}

interface EnergyToUProviderState {
  touPricePeriods: ToUPricePeriods;
  isPricePeriodsLoading: boolean;
  currentPeriodIndex: number;
  groupedPeriods: ToUPricePeriod[][];
}

export class EnergyToUProvider extends React.Component<EnergyToUProviderProps, EnergyToUProviderState> {
  constructor(props: EnergyToUProviderProps) {
    super(props);

    this.state = {
      touPricePeriods: {
        granularity: 0,
        periods: [],
      },
      isPricePeriodsLoading: false,
      currentPeriodIndex: 0,
      groupedPeriods: [],
    }
  }
  async componentDidMount() {
     await this.loadPricePeriods();

     this.setState({
       currentPeriodIndex: findCurrentPeriodIndex(this.state.touPricePeriods.periods, this.state.touPricePeriods.granularity),
       groupedPeriods: groupPeriods(this.state.touPricePeriods.periods),
    });
  }

  async loadPricePeriods() {
    try {
      this.setState({
        isPricePeriodsLoading: true,
      });

      const touPricePeriods = await LocationAPI.getTOUPricePeriods(this.props.evseId) as ToUPricePeriods;

      this.setState({
        touPricePeriods,
      })
    } finally {
      this.setState({
        isPricePeriodsLoading: false,
      })
    }
  }

  render() {
    return (
      <EnergyToUContext.Provider value={{
        touPricePeriods: this.state.touPricePeriods,
        pricePeriodsLoading: this.state.isPricePeriodsLoading,
        isPersonalCP: this.props.isPersonalCP,
        groupedPeriods: this.state.groupedPeriods,
      }}>
        <EnergyToUTariff currency={this.props.currency} currentPeriodIndex={this.state.currentPeriodIndex}/>
      </EnergyToUContext.Provider>
    );
  }
}
