import Echo from 'laravel-echo';
import { getAuthenticationInfo, settings as netSettings } from '@ampeco/net';
import SocketIOClient from 'socket.io-client';

export async function broadcast(endpoint: string): Promise<Echo> {
    const auth = await getAuthenticationInfo();

    let headers: {[index: string]: string} = {};
    if (auth !== null) {
        headers = {Authorization: 'Bearer ' + auth.access_token};
    }
    headers['X-Endpoint'] = netSettings().endpoint;
    const broadcastInstance = new Echo({
        broadcaster: 'socket.io',
        auth: {
            headers,
        },
        host: endpoint,
        client: SocketIOClient,
        debug: true,
    });
    return broadcastInstance;
}
