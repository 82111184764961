import React, {useEffect} from 'react';
import {View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Button, Input, Text, ThemeConsumer} from 'react-native-elements';
import {__, LocaleStore} from '@ampeco/i18n';
import {HomeStackParamList} from '@ampeco/navigation';
import {Action, Error, Field, FormStore, FormStoreProvider} from '@ampeco/forms';
import {Profile} from '@ampeco/charge-api';
import AmpecoTheme from "@ampeco/theme";


type EmailReceiptProps = StackScreenProps<HomeStackParamList, 'EmailReceipt'>;

const store = new FormStore();

store.rules = {
    email: 'required|email|max:255',
};

const EmailReceipt: React.FC<EmailReceiptProps> = ({ navigation, route }) => {

    useEffect(() => {
        navigation.setOptions({title: __('email-receipt.title')});
    }, [LocaleStore.language]);
    useEffect(() => {
        store.actions.submit = async () => {
            try {
                await Profile.addEmailForAdHocCharging({email: store.form.email as string});
                successCallback();
            } catch (e) {
                store.lastRemoteError =  e.response.data.errors.email;
            }
        };
        store.actions.skip = async () => {
            try {
                await Profile.addEmailForAdHocCharging({sendEmail: false});
                skipCallback();
            } catch (e) {
                store.lastRemoteError =  e.response.data.errors.email;
            }
        };

        store.reset();
    }, []);

    const defaultSuccessCallback = () => navigation.goBack();
    const successCallback = route.params?.successCallback || defaultSuccessCallback;
    const skipCallback = route.params?.skipCallback || defaultSuccessCallback;

    return <ThemeConsumer<AmpecoTheme>>{
        ({theme}) => (
            <SafeAreaView>
                <View style={[theme.Container?.byPadding]}>
                    <Text style={{marginBottom: 20}}>
                        {__('email-receipt.description')}
                    </Text>
                    <FormStoreProvider store={store}>
                        <Field
                            type={Input}
                            placeholder={__('email-receipt.placeholder')}
                            textContentType="emailAddress"
                            autoCompleteType="email"
                            keyboardType="email-address"
                            name="email"
                            autoCapitalize="none"
                        />
                        <Error />
                        <Action
                            disableIfInvalid
                            type={Button}
                            name="submit"
                            title={__('email-receipt.continue')}
                            validateOnPress
                        />
                        <Action
                            buttonStyle={{backgroundColor: 'transparent'}}
                            titleStyle={[theme.EmailReceipt?.textStyle, theme.EmailReceipt?.clearButtonTextStyle, {backgroundColor: 'none'}]}
                            type={Button}
                            name="skip"
                            title={__('email-receipt.skip')}
                        />
                    </FormStoreProvider>
                </View>
            </SafeAreaView>
        )}</ThemeConsumer>;
};

export default EmailReceipt;
