import React, {ReactChildren, ReactNode} from 'react';
import {ActivityIndicator, StyleSheet, TextStyle, View} from 'react-native';
import {Button, Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {format} from '@ampeco/currency';
import {ButtonIcon} from '@ampeco/icons';
import {observer} from 'mobx-react';
import PaymentsStore from '../PaymentsStore';
import PaymentMethodSelect from '../PaymentMethodSelect';
import PaymentFailureNotice from '../screens/PaymentMethods/PaymentFailureNotice';
import {Session} from '@ampeco/charge-models';
import {PaymentMethod, SCAService} from '@ampeco/models';
import {LocationStore} from '@ampeco/charge-stores';
import {SCAServiceFactory} from '@ampeco/payment-methods';

interface Props {
    session: Session;
    paymentMethod?: PaymentMethod;
    onSessionChanged: (session?: any) => void;
    payEndpoint: (session_id: string, payment_method_id: string) => Promise<Session>;
    addPaymentMethodNavigation?: (screen: string, params: any) => void;
    stopReasonText?: string | null;
    onTopUpPress?: () => void;
    hasPaidPartially?: boolean;
    amountPaid?: number;
}
function StatsRow(props: { label: string, value: any, bold?: boolean, theme: any; }) {
    return <View style={styles.statsRow}>
        <Text style={[styles.statsLabel, { color: props.theme.Text.style.color }]}>{props.label}</Text>
        <Text style={[styles.statsValue, { ...props.theme.PayForSession.titleStyle }, { color: props.theme.Text.style.color }]}>{props.value}</Text>
    </View>;
}

const SessionStatsRow = (props: { label: string, children: ReactNode | ReactChildren | string, left: boolean, theme: { infoLabelStyle?: TextStyle, infoValueStyle?: TextStyle; }; }) => {
    return <View style={[styles.statBox, props.left ? { marginRight: 6 } : { marginLeft: 6 }]}>
        <Text style={{ ...styles.statTitle, ...props.theme.infoLabelStyle }}>{props.label}</Text>
        {typeof props.children === 'string' &&
          <Text style={{ ...styles.statValue, ...props.theme.infoValueStyle }}>{props.children}</Text>}
        {typeof props.children !== 'string' && <View style={styles.statValueContainer}>{props.children}</View>}
    </View>;
};

let scaService: SCAService | null = null;

@observer
class PayForSession extends React.Component<Props, { loading: boolean; corporateBillingAsDefaultPaymentMethod: string | null }> {
    state = {
        loading: false,
        corporateBillingAsDefaultPaymentMethod: null,
    };

    componentDidMount() {
        const locationStore = LocationStore.sharedInstance();
        locationStore.resolveEVSE(this.props.session.evseId).then(({location, evse}) => {
            if (evse?.corporateBillingAsDefaultPaymentMethod) {
                this.setState({
                    ...this.state,
                    corporateBillingAsDefaultPaymentMethod: evse.corporateBillingAsDefaultPaymentMethod,
                });
            }
        });

        if (this.props.session.scaPendingTransaction) {
            scaService = SCAServiceFactory.get(this.props.session.scaPendingTransaction);
        }
    }

    render() {
        const paymentStore = PaymentsStore.sharedInstance();
        const amountPaid = this.props.session.totalAmount - this.props.session.amountDue;
        const hasPaidPartially = amountPaid > 0 && amountPaid < this.props.session.totalAmount;
        const isScaRequired = scaService?.requiresSCA();

        return <ThemeConsumer>{({ theme }) => {
            const payForSessionTheme = theme.PayForSession || { payNowIcon: undefined };

            return (<View style={styles.masterContainer}>
                <Text style={{ ...styles.title, ...payForSessionTheme.titleStyle }}>
                    {this.props.stopReasonText
                      ? __('pay-for-session.session-stopped')
                      : paymentStore.allowPaymentMethod
                        ? (isScaRequired ? __('pay-for-session.sca-title') : __('pay-for-session.title'))
                        : __('pay-for-session.balance-title')
                    }
                </Text>
                <Text style={{ ...styles.description, ...payForSessionTheme.descriptionStyle }}>
                    {this.props.stopReasonText ? this.props.stopReasonText + '\n\n' : ''}
                    {this.props.session.failureReason && this.props.session.failureReason + '\n\n'}
                    {paymentStore.allowPaymentMethod
                      ? this.props.session.paymentStatus === 'failed'
                        ? (isScaRequired ? __('pay-for-session.sca-description') : __('pay-for-session.description'))
                        : __('pay-for-session.partially-paid')
                      : __('pay-for-session.balance-description')
                        .replace('{amount_due}', format(this.props.session.amountDue, this.props.session.currency))
                    }
                </Text>
                {hasPaidPartially && <View style={{ marginBottom: 20 }}><StatsRow theme={theme} label={__('session.summary.amount-paid')}
                                                                                  value={format(amountPaid || 0, this.props.session.currency)} /></View>}
                <View style={styles.container}>
                    <SessionStatsRow
                      label={__('pay-for-session.amount-due')}
                      left={true}
                      theme={payForSessionTheme}
                    >
                        {format(this.props.session.amountDue, this.props.session.currency)}
                    </SessionStatsRow>
                    {paymentStore.allowPaymentMethod && <SessionStatsRow
                      label={__('pay-for-session.payment-method')}
                      left={false}
                      theme={payForSessionTheme}
                    >
                        <PaymentMethodSelect
                          addPaymentMethodNavigation={this.props.addPaymentMethodNavigation}
                          currency={this.props.session.currency}
                          amount={this.props.session.amountDue}
                          evseId={this.props.session.evseId}
                          corporateBillingAsDefaultPaymentMethod={this.state.corporateBillingAsDefaultPaymentMethod}
                          paymentAddedCallback={this.props.onSessionChanged}
                          selectWidth={'100%'}
                        />
                    </SessionStatsRow>}
                </View>

                <PaymentFailureNotice style={{ marginTop: 10 }} />

                {paymentStore.allowPaymentMethod && <Button
                  disabled={this.state.loading}
                  title={__('pay-for-session.pay-now')}
                  style={theme.PayForSession.buttonStyle}
                  icon={this.state.loading
                    ? <ActivityIndicator
                      color={theme.Text && theme.Text.style && theme.Text.style.color || undefined} />
                    : <ButtonIcon source={payForSessionTheme.payNowIcon} />
                  }
                  onPress={this.onPayNowPress}
                />}
                {!paymentStore.allowPaymentMethod && this.props.onTopUpPress && <Button
                  disabled={this.state.loading}
                  title={__('pay-for-session.top-up-balance')}
                  icon={this.state.loading
                    ? <ActivityIndicator
                      color={theme.Text && theme.Text.style && theme.Text.style.color || undefined} />
                    : <ButtonIcon source={payForSessionTheme.payNowIcon} />
                  }
                  onPress={this.props.onTopUpPress}
                />}
            </View>);
        }}</ThemeConsumer>;
    }

    onPayNowPress = async () => {
        const paymentStore = PaymentsStore.sharedInstance();
        paymentStore.hideLastPaymentFailure();
        this.setState({ ...this.state, loading: true });
        try {
            const paymentMethodId = paymentStore.lastPaymentMethod ? paymentStore.lastPaymentMethod.id : null;
            const session = await this.props.payEndpoint(this.props.session.id, paymentMethodId);

            scaService?.setTransaction(session.scaPendingTransaction);
            if (scaService?.requiresSCA()) {
                await scaService?.openChallenge();
            }

            if (session.paymentStatus === 'failed') {
                paymentStore.showLastPaymentFailure(session.failureReason);
            } else if (session.paymentStatus === 'partially_paid' || session.paymentStatus === 'pending') {
                paymentStore.showLastPaymentFailure(__('pay-for-session.add_voucher'));
            }
            this.props.onSessionChanged(session);
        } catch (e) {
            // tslint:disable-next-line:prefer-conditional-expression
            if (e.response && e.response.data) {
                paymentStore.showLastPaymentFailure(e.response.data.message);
            } else {
                paymentStore.showLastPaymentFailure(__('pay-for-session.attempt-to-pay-failed'));
            }
        } finally {
            this.setState({ ...this.state, loading: false });
        }
    };

}

export default PayForSession;

const styles = StyleSheet.create({
    masterContainer: {
        marginTop: 28,
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
    },
    description: {
        fontSize: 14,
        marginBottom: 30,
    },
    statBox: {
        alignItems: 'flex-start',
        flex: 1,
    },
    statTitle: {

        fontSize: 12,
        marginBottom: 7,
    },
    statValue: {
        fontSize: 36,
        lineHeight: 42,
    },
    statValueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    statsRow: {
        flexDirection: 'row',
        color: 'white',
    },
    statsLabel: {
        textAlign: 'right',
        fontSize: 16,
        flex: 1,
        marginRight: 6,
        lineHeight: 15,
    },
    statsValue: {
        fontSize: 16,
        flex: 1,
        marginLeft: 6,
        lineHeight: 15,
    },
});
