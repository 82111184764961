import moment from 'moment';
import {computed, observable} from 'mobx';

export class CountdownTimer {

    @observable remainingSeconds: number;

    protected name?: string
    protected toTime: moment|(() => moment);
    protected interval: NodeJS.Timeout | undefined;
    protected stopCallback?: () => void;
    public constructor(toTime: moment|(() => moment), name?: string, stopCallback?: () => void) {
        this.toTime = toTime;
        this.remainingSeconds = 0;
        this.name = name;
        this.stopCallback = stopCallback;
    }

    public start()
    {
        let seconds = this.getRemainingSeconds();
        seconds --;
        if (seconds > 0){
            this.remainingSeconds = seconds;
        }
        this.interval = setInterval(() => {
            let seconds = this.getRemainingSeconds();
            seconds --;

            if (seconds <= 0) {
                this.remainingSeconds = 0;
                this.stop();

                if (this.stopCallback) {
                    this.stopCallback();
                }

                return;
            }

            this.remainingSeconds = seconds;
        }, 1000);
    }

    public stop() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    protected getRemainingSeconds()
    {
        const toTime = (typeof this.toTime === 'function') ? this.toTime() : this.toTime;
        if (toTime === undefined || toTime === null){
            return 0;
        }

        const timeEnd = moment.utc(toTime);
        const currentTime = moment().utc();
        const diff = timeEnd.diff(currentTime);
        return Math.round(diff / 1000);
    }

    @computed get remainingMinutes()
    {
        return Math.round(this.remainingSeconds / 60);
    }
}
