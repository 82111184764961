import {Profile} from '@ampeco/charge-api';
import {LocaleStore} from "../../frontend_modules/i18n/index";
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Select from 'react-select';
import fonts, {FontWeight} from "../res/fonts";
import RNRestart from "react-native-restart";

interface Props {
    shouldPersist?: boolean;
    callback?: (locale: string) => void;
}

@observer
export default class SelectLanguage extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render(){
        const localeStore = LocaleStore.sharedInstance();

        const options: { value: string; label: string; }[] = [];
        Object.entries(localeStore.languages).forEach((val) => {
            options.push({value: val[0], label: val[0].toUpperCase()});
        });

        const selected = options.find((o) => o.value === localeStore.language);
        const styles = {
            option: (provided: any, state: { isSelected: any; }) => ({
                ...provided,
                color: '#000',
                backgroundColor: state.isSelected ? '#949393' : 'white',
                fontSize: 18,
                fontWeight: FontWeight.light,
                fontFamily: fonts.light,
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                width: 40
            }),
            control: (provided: any) => ({
               ...provided,
               border: 'none'
            }),
            singleValue: (provided: any) => ({
                ...provided,
                fontSize: 18,
                fontWeight: FontWeight.light,
                fontFamily: fonts.light,
            })
        }

        return (<Select
            options={options}
            searchable={false}
            styles={styles}
            onChange={async (selected: { value: any; }) => {
                const langValue = selected.value;
                localeStore.setLanguage(langValue);
                localStorage.setItem('user-language', langValue);

                setTimeout(() => RNRestart.Restart(), 200);

                if (this.props.callback) {
                    this.props.callback(langValue);
                }

                if (this.props.shouldPersist) {
                    try {
                        await Profile.updateLocale(langValue);
                    } catch (e) {
                        console.log('could not persist the language');
                    }
                }
            }}
            value={[selected]}
        />)
    }
}
