import { __ } from '@ampeco/i18n';
import Config from 'react-native-config';
// @ts-ignore
import { facebook as fb, google as gg } from 'react-native-simple-auth';
import TPAAPI from './api/third_party_authentiction';
import Alert from '@ampeco/alert';

let profileStore: ProfileProviderStore;

export let passwordChangeHandler: () => void;

export interface AuthProfile {
    authentications: string[];
}

export interface ProfileProviderStore {
    profile: AuthProfile|null;
    thirdPartyAuthentications: {[index: string]: any};
    reloadProfile(): void;
}

export class Authenticated {
    private readonly doLogin: () => Promise<boolean>;
    private readonly doLink: () => Promise<boolean>;

    constructor(doLogin: () => Promise<boolean>, doLink: () => Promise<boolean>) {
        this.doLogin = doLogin;
        this.doLink = doLink;
    }

    async login() {
        return this.doLogin();
    }

    async link() {
        return this.doLink();
    }
}

export function init(store?: ProfileProviderStore, passwordHandler?: () => void) {
    if (store !== undefined) {
        profileStore = store;
    }
    if (passwordHandler !== undefined) {
        passwordChangeHandler = passwordHandler;
    }
}

export async function unlink(type: string, force: boolean = false) {
    const passwordLinked = profileStore.profile && profileStore.profile.authentications && profileStore.profile.authentications.includes('password');
    if (!passwordLinked && !force) {
        Alert.sharedInstance().show(__('profile.accessWarning.title'), __('profile.accessWarning.message'),
            [
                {text: __('profile.accessWarning.setPassword'), style: 'cancel', onPress: passwordChangeHandler },
                {
                    text: __('profile.accessWarning.forceUnlink'),
                    style: 'destructive',
                    onPress: () => {
                        unlink(type, true);
                    },
                },
            ],
            {cancelable: false},
        );
        return;
    }
    await TPAAPI.unlink(type);
    await profileStore.reloadProfile();
}

export async function facebook(): Promise<Authenticated> {
    if (!profileStore.thirdPartyAuthentications.facebook) {
        return new Authenticated(async () => true, async () => true);
    }

    const facebookConfig = {
        appId: profileStore.thirdPartyAuthentications.facebook.app_id + '',
        callback: 'fb' + profileStore.thirdPartyAuthentications.facebook.app_id + '://authorize',
        scope: 'public_profile,email', // you can override the default scope here
    };

    const info = await fb(facebookConfig);

    return new Authenticated(async () => {
        const res = await TPAAPI.login(info.credentials.access_token, 'facebook');

        await profileStore.reloadProfile();

        return res;
    }, async () => {
        const res = await TPAAPI.link(info.credentials.access_token, 'facebook');

        await profileStore.reloadProfile();
        return res;
    });

}

export async function google(): Promise<Authenticated> {
    if (!profileStore.thirdPartyAuthentications.google) {
        return new Authenticated(async () => true, async () => true);
    }

    const googleConfig = {
        appId: profileStore.thirdPartyAuthentications.google.app_id + '',
        callback: Config.BUNDLE_ID + '.google.login' + ':/oauth2redirect',
        // scope: 'public_profile,email', // you can override the default scope here
    };

    const info = await gg(googleConfig);
    console.log(info);

    return new Authenticated(async () => {
        const res = await TPAAPI.login(info.credentials.access_token, 'google');

        await profileStore.reloadProfile();
        console.log(res);
        return res;
    }, async () => {
        const res = await TPAAPI.link(info.credentials.access_token, 'google');

        console.log(res);
        await profileStore.reloadProfile();
        return res;
    });

}
