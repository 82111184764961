import axios from 'axios';
import DevEndpointStore from './DevEndpointStore';

export async function discoverDeploys() {
    return (await axios.get(DevEndpointStore.sharedInstance().discoverEndpoint)).data as string[];
}

export async function discoverEntryPoints(deploy: string) {
    return (await axios.get(deploy + DevEndpointStore.sharedInstance().listInstancesPath)).data as string[];
}
