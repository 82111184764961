import Echo from 'laravel-echo';
import BroadcastService, {BroadcastSettings} from '@ampeco/broadcast';
import {reaction} from 'mobx';
import {PaymentTransaction} from '@ampeco/models';
import {PaymentsStore} from '@ampeco/payment-methods';

type TopUpPackageScaHandledPayload = {
  paymentTransaction: PaymentTransaction;
}

class TopUpPackageChangesMonitorClass{

  private echo?: Echo;

  private globalStore: any;
  private setting!: () => BroadcastSettings;

  private subscribed?: string;

  init(globalStore: any, setting: () => BroadcastSettings) {
    this.globalStore = globalStore;
    this.setting = setting;
    reaction(() => BroadcastService.sharedInstance(this.setting).connected && this.globalStore.profile !== null, connected => {

      if (connected) {
        this.subscribe();

      } else {
        this.unsubscribe();
      }

    }, {name: 'TopUpPackage changes monitor'});
  }

  async subscribe() {
    if (this.subscribed) {
      return false;
    }
    const channelName = this.channelName();
    this.subscribed = channelName;

    this.echo = await BroadcastService.sharedInstance(this.setting).connect();

    const channel = this.echo.private(channelName);
    channel.listen('.top-up-package.sca-handled', this.onTopUpPackageScaHandled.bind(this));

    console.log('Echo: Subscribed to TopUpPackages channel ' + channelName);
  }

  unsubscribe() {
    if (!this.subscribed) {
      return;
    }
    this.echo && this.echo.leave(this.subscribed);
    this.echo && console.log('Echo: Unsubscribed from ' + this.subscribed);
    this.subscribed = undefined;
  }

  private onTopUpPackageScaHandled(payload: TopUpPackageScaHandledPayload) {
    PaymentsStore.sharedInstance().setPaymentTransactionScaSucceeded(payload.paymentTransaction.status === 'finalized');
  }

  private channelName(): string {
    const service = BroadcastService.sharedInstance(this.setting);
    if (this.globalStore.profile === null || this.globalStore.profile.id === null) {
      throw new Error('Cannot resolve channel name - no profile logged in');
    }
    return `${service.channelPrefix()}.user.${this.globalStore.profile.id}`;
  }
}

export const TopUpPackageChangesMonitor = new TopUpPackageChangesMonitorClass();
