import React, {useCallback} from 'react';
import {slide as Burger} from 'react-burger-menu';
import {Route, Switch} from 'react-router-dom';
import {getRoutes} from '../../Routes';
import NotFound from '../../pages/NotFound/NotFound';
import {useLocation} from 'react-router';
import {observer} from 'mobx-react';
import MenuItem from './MenuItem';
import {icons} from '../../res/images';
import {Divider} from 'react-native-elements';
import {TermsStore} from '@ampeco/terms';
import GlobalStore from '../../stores/GlobalStore';
import {__, LocaleStore} from '@ampeco/i18n';
import ErrorBoundary from '../Common/ErrorBoundary';
import Header from './Header';
import colors from '../../res/colors';
import {View, Text} from 'react-native';
import {MenuItemsStore} from "@ampeco/charge-stores";
import settings from "../../settings";

const Menu = observer(() => {
    const globalStore = GlobalStore.sharedInstance();
    const menuItemsStore = MenuItemsStore.sharedInstance();

    const routes = getRoutes();
    const location = useLocation();

    const showMap = useCallback((event: { preventDefault: () => void; }) => {
        event.preventDefault();
        globalStore.closeMenu()
    }, []);

    const termsStore = TermsStore.sharedInstance();
    const lang = LocaleStore.language;
    const isRTL = LocaleStore.isRTL(lang);

    const isMenuOpen = (state: any) => {
        if (globalStore.isMenuOpen !== state.isOpen) {
            globalStore.isMenuOpen = state.isOpen;
        }
        return state.isOpen;
    };
    const burgerStyles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '20px',
            height: '15px',
            top: '14px',
            ...(isRTL ? {right: '14px'} : {left: '14px'}),
        },
        bmBurgerBars: {
            background: colors.menuBarsColor
        },
        bmBurgerBarsHover: {
            background: 'blue'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: 'white',
            marginLeft: '-10px',
            display: 'none',
        },
        bmMenuWrap: {
            position: 'fixed',
            top: 44,
            bottom: 0,
            height: 'auto',
            ...(isRTL ? {transform: 'translate3d(100%, 0px, 0px'} : {transform: 'translate3d(-100%, 0px, 0px'}),
        },
        bmMenu: {
            background: colors.backgroundColor,
            paddingTop: '35px',
            fontSize: '16px',
        },
        bmMorphShape: {
            fill: '#373a47',
        },
        bmItemList: {
            color: '#ffffff',
            padding: '0.1em',
            justifyContent: 'flex-start',
        },
        bmItem: {
            listStyleType: 'none',
            flex: 1,
            a: {
                textDecoration: 'none',
                color: 'white!important',
            },
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)',
        },
    };
    return (
        <Burger
            right={isRTL}
            isOpen={globalStore.isMenuOpen} onStateChange={isMenuOpen} noOverlay
            styles={burgerStyles}
            pageWrapId={"page-wrap"} outerContainerId={"outer-container"}
        >
            {
                location.pathname === '/' && <View style={styles.container}>
                    {globalStore.isLoggedIn && <Header profile={globalStore.profile || undefined}/>}
                    {globalStore.isLoggedIn ? menuItemsStore.isItemVisible('balance') && <MenuItem
                            id="balance"
                            text={__('menu.items.balance')}
                            icon={icons.receipts}
                            className="menu-item"
                            route="Balance"
                            disclosure
                        /> : null}
                    {globalStore.isLoggedIn ? <Divider style={styles.headerDivider}/> : null}
                    <MenuItem
                        onPress={showMap} icon={icons.mapTabBar} id="map" className="menu-item" route="Home"
                        text={__('menu.items.map')}
                    />
                    {globalStore.isLoggedIn &&
                    <MenuItem
                        id="history"
                        text={__('menu.items.history')}
                        icon={icons.receipts}
                        className="menu-item"
                        route="History"
                    />}
                    {globalStore.isLoggedIn && <MenuItem
                        icon={icons.favorites} id="favorites" className="menu-item" route="Favorites"
                        text={__('menu.items.favorites')}
                    />}
                    {globalStore.isLoggedIn && menuItemsStore.isItemVisible('vouchers') && <MenuItem
                        icon={icons.redeem} id="voucher" className="menu-item" route="RedeemVoucher"
                        text={__('menu.items.redeem')}
                    />}
                    {globalStore.isLoggedIn && <MenuItem
                        icon={icons.settings} id="settings" className="menu-item" route="Settings"
                        text={__('menu.items.settings')}
                    />}
                    <MenuItem
                        icon={icons.info} id="info" className="menu-item" route="InfoCenter"
                        text={__('menu.items.help-center')}
                    />
                    {termsStore.terms
                        .filter(term => (term.urls && term.title && term.urls[lang]))
                        .map((term: any) => {
                            return <MenuItem
                                key={term.title}
                                icon={icons.terms}
                                id="terms"
                                className="menu-item"
                                route="Terms"
                                state={{url: term.urls[lang], title: term.title}}
                                text={term.title}
                            />;
                        })}
                    <Divider />
                    {globalStore.isLoggedIn
                        ? <MenuItem
                            icon={icons.logout} id="logout" className="menu-item" route="Home" text={__('menu.logout')}
                            onPress={() => globalStore.unsetProfile()}
                        />
                        : <MenuItem
                            icon={icons.login} id="login" className="menu-item" route="Login"
                            text={__('menu.login-or-register-button')}
                        />
                    }
                </View>
            }

            <main className="content">
                <ErrorBoundary>
                    {location.pathname !== '/' && <View>
                        <Switch>{
                            routes.map((route) => {
                                const {path, component, name: id} = route;
                                return (
                                    <Route key={id} exact path={path} component={component}/>
                                );
                            })
                        }<Route path="*" component={NotFound} status={404}/>
                        </Switch>
                    </View>}
                </ErrorBoundary>
            </main>
            <View style={styles.versionContainer}>
                <Text style={styles.version}>v{settings.appVersion}</Text>
            </View>
        </Burger>
    );
});
export default Menu;


const styles = {
    container: {
        marginRight: 14,
        marginLeft: 25,
        paddingBottom: 25,
    },
    headerDivider: {
        marginBottom: 20,
    },
    versionContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        padding: 5,
        backgroundColor: colors.backgroundColor
    },
    version: {
        color: colors.textColor,
        textAlign: 'center',
        fontSize: 10,
    }
};
