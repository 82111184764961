import React from 'react';

interface Props {
    index: number,
    routes: {key: string, title: string}[],
    handleIndexChange: (index: number) => void,
    renderScene: ({route}: any) => React.ReactNode,
    hideTabs?: boolean
}
interface State {}

export default class TabViewComponent extends React.PureComponent<Props, State> {
    render () {
        return null;
    }
}
