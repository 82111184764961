import {observable} from "mobx";
import {ChargePoint} from "../models/ChargePoint";
import {getChargePoint} from "../api/displays";

let instance: DisplayStore | null = null;

export default class DisplayStore {
    @observable chargePoint: ChargePoint

    static sharedInstance(): DisplayStore {
        if (instance === null) {
            instance = new DisplayStore();
        }
        return instance;
    }

    async loadFromBackend(identifier: string): Promise<any> {
        const connectorOrdering = window.initialConfig?.additional?.displays?.connectorOrdering;

        const response = await getChargePoint(identifier, connectorOrdering);

        if (response?.data) {
            this.chargePoint = response.data;
        }
    }

    async refreshFromBacked(identifier: string): Promise<any> {
        if (this.chargePoint?.networkId === identifier) {
            await this.loadFromBackend(identifier);
        }
    }
}
