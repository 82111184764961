import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import NavigationService from '@ampeco/navigation';
import GlobalStore from "../../stores/GlobalStore";
import {RedeemVoucherForm} from "@ampeco/vouchers";
import {ButtonIcon} from "@ampeco/icons";
import {icons} from "../../res/images";
import {ProfileStore} from '@ampeco/charge-stores';
import {BalanceHistoryStore} from "@ampeco/balance";
import {useLocation} from "react-router";
import {ModulePage} from "../ModulePage";

function RedeemVoucherScreen(): JSX.Element {
    const locationState = useLocation<{code?:string}>();

    return <ModulePage
        title={'redeem.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home')
        }}
        moduleName='vouchers'
    >
        <SafeAreaProvider>
            <RedeemVoucherForm
                code={locationState.state?.code}
                actionIcon={<ButtonIcon style={styles.iconStyle} source={icons.add}/>}
                isLoggedIn={ProfileStore.sharedInstance().isLoggedIn}
                callback={() => {
                    NavigationService.sharedInstance().navigate('Home')
                    BalanceHistoryStore.sharedInstance().load();
                    GlobalStore.sharedInstance().statusUpdate();
                }}
            />
        </SafeAreaProvider>
    </ModulePage>;
}

export default RedeemVoucherScreen;

const styles = {
    iconStyle: {
        width: 21,
        height: 21,
    },
};
