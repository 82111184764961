import React from 'react';
import {StyleSheet, View} from 'react-native';
import {InputProps, ThemeConsumer} from 'react-native-elements';
import {FieldInputProps} from '@ampeco/forms';
import DatePicker from 'react-date-picker';
import AmpecoTheme from '@ampeco/theme';
import moment from 'moment';

export type DatePickerProps = {
    value: any;
    onChange: (value: any) => void;
    errorMessage: string;
    editable: boolean;
    iconComponent?: React.Component;
    confirmBtnText: string;
    cancelBtnText: string;
    minDate?: Date;
    maxDate?: Date;
    format?: string;
    placeholder?: string;
} & FieldInputProps & InputProps;

type InputDate = string;

const toDate = (format: string, date: Date) => {
    return moment(date).format(format);
}

const toDateObject = (format: string, date?: InputDate) => {
    if (!date) {
        return new Date();
    }
    return moment(date, format).toDate();
}

export const DatePickerAdapter = (props: DatePickerProps) => {
    const {
        onChange,
        value,
        placeholder,
        iconComponent,
    } = props;

    const format = props.format || 'YYYY-MM-DD';

    const date = toDateObject(format, value);

    const containerStyle = StyleSheet.flatten([styles.container, props.containerStyle]);

    return <ThemeConsumer<AmpecoTheme>>{({theme}) => (
        <View style={containerStyle}>
            <DatePicker
                showLeadingZeros={false}
                onChange={(newDate: Date) => {
                    onChange(toDate(format, newDate));
                }}
                format={'y-MM-dd'}
                clearIcon={null}
                style={[styles.timePicker]}
                value={date}
                minDate={props.minDate}
                maxDate={props.maxDate}
            />
        </View>
    )}</ThemeConsumer>;
};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    timePicker: {
        width: '100%',
        color: 'white',
    },
    inputStyle: {
        height: 54,
        justifyContent: 'center',
    },
    iconStyle: {
        marginLeft: 0,
    },
});
