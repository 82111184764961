import React, { useEffect, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeModalParamList } from '@ampeco/navigation';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { ThemeConsumer, Button } from 'react-native-elements';
import PaymentsStore from '../PaymentsStore';
import { Action, FormStore, FormStoreProvider } from '@ampeco/forms';
import AmpecoTheme from '@ampeco/theme';
import { PaymentFailureNotice } from '../index';
import { __ } from '@ampeco/i18n';
import {View} from "react-native";

type AddPaymentMethodProps = StackScreenProps<
    HomeModalParamList,
    'AddPaymentMethodNative'
>;
const store = new FormStore();

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [complete, setComplete] = useState(false);
    store.actions.handleSubmit = async () => {
        if (elements == null) {
            return;
        }

        const { error, paymentMethod } = await stripe?.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        const paymentsStore = PaymentsStore.sharedInstance();
        if (paymentsStore.onAddPaymentMethodCloseHandler) {
            paymentsStore.paymentMethodId = paymentMethod?.id;
            paymentsStore.onAddPaymentMethodCloseHandler(true);
        }
        setComplete(false);
    }

    return (
        <ThemeConsumer<AmpecoTheme>>{({theme}) => (
            <View  style={{marginTop: 20}}>
                <FormStoreProvider store={store}>
                    <CardElement
                        id="payment-element"
                        options={{
                            hidePostalCode: true,
                            style: {
                                base: {
                                    color: theme.AddPaymentMethod.native.inputs.color,
                                    iconColor: theme.AddPaymentMethod.native.inputs.color
                                },
                            },
                        }}
                        onChange={(cardDetails) => {
                            if (cardDetails.complete) {
                                setComplete(true);
                            }
                        }} />
                    <PaymentFailureNotice />
                    <Action
                        style={{marginTop: 40}}
                        disabled={!complete} name='handleSubmit' type={Button}  title={__('payment_methods.add')}
                    />
                </FormStoreProvider>
            </View>
        )}</ThemeConsumer>
    );
};

const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({ navigation, params }) => {

    const form = new FormStore();
    const paymentsStore = PaymentsStore.sharedInstance();

    const stripe = paymentsStore.paymentMethodProcessor;

    useEffect(() => {
        paymentsStore.hideLastPaymentFailure()
    }, []);

    return (<ThemeConsumer<AmpecoTheme>>{({ theme }) => {
        return <FormStoreProvider store={form}>
            <Elements
                stripe={stripe}
            >
                <CheckoutForm />
            </Elements>
        </FormStoreProvider>
    }}
    </ThemeConsumer>
    );
};

export default AddPaymentMethod;
