import {__} from '@ampeco/i18n';
import WarningNotice from './WarningNotice';
import React from 'react';

export default (props: { component: string, onPress?: () => void, inverse?: boolean}) => {
    return <WarningNotice
        onPress={props.onPress}
        text={__('verify.notice').replace('{component}', props.component)}
    />;
};
