import React from 'react';
import { StyleSheet, View } from 'react-native';
import { __, LocaleStore } from '@ampeco/i18n';
import { observer } from 'mobx-react';
import { Notices as NoticeComponents } from '@ampeco/components';
import {PinsStore, ProfileStore, ReservationStore, SubscriptionPlanStore} from '@ampeco/charge-stores';
import ActiveReservationHint from './ActiveReservationHint';
import { Flow } from '@ampeco/charge-flow';
import { GlobalStoreProxy } from '@ampeco/charge-globalstore';
import Navigation from '@ampeco/navigation';
import {FlowStore} from '@ampeco/charge-stores';

interface Props {
    hasUserPosition: boolean;
    filterCallback?: (notice: JSX.Element) => boolean;
    isOnMap?: boolean;
}

@observer
export default class Notices extends React.Component<Props> {
    render() {
        const globalStoreProxy = GlobalStoreProxy.sharedInstance();
        const profileStore = ProfileStore.sharedInstance();
        const navigationService = Navigation.sharedInstance();
        const pinsStore = PinsStore.sharedInstance();

        LocaleStore.observeLanguage();

        let notices = [];

        if (profileStore.isLoggedIn) {
            if (!Flow.isAccountCompleted) {
                const subscriptionStore = SubscriptionPlanStore.sharedInstance();

                if (Flow.isPartnerRequired || !Flow.profileComplete) {
                    notices.push(<NoticeComponents.WarningNotice
                        onPress={() => {
                            FlowStore.isFlowClosed = false;
                            globalStoreProxy.flow.navigate();
                        }}
                        text={__('notice.complete-account')}
                        onMap={this.props.isOnMap ?? true}
                        key={'ProfileNotCompleteNotice'}
                    />);
                } else if (Flow.requiresPaymentMethod && profileStore.emailVerified && profileStore.phoneVerified) {
                    notices.push(<NoticeComponents.WarningNotice
                        onPress={() => globalStoreProxy.flow.navigate()}
                        text={__('notice.add-payment-method')}
                        onMap={this.props.isOnMap ?? true}
                        key={'AddPaymentMethodNotice'}
                    />);
                } else if (Flow.requiresSubscription && subscriptionStore.showNotice && !subscriptionStore.currentSubscription) {
                    notices.push(<NoticeComponents.WarningNotice
                        onPress={() => navigationService.navigate('SubscriptionPlans')}
                        text={__('notice.no-active-subscription')}
                        onMap={true}
                        key={'NoActiveSubscriptionNotice'}
                    />);
                }
            }

            const session = profileStore.unpaidSession;
            if (session && !session.isPersonal && (session.paymentStatus === 'failed' || session.paymentStatus === 'partially_paid')) {
                notices.push(<NoticeComponents.PaymentFailedNotice
                    onPress={() => navigationService.navigate('SessionSummary', {
                        session,
                        dismissButton: true,
                    })}
                    session={session}
                    onMap={true}
                    key={'PaymentFailedNotice'}
                />);
            }

            if (ReservationStore.sharedInstance().activeReservation) {
                notices.push(<ActiveReservationHint key={'ActiveReservationHintNotice'} />);
            }

            if (Flow.profileComplete && !profileStore.emailVerified) {
                notices.push(<NoticeComponents.ProfileComponentNotVerified
                    component={__('verify.email')}
                    onPress={() => navigationService.navigate('UserVerify', {
                        type: 'email',
                        component: __('verify.email'),
                        next: 'UserVerified',
                        onSuccess: () => {
                            const store = GlobalStoreProxy.sharedInstance();
                            if (store.profile) {
                                store.profile.email_verified_at = 'true';
                            }
                        },
                        reactionExpressionToNavigateNext: () => GlobalStoreProxy.sharedInstance().profile && GlobalStoreProxy.sharedInstance().profile?.email_verified_at,
                    })}
                    key={'ProfileComponentNotVerifiedEmailNotice'}
                />);
            }
            if (Flow.profileComplete && !profileStore.phoneVerified) {
                notices.push(<NoticeComponents.ProfileComponentNotVerified
                    component={__('verify.phone')}
                    onPress={() => navigationService.navigate('UserVerify', {
                        type: 'phone',
                        component: __('verify.phone'),
                        next: 'UserVerified',
                        onSuccess: () => {
                            const store = GlobalStoreProxy.sharedInstance();
                            if (store.profile) {
                                store.profile.phone_verified_at = 'true';
                            }
                        },
                        reactionExpressionToNavigateNext: () => GlobalStoreProxy.sharedInstance().profile && GlobalStoreProxy.sharedInstance().profile?.phone_verified_at,
                    })}
                    key={'ProfileComponentNotVerifiedPhoneNotice'}
                />);
            }
        }

        if (pinsStore.showZoomInNotice) {
            notices.push(<NoticeComponents.WarningNotice
                text={__('notice.zoom-in-for-more-pins')}
                onMap={true}
                key={'ZoomInForMorePinsNotice'}
            />);
        }
        if (notices.length === 0) {
            return <></>;
        }

        if (this.props.filterCallback) {
            notices = notices.filter(this.props.filterCallback);
        }

        return <View style={[styles.container, { right: this.props.hasUserPosition ? 16 + 35 + 16 : 16 }]}>
            {notices.map((notice, index) => (
                <React.Fragment key={index}>
                    {notice}
                </React.Fragment>
            ))}
        </View>;
    }
}

const styles = StyleSheet.create({
    container: {
        bottom: 20,
        position: 'absolute',
        left: 16,
    },
});
