import React from 'react';
import {Button, Text, ThemeConsumer} from 'react-native-elements';
import {__, LocaleStore} from '@ampeco/i18n';
import {ActivityIndicator, Image, StyleSheet, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {ChargingPeriod, EVSE, PriceTypes, Session, Tariff} from '@ampeco/charge-models';
import {LocationStore, SessionStartStore, SubscriptionPlanStore} from '@ampeco/charge-stores';
import {observer} from 'mobx-react';
import {ToasterStore} from '@ampeco/toaster';
import {Address, CarChargeAnimation, GenericChargeAnimation, SessionStatsComponent} from './index';
import {SessionChangesMonitorProxy, SessionMock, SessionPollingService} from '@ampeco/charge-services';
import {ButtonIcon} from '@ampeco/icons';
import {Session as SessionAPI} from '@ampeco/charge-api';
import {ChargeTheme} from '@ampeco/theme';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {humanizeDuration} from '@ampeco/humanize-duration';
import {IReactionDisposer, reaction} from 'mobx';
import Currency, {format} from '@ampeco/currency';
import {confirm, CountdownTimer, formatEnergy} from '@ampeco/utils';

type SessionRunningProps = {};
@observer
export class SessionRunning extends React.Component<SessionRunningProps, {loading: boolean, waitingToUnplug: boolean;}> {

    protected waitingForStartChargingCountdown?: CountdownTimer;
    protected vehicleParkedReaction: IReactionDisposer | null = null;
    protected chargingDoneAndWaitingForVehicleToLeaveReaction: IReactionDisposer | null = null;
    protected chargingDoneAndWaitingForVehicleToLeaveCountdown?: CountdownTimer;
    protected sessionPollingService?: SessionPollingService | undefined;

    constructor(props: SessionRunningProps) {
        super(props);
        this.state = {
            loading: false,
            waitingToUnplug: false,
        };
    }

    protected getLastGracePeriodEnd()
    {
        const activeSession = GlobalStoreProxy.sharedInstance().activeSession;

        if (!activeSession || !activeSession.lastPeriod) {
            return null;
        }
        if (!activeSession.lastPeriod.graceTimeEnd) {
            return null;
        }

        return activeSession.lastPeriod.graceTimeEnd;
    }

    componentDidMount() {
        this.vehicleParkedReaction = reaction(
          () => SessionStartStore.sharedInstance().vehicleParked,
          (isParked) => {
              if (!isParked) {
                  return;
              }
              if (this.waitingForStartChargingCountdown) {
                  this.waitingForStartChargingCountdown.stop();
              }

              this.waitingForStartChargingCountdown = new CountdownTimer(
                () => this.getLastGracePeriodEnd(),
                'start charging',
              );
              this.waitingForStartChargingCountdown.start();

          },
          {fireImmediately: true},
        );

        this.chargingDoneAndWaitingForVehicleToLeaveReaction = reaction(
          () => GlobalStoreProxy.sharedInstance().activeSession?.status === 'waiting_for_lock',
          (waitingForLock) => {
              if (!waitingForLock) {
                  return;
              }
              this.chargingDoneAndWaitingForVehicleToLeaveCountdown = new CountdownTimer(
                () => this.getLastGracePeriodEnd(),
                'waiting to leave',
              );
              this.chargingDoneAndWaitingForVehicleToLeaveCountdown.start();
          },
          {fireImmediately: true},
        );
    }
    componentWillUnmount() {
        if (this.waitingForStartChargingCountdown) {
            this.waitingForStartChargingCountdown.stop();
        }
        if (this.vehicleParkedReaction) {
            this.vehicleParkedReaction();
        }
        if (this.chargingDoneAndWaitingForVehicleToLeaveCountdown) {
            this.chargingDoneAndWaitingForVehicleToLeaveCountdown.stop();
        }
        if (this.chargingDoneAndWaitingForVehicleToLeaveReaction) {
            this.chargingDoneAndWaitingForVehicleToLeaveReaction();
        }
        if (this.sessionPollingService) {
            this.sessionPollingService.stop();
        }
    }

    render() {
        const globalStore = GlobalStoreProxy.sharedInstance();
        const locationStore = LocationStore.sharedInstance();
        this.waitingForStartChargingCountdown?.remainingSeconds; // Observe the remaining seconds
        this.chargingDoneAndWaitingForVehicleToLeaveCountdown?.remainingSeconds; // Observe the remaining seconds

        LocaleStore.observeLanguage();

        const activeSession = globalStore.activeSession;
        if (activeSession === null || activeSession === undefined) {
            return this.renderLoading();
        }

        const evse_id = activeSession.evseId;
        const location = locationStore.getLocationByEVSEId(evse_id);
        if (location === undefined) {
            // TODO: Render failure - no way its not in the database!
            // TODO: Load from backend
            return this.renderLoading();
        }

        const evse = locationStore.getEVSE(location, evse_id);

        if (evse === undefined) {
            console.error(`Missing evse ID: ${evse_id} in location`);
            return this.renderLoading();
        }

        const startSessionStore = SessionStartStore.sharedInstance();
        const subscriptionPlansStore = SubscriptionPlanStore.sharedInstance();
        const tariff = locationStore.getTariffById(evse.tariffId);
        const currency = locationStore.getCurrencyByCode(activeSession.currency?.code) || null;

        return <ThemeConsumer<ChargeTheme>>{({theme}) => { return <SafeAreaView style={{flex: 1}}><View style={{marginTop: 28, flex: 1}}>

            {/** Location */}
            <View style={{paddingHorizontal: 25}}>
                <Address location={location} />
            </View>

            {/** Charging animation */}
            {/*//TODO stop animation for car charge animation too*/}
            {theme.SessionRunning.chargingAnimation === 'car' && <CarChargeAnimation percent={activeSession.carBatteryPercent} />}
            {theme.SessionRunning.chargingAnimation === 'generic' && <GenericChargeAnimation percent={activeSession.carBatteryPercent} isCharging={activeSession.status === 'active'}/>}

            {/** Charging stats */}
            {evse?.capabilities.includes('METER_VALUES') ?
              <SessionStatsComponent
                activeSession={activeSession}
                hideEnergyAndPower={activeSession.status === 'waiting_for_start'}
              />
              : null}

            {/** Charging error notice */}
            {globalStore.activeSessionEvseErrorCode && (
                globalStore.activeSessionEvseErrorCode === 'EVCommunicationError' ||
                globalStore.activeSessionEvseErrorCode === 'GroundFailure'
              ) &&
              <View style={[theme.Container.byPaddingAndMarginTop, {alignItems: 'center'}]}>
                  <Text style={theme.SessionRunning.errorTextStyle}>
                      {globalStore.activeSessionEvseErrorCode}
                  </Text>
              </View>}

            <View style={theme.Container.byPaddingAndMarginTop}>
                {/** Stop session button */}
                {(activeSession.status === 'active' || activeSession.status === 'stopping') && <Button
                  title={this.state.loading
                    ? __('session.running.button-loading')
                    : (this.state.waitingToUnplug ? __('session.running.button-waiting') : __('session.running.button'))
                  }
                  icon={(this.state.loading || this.state.waitingToUnplug)
                    ? <ActivityIndicator color={theme.SessionRunning.activityIndicatorColor} />
                    : <ButtonIcon source={theme.SessionRunning.endSessionIcon} />
                  }
                  onPress={this.onStopSessionPress(evse, activeSession, tariff || undefined, currency || undefined)}
                />}

                {/** Start session (when the car is parked) */}
                {(activeSession.status === 'waiting_for_start' || activeSession.status === 'starting') &&
                  <Button
                    title={startSessionStore.loading
                      ? __('session.start.button-loading')
                      : (startSessionStore.waitingForCar
                          ? __('session.start.button-waiting')
                          : __('session.start.button')
                      )}
                    icon={(startSessionStore.loading || startSessionStore.waitingForCar)
                      ? <ActivityIndicator color={theme.SessionRunning.activityIndicatorColor} />
                      : <ButtonIcon source={theme.SessionRunning.startSessionIcon} />
                    }
                    onPress={() => startSessionStore.sendRemoteStart()}
                  />
                }

                {activeSession.isUsingChargingAllowance && <View style={{ marginTop: 15 }}>
                    <Text
                      style={StyleSheet.flatten([theme.Fonts.condensed, { fontSize: 14 }])}
                    >* {__('session.running.subscription').toUpperCase()}</Text>
                    <Text style={StyleSheet.flatten([theme.Fonts.condensedLight, { fontSize: 14 }])}>
                        {__('session.running.free_allowance', { freeAllowanceKwh: formatEnergy(this.calculateRemainingAllowance(activeSession.lastPeriod).toString()) })}
                    </Text>
                </View>}

            </View>

            {/** Waiting for car message */}
            {activeSession.status === 'starting' && startSessionStore.waitingForCar && <Text style={{textAlign: 'center'}}>{__('session.start.waiting-for-car-message')}</Text>}

            {this.renderStartChargingNotice(evse, activeSession ?? undefined, theme)}

            {this.renderStartChargingFees(locationStore, evse, activeSession ?? undefined, theme)}

            {this.renderVacateParkingSpotNotice(evse, activeSession ?? undefined, theme)}

            {this.renderVacateParkingSpotFees(locationStore, evse, activeSession ?? undefined, theme)}

            {/** Waiting to unplug or session running notice */}
            {(activeSession.status !== 'waiting_for_start' && activeSession.status !== 'starting' && activeSession?.status !== 'waiting_for_lock') && <View style={theme.Container.byPaddingAndMarginTop}>
                {this.state.waitingToUnplug &&
                  <Text
                    style={{textAlign: 'center', fontSize: 14}}>{__('session.running.waiting-for-stop-message')}</Text>
                  || <Text style={{textAlign: 'center', fontSize: 14}}>{__('session.running.notice')}</Text>}
            </View>}

            {activeSession && tariff?.priceType !== PriceTypes.OPTIMISED && tariff?.priceType !== PriceTypes.OPTIMISED_DYNAMIC ? this.renderAgileStreets(activeSession, theme) : null}
        </View></SafeAreaView>; }}</ThemeConsumer>;
    }

    renderLoading() {
        return <View style={{flex: 1}}><ActivityIndicator /></View>;
    }

    renderStartChargingFees(locationStore: LocationStore, evse?: EVSE, activeSession?: Session, theme)
    {
        if (!evse?.hasParkingBarrier) {
            return null;
        }
        if (!(activeSession?.status === 'waiting_for_start' || activeSession?.status === 'starting')){
            return null;
        }
        if ((this.waitingForStartChargingCountdown?.remainingSeconds ?? 0) > 0){
            return null;
        }
        const tariff = locationStore.getTariffById(evse.tariffId);
        const idleFee = SessionStartStore.getIdleFee(activeSession, tariff);
        const currency = (activeSession && locationStore.getCurrencyByCode(activeSession?.currency?.code)) || null;

        return <View style={theme.Container.byPadding}>
            <Text style={{textAlign: 'center', marginTop: 20}}>{__('session.start.barrier.charging-hasnt-started-in-time')
              .replace('{start-charging-time-limit}', evse?.startChargingTimeLimitMinutes ?? 0)
              .replace('{idle-fee}', format(idleFee ?? 0, currency))
              .replace('{current-idle-fees}', format(SessionStartStore.lastPeriodAmount(activeSession), currency))
            }
            </Text>
        </View>;
    }
    renderStartChargingNotice(evse?: EVSE, activeSession?: Session, theme)
    {
        if (!evse?.hasParkingBarrier){
            return null;
        }
        if (!(activeSession?.status === 'waiting_for_start' || activeSession?.status === 'starting')){
            return null;
        }
        if ((this.waitingForStartChargingCountdown?.remainingSeconds ?? 0) <= 0) {
            return null;
        }
        return <View style={theme.Container.byPadding}>
            <Text style={{textAlign: 'center', marginTop: 20}}>{__('session.start.barrier.waiting-to-start-charging') + ' ...' + humanizeDuration(this.waitingForStartChargingCountdown?.remainingSeconds ?? 0) + '...'}</Text>
        </View>;
    }
    renderVacateParkingSpotFees(locationStore: LocationStore, evse?: EVSE, activeSession?: Session, theme)
    {
        if (!evse?.hasParkingBarrier) {
            return null;
        }
        if (!(activeSession?.status === 'waiting_for_lock')){
            return null;
        }
        if ((this.chargingDoneAndWaitingForVehicleToLeaveCountdown?.remainingSeconds ?? 0) > 0) {
            return null;
        }
        const tariff = locationStore.getTariffById(evse.tariffId);
        const idleFee = SessionStartStore.getIdleFee(activeSession, tariff);
        const currency = (activeSession && locationStore.getCurrencyByCode(activeSession?.currency?.code)) || null;

        return <View style={theme.Container.byPadding}>
            <Text style={{textAlign: 'center', marginTop: 20}}>{__('session.running.barrier.vehicle-didnt-leave-in-time')
              .replace('{exit-time-limit}', evse?.exitTimeLimitMinutes ?? 0)
              .replace('{idle-fee}', format(idleFee ?? 0, currency))
              .replace('{current-idle-fees}', format(SessionStartStore.lastPeriodAmount(activeSession), currency))
            }
            </Text>
        </View>;
    }
    renderVacateParkingSpotNotice(evse?: EVSE, activeSession?: Session, theme)
    {
        if (!evse?.hasParkingBarrier){
            return null;
        }
        if (!(activeSession?.status === 'waiting_for_lock')){
            return null;
        }
        if ((this.chargingDoneAndWaitingForVehicleToLeaveCountdown?.remainingSeconds ?? 0) <= 0) {
            return null;
        }
        return <View style={theme.Container.byPadding}>
            <Text style={{textAlign: 'center', marginTop: 20}}>{__('session.running.barrier.waiting-to-leave') + ' ...' + humanizeDuration(this.chargingDoneAndWaitingForVehicleToLeaveCountdown?.remainingSeconds ?? 0) + '...'}</Text>
        </View>;
    }
    renderAgileStreets(activeSession: Session, theme: ChargeTheme)
    {
        if (!activeSession.isOptimized) {
            return null;
        }
        return <View style={[agileStreetsStyles.wrapper, theme.Container.byMargin]}>
            <Image source={theme.SessionRunning.agileStreetsIcon} style={agileStreetsStyles.sideIcon}/>
            <View style={agileStreetsStyles.mainContent}>
                <Text style={agileStreetsStyles.title}>
                    {__('agile-streets.running.title')}
                </Text>
                <Text style={[agileStreetsStyles.descr, theme.Text.labelColorStyle]}>
                    {__('agile-streets.running.descr')}
                </Text>
                <View style={agileStreetsStyles.table}>
                    <View style={agileStreetsStyles.row}>
                        <View style={[agileStreetsStyles.cell, agileStreetsStyles.iconCell]}>
                            <Image source={theme.SessionRunning.agileStreetsTargetChargeIcon}/>
                        </View>
                        <View style={[agileStreetsStyles.cell, agileStreetsStyles.titleCell]}>
                            <Text style={theme.Text.labelStyle}>{__('agile-streets.target-charge-lbl')}:</Text>
                        </View>
                        <View style={[agileStreetsStyles.cell, agileStreetsStyles.valueCell]}>
                            <Text style={theme.Text.labelFontStyle}>{activeSession.targetCharge?.value} kWh</Text>
                        </View>
                    </View>
                    <View style={agileStreetsStyles.row}>
                        <View style={[agileStreetsStyles.cell, agileStreetsStyles.iconCell]}>
                            <Image source={theme.SessionRunning.agileStreetsEndTimeIcon}/>
                        </View>
                        <View style={[agileStreetsStyles.cell, agileStreetsStyles.titleCell]}>
                            <Text style={theme.Text.labelStyle}>{__('agile-streets.end-time-lbl')}:</Text>
                        </View>
                        <View style={[agileStreetsStyles.cell, agileStreetsStyles.valueCell]}>
                            <Text style={theme.Text.labelFontStyle}>{activeSession.scheduleStop}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    }

    calculateRemainingAllowance(chargingPeriod?: ChargingPeriod): number {
        if (! chargingPeriod || chargingPeriod.maxAllowanceWh === 0) {
            return 0;
        }

        return chargingPeriod.maxAllowanceWh - chargingPeriod.freeEnergyWh;
    }

    stop(evse: EVSE, session: Session) {
        if (GlobalStoreProxy.sharedInstance().mockSession) {
            return SessionMock.sharedInstance().stop(evse.identifier);
        } else {
            return SessionAPI.stop(session.id)
              .catch((error: any) => {
                  return Promise.reject(error.message);
              });
        }
    }

    onStopSessionPress(evse: EVSE, session: Session, tariff?: Tariff, currency?: Currency) {
        const globalStore = GlobalStoreProxy.sharedInstance();
        return async () => {

            if (this.state.loading) {
                return;
            }
            if (this.state.waitingToUnplug) {
                return;
            }
            if (evse.hasParkingBarrier) {
                const ok = await confirm(
                  __('session.running.barrier.title'),
                  __('session.running.barrier.description')
                    .replace('{exit-time-limit}', evse?.exitTimeLimitMinutes ?? 0)
                    .replace('{idle-fee}', format(tariff?.priceForIdle ?? 0, currency))
                  ,
                  __('session.running.barrier.confirm-text'),
                  __('message.cancel'),
                );

                if (!ok) {
                    return;
                }
            }

            this.setState({loading: true});
            this.stop(evse, session)
              .then((session: Session) => {
                  globalStore.activeSession = session;
                  this.setState({waitingToUnplug: true});
                  this.sessionPollingService = SessionPollingService.poll(evse, session, 'stopping', session => {
                      globalStore.activeSession = (session.status !== 'finished') ? session : null;

                      if (session.status !== 'waiting_for_lock') {
                          SessionChangesMonitorProxy.getClass()?.sessionHasEnded?.(session);
                      }
                      this.setState({waitingToUnplug: false});
                  });
              })
              .catch((reason: any) => {
                  ToasterStore.sharedInstance()
                    .setMessage(__('session.running.failure'));
              })
              .finally(() => {
                  this.setState({loading: false});
              });
        };
    }
}

const agileStreetsStyles = StyleSheet.create({
    wrapper: {flexDirection: 'row', marginTop: 40},
    sideIcon: {marginRight: 10},
    mainContent: {flex: 1},
    row: {flexDirection: 'row', marginBottom: 10},
    title: {fontWeight: 'bold'},
    descr: {},
    table: {marginTop: 20},
    cell: {justifyContent: 'center'},
    iconCell: {flex: 1},
    valueCell: {flex: 6},
    titleCell: {flex: 4},
});
