import React, {Component} from 'react';
import Select from '@ampeco/select';
import {observer} from 'mobx-react';
import {Text, ThemeProps, withTheme} from 'react-native-elements';
import {Image, StyleSheet, View} from 'react-native';
import PaymentsStore from './PaymentsStore';
import AmpecoTheme from '@ampeco/theme';
import {__} from '@ampeco/i18n';
import {PaymentMethod} from '@ampeco/models';
import {BalanceStore} from '@ampeco/balance';

interface Props {
    addPaymentMethodNavigation?: (screen: string, params: any) => void;
    paymentAddedCallback?: () => void;
    value: string;
    onChange: (paymentMethodId: string) => void;
}

@observer
class PaymentMethodDropdown extends Component<ThemeProps<AmpecoTheme> & Props> {
    balanceOptionIsAvailable() {
        const store = PaymentsStore.sharedInstance();
        return PaymentsStore.sharedInstance().canUseServiceWithoutCard()
            && (
                !store.payments
                || store.payments.methods.length === 0
                || !store.allowPaymentMethod
            );
    }

    renderSelected(theme: AmpecoTheme, paymentMethodId: string) {
        const paymentMethod = PaymentsStore.sharedInstance().getPaymentMethodById(paymentMethodId);
        if (!paymentMethod) {
            return <><Text>{paymentMethodId}</Text></>
        }
        return <>{(theme.PaymentMethods.paymentMethodTypeImages[paymentMethod.type] && !['corporate'].includes(paymentMethod.type)) ?
            <Image source={theme.PaymentMethods.paymentMethodTypeImages[paymentMethod.type]}
                   style={{margin: 5, height: 22, width: 32}}/> : null}<Text
            style={{margin: 5}}>{paymentMethod.name}</Text></>;
    }

    renderSelectedText(text: string) {
        return <><Text
            style={{margin: 5}}>{text}</Text></>;
    }

    render() {
        const store = PaymentsStore.sharedInstance();
        const {theme, addPaymentMethodNavigation, value} = this.props;

        const options: {type: string; id?: string}[] = [];
        const stringOptions: string[] = [];

        if (store.paymentsEnabled) {
            options.push({
                type: 'default',
            });
            stringOptions.push(__('addPaymentMethod.lastUsed'));
        }

        if (store.payments) {
            store.payments.methods.map(pm => {
                if (store.allowPaymentMethod || pm.type === 'corporate') {
                    options.push({
                        type: pm.type,
                        id: pm.id,
                    });
                    stringOptions.push(pm.name);
                }
            });
        }

        // add balance if necessary
        if (this.balanceOptionIsAvailable()) {
            options.push({
                type: 'balance',
            });
            stringOptions.push(__('addPaymentMethod.balance').replace(':amount', BalanceStore.sharedInstance().formattedWalletBalance));
        }

        // add "Add new" button if necessary
        if (addPaymentMethodNavigation && store.paymentsEnabled && PaymentsStore.sharedInstance().canAddPaymentMethod() && store.allowPaymentMethod) {
            options.push({
                type: 'new',
            });
            stringOptions.push(__('addPaymentMethod.shortcut'));
        }
        return <Select options={stringOptions}
                       selected={
                           value !== 'default'
                               ? this.renderSelected(theme, value)
                               : (this.balanceOptionIsAvailable()
                               ? this.renderSelectedText(__('addPaymentMethod.balance').replace(':amount', BalanceStore.sharedInstance().formattedWalletBalance))
                               : this.renderSelectedText(__('addPaymentMethod.lastUsed')))
                       }
                       itemHeight={50}
                       onChange={(pmName, index) => {
                           if (!store.payments || !store.allowPaymentMethod) {
                               return;
                           }

                           index = parseInt(index + '', 10);

                           if (addPaymentMethodNavigation && options[index].type === 'new') {
                               store.newPaymentMethod(addPaymentMethodNavigation)
                                   .then((paymentMethod?: PaymentMethod) => {
                                       paymentMethod && this.props.paymentAddedCallback && this.props.paymentAddedCallback();
                                   });
                               return;
                           }

                           const selected = store.payments.methods.find(pm => pm.name === pmName);
                           if (selected) {
                               this.props.onChange(selected.id);
                           } else {
                               if (options[index].type === 'default') {
                                   this.props.onChange('default');
                               } else if (this.balanceOptionIsAvailable() && options[index].type === 'balance') {
                                   this.props.onChange('balance');
                               }
                           }
                       }}
                       renderRow={
                           (item, index, isSelected) => {
                               index = parseInt(index + '', 10);
                               if (
                                   (addPaymentMethodNavigation && options[index].type === 'new')
                                   || options[index].type === 'balance'
                                   || options[index].type === 'default'
                                   || options[index].type === 'corporate'
                               ) {
                                   return <View style={{
                                       flexDirection: 'row',
                                       height: 50,
                                       paddingHorizontal: 20,
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       flex: 1,
                                   }}><Text>
                                       {item}
                                   </Text>
                                   </View>;
                               }
                               const selectedStyle = (theme.Select && theme.Select.selectedStyle) || undefined;
                               const unselectedStyle = (theme.Select && theme.Select.unselectedStyle) || undefined;

                               return <View style={{flexDirection: 'row', height: 50, alignItems: 'center'}}>
                                   {options[index].type !== 'corporate' &&
                                   <Image style={{marginLeft: 10, margin: 5, height: 22, width: 32}}
                                          source={theme.PaymentMethods.paymentMethodTypeImages[options[index].type]}/>}
                                   <Text
                                       style={[styles.rowText, isSelected ? selectedStyle : unselectedStyle]}>{item}</Text>
                               </View>;
                           }
                       }
                       fullWidth={false}
        />
            ;
    }
}

export default withTheme<Props, AmpecoTheme>(PaymentMethodDropdown);

const styles = StyleSheet.create({
    rowText: {
        marginRight: 30,
        margin: 5,
        fontSize: 18,
    },
});
