import React from 'react';
import {Button, Divider, Text, ThemeConsumer} from 'react-native-elements';
import InvoiceDetailsStore, {shouldShow} from '../stores/InvoiceDetailsStore';
import AmpecoTheme from '@ampeco/theme';
import {observer} from 'mobx-react';
import {__} from '@ampeco/i18n';
import {I18nManager, StyleSheet, View} from 'react-native';
import {ButtonIcon} from '@ampeco/icons';

const InvoiceDetailsView: React.FC<{ navigate: (route: string) => void }> = (({navigate}) => {
    const details = InvoiceDetailsStore.details;
    const fields = InvoiceDetailsStore.fields;
    if (!InvoiceDetailsStore.ready || !InvoiceDetailsStore.detailsRequired || !fields) {
        return null;
    }
    return <ThemeConsumer<AmpecoTheme>>{
        ({theme}) => (
            <>
                <Divider style={StyleSheet.flatten([styles.divider])}/>

                <Text style={StyleSheet.flatten([styles.header, theme.Invoices.headerStyle])}>
                    {__('invoices.details')}
                </Text>
                {!details && <Text style={StyleSheet.flatten([theme.Invoices.labelStyle])}>
                    {__('invoices.no-details')}
                </Text>}
                {details && Object.entries(fields).map(([key, field]) => {
                    const value = details[key];
                    if (!shouldShow({form: details}, field)) {
                        return;
                    }
                    const label = I18nManager.isRTL ? ':' + fields[key].label : fields[key].label + ':';
                    const options = (fields[key] && fields[key].options) || {};
                    const val = field.type === 'checkbox'
                        ? (value ? __('invoices.yes') : __('invoices.no'))
                        : (field.type === 'select' && fields[key].options !== undefined ? options[value] : value);

                    return <View key={key} style={field.hidden === true ? styles.hiddenElement : styles.row} >
                        <Text style={StyleSheet.flatten([theme.Invoices.labelStyle, styles.label])}>{label}</Text>
                        <Text style={StyleSheet.flatten([theme.Invoices.valueStyle, styles.value])}>{val}</Text>
                    </View>
                })}
                <Button style={styles.button}
                        icon={<ButtonIcon
                            style={{width: 21, height: 21}}
                            source={details ? theme.Invoices.editButtonIcon : theme.Invoices.addButtonIcon}
                        />}
                        title={details ? __('invoices.edit-button') : __('invoices.add-button')}
                        onPress={() => navigate('InvoiceDetailsEdit')}
                />
            </>)}
    </ThemeConsumer>;
});

export default observer(InvoiceDetailsView);

const styles = StyleSheet.create({
    divider: {
        marginVertical: 24,
    },
    header: {
        marginBottom: 24,
    },
    row: {flex: 1, flexDirection: 'row', paddingVertical: 5, textAlign: 'left'},
    value: {flex: 1, fontSize: 14},
    label: {width: 110, fontSize: 10},
    button: {marginTop: 10},
    hiddenElement: {
        opacity: 0,
        height: 0,
        width: 0,
    },
});
