import React from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';
import {StyleProp, View, ViewStyle} from 'react-native';
import { ChargeTheme } from '@ampeco/theme';

interface Props extends React.PropsWithChildren {
    label: string,
    style?: StyleProp<ViewStyle>,
}
export default class CompositeInput extends React.PureComponent<Props> {
    render() {
        return <ThemeConsumer>{({theme}) => {
            return (<View
                style={[
                    theme.CompositeInput.containerStyle,
                    this.props.style,
                ]}
            >
                <Text
                    style={theme.CompositeInput.textStyle}
                >
                    {this.props.label}
                </Text>
                <View>
                    {this.props.children}
                </View>
            </View>);
        }}</ThemeConsumer>;
    }
}