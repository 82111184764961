import React, {PropsWithChildren, useEffect} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import settings from "./settings";

const Analytics: React.FC = (props: PropsWithChildren<any>) => {
    const location = useLocation();
    const trackingId = settings.analytics.trackingId;

    useEffect(() => {
        if (trackingId) {
            ReactGA.initialize(trackingId);
        }
    }, []);

    useEffect(() => {
        if (trackingId) {
            ReactGA.pageview(`${location.pathname}${location.search}`);
        }
    }, [location.pathname, location.search]);

    return (<>
        { props.children }
    </>);
}

export default Analytics;
