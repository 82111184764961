import React from "react";
import {BasePage, BasePageProps} from "./BasePage";
import {MenuItemsStore} from "@ampeco/charge-stores";
import NavigationService from "@ampeco/navigation";

type ModulePageProps = BasePageProps & {
    moduleName: string;
    children: React.ReactNode;
    navigateToWhenDisabled?: string;
}

export function ModulePage(props: ModulePageProps) {
    const navigationService = NavigationService.sharedInstance();
    const menuItemsStore = MenuItemsStore.sharedInstance();

    if (menuItemsStore.isItemHidden(props.moduleName)) {
        navigationService.navigate(props.navigateToWhenDisabled ?? 'Home');
        return null;
    }

    return (
        <BasePage {...props}>
            {props.children}
        </BasePage>
    );
}
