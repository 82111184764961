import {SignUpBonus} from './SignUpBonus';
import {API} from './index';
import {observable} from 'mobx';
import Alert from '@ampeco/alert'

export default class SignUpBonusStore {
    private static instance: SignUpBonusStore;
    @observable signUpBonus: SignUpBonus | null = null;

    static sharedInstance(): SignUpBonusStore {
        if (SignUpBonusStore.instance === undefined) {
            SignUpBonusStore.instance = new SignUpBonusStore();
        }
        return SignUpBonusStore.instance;
    }

    showPopUp(declineButtonText: string) {
        if (!this.signUpBonus) {
            return;
        }

        Alert.sharedInstance().show(
            this.signUpBonus.pop_up_dialog.title,
            this.signUpBonus.pop_up_dialog.content,
            [
                {
                    text: this.signUpBonus.pop_up_dialog.claim_button_label,
                    style: 'cancel',
                    onPress: async () => {
                        await API.claimSignUpBonus(this.signUpBonus.id);
                        this.clearSignUpBonus()
                    },
                },
                {
                    text: declineButtonText,
                    style: 'cancel',
                    onPress: async () => {
                        await API.declineSignUpBonus(this.signUpBonus.id);
                        this.clearSignUpBonus()
                    },
                },
            ],
            {cancelable: false},
        );
    }

    clearSignUpBonus() {
        this.signUpBonus = null;
    }
}
