import React from 'react';
import {ScrollView, View} from 'react-native';
import {observer} from 'mobx-react';
import {CreateUser} from '@ampeco/charge-models';
import {Action, Error, Field, FormStore, FormStoreProvider, PasswordRevealInput} from '@ampeco/forms';
import {Button, Input, ThemeConsumer} from 'react-native-elements';
import {__, LocaleStore} from '@ampeco/i18n';
// @ts-ignore
import {debounce} from 'throttle-debounce';
import {IReactionDisposer, reaction} from 'mobx';
import {ButtonIcon} from '@ampeco/icons';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {Wizard} from '@ampeco/charge-flow';
import {TermsStore} from '@ampeco/terms';
import {Signup} from '@ampeco/charge-api';
import {ConsentCheckboxes} from '@ampeco/components';

const store = new FormStore();

store.actions.register = async () => {
    const globalStore = GlobalStoreProxy.sharedInstance();

    const params: CreateUser = {
        email: store.form.email + '',
        password: store.form.password + '',
        locale: LocaleStore.sharedInstance().language,
    };

    await Signup.register(params);

    await globalStore.reloadResourcesOnRegistration();

    globalStore.flow.navigateToNext();
    store.reset();
};

const checkEmail = debounce(300, async () => {
    if (!store.errors.email || store.errors.email === __('message.errors.taken')) {
        if (!(await Signup.emailIsAvailable(store.form.email as string))) {
            store.errors.email = __('message.error.taken');
        } else {
            delete store.errors.email;
        }
    }
});

@observer
export default class Register extends Wizard {

    static reaction: IReactionDisposer | null = null;

    componentDidMount(): void {
        Register.reaction = reaction(
            () => LocaleStore.sharedInstance().language,
            () => {
                this.props.navigation?.setOptions({title: __('register.title')});
            },
        );

        const termsStore = TermsStore.sharedInstance();

        store.rules = {
            email: 'required|email|max:255',
            password: 'required|strongPassword|max:255',
        };
        termsStore.terms.map(term => {
            store.rules['accept ' + term.title] = 'required';
        });
        store.reset();
    }

    render() {
        return (
            <ThemeConsumer>{({theme}) => {
                return <ScrollView keyboardDismissMode="on-drag" keyboardShouldPersistTaps={'always'} indicatorStyle="white" showsVerticalScrollIndicator={true}>
                    {this.renderSteps()}
                    <View style={[theme.Container?.byMargin,  {marginBottom: 60}]}>
                        <FormStoreProvider store={store}>
                            <Field
                                type={Input}
                                placeholder={__('profile.label.email')}
                                textContentType="emailAddress"
                                autoCompleteType="email"
                                keyboardType="email-address"
                                name="email"
                                autoCapitalize="none"
                                onChangeText={async (text: string) => {
                                    checkEmail();
                                }}
                                style={{textAlign: 'left'}}
                            />

                            <Field
                                type={PasswordRevealInput}
                                placeholder={__('register.label.password')}
                                name="password"
                                textContentType="newPassword"
                                style={{textAlign: 'left'}}
                            />

                            <ConsentCheckboxes
                                terms={TermsStore.sharedInstance().terms}
                                language={LocaleStore.language}
                            />

                            <Error />

                            <Action disableIfInvalid type={Button} name="register"
                                    title={__('register.next')} iconRight={true}
                                    icon={<ButtonIcon source={theme.Register?.actionIconSource}
                                                      style={{width: 12, height: 12}}/>} validateOnPress/>

                        </FormStoreProvider>
                    </View>
                </ScrollView>
            }}</ThemeConsumer>
        );
    }
}
