import {ReactNode} from 'react';
import {IReactionPublic} from 'mobx';
import {Term} from '@ampeco/terms';
import {NavigatorScreenParams} from '@react-navigation/native';
import {PersonalCharger, Session, SubscriptionPlan} from '@ampeco/charge-models';
import {EmbeddedUrls} from '@ampeco/charge-models';

export type RootStackParamList = {
    Loading: undefined;
    HomeModal: undefined;
    TermsConsentStack: NavigatorScreenParams<TermConsentStackParamList>;
    NetworkError: {message?: any, error: any};
    UpdateRequired: undefined;
};

export type HomeModalParamList = {
    HomeStack: NavigatorScreenParams<HomeStackParamList>;
    SessionSummary: {
        dismissButton: boolean;
        sessionId?: string;
        item?: any;
        session?: any;
    };
    PersonalChargingSummaryContainer: {
        isHistory: boolean;
        sessionId: string;
    };
    AddPaymentMethod: {
        url: string;
        html: string;
        sourceType: string;
        successUrl: string;
        waitForSuccessUrl: boolean;
        onClose?: (success: boolean) => void;
    };
    AddPaymentMethodNative: {onClose?: (card?: object, shouldNavigateBack?: boolean) => void};
    AddPaymentMethodNative3ds: {
        setup_intent?: string;
        setup_intent_client_secret?: string;
        source_redirect_slug?: string;
    };
    ContactDetails: undefined;
    AddPartner: undefined;
    FirstPaymentMethod: undefined;
    SubscriptionPlansStep: undefined;
    UserVerify: {
        component: string;
        reactionExpressionToNavigateNext: (r: IReactionPublic) => unknown;
        next?: any;
        type?: any;
        onSuccess?: () => void;
    };
    UserVerified: {
        component: string;
    };
    InvoiceDetailsEdit: {
        message: ReactNode;
        successCallback?: () => void;
    };
    PersonalAssetAddStepsContainer: {
        isModal?: boolean;
        onNextStep?: () => Promise<any>;
    };
    PersonalChargerBLEContainer: {
        isModal?: boolean;
        onNextStep?: () => Promise<any>;
        onConnectionSuccess?: () => void;
        personalCharger: PersonalCharger;
    };
    BleInstallerBLEContainer: {
        isModal?: boolean;
        onNextStep?: () => Promise<any>;
        onConnectionSuccess?: () => void;
        personalCharger: PersonalCharger;
    };
    PersonalChargingSuccessContainer: {
        personalCharger: PersonalCharger;
    };
    VerifyPin: {
        existingPersonalAsset?: any;
        onPinSuccess?: (response: any) => void;
        onPinConfirmCancel?: () => void;
        urlPrefix?: string;
    };
    BleInstallerVerifyPin: {
        existingPersonalAsset?: any;
        onPinSuccess?: (response: any) => void;
        onPinConfirmCancel?: () => void;
        urlPrefix?: string;
    };
    UserBankDetails: {
        showNotice?: boolean;
    };
    ExternalBalanceTopUp: {
        url: string | null;
        onClose?: (success: boolean) => void;
    };
};

export type HomeStackParamList = {
    Login: undefined;
    Logout: undefined;
    SsoLogin: undefined;
    Register: undefined;
    Forgotten: {
        email?: string;
    };
    ResetPassword: {
        code: string;
    };
    AcceptToU: undefined,
    HomeDrawer: NavigatorScreenParams<HomeDrawerParamList>;
    EmbeddedWebApp: {
        embeddedUrls: EmbeddedUrls;
    };
    PaymentMethods: {
        addPaymentMethodCallback?: () => void;
        footerComponent?: () => ReactNode;
    };
    Terms: undefined;
    SessionStart: {
        evseId: string;
    };
    EditProfile: undefined;
    ViewProfile: undefined;
    PasswordChange: undefined;
    Favorites: undefined;
    Settings: undefined;
    Balance: {
        shouldShowTopup?: () => boolean;
        afterPurchaseCallback?: () => void;
    };
    RedeemVoucher: {
        code: string;
    } | undefined;
    AcceptInvite: {
        code: string;
    };
    ComingSoon: undefined;
    EnterCode: {
        disableIfInvalid: boolean;
        codeEntered: boolean;
        enterCodeDescription?: string;
    };
    History: {
        isPersonalChargingEnabled: () => boolean;
        filter?: string | null;
    };
    HistoryDetails: undefined;
    IdTags: {
        title?: string | null;
        type: string;
    };
    IdTagAdd: undefined;
    IdTagScanQRCode: undefined;
    IdTagRead: {
        evse_identifier?: string;
    };
    IdTagEdit: {
        newlyAdded?: boolean;
        cardIndex?: number;
        title?: string;
        canCancelAutocharge?: boolean;
        autochargeIdTagId?: number;
    };
    IdTagCreate: undefined;
    IdTagError: {
        errorMessage?: string;
    };
    TopUpPackages: {
        autoTopUp: boolean;
        afterPurchaseCallback?: () => void;
    };
    SubscriptionPlans: undefined;
    SubscriptionPlan: {
        subscription: SubscriptionPlan;
        subscriptionListPlanIds?: string[];
    };
    SubscriptionPlansListScreen: undefined;
    PostPaidFeesScreen: undefined;
    PersonalAssetUnknownContainer: {
        enteredId?: string;
        onPress: () => Promise<any>;
    };
    PersonalAssetPinContainer: {
        onVerifyPin?: (pin: string, existingPersonalAsset: any) => Promise<any>;
        existingPersonalAsset?: any;
    };
    BleInstallerPinContainer: {
        onVerifyPin?: (pin: string, existingPersonalAsset: any) => Promise<any>;
        existingPersonalAsset?: any;
    };
    PersonalChargerConfigurationContainer: undefined;
    PersonalChargerSecurityEventsContainer: {
        personalCharger: PersonalCharger;
        chargePointId?: string;
    };
    BleInstallerConfigurationContainer: undefined;
    PersonalChargerConfigurationWrapper: {
        selectedCharger?: PersonalCharger;
        settingsComponent: any;
        session?: Session;
    };
    BleInstallerConfigurationWrapper: {
        selectedCharger?: PersonalCharger;
        settingsComponent: any;
        session?: Session;
    };
    PersonalChargerChargingContainer: undefined;
    ElectricityTaxReimbursement: undefined;
    SCAChallenge: {
        redirectUrl: string;
        returnUrl: string;
        failureUrl: string;
        sessionId?: string;
    };
    PersonalChargerFirmwareUpdateContainer: {
        chargePointId: string;
        fromPush?: boolean;
        shouldTriggerImmediately?: boolean;
    }

    PaymentTransactionSca: {
        paymentTransactionId: string;
        title: string;
        fromOutsideApp?: boolean;
        onSuccessRoute?: string;
        onSuccessRouteParams?: object;
        onFailRoute?: string;
        onFailRouteParams?: object;
        onCancelRoute?: string;
        onCancelRouteParams?: object;
    };
    EmailReceipt: {
        successCallback?: () => void;
        skipCallback?: () => void;
    };
    EVSESession: {
        session: Session;
    }
};

export type HomeDrawerParamList = {
    HomeTabBar: NavigatorScreenParams<HomeTabBarParamList>;
};

export type HomeTabBarParamList = {
    BleInstallerContainer: {

    } | undefined;
    PersonalChargingContainer: {
        chargePointId?: string;
        isNewChargePoint?: boolean;
        newChargePointId?: string;
    } | undefined;
    MapDrawer: NavigatorScreenParams<MapDrawerParamList>;
    ScanQR: {
        onCode: (code: string) => void;
        enterCodeDescription?: string;
    } | undefined;
    ActiveSession: undefined;
    Help: undefined;
};

export type MapDrawerParamList = {
    Map: {
        zoomToCoordinates?: string;
    } | undefined;
};

export type TermConsentStackParamList = {
    TermChangeContainer: {
        activeTerm: Term;
        isAdHock?: boolean;
    };
    TermsContent: {
        title: string;
        url: string;
    };
};

export type CombinedParamList = RootStackParamList & HomeModalParamList & HomeStackParamList & HomeTabBarParamList & TermConsentStackParamList;

export default class Navigation {
    private static instance: Navigation;
    private navigation: any;

    static sharedInstance(): Navigation {
        if (Navigation.instance === undefined) {
            Navigation.instance = new Navigation();
        }
        return Navigation.instance;
    }

    init(navigation: any) {
        this.navigation = navigation
    }

    navigate(path: string, props?: any) {
        this.navigation.navigate(path, props);
    }

    goBack() {
        return this.navigation.goBack();
    }

    switchToMain() {
        this.navigation.switchToMain();
    }
}
