import { I18N } from './i18n';
import {I18n, Scope, TranslateOptions } from 'i18n-js';

// Shorthand
export function getLocale() {
    return I18N.getLocale();
}

// Shoarthand
export function setLocale(locale: string) {
    return I18N.setLocale(locale);
}

// The method we'll use instead of a regular string
export function __(name: Scope, params: TranslateOptions = {}) {
    return I18N.translate(name, params);
}
