import {action, computed, observable} from 'mobx';
import {__} from '@ampeco/i18n';
import {defaultErrorMessages, Validator} from './Validator';

export class FormStore {
    @observable lastRemoteError: string | null = null;
    @observable errors: {[index: string]: string} = {};
    @observable form: {[index: string]: string | number | boolean | FormData | null} = {};

    actions: {[index: string]: (...args: any[]) => Promise<any>} = {};

    rules: {[index: string]: string} = {};

    messages: {[index: string]: {[index: string]: string}} = {};

    httpErrors: {[index: number]: string} = {};

    @observable processing: string | null = null;
    @observable processingItem?: string;

    @computed get isValid() {
        return this.errors === null || Object.keys(this.errors).length === 0;
    }

    @action.bound reset() {
        this.form = {};
        this.errors = {};
        this.lastRemoteError = null;
    }

    @action.bound
    setError(e: any, errorKey: string | null = null) {
        if (e.response && e.response.status === 422) {
            const errors: {[index: string]: string} = {};
            if (e.response.data.errors) {
                Object.entries(e.response.data.errors).forEach(([key, values]) => {
                    // @ts-ignore
                    errors[errorKey || key] = values.join('\n');
                });
            }
            this.errors = errors;
            this.lastRemoteError = e.response.data.message;
        } else if (e.response && e.response.status && typeof this.httpErrors[e.response.status] !== 'undefined') {
            this.lastRemoteError = this.httpErrors[e.response.status];
        } else if (e.response && e.response.data && e.response.data.message) {
            this.lastRemoteError = e.response.data.message;
        } else {
            this.lastRemoteError = __('message.error.error');
        }
    }

    @action.bound
    async action(action: string, attributes?: any[]) {
        this.processing = action;
        try {
            this.lastRemoteError = null;
            this.errors = {};
            await this.actions[action](...(attributes || []));
        } catch (e) {
            this.setError(e);
        } finally {
            this.processing = null;
        }
    }

    @action.bound
    clearErrors() {
        this.errors = {};
    }

    @action.bound
    validateAll() {
        for (const name in this.rules) {
            if (this.rules.hasOwnProperty(name)) {
                this.validate(name);
            }
        }
    }

    @action.bound
    validate(name: string) {
        const validate = this.rules[name];

        if (validate === undefined) {
            delete this.errors[name];
            return;
        }
        const errorMessages = this.messages[name] || {};
        const text = this.form[name];
        const requirements = validate.split('|');

        let hasError = false;
        for (const id in requirements) {
            if (requirements.hasOwnProperty(id)) {
                const requirement = requirements[id];
                const options = requirement.split(':');
                const test = options.splice(0, 1);
                if (typeof Validator[test[0]] !== 'undefined') {
                    const args: any[] = [text];
                    if (options.length > 0) {
                        args.push(options[0].split(','));
                    }
                    const isValid = Validator[test[0]].call(this, ...args);
                    if (!isValid) {
                        if (typeof errorMessages !== 'undefined' && typeof errorMessages[requirement] !== 'undefined') {
                            this.errors[name] = errorMessages[requirement];
                        } else if (typeof defaultErrorMessages()[test[0]] === 'function') {
                            this.errors[name] = defaultErrorMessages()[test[0]](options);
                        } else if (typeof defaultErrorMessages()[test[0]]) {
                            this.errors[name] = defaultErrorMessages()[test[0]];
                        } else {
                            this.errors[name] = 'Error';
                        }
                        hasError = true;
                        return;
                    }
                }
            }
        }
        if (!hasError) {
            delete this.errors[name];
        }
    }
}
