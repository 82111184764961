import React from "react";
import {Connector, EVSE} from "@ampeco/charge-models";
import {Image, StyleSheet} from "react-native";
import {connectorImages} from "../../res/images";

interface Props {
    evse: EVSE
}

const EvseIdentifier = ({evse}: Props): JSX.Element => {
    return (<div className="evseItem col">
        <div className="identifier">
            {evse.connectors.map((connector: Connector, index: number) => {
                return (<Image
                    key={connector.icon + index}
                    source={connectorImages[connector.icon]}
                    style={[styles.connectorIcon]}
                    resizeMode={'center'}
                />);
            })}
            <span>{evse.identifier}</span>
        </div>
    </div>);
}

const styles = StyleSheet.create({
    connectorIcon: {
        height: 40,
        width: 40,
        marginRight: 15
    }
});

export default EvseIdentifier;
