import {__} from '@ampeco/i18n';

export function translateSessionStartErrorCodeToMessage(reason: any) {

    if (!reason.response || reason.response.status === null) {
        return __('session.start.failure');
    }

    let message = '';

    switch (reason.response.status) {
        case 402:
            message = __('session.start.unpaid-session-failure');
            break;
        case 403:
        case 412:
            message = reason.response.data.message;
            break;
        case 407:
            message = __('session.start.payment-method-cannot-be-used');
            break;
        case 408:
            message = __('session.start.access_is_restricted');
            break;
        case 409:
            message = __('session.start.concurrent-session-failure');
            break;
        case 425:
            message = __('session.start.account_verification_not_complete');
            break;
        default:
            message = __('session.start.failure');
            break;
    }


    return message;
}
