import {Divider, ThemeConsumer} from 'react-native-elements';
import {StyleSheet, View, ViewStyle, Text} from 'react-native';
import React from 'react';

type CardWidgetProps = {
    children: React.ReactNode;
    title?: string | React.ReactNode;
    containerStyle?: ViewStyle;
    bodyStyle?: ViewStyle;
}

export function CardWidget(props: CardWidgetProps) {
    return <ThemeConsumer>{({theme}) => (
        <View style={[theme.CardWidget.container, props.containerStyle]}>
            <View style={styles.title}>
                {typeof props.title === 'string' ? <Text>{props.title}</Text> : props.title}
            </View>
            <Divider style={theme.CardWidget.titleDivider}/>
            <View style={[styles.body, props.bodyStyle]}>
                {props.children}
            </View>
        </View>
    )}</ThemeConsumer>
}

const styles = StyleSheet.create({
    title: {
        padding: 15,
        color: 'white',
    },
    body: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});