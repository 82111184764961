import React from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';
import {StyleSheet, TextStyle} from 'react-native';
import AmpecoTheme from '@ampeco/theme';

const SwipeToShowActionsText: React.FC<{ label: string, style?: TextStyle }> = ({label, style}) => {
    return <ThemeConsumer<AmpecoTheme>>{({theme}) => (<Text
        style={StyleSheet.flatten([style, styles.swipeText, theme.SwipeToShowActionsText.style])}>{label}</Text>)}</ThemeConsumer>;
};

export default SwipeToShowActionsText;

const styles = StyleSheet.create({
    swipeText: {
        marginBottom: 20,
        fontStyle: 'italic',
        fontSize: 14,
    },
});
