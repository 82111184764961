import net from '@ampeco/net';
import {TopUpPackage} from '../TopUpPackage';
import BalanceStatus from '../BalanceStatus';

export async function get(): Promise<TopUpPackage[]> {
    return (await net.get('/app/top-up')).data as TopUpPackage[];
}

export async function status(): Promise<BalanceStatus> {
    return (await net.get('/app/top-up/status')) as BalanceStatus;
}

export async function purchase(topUp: string, paymentMethodId: string, enableAutoTopUp: boolean): Promise<TopUpPackage[]> {
    return (net.post(`/app/top-up/${topUp}/purchase`, {paymentMethodId, enableAutoTopUp}));
}

export async function enableAutoTopUP(topUp: string): Promise<void> {
    return (net.post(`/app/top-up/${topUp}/auto-top-up`));
}

export async function disableAutoTopUP(): Promise<void> {
    return (net.delete('/app/top-up/auto-top-up'));
}
