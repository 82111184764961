const fonts = {
    title: 'RobotoCondensed-Regular',
    regular: 'Roboto-Regular',
    light: 'Roboto-Light',
    condensedLight: 'RobotoCondensed-Light',
    condensedBold: 'RobotoCondensed-Bold',
    condensed: 'RobotoCondensed-Regular',
    bold: 'Roboto-Bold',
    italic: 'Roboto-Italic',
    medium: 'Roboto-Medium',
};
export enum FontWeight {
    light = '300',
    regular = '400',
    medium = '500',
    bold = '700',
}
export default fonts;
