import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Location} from '@ampeco/charge-models';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import Alert from '@ampeco/alert'
import {Common} from '@ampeco/components';

export default (props: {location: Location}) => (
    <ThemeConsumer>{({theme}) => {
        return <View style={styles.container}>
            <Text style={theme.What3words.textStyle}>///{props.location.what3words_address}</Text>
            <TouchableOpacity activeOpacity={0.6} onPress={
                e => Alert.sharedInstance().show(
                    __('location.what-are-these-3-label'),
                    __('location.what-are-these-3-description'),
                    [
                        {text: __('message.ok')},
                        {
                            text: __('message.learn-more'),
                            onPress: () => {
                                Common.openURL('https://what3words.com/about/');
                            },
                            style: 'cancel',
                        },
                    ],
                )}>
                <Text style={theme.What3words.learnMore}>{__('location.what-are-these-3-label')}</Text>
            </TouchableOpacity>
        </View>
    }}</ThemeConsumer>
);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
});
