import net from '@ampeco/net';
import {
    PartialProfile,
    Profile,
    SessionHistory,
    UpdateProfile,
    SessionStat,
    ExportResponse, Session, UserSettings,
} from '@ampeco/charge-models';

export async function updateLocale(locale: string): Promise<boolean> {
    return _patchProfile({locale});
}

export async function updateNewsAndPromotionsPreferences(shouldReceive: boolean): Promise<boolean> {
    return _patchProfile({receiveNewsAndPromotions: shouldReceive});
}

async function _patchProfile(profile: PartialProfile): Promise<boolean> {
     return !!(await net.patch('/app/profile', profile));
}

export async function patchProfile(profile: UpdateProfile): Promise<Profile> {
    return (await net.patch('/app/profile', profile)) as Profile;
}

export async function patchEmail(email: UpdateProfile): Promise<Profile> {
    return (await net.patch('/app/profile/email', email)) as Profile;
}

export async function getSessionHistory(startDate?: string, endDate?: string): Promise<SessionHistory[]> {
    let params = '';
    if (startDate && endDate) {
        params = '?&start_date=' + startDate +  '&end_date=' + endDate;
    }

    return (await net.getOrWarn('/app/profile/session_history' + params)).session_history as SessionHistory[];
}

export async function getSessionStats(seconds: number, startDate?: string, endDate?: string): Promise<SessionStat> {
    if (!startDate || !endDate) {
        return (await net.getOrWarn('/app/profile/session_stats?seconds=' + seconds)).energy as SessionStat;
    }
    return (await net.getOrWarn('/app/profile/session_stats?&start_date=' + startDate +  '&end_date=' + endDate)).energy as SessionStat;
}

export function getExportUrl(): Promise<ExportResponse> {
    return net.getOrWarn('/app/export/create');
}

export function getUserSettings(): Promise<UserSettings> {
    return net.get('/app/settings/user');
}
