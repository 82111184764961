import {StackScreenProps} from '@react-navigation/stack';
import Navigation, {HomeStackParamList} from '@ampeco/navigation';
import {observer} from 'mobx-react';
import React from 'react';
import {__} from '@ampeco/i18n';
import { SafeAreaView } from 'react-native';
import {SessionRunning} from './SessionRunning';
import {Session} from '@ampeco/charge-models';
import {GlobalStoreProxy} from '@ampeco/charge-globalstore';
import {SessionChangesMonitorProxy} from '@ampeco/charge-services';

type NavigationProp = StackScreenProps<HomeStackParamList, 'EVSESession'>;

@observer
export class EVSESession extends React.Component<NavigationProp> {
  componentDidMount() {
    this.props.navigation.setOptions({ title: __('session.initiated.title') });
  }

  async onSessionStopped(session: Session) {
    if (GlobalStoreProxy.sharedInstance().activeSession?.id === session.id) {
      GlobalStoreProxy.sharedInstance().setActiveSession(null);
    }

    if (session.status !== 'waiting_for_lock') {
      Navigation.sharedInstance().switchToMain();
      SessionChangesMonitorProxy.getClass()?.sessionHasEnded?.(session);
    }

    return Promise.resolve();
  }

  render() {
    if (!this.props.route.params.session) {
      return null;
    }

    return (
        <SafeAreaView style={{ flex: 1 }}>
          <SessionRunning
              session={this.props.route.params.session}
              onSessionStopped={this.onSessionStopped.bind(this)}
          />
        </SafeAreaView>
    )
  }
}
