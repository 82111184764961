import { Platform } from 'react-native';
import { Pin } from "@ampeco/charge-models";
import { calculateDistance } from "@ampeco/utils";
import { splitGeoToLatLng } from "../utils/Map";
import {__} from '@ampeco/i18n';
import Alert from '@ampeco/alert'

let userLocationInstance: UserLocationStore;

interface Coordinates {
    latitude: number;
    longitude: number;
}

export default class UserLocationStore {
    userPosition?: Coordinates = undefined;

    static sharedInstance(): UserLocationStore {
        if (userLocationInstance === undefined) {
            userLocationInstance = new UserLocationStore();
        }
        return userLocationInstance;
    }

    getCurrentPosition(): Promise<Coordinates> {
        return new Promise((resolve, reject) => {
            navigator?.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    this.userPosition = { latitude, longitude }
                    resolve({ latitude, longitude })
                },
                (error) => {
                    switch (error.code) {
                        case 1:
                            // FIXME: Error handling should be improved or @react-native-community/geolocation package should be changed. [ch20476]
                            // Alert.sharedInstance().show('', __('message.info.enable-location'));
                            break;
                        default:
                            Alert.sharedInstance().show('', __('message.error.no-location'));
                    }
                    reject(error.message)
                },
                (Platform.OS === 'android' || Platform.OS === 'web') ? {
                    enableHighAccuracy: true,
                    timeout: 20000,
                    maximumAge: 10000,
                } : {},
            )
        })
    }

    getNearestPinCoordinates(pins: Pin[]): Pin {
        if (!this.userPosition || pins.length === 0) {
            return null;
        }

        return pins.reduce((previous: Pin, current: Pin) => {
            const previousDistance = previous?.geo ? this.distanceFromUser(previous.geo) : null;
            const currentDistance = current?.geo ? this.distanceFromUser(current.geo) : null;

            if (previousDistance === null) {
                return current;
            } else if (currentDistance === null) {
                return previous;
            } else if (currentDistance < previousDistance) {
                return current;
            } else {
                return previous;
            }
        });
    }

    distanceFromUser(position: string): number {
        const { latitude, longitude } = splitGeoToLatLng(position);

        if (isNaN(latitude) || isNaN(longitude)) {
            return Infinity;
        }

        const userCoordinates = this.userPosition;
        if (!userCoordinates) {
            return Infinity;
        }

        return calculateDistance(latitude, longitude, userCoordinates.latitude, userCoordinates.longitude, 'K');
    }
}
