import React, {Component} from 'react';
import {Location, UnitFormat} from '@ampeco/charge-models';
import {Image, StyleSheet, Text, View} from 'react-native';
import {calculateDistance, formatDistance} from '@ampeco/utils';
import {UserLocationStore} from '@ampeco/charge-stores';
import {ChargeTheme} from '@ampeco/theme';
import {ThemeConsumer} from 'react-native-elements';
import {GeolocationResponse} from '@react-native-community/geolocation';
import {LocaleStore} from '@ampeco/i18n';
import {RTLImage} from '@ampeco/rtl-image';

interface State {
    userPosition?: GeolocationResponse;
    distance: string | undefined;
}

export class Distance extends Component<{location: Location }, State> {
    constructor(props: {location: Location }) {
        super(props);
        this.state = {
            userPosition: undefined,
            distance: undefined,
        };
    }

    componentDidMount() {
        this.getDistance();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.location !== prevProps.location.location) {
            this.getDistance();
        }
    }

    getDistance() {
        const userLocationStore = UserLocationStore.sharedInstance();
        userLocationStore.getCurrentPosition().then((position) => {
            this.setState({userPosition: position}, () => {
                if (this.state.userPosition !== undefined) {
                    const [latitude, longitude] = this.props.location.location.split(',').map(parseFloat);
                    const distance = calculateDistance(this.state.userPosition.coords.latitude, this.state.userPosition.coords.longitude, latitude, longitude, LocaleStore.sharedInstance().displayUnit as UnitFormat);
                    this.setState({distance: formatDistance(distance, LocaleStore.sharedInstance().displayUnit as UnitFormat)});
                }
            });
        }).catch(e => {});
    }

    render() {
        return <ThemeConsumer>{({theme}) => {
            return <View style={styles.distance}>
                <RTLImage source={theme.Distance.flagIcon} style={theme.Distance.flagStyle}/>
                {this.state.distance !== undefined && <Text numberOfLines={1} style={theme.Distance.textStyle}>{this.state.distance}</Text>}
            </View>;
        }}</ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    distance: {
        flexDirection: 'row',
    },
});
