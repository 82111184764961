import net from '@ampeco/net';
import {Location} from '@ampeco/charge-models';

export async function addFavorite(location: Location): Promise<void> {
    return net.post('/app/profile/favorite/' + location.id);
}

export async function removeFavorite(location: Location): Promise<void> {
    return net.delete('/app/profile/favorite/' + location.id);

}
