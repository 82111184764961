import React from 'react';
import {EVSE} from "@ampeco/charge-models";
import OutOfOrder from "./OutOfOrder";
import EvseIdentifier from "./EvseIdentifier";
import EvseStatus from "./EvseStatus";
import {ChargePoint} from "../../models/ChargePoint";

interface Props {
    chargePoint: ChargePoint
}

const EvseList = ({chargePoint}: Props): JSX.Element => {
    if (chargePoint.evses) {
        const classes = ['row'];

        classes.push((chargePoint.evses.length === 2) ? 'cols-2' : 'cols-1')

        const outOfOrder = chargePoint.evses.every((evse: EVSE) => ['out of order', 'unavailable'].includes(evse.status))
            || chargePoint.status === 'out of order';

        if (outOfOrder) {
            classes.push('no-border');
        }

        const rowClassNames = classes.join(' ');

        return (<div className="evseList-wrapper">
            <div className={rowClassNames}>
                {chargePoint.evses.map((evse: EVSE) => <EvseIdentifier key={evse.identifier} evse={evse}/>)}
            </div>
            {!outOfOrder
                ? <div className={rowClassNames}>
                    {chargePoint.evses.map((evse: EVSE) => <EvseStatus key={evse.identifier} evse={evse}/>)}
                </div>
                : <OutOfOrder />
            }
        </div>);
    }

    return null;
}

export default EvseList;
