import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import NavigationService from '@ampeco/navigation';
import GlobalStore from "../../stores/GlobalStore";
import {Balance} from "@ampeco/balance";
import {ModulePage} from "../ModulePage";

function BalanceScreen(): JSX.Element {
    const navigationService = NavigationService.sharedInstance();
    const navigation: any = {
        setOptions: () => {/**/},
        navigate: navigationService.navigate.bind(navigationService),
    };

    return <ModulePage
        title={'wallet.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('Home')
        }}
        moduleName='balance'
    >
        <SafeAreaProvider>
            <Balance
                navigation={navigation}
                route={{
                    params: {
                        shouldShowTopup: () => {
                            return GlobalStore.sharedInstance().canAddPaymentMethod;
                        }
                    },
                }}
            />
        </SafeAreaProvider>
    </ModulePage>;
}

export default BalanceScreen;
