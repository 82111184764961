import React from 'react';
import {Tariff} from '@ampeco/charge-models';
import Currency, {format} from '@ampeco/currency';
import {View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__, LocaleStore} from '@ampeco/i18n';
import PriceRow from './PriceRow';
import {ChargeTheme} from '@ampeco/theme';
import {PriceFieldsFormatterStore} from '@ampeco/charge-stores';

const TimeOfDayPrices: React.FC<{
    tariff: {
        priceForDurationAtDay: number | null,
        pricingPeriodInMinutes: number | null,
        priceForEnergyAtDay: number | null,
        priceForDurationAtNight: number | null,
        priceForEnergyAtNight: number | null,
    },
    currency: Currency, canUseChargingAllowance: boolean}> = ({tariff, currency, canUseChargingAllowance}) => <ThemeConsumer>{({theme}) => {
    const priceFormatterStore = PriceFieldsFormatterStore.sharedInstance();

    return <View style={{marginTop: 5}}>
        <Text style={theme.Pricing.dayNightLabel}>{__('session.start.pricing.day')}</Text>
        {
            tariff.priceForDurationAtDay !== null
            && tariff.priceForDurationAtDay > 0
            &&
            <PriceRow
                label={__('session.start.pricing.per-period-minutes').replace(':period', tariff?.pricingPeriodInMinutes?.toString() ?? '1')}
                value={format(tariff.priceForDurationAtDay, currency)}
            />
        }
        {
            tariff.priceForEnergyAtDay !== null
            && tariff.priceForEnergyAtDay > 0
            &&
            <PriceRow
                label={__('session.start.pricing.per-kwh')}
                value={format(tariff.priceForEnergyAtDay, currency)}
                hasAsterisk={canUseChargingAllowance}
            />
        }
        <Text style={[theme.Pricing.dayNightLabel, {marginTop: 10}]}>{__('session.start.pricing.night')}</Text>
        {
            tariff.priceForDurationAtNight !== null
            && tariff.priceForDurationAtNight > 0
            &&
            <PriceRow
                label={__('session.start.pricing.per-period-minutes').replace(':period', tariff?.pricingPeriodInMinutes?.toString() ?? '1')}
                value={format(tariff.priceForDurationAtNight, currency)}
            />
        }
        {
            tariff.priceForEnergyAtNight !== null
            && tariff.priceForEnergyAtNight > 0
            &&
            <PriceRow
                label={__('session.start.pricing.per-kwh')}
                value={format(tariff.priceForEnergyAtNight, currency)}
                hasAsterisk={canUseChargingAllowance}
            />
        }
    </View>
}}</ThemeConsumer>;

export default TimeOfDayPrices;
