import React, {PropsWithChildren} from 'react';
import {Button} from 'react-native-elements';
import Navigation from '@ampeco/navigation';
import {LocaleStore, __} from '@ampeco/i18n';
import {bugsnag} from '@ampeco/logging';

type State = {
    hasError: boolean;
};
type Props = {
    children: JSX.Element
};
export default class ErrorBoundary extends React.Component<Props, State> {
    displayName: 'ErrorBoundary';

    constructor(props: PropsWithChildren<Props>) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        bugsnag.notify(error, 'Reported from ErrorBoundary component', errorInfo);
    }

    render(): JSX.Element {
        LocaleStore.observeLanguage();
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{flex: 1}}>
                <h2 style={{textAlign: 'center'}}>{__('web-langs.error-message')}</h2>
                <Button onPress={this.navigateOnHome} title={__('web-langs.button-go-home')}></Button>
            </div>;
        }

        return this.props.children;
    }
    navigateOnHome = (): void => {
        this.setState({hasError: false});
        Navigation.sharedInstance().navigate('/');
    };
}
