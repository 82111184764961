import React from 'react';
import {__} from '@ampeco/i18n';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {BasePage} from '../BasePage';
import NavigationService from '@ampeco/navigation';
import {EmailReceipt} from '@ampeco/invoices';
import {useLocation} from "react-router-dom";
import {StyleSheet} from "react-native";
import {EVSE} from "@ampeco/charge-models";
import {addPaymentMethodAndStartSession} from "../../services/StartSession";


function EmailReceiptScreen(): JSX.Element {
    const location = useLocation<{intendedNavigation?: {
            screen: string;
            evseId: string;
            evse: EVSE;

        }}>();
    const navigation: any = {
        title: __('invoices.details'),
        setOptions: () => {/**/},
        addListener: () => {/**/},
        removeListener: () => {/**/},
    };

    return <BasePage
        title={'email-receipt.title'}
        onGoBackClick={() => {
            NavigationService.sharedInstance().navigate('PaymentMethods')
        }}
    >
        <SafeAreaProvider style={styles.container}>
            <EmailReceipt
                navigation={navigation}
                route={{
                    params: {
                        successCallback: async () => {
                            addPaymentMethodAndStartSession(location.state.intendedNavigation.evse)
                        },
                        skipCallback: async () => {
                            addPaymentMethodAndStartSession(location.state.intendedNavigation.evse)
                        }
                    }
                }}
            />
        </SafeAreaProvider>
    </BasePage>;
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 35,
    }
});

export default EmailReceiptScreen;
