import React from 'react';
import PropTypes from 'prop-types';
import {FormStore} from './FormStore';
import {ProviderContext} from './FormStoreProvider';
import {Text, ThemeProps, withTheme} from 'react-native-elements';
import {observer} from 'mobx-react';
import AmpecoTheme from '@ampeco/theme';
import {StyleSheet} from 'react-native';

interface Props {message?: string, key?: string;}
@observer
class Error extends React.Component<Props & ThemeProps<AmpecoTheme>, ProviderContext> {

    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    get store(): FormStore {
        if (this.context.store) {
            return this.context.store;
        } else {
            throw new Error({message: 'Store not defined for element', ...this.props});
        }
    }

    render() {
        if (!this.props.message && !this.store.lastRemoteError) {
            return null;
        }
        // `Children.only` enables us not to add a <div /> for nothing
        return <Text
            style={[this?.props?.theme?.Text.style, {color: this?.props?.theme?.Input.errorStyle.color}, styles.error]}>{this.props.message || this.store.lastRemoteError}</Text>;
    }
}

export default withTheme(Error);

const styles = StyleSheet.create({
    error: {
        marginBottom: 10,
    },
});
