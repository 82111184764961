import {EVSE, Reservation} from '@ampeco/charge-models';
import AdapterInterface from './Reservation/AdapterInterface';
import MockAdapter from './Reservation/MockAdapter';
import LiveAdapter from './Reservation/LiveAdapter';

export class ReservationService {

    adapter: AdapterInterface;

    constructor(globalStore: any) {
        this.adapter = globalStore.mockSession
            ? new MockAdapter()
            : new LiveAdapter();
    }

    async reserveEVSE(evse: EVSE) {
        try {
            return this.adapter.reserve(evse.id);
        } catch (e) {
            // if (bugsnag) {
            //     bugsnag.notify(e, (report) => {
            //         report.metadata = {
            //             info: {
            //                 message: 'Failed to make a reservation',
            //             },
            //             evse: {id: evse.id},
            //         };
            //     });
            // }
        }

    }

    async cancelReservation(reservation: Reservation) {
        try {
            return this.adapter.cancel(reservation.id);
        } catch (e) {
            // if (bugsnag) {
            //     bugsnag.notify(e, (report) => {
            //         report.metadata = {
            //             info: {
            //                 message: 'Failed to cancel reservation',
            //             },
            //             reservation: {id: reservation.id},
            //         };
            //     });
            // }
        }
    }

    async updateActiveReservation(): Promise<Reservation | null> {
        try {
            return this.adapter.active();
        } catch (e) {
            // if (bugsnag) {
            //     bugsnag.notify(e, (report) => {
            //         report.metadata = {
            //             info: {
            //                 message: 'Failed to update active reservation',
            //             },
            //         };
            //     });
            // }
            return null;
        }
    }

    getReservationsRestriction(): void {
        this.adapter.restriction();
    }
}
