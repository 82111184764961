import {EVSE, Connector, Location} from '@ampeco/charge-models';

interface EVSEConnector {
    connector: Connector;
    evse: EVSE;
}

export default class SelectedConnectors {

    private sortedConnectors(location: Location): EVSEConnector[] {
        const connectors = this.connectors(location);
        return this.sort(connectors);
    }

    private connectors(location: Location): EVSEConnector[] {
        const res: EVSEConnector[] = [];
        location.zones && location.zones.map(zone => zone.evses && zone.evses.map(evse => evse.connectors.map(connector => res.push({connector, evse}))));
        return res;
    }

    private sort(connectors: EVSEConnector[]): EVSEConnector[] {
        const copy = connectors;
        copy.sort((first, second) => first.evse.maxPower === second.evse.maxPower ? 0 : (first.evse.maxPower > second.evse.maxPower ? -1 : 1));
        return copy;
    }

    private distinct(connectors: EVSEConnector[]): EVSEConnector[] {
        return connectors.filter((value, index, self) =>
            self.findIndex((el) => el.connector.icon === value.connector.icon) === index,
        );
    }

    selected(location: Location, top: number): EVSEConnector[] {
        const connectors = this.sortedConnectors(location);
        const distinctConnectors = this.distinct(connectors);
        if (distinctConnectors.length < top) {
            return connectors.slice(0, top);
        }

        return this.sort(distinctConnectors).slice(0, top);
    }
}
