import React from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {format} from '@ampeco/currency';
import WarningNotice from './WarningNotice';
import {Session} from '@ampeco/charge-models';
import {Observer} from 'mobx-react';
import {ChargeTheme} from '@ampeco/theme';
import {ImageStyle} from "react-native";

export default (props: {session: Session, onPress?: () => void, inverse?: boolean, onMap?: boolean, iconStyle?: ImageStyle}) => {

    return <ThemeConsumer>{({theme}) => {
        const bold = {...theme.Notice.boldTextStyle, ...(props.onMap ? theme.Notice.mapTextStyle : theme.Notice.textStyle)};

        return <WarningNotice
            onPress={props.onPress}
            onMap={props.onMap}
            iconStyle={props.iconStyle}
        >
            <Text style={bold}>{__('notice.unpaid-session')}</Text>
            <Observer>{() => {
                return <Text style={props.onMap ? theme.Notice.mapTextStyle : theme.Notice.textStyle}>
                    {__('notice.unpaid-session-details').replace(':amount', format(props.session.amountDue, props.session.currency))}
                </Text>;
            }}</Observer>
        </WarningNotice>;
    }}</ThemeConsumer>;
};
