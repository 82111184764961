import React from 'react';
import {I18nManager, StyleSheet, View} from 'react-native';
import {LineChart,} from 'react-native-chart-kit';
import {Session} from '@ampeco/charge-models';
// tslint:disable-next-line: no-submodule-imports
import {ChartConfig} from 'react-native-chart-kit/dist/HelperTypes';
import {TimeStats} from '@ampeco/charge-screens';
import ConfigurableActivityIndicator from '@ampeco/configurable-activity-indicator';
import colors from "../../res/colors"
import {FontWeight} from "../../res/fonts";

interface Props {
    session: Session;
}

interface State {
    isLoading: boolean;
}
const chartConfig: ChartConfig = {
    backgroundGradientFrom: colors.backgroundColor,
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: colors.backgroundColor,
    backgroundGradientToOpacity: 0.5,
    color: () => colors.completedColor,
    decimalPlaces: 3, // optional, defaults to 2dp
    labelColor: () => colors.textEmphasisColor,
    style: {
        borderRadius: 16,
    },
    propsForDots: {
        r: '1',
        strokeWidth: 2,
        stroke: colors.textEmphasisColor,
    },
    propsForLabels: {
        y: '10',
    },
    propsForBackgroundLines: {
        stroke: colors.textEmphasisColor,
        strokeWidth: StyleSheet.hairlineWidth,
        strokeDasharray: [999],

    },

};

export default class SummaryGraphComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {isLoading: props.session.powerValues || props.session.powerAvg === 0 ? false : true};
    }

    componentDidUpdate(prevProps: Props) {

        if (!!prevProps?.session?.powerValues?.max || (prevProps?.session?.status === this.props.session.status) && 'finished' === this.props.session.status) {
            if (this.state.isLoading !== false) this.setState({isLoading: false});
        }

    }

    render() {
        const {session} = this.props;
        const {isLoading} = this.state;
        const powerValues = session?.powerValues;

        const maxValue = powerValues?.max;

        return <View style={[styles.container]}>
            <TimeStats session={session}/>
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 1}}>
                    {isLoading ? <ConfigurableActivityIndicator /> :
                        !!maxValue && <LineChart
                            data={{
                                labels: [],
                                datasets: [
                                    {
                                        data: [maxValue], // Y top element
                                    },
                                    {
                                        data: [0, ...powerValues?.values || [], 0] || [],
                                        strokeWidth: 2,
                                        withDots: false,
                                    },
                                ],
                            }}
                            width={360} // from react-native
                            height={220}
                            withOuterLines
                            withInnerLines={false}
                            chartConfig={chartConfig}
                            yLabelsOffset={I18nManager.isRTL ? -10 :-70}
                            formatYLabel={(value) => {
                                // tslint:disable-next-line: radix
                                if (value === powerValues?.max?.toFixed(3)) {
                                    value = value + ' kW';
                                    return value;
                                }
                                return '';
                            }}
                            style={{
                                marginLeft: I18nManager.isRTL ? 'auto' : -60,
                                marginVertical: 8,
                                borderRadius: 16,
                            }}
                        />}
                </View>
            </View>
        </View>;
    }
}

const styles = StyleSheet.create({
    container: {
        marginTop: 10,
    },
    statBox: {
        alignItems: 'center',
    },
    textStyles: {
        fontSize: 12,
        color: colors.textEmphasisColor,
        fontWeight: FontWeight.light,
        fontFamily: 'RobotoCondensed-Regular',
    },
    pmTimeStyles: {
        marginRight: 5,
        fontWeight: FontWeight.bold,
    },
    labelStyles: {
        marginBottom: 5,
        fontSize: 10,
        fontWeight: FontWeight.regular,
        fontFamily: 'RobotoCondensed-Regular',
        color: colors.textEmphasisColor,
    },
    chart: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
    },
});
