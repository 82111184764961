import net from '@ampeco/net';
import {SignUpBonus} from './SignUpBonus';

export async function redeemVoucher(voucher: string): Promise<void> {
    return net.post('/app/profile/redeem_voucher', {voucher});
}

export async function getSignUpBonus(): Promise<SignUpBonus> {
    return net.get('/app/profile/sign_up_bonus');
}

export async function claimSignUpBonus(promoCodeId: string): Promise<void> {
    return net.post(`/app/profile/sign_up_bonus/${promoCodeId}/claim`);
}

export async function declineSignUpBonus(promoCodeId: string): Promise<void> {
    return net.post(`/app/profile/sign_up_bonus/${promoCodeId}/decline`);
}

export async function shouldRedeemButtonBeDisabledCall(): Promise<boolean> {
    const response = await net.post('/app/profile/is_available_for_redeem');
    return response.maxAttemptsReached;
}
