import Alert from '@ampeco/alert'

export function confirm(title: string, message: string, okButtonText: string, cancelButtonText: string): Promise<boolean> {

    return new Promise((resolve, reject) => {
        Alert.sharedInstance().show(
            title,
            message,
            [
                {
                    text: okButtonText,
                    onPress: () => resolve(true),
                },
                {
                    text: cancelButtonText,
                    onPress: () => resolve(false),
                    style: 'cancel',
                },
            ],
            {cancelable: false},
        );
    });
}
