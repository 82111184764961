import React from 'react';
import {FieldInput} from './FieldInput';
import {Button, Input, InputProps, ThemeProps, withTheme} from 'react-native-elements';
import {StandaloneIcon} from '@ampeco/icons';
import AmpecoTheme from '@ampeco/theme';

class PasswordRevealInputComponent extends React.Component<{
    value: any,
    onChange: (value: any) => void,
    setError?: (error: string) => void,
    errorMessage: string,
    editable: boolean,

} & InputProps & ThemeProps<AmpecoTheme>, {visible: boolean;}> implements FieldInput {
    state = {visible: false};

    toggle = () => {
        this.setState(({visible}) => ({visible: !visible}));
    };

    render(): React.ReactElement {

        const {
            onChange,
            value,
            onChangeText,
        } = this.props;

        return <Input
            {...this.props}
            value={value}
            rightIcon={<Button
                type="clear"
                onPress={this.toggle}
                icon={<StandaloneIcon
                    style={this.props.theme.PasswordRevealInput ? this.props.theme.PasswordRevealInput.iconStyle : null}
                    source={this.props.theme.PasswordRevealInput
                        && (
                            this.state.visible
                                ? this.props.theme.PasswordRevealInput.hidePasswordIcon
                                : this.props.theme.PasswordRevealInput.showPasswordIcon)} />}
            />}
            autoCompleteType={'password'}
            secureTextEntry={!this.state.visible}
            autoCapitalize={'none'}
            onChangeText={(text: string) => {
                onChange(text);
                if (onChangeText !== undefined) {
                    onChangeText(text);
                }
            }}
        />;
    }
}

const PasswordRevealInput = withTheme(PasswordRevealInputComponent);

export {PasswordRevealInput};
