import React from 'react';
import {StyleSheet, TextStyle, View} from 'react-native';
import { Text, ThemeConsumer } from 'react-native-elements';
import {I18nManager} from 'react-native';

interface Props {
    label: string,
    value: string,
    labelBold?: string,
    valueIsBold?: boolean,
    hasAsterisk?: boolean,
    hasLongValue?: boolean,
    specialTextStyle?: TextStyle,
    reverse?: boolean
}

const PriceRow: React.FC<Props> = ({ label, value, valueIsBold, labelBold, hasAsterisk = false, hasLongValue = false, specialTextStyle, reverse}) => <ThemeConsumer>{({ theme }) => {
    return <View style={styles.row}>
        <View numberOfLines={1}
              style={StyleSheet.flatten([
                  theme.Pricing.labelStyle,
                  styles.tableLabel,
                  { fontSize: 14, lineHeight: 22 },
                  reverse ? {flexDirection: 'row-reverse', justifyContent: 'flex-end'} : undefined,
              ])}>
            <View style={{ flexDirection: reverse ? 'row-reverse' : 'row', alignItems: 'center'}}>
                <Text style={[theme.Pricing?.labelStyle, { fontSize: 14, marginRight: 5, marginLeft: reverse ? 5 : 0 }, specialTextStyle]}>
                    {label}{reverse && labelBold ? ':' : ''}
                </Text>
                {labelBold && (
                    <Text
                        style={{ fontSize: 14, fontWeight: 'bold' }}>
                        {labelBold}{reverse ? '' : ':'}
                    </Text>
                )}
            </View>
        </View>
        <Text numberOfLines={1} style={[theme.Pricing.valueStyle, styles.tableValue, valueIsBold ? { fontWeight: 'bold' } : null, hasLongValue ? { flex: 4 } : null, specialTextStyle]}>
            {value + (hasAsterisk ? '*' : '')}
        </Text>
    </View>;
}}</ThemeConsumer>;

export default PriceRow;

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
    },
    tableLabel: {
        flex: 4,
    },
    tableValue: {
        textAlign: I18nManager.isRTL ? 'left' : 'right',
        flex: 2,
    },
});
