type Status = 'waiting-for-vehicle' | 'not-charging' | 'charging' | 'failed';

export interface StartPersonalSessionParams {
    is_smart: boolean;
    target_charge?: TargetCharge;
    start_time?: string;
    end_time?: string;
}

export interface PendingChargingSession extends StartPersonalSessionParams {
    evse_id: string;
    status: Status;
    session_id?: string;
}

export interface TargetCharge {
    value: string;
    unit: string;
    ev_reference_id?: string;
}

export enum PendingChargingSessionStatuses {
    WAITING_FOR_VEHICLE = 'waiting-for-vehicle',
    NOT_CHARGING = 'not-charging',
    CHARGING = 'charging',
    FAILED = 'failed',
}
