import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';
import React from 'react';
import {Platform, Switch as ReactNativeSwitch} from 'react-native';
import {FieldInput} from './FieldInput';

class Switch extends React.Component<{
    value: boolean,
    onChange: (value: any) => void,
    setError?: (error: string) => void,
    errorMessage?: string,
    editable?: boolean,
}, {}> implements FieldInput {
    render(): React.ReactElement {
        const {
            onChange,
            value,
        } = this.props;

        return (
            <ThemeConsumer<AmpecoTheme>>{({theme}) => (
                    <ReactNativeSwitch
                        value={value}
                        thumbColor={value ? theme.Switch.thumbColor.true : theme.Switch.thumbColor.false}
                        trackColor={{false: theme.Switch.trackColor.false, true: Platform.OS === 'android' ? '' : theme.Switch.trackColor.true}}
                        ios_backgroundColor={theme.Switch.trackColor.false}
                        onValueChange={onChange}
                    />
                )}
            </ThemeConsumer>
        );
    }
}

export {Switch};
