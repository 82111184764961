import React from 'react';
import {Image, StyleSheet} from "react-native";
import settings from '../../settings';

const OutOfOrder = (): JSX.Element => {
    return (<div className="row cols-1">
        <div className="col">
            <div className="statusImage">
                <Image
                    source={`${settings.images}/icon-out-of-order.png`}
                    style={[styles.statusImage]}
                    resizeMode={'center'}
                />
            </div>
            <div className="statuses">
                <h2>Станцията временно не работи</h2>
                <h3>Charge point temporary out of order</h3>
            </div>
        </div>
    </div>);
}

const styles = StyleSheet.create({
    statusImage: {
        height: 200,
        width: 270
    }
});

export default OutOfOrder;
