import {computed, observable} from 'mobx';
import Currency, {format} from '@ampeco/currency';
import {TopUpPackage} from '../TopUpPackage';
import {status} from '../api/topuppackages';

let storeInstance: BalanceStore;

export default class BalanceStore {
    @observable walletBalance: number = 0;
    @observable walletCurrency: Currency | null = null;
    @observable topUpInternalEnabled: boolean = false;
    @observable topUpExternalEnabled: boolean = false;
    @observable autoTopUpEnabled: boolean = false;
    @observable autoTopUpThreshHold: number = 0;
    @observable autoTopUpPackage: TopUpPackage | null = null;
    @observable topUpAdditionalInformation: string | null = null;
    @observable topUpLearnMoreUrl: string | null = null;
    @observable externalBalanceTopUpUrl: string | null = null;
    @observable loading: boolean = false;

    @computed get userAutoTopUpEnabled(): boolean {
        return !!this.autoTopUpPackage;
    }

    @computed get formattedWalletBalance(): string {

        if (this.walletCurrency === null) {
            return '0.00';
        } else {
            return format(this.walletBalance, this.walletCurrency);
        }
    }

    static sharedInstance() {
        if (storeInstance === undefined) {
            storeInstance = new BalanceStore();
        }
        return storeInstance;
    }

    async load() {
        try {
            this.loading = true;
            const st = await status();
            this.topUpInternalEnabled = st.topUpEnabled;
            this.topUpExternalEnabled = st.topUpExternalEnabled;
            this.autoTopUpEnabled = st.autoTopUpEnabled;
            this.autoTopUpThreshHold = st.autoTopUpThreshHold;
            this.autoTopUpPackage = st.autoTopUpPackage;
            this.topUpAdditionalInformation = st.topUpAdditionalInformation;
            this.topUpLearnMoreUrl = st.topUpLearnMoreUrl;
            this.externalBalanceTopUpUrl = st.externalBalanceTopUpUrl;
        } catch (e) {
            if (!e.response || e.response.status !== 401) { // Suppress errors for guests
                throw e;
            }
        } finally {
            this.loading = false;
        }
    }
}
