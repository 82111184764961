import BaseSCAService from './BaseSCAService';
import { confirmPayment, ConfirmPaymentResult } from '@stripe/stripe-react-native';
import { StripeScaMeta } from '@ampeco/charge-models';
import { Platform } from "react-native";
import PaymentsStore from '../../PaymentsStore';

export default class StripeSCAService extends BaseSCAService {
    handleOpenChallenge(): Promise<ConfirmPaymentResult> {

        const scaMeta = this.transaction?.scaMeta as StripeScaMeta;

        if (!scaMeta.stripeClientSecret || !scaMeta.paymentMethodToken) {
            throw Error('Stripe SCA service requires client secret and payment method token');
        }

        if (Platform.OS === 'web') {
            //TODO do not hardcode it; and remove await if possible
            const paymentsStore = PaymentsStore.sharedInstance();
            const stripe = paymentsStore.paymentMethodProcessor;

            return stripe.confirmCardPayment(scaMeta.stripeClientSecret, {
                payment_method: scaMeta.paymentMethodToken,
            }).then(function (result) {
                // Handle result.error or result.paymentIntent
                if(result.paymentIntent?.status == 'succeeded') {
                    PaymentsStore.sharedInstance().hideLastPaymentFailure();
                }
            });
        } else {
            return confirmPayment(scaMeta.stripeClientSecret, {
                paymentMethodType: 'Card',
                paymentMethodData: {
                    paymentMethodId: scaMeta.paymentMethodToken,
                },
            });
        }
    }
}
