 export enum PriceTypes {
    CHARGING_NOT_ALLOWED = 'charging not allowed',
    FREE = 'free',
    FLAT_RATE = 'flat rate',
    DURATION_AND_ENERGY = 'duration+energy',
    DURATION_AND_ENERGY_TIME_OF_DAY = 'duration+energy time of day',
    POWER_LEVELS = 'power levels',
    // AVERAGE_POWER_LEVELS is the same as POWER_LEVELS
    AVERAGE_POWER_LEVELS = 'average power levels',
    PEAK_POWER_LEVELS = 'peak power levels',
    AGILE_STREETS = 'agile streets',
    ENERGY_TOU = 'energy tou',
    OPTIMISED = 'optimised',
    STANDARD = 'standard',
    STANDARD_TOD = 'standard_tod',
    OPTIMISED_DYNAMIC = 'optimised dynamic pricing',
}
 export interface Tariff {
    id: string;
    currencyCode: string;
    description: string | null;
    additionalInformation: string | null;
    learnMoreUrl: string | null;
    priceForEnergy: number | string | null;
    priceForEnergyWhenOptimized: number | null;
    priceForDuration: number | null;
    durationFeeGracePeriod: number | null;
    priceForEnergyAtNight: number | null;
    priceForDurationAtNight: number | null;
    priceForEnergyAtDay: number | null;
    priceForDurationAtDay: number | null;
    pricingPeriodInMinutes: number | null;
    standardTariffDurationLastUnit: number | null;
    priceForSession: number | null;
    priceForIdle: number | null;
    priceForIdleAtNight: number | null;
    priceForIdleAtDay: number | null;
    connectionFee: number | null;
    minPrice: number | null;
    regularUseMinutes: number | null;
    idleFeeGracePeriodMinutes: number | null;
    pricePeriod: PricePeriod[];
    standardTariffIdleFee: number | null;
    standardTariffDurationFee: number | null;
    standardTariffFeePerKwh: number | null;
    priceType: PriceTypes;
    powerLevels: PowerLevel[];
    isAdHoc: boolean;
    defaultTopUpKwh?: number;
    optimisedLabel?: string;
    dayTariffStart?: string;
    nightTariffStart?: string;
    offPeakHoursLabel?: string;
    peakHoursLabel?: string;
    referenceRange?: number;
    defaultChargeByTime?: string;
    optimisedTariffStart?: string;
    optimisedTariffEnd?: string;
    durationFeeFrom: string | null;
    durationFeeTo: string | null;
}

 export interface PowerLevel {
    max_power_kw: number;
    price_per_minute: number;
    excess_use_price_per_minute: number;
}

 export interface PricePeriod {
    startTime: string;
    endTime: string;
    energyPerKwh?: number;
    connectionFee?: number;
    duration?: {
        fee: number;
        billableUnit: number;
    },
    idle?: {
        fee: number;
        billableUnit: number;
    }
}
 export interface ToUPricePeriods {
     granularity: number;
     periods: ToUPricePeriod[];
 }
 export interface ToUPricePeriod {
     atDay : string;
     atTime: string;
     pricePerKwh: number;
 }

 export type PricePeriodFeesAvailability = {
    connectionFee: boolean;
    energyPerKwh: boolean;
    idleFee: boolean;
    durationFee: boolean;
}

// personal tariff
 export interface PersonalTariff {
    id: string; //
    type: PriceTypes;
    pricing: PersonalPricing;
}
// personal peak power level
 export interface PeakPowerLevel {
     peakPowerUpToKw: number;
     regularPricePerMinute: number;
     excessUsePricePerMin: number;
 }
 // personal average power level
 export interface AveragePowerLevel {
     averagePowerUpToKw: number;
     pricePerMinute: number;
 }

 // standard tariff
 export interface MultiIdleFee {
     firstUnit: number;
     lastUnit: number;
     feePerUnit: number;
     flatFee: number;
 }

 // standard tariff
 export interface MultiPricePerKwh {
     firstKwh: number;
     lastKwh: number;
     feePerKwh: number;
     flatFee: number;
 }

 // personal tariff pricing
 export interface PersonalPricing {
     pricePerSession?: number;
     connectionFee?: number;
     idleFeePerMinute?: number;
     idleFeeGracePeriodMinutes?: number;
     durationFeeGracePeriod?: number;
     pricePerPeriod?: number;
     pricePerKwh?: number;
     pricePeriodInMinutes?: number;
     peakPowerLevels?: PeakPowerLevel[];
     averagePowerLevels?: AveragePowerLevel[];
     multiIdleFee?: MultiIdleFee[];
     multiPricePerKwh?: MultiPricePerKwh[];
     regularUsePeriod?: number | null;
     dayPricePerPeriod?: number | null;
     dayPricePerKwh?: number | null;
     nightPricePerPeriod?: number | null;
     nightPricePerKwh?: number | null;
     priceForEnergyAtDay?: number | null;
     optimisedTariffEnd?: string | null;
     optimisedTariffStart?: string | null;
     priceForEnergyAtNight?: number | null;
 }
