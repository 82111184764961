import {StyleProp, StyleSheet, TextStyle, ViewStyle} from 'react-native';
import {Text, ThemeProps, withTheme} from 'react-native-elements';
import React, {ReactNode} from 'react';
import AmpecoTheme from '@ampeco/theme';
import {TouchableOpacity} from 'react-native-gesture-handler';

interface TextButtonProps {
    onPress?: () => void;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    children?: ReactNode;
}

const TextButton = (props: TextButtonProps & ThemeProps<AmpecoTheme>) => {
    return <TouchableOpacity activeOpacity={0.8} style={[{alignContent: 'center', justifyContent: 'center', alignItems: 'center', height: 30}, props?.theme.TextButton.style, props.style]} onPress={props.onPress}>
        <Text style={StyleSheet.flatten([{textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontSize: 14}, props.theme.TextButton.textStyle, props.textStyle])}>
            {props.children}
        </Text>
    </TouchableOpacity>;
};

export default withTheme<TextButtonProps, AmpecoTheme>(TextButton);
