import {WebViewMessageEvent} from 'react-native-webview';
import PaymentsStore from "../PaymentsStore";

// @TODO remove from src/services/AdditionalAddCardsWebViewEventsService.tsx in mobile
export default class AdditionalAddCardsWebViewEventsService {
    public static injectedJavaScript(provider: String) {
        switch (provider) {
            case PaymentsStore.PROCESSOR_QORPAY:
                return `
                      window.addEventListener('message',function (e) {
                          if (e.data.event === 'success_response') {
                             window.ReactNativeWebView.postMessage(JSON.stringify(e.data.data));
                          }
                      });
                      true;
                    `;
            default:
                return null;
        }
    }

    public static onMessage(provider: String) {
        switch (provider) {
            case PaymentsStore.PROCESSOR_QORPAY:
                return async (event: WebViewMessageEvent) => {

                    const response = JSON.parse(event.nativeEvent.data);

                    if (response.code === 'GW00') {
                        PaymentsStore.sharedInstance().setQorPayTokenData(event.nativeEvent.data);
                    } else {
                        PaymentsStore.sharedInstance().setQorPayTokenData(null);
                    }
                }
            default:
                return null;
        }
    }
}
