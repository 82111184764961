import React, {useState, useEffect} from 'react';
import AmpecoTheme from '@ampeco/theme';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import {Button, CheckBox, Divider, ThemeConsumer} from "react-native-elements";
import {Term, TermApi} from '@ampeco/terms';
import {Action, Error, Field, FormStore, FormStoreProvider} from "@ampeco/forms";
import {__} from '@ampeco/i18n';
import {HTMLRenderer} from '@ampeco/html-renderer';
import {RTLImage} from '@ampeco/rtl-image';
import colors from '../../res/colors';

type Props = {
    term: Term,
    onConsentClick: () => void,
    onCancelClick: () => void,
    onTermLinkClick: () => void,
}

const TermConsentScreen = ({term, onConsentClick, onCancelClick, onTermLinkClick}: Props): JSX.Element => {
    const [formStore, setFormStore] = useState(null);
    const [formStoreReady, setFormStoreReady] = useState(false);

    useEffect(() => {
        const fm = new FormStore();
        fm.rules['accept'] = 'required';

        fm.actions['consent'] = async () => {
            const result = await TermApi.consent(term.version_id);
            if (result) {
                fm.reset();
                onConsentClick();
            } else {
                fm.lastRemoteError = __('terms-change.error_message');
            }
        };
        setFormStoreReady(true);

        fm.reset();
        setFormStore(fm);
    }, []);

    if (!formStoreReady) {
        return null;
    }

    return <ThemeConsumer<AmpecoTheme>>{({theme}) => {
        return <View>
            {term.version_summary && <View style={styles.summary}><HTMLRenderer text={term.version_summary}/></View>}
            <Divider/>
            <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => onTermLinkClick()}
            >
                <View style={theme.TermChange.linkContainerStyle}>
                    <Text style={theme.TermChange.linkTextStyle}>{term.title}</Text>
                    <View>
                        <RTLImage source={theme.TermChange.linkIconSource} resizeMode={'contain'} style={styles.image}/>
                    </View>
                </View>
            </TouchableOpacity>
            <View>
                <FormStoreProvider store={formStore}>
                    <Field
                        type={CheckBox}
                        title={term.consent_request}
                        name="accept"
                    />
                    <Error/>
                    <Action
                        disableIfInvalid type={Button}
                        name={'consent'}
                        title={__('terms-change.continue')}
                        validateOnPress
                    />
                    <Button
                        type={'clear'}
                        onPress={() => {
                            formStore.reset();
                            onCancelClick();
                        }}
                        title={__('terms-change.cancel')}
                    />
                </FormStoreProvider>
            </View>
        </View>
    }}</ThemeConsumer>
}

export default TermConsentScreen;

const styles = StyleSheet.create({
    summary: {
        paddingVertical: 24,
    },
    image: {
        tintColor: colors.textEmphasisColor,
        height: 20,
        width: 20,
    },
});
