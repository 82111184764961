import React, {useEffect, useState} from 'react';
import {AddPaymentMethodNative3ds} from '@ampeco/payment-methods';
import NavigationService, { HomeModalParamList } from '@ampeco/navigation';
import {LocationStore} from "@ampeco/charge-stores";

function AddPaymentMethodNative3dsScreen({ location }: { location: { state: HomeModalParamList['AddPaymentMethodNative3ds'] } }): JSX.Element {

    const navigationService = NavigationService.sharedInstance();
    const navigation: any = {
        setOptions: () => {/**/ },
        addListener: () => {/**/ },
        removeListener: () => {/**/ },
        navigate: navigationService.navigate.bind(navigationService),
    };

    const params = location.state;
    const searchParams = new URLSearchParams(location.search);
    const evseIdentifier = searchParams.get('evseIdentifier');
    // State variable for evse
    const [evse, setEvse] = useState(null);
    // Using useEffect to handle the async operation
    useEffect(() => {
        const fetchEvse = async () => {
            if (evseIdentifier) {
                const result = await LocationStore.sharedInstance().resolveEVSE(evseIdentifier);
                setEvse(result.evse);
            }
        };
        fetchEvse();
    }, []);

    return  <AddPaymentMethodNative3ds
        navigation={navigation}
        route={{
            ...params,
            evse,
        }}
    />;
}

export default AddPaymentMethodNative3dsScreen;
