import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ThemeConsumer} from 'react-native-elements';
import AmpecoTheme from '@ampeco/theme';

interface Props {
    activeStep: number;
    totalSteps?: number;
}

export default class Steps extends React.Component<Props> {
    get totalSteps() {
        if (!this.props.totalSteps) {
            return 3;
        }
        return this.props.totalSteps;
    }
    render() {
        return <ThemeConsumer<AmpecoTheme>>{({theme}) => {
            return <View style={styles.container}>
                {Array.from(Array(this.totalSteps))
                    .map((_, i) => <View key={i} style={[styles.step, theme.FlowWizard.steps.style, this.props.activeStep === i + 1 ? theme.FlowWizard.steps.active : theme.FlowWizard.steps.inactive]}/>)}
            </View>
        }}</ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginBottom: 30,
        marginTop: 30,
        justifyContent: 'center',
    },
    step: {
        height: 8,
        width: 8,
        borderRadius: 50,
        marginRight: 10,
    },
});
