import React from 'react';
import {Image} from 'react-native';
import {withTheme} from 'react-native-elements';

export default withTheme((props: any) => {
    return <Image
        {...props}
        resizeMode="contain"
        style={[{tintColor: (props.theme.Button && props.theme.Button.titleStyle && props.theme.Button.titleStyle.color) || 'white'}, props.style]}/>;
});
