import React from 'react';
import {Button} from 'react-native-elements';
import NavigationService from '@ampeco/navigation';
import {__, LocaleStore} from '@ampeco/i18n';


const notFound = function NotFound() {
    LocaleStore.observeLanguage();
    const onPress = () => {
        NavigationService.sharedInstance().navigate('Home');
    };
    return <div style={{flex: 1}}>
        <h2 style={{textAlign: 'center'}}>{__('web-langs.page-missing')}</h2>
        <Button title={__('web-langs.button-go-home')} onPress={onPress}></Button>
    </div>;
}

notFound.displayName = 'NotFound';

export default notFound;
