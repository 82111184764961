import {observable} from 'mobx';
import net from '@ampeco/net';

interface PriceFieldsFormatterMap {
    [key: string]: any;
}

export default class PriceFieldsFormatterStore {
    private static instance: PriceFieldsFormatterStore;

    @observable priceFieldsFormatterMap: PriceFieldsFormatterMap = {};

    static sharedInstance(): PriceFieldsFormatterStore {
        if (PriceFieldsFormatterStore.instance === undefined) {
            PriceFieldsFormatterStore.instance = new PriceFieldsFormatterStore();
        }
        return PriceFieldsFormatterStore.instance;
    }

    async loadFromBackend() {
        const res = await net.get('/app/price-fields-formatter');
        this.priceFieldsFormatterMap = res.data ?? {};
    }

    getFormatterForField(field: string) {
        return this.priceFieldsFormatterMap[field] ?? 'formatter';
    }
}
