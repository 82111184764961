import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';

export default function DetailsLine(props: {title: string, value: string|undefined|null}) {
    return <ThemeConsumer>{({theme}) => {
        return <View style={styles.line}>
            <View style={styles.titleWrapper}>
                <Text allowFontScaling={false} numberOfLines={1} style={[theme.DetailsLine.titleStyle]}>{props.title}</Text>
                <Text allowFontScaling={false} numberOfLines={1} style={[theme.DetailsLine.titleStyle]}>:</Text>
            </View>
            <Text allowFontScaling={false} numberOfLines={1} style={[styles.value, theme.DetailsLine.valueStyle]}>{props.value}</Text>
        </View>;
    }}</ThemeConsumer>;
}

const styles = StyleSheet.create({
    line: {
        flexDirection: 'row',
        marginVertical: 5,
    },
    titleWrapper: {
        flex: 0.5,
        flexDirection: 'row',
    },
    value: {
        flex: 1,
    },
});
