import {Session} from '@ampeco/charge-models';

export const isVehicleParked = (session: Session | null) => {
  if (!session) {
    return false;
  }

  return session.status === 'waiting_for_start';
}

export const isSessionUnknown = (session: Session) => {
  return session.status === 'unknown';
}
