import {autorun, computed, observable} from 'mobx';

import AsyncStorage from '@react-native-async-storage/async-storage';
import Config from 'react-native-config';
import RNRestart from 'react-native-restart';

const devDeployStorageKey = '@DevDeploy';
const devEntrypointStorageKey = '@DevEntrypoint';
const devEntrypointNameStorageKey = '@DevEntrypointName';

export default class DevEndpointStore {
    static instance: DevEndpointStore | null = null;

    @observable deploys: string[] | null = null;
    @observable deploy: string | null = null;
    @observable endpoints: { [key: string]: string} | null = null;
    @observable endpoint: string | null = null;
    @observable endpointName: string | null = null;
    restored = false;

    static sharedInstance(): DevEndpointStore {
        if (DevEndpointStore.instance === null) {
            throw new Error('DevEndpointStore not initialized properly!');
        }
        return DevEndpointStore.instance;
    }

    static init(discoverEndpoint: string, listInstancesPath: string, appName: string) {
        DevEndpointStore.instance = new DevEndpointStore(discoverEndpoint, listInstancesPath, appName);
    }

    initializing = true;

    discoverEndpoint: string;
    listInstancesPath: string;
    appName: string;

    constructor(discoverEndpoint: string, listInstancesPath: string, appName: string) {
        this.discoverEndpoint = discoverEndpoint;
        this.listInstancesPath = listInstancesPath;
        this.appName = appName.toLowerCase();

        autorun(() => {
            JSON.stringify(this.endpointName);
            JSON.stringify(this.deploy);
            JSON.stringify(this.endpoint);
            if (!this.initializing) {
                this.save();
            }
        });

    }

    save() {
        // Persist changed filters to local storage
        if (this.deploy === null) {
            return;
        }
        AsyncStorage.setItem(devDeployStorageKey, JSON.stringify(this.deploy));

        if (this.endpoint === null) {
            return;
        }
        AsyncStorage.setItem(devEntrypointStorageKey, JSON.stringify(this.endpoint)).then(() => {
            if (this.endpointName === null) {
                return;
            }
            AsyncStorage.setItem(devEntrypointNameStorageKey, JSON.stringify(this.endpointName)).then(() => {
                this.reboot();
            });
        });

    }

    @computed get broadcastEndpoint() {
        if (this.deploy === null) {
            return null;
        }
        return this.deploy.replace(/\/\//g, '//' + Config.APP_DISCOVER_ECHO_SUBDOMAIN + '.');
    }

    @computed get broadcastChannelPrefix() {
        if (this.endpoint === null) {
            return null;
        }
        return this.endpoint.split('://')[1].split('.')[0];
    }

    @computed get deploysName() {
        if (this.deploys === null) {
            return [];
        }
        return this.deploys.map((deploy) => {
            return this.getDeployName(deploy);
        });
    }

    @computed get deployName() {
        if (this.deploy === null) {
            return '';
        }

        return this.getDeployName(this.deploy);

    }

    private getDeployName(deploy: string) {
        const domain = (deploy.split('://')[1]).toLowerCase();
        if (domain.endsWith('.dev.' + this.appName + '.ampeco.tech')) {
            return domain.substr(0, domain.length - ('.dev.' + this.appName + '.ampeco.tech').length);
        }
        if (domain.endsWith('.dev.ampeco.tech') && domain.startsWith('' + this.appName + '.')) {
            return 'Local: ' + domain.substr((this.appName + '.').length, domain.length - '.dev.ampeco.tech'.length - (this.appName + '.').length);
        }

        return domain;
    }

    async restore() {
        this.restored = true;
        const devDeploy = await AsyncStorage.getItem(devDeployStorageKey);
        if (devDeploy !== null) {
            this.deploy = JSON.parse(devDeploy);
        }

        const devEntryPoint = await AsyncStorage.getItem(devEntrypointStorageKey);
        if (devEntryPoint !== null) {
            this.endpoint = JSON.parse(devEntryPoint);
        }

        const devEntryPointName = await AsyncStorage.getItem(devEntrypointNameStorageKey);
        if (devEntryPointName !== null) {
            this.endpointName = JSON.parse(devEntryPointName);
        }
        if (this.initializing) {
            autorun(() => {
                if (this.deploy) {
                    this.loadEndpointsFromBackend();
                }
            });
        }
        this.initializing = false;
    }
    async loadDeploysFromBackend() {

        try {
            this.deploys = await require('./api').discoverDeploys();
        } catch (error) {
            // ignore
        }
    }

    async loadEndpointsFromBackend() {
        if (this.deploy === null) {
            return;
        }
        try {
            this.endpoints = await require('./api').discoverEntryPoints(this.deploy);
        } catch (error) {
            // ignore
        }
    }

    reboot() {
        RNRestart.Restart();
    }
}
