import React, {ReactElement, useEffect, useState} from 'react';
import {Dimensions, StyleSheet, View} from 'react-native';
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import CustomConfigurableSlider from './CustomConfigurableSlider';
import {SessionStartStore} from '@ampeco/charge-stores';
import {format} from '@ampeco/currency';
import {useOptimisedOffPeakPrices} from '@ampeco/charge-services';
import {ThemeConsumer} from "react-native-elements";
import AmpecoTheme from "@ampeco/theme";

export const OptimisedOffPeakSlider: React.FC<{
    tariff: any,
    currency: any,
    offPeakMaxPowerInKwh: number,
    maxTime: number,
    isOptimisedChargingAvailable: boolean,
    chargeByTime: string,
}> = ({
          tariff,
          currency,
          offPeakMaxPowerInKwh,
          maxTime,
          isOptimisedChargingAvailable,
          chargeByTime,
      }): ReactElement | null =>
{
    const sessionStartStore = SessionStartStore.sharedInstance();
    const sliderLength = Dimensions.get('window').width - 150;
    const sliderMax = Math.round(offPeakMaxPowerInKwh * maxTime);
    const initialSliderValue = sliderMax;

    const [targetCharge, setTargetCharge] = useState(initialSliderValue);

    const {optimisedPrice, boostPrice, updateBoostPrice, updateOptimisedPrice} = useOptimisedOffPeakPrices({
        offPeakMaxPowerInKwh,
        chargeByTime,
        targetCharge,
        tariff,
        isOptimisedChargingAvailable,
    });

    useEffect(() => {
        sessionStartStore.setTargetCharge(targetCharge);
    }, []);

    useEffect(() => {
        updateOptimisedPrice(targetCharge);
        updateBoostPrice(targetCharge);
    }, [maxTime, targetCharge]);

    useEffect(() => {
        setTargetCharge(sliderMax);
    }, [sliderMax]);

    if (initialSliderValue === 0 || sliderMax === 0) {
        return null;
    }

    return <ThemeConsumer<AmpecoTheme>>{({ theme }) => {
        return (
            <View style={styles.container}>
                <MultiSlider
                    values={[initialSliderValue]}
                    min={1}
                    max={sliderMax + 1} // +1 for some reason fixes the ability to slide all the way to the calculated max after sliding back before that
                    step={1}
                    onValuesChange={(values: number[]) => {
                        setTargetCharge(values[0]);
                        sessionStartStore.setTargetCharge(values[0]);
                    }}
                    selectedStyle={{
                        backgroundColor: theme.AgileStreetSlider?.boostColor,
                    }}
                    unselectedStyle={{
                        backgroundColor: theme.AgileStreetSlider?.boostColor,
                    }}
                    sliderLength={sliderLength}
                    trackStyle={{height: 10, borderRadius: 10}}
                    customMarker={(e) => {
                        return <CustomConfigurableSlider
                            referenceRange={tariff.referenceRange}
                            agilePrice={format(optimisedPrice, currency)}
                            boostPrice={format(boostPrice, currency)}
                            kwh={targetCharge}
                            label={tariff?.optimisedLabel ?? undefined}
                        />
                    }}
                />
            </View>);
    }}</ThemeConsumer>
};

const styles = StyleSheet.create({
    container: {
        marginTop: 70,
        marginBottom: 10,
        alignItems: 'center',
    },
});
