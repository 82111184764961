import {StyleSheet, View} from 'react-native';
import Spinner from 'react-spinkit';
import {ThemeConsumer} from 'react-native-elements';
import React from 'react';
import AmpecoTheme from '@ampeco/theme';

const ConfigurableActivityIndicator: React.FC = () => {
    return (
        <ThemeConsumer<AmpecoTheme>>{({theme}) => (
            <View style={styles.container}>
                {/*@ts-ignore*/}
                <Spinner name={theme.Spinner.type ?? 'three-bounce'} size={theme.Spinner.size ?? 40}
                         color={theme.Spinner.color}/>
            </View>
        )}</ThemeConsumer>
    );
};

export default ConfigurableActivityIndicator;

const styles = StyleSheet.create({
    container: {
        flex: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
