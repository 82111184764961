import {IGlobalStore} from './IGlobalStore';

export class GlobalStoreProxy {
    private static class: any;
    private static instance: IGlobalStore;

    static sharedInstance(): IGlobalStore {
        if (GlobalStoreProxy.instance === undefined) {
            GlobalStoreProxy.instance = GlobalStoreProxy.class.sharedInstance();
        }
        return GlobalStoreProxy.instance;
    }

    static init<T extends new (...args: any[]) => IGlobalStore>(globalStoreClass: T) {
        GlobalStoreProxy.class = globalStoreClass;
    }
}
