import {action, observable} from 'mobx';
import {Partner} from '@ampeco/charge-models';
import {Partners as PartnersAPI} from '@ampeco/charge-api';

export default class PartnersStore {

    private static instance: PartnersStore;

    @observable partners: Partner[] = [];

    static sharedInstance(): PartnersStore {
        if (PartnersStore.instance === undefined) {
            PartnersStore.instance = new PartnersStore();
        }
        return PartnersStore.instance;
    }

    @action.bound
    public async load() {
        try {
            this.partners = await PartnersAPI.getPartnersList();
        } catch (e) {
            this.partners = [];
        }
    }
}
