import Echo from 'laravel-echo';
import BroadcastService, {BroadcastSettings} from '@ampeco/broadcast';
import {reaction} from 'mobx';
import {BalanceHistoryStore, BalanceStore} from '@ampeco/balance';

export default class BalanceChangesMonitor {
    private static instance: BalanceChangesMonitor;

    private echo?: Echo;

    private globalStore: any;
    private setting!: () => BroadcastSettings;

    private subscribed?: string;

    static sharedInstance() {
        if (!BalanceChangesMonitor.instance) {
            BalanceChangesMonitor.instance = new BalanceChangesMonitor();
        }
        return BalanceChangesMonitor.instance;
    }

    init(globalStore: any, setting: () => BroadcastSettings) {
        this.globalStore = globalStore;
        this.setting = setting;
        reaction(() => BroadcastService.sharedInstance(this.setting).connected && this.globalStore.profile !== null, connected => {

            if (connected) {
                this.subscribe();

            } else {
                this.unsubscribe();
            }

        }, {name: 'Balance changes monitor'});
    }

    async subscribe() {
        if (this.subscribed) {
            return false;
        }
        const channelName = this.channelName();
        this.subscribed = channelName;

        this.echo = await BroadcastService.sharedInstance(this.setting).connect();

        const channel = this.echo.private(channelName);
        // TODO for some reason cannot listen on custom namespace. task [ch3811]
        // channel.listen('.Ampeco.Modules.Balance.Events.BalanceUpdated', this.onBalanceUpdated.bind(this));
        channel.listen('BalanceUpdated', this.onBalanceUpdated.bind(this));

        console.log('Echo: Subscribed to ' + channelName);
        return true;
    }

    unsubscribe() {
        if (!this.subscribed) {
            return;
        }
        this.echo && this.echo.leave(this.subscribed);
        this.echo && console.log('Echo: Unsubscribed from ' + this.subscribed);
        this.subscribed = undefined;
    }

    private onBalanceUpdated(payload: any) {
        BalanceStore.sharedInstance().walletBalance = payload.balance || 0;
        BalanceStore.sharedInstance().walletCurrency = payload.currency || null;
    }

    private channelName(): string {
        const service = BroadcastService.sharedInstance(this.setting);
        if (this.globalStore.profile === null || this.globalStore.profile.id === null) {
            throw new Error('Cannot resolve channel name - no profile logged in');
        }
        return `${service.channelPrefix()}.user.${this.globalStore.profile.id}`;
    }
}
