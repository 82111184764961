import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Platform, Linking } from 'react-native';
import { Button, Text, Theme, ThemeConsumer } from 'react-native-elements';
import Currency, { format } from '@ampeco/currency';
import moment from 'moment';
import AmpecoTheme from '@ampeco/theme';
import images from '../../images';
import { humanizeDuration } from '@ampeco/humanize-duration';
import { StandaloneIcon } from '@ampeco/icons';
import {__, LocaleStore} from '@ampeco/i18n';
import { Common } from '@ampeco/components';
import {formatNumber} from '@ampeco/utils';

const HistoryLine: React.FC<{
    value?: {
        totalAmount: number,
        currency: Currency,
        reimbursementEligibility: boolean,
        canToggleReimbursementEligibility: boolean,
        hasThirdPartyElectricityCostIntegrationEnabled: boolean,
        isPersonal: boolean,
        electricityCost?: number,
        electricityCostCurrencyId: string,
    },
    energy?: number, // in kWh
    showDisclosure?: boolean,
    leftColumn?: 'value' | 'energy' | 'amount',
    note: string,
    noteIcon?: any,
    timestamp: Date,
    duration?: number, // in seconds
    onPress?: () => void,
    children?: React.ReactNode,
    type?: 'private' | 'public' | 'card',
    paymentStatus?: null | string,
    receiptUrl?: string,
    paymentIcon?: string,
    upperText?: string,
}> = ({
    onPress,
    leftColumn,
    showDisclosure,
    timestamp,
    value,
    energy,
    duration,
    note,
    noteIcon,
    children,
    type,
    paymentStatus,
    receiptUrl,
    paymentIcon,
    upperText,
}) => {
        if (value?.electricityCostCurrencyId) {
            Currency.fetch(value?.electricityCostCurrencyId);
        }
        const ValueComponent: React.FC<{ amount: number, currency: Currency, theme: Theme<AmpecoTheme> }> = (
            value,
        ) => {
            return <View style={styles.line}>{value.amount >= 0 ?
                <Text
                    style={StyleSheet.flatten([styles.valueText, value.theme.Fonts.condensedBold, value.theme.History.plusStyle])}>+ </Text> :
                <Text
                    style={StyleSheet.flatten([styles.valueText, value.theme.Fonts.condensedBold, value.theme.History.minusStyle])}>- </Text>}<Text
                        style={StyleSheet.flatten([styles.valueText, value.theme.Fonts.condensedLight, value.theme.History.textStyle])}>{format(Math.abs(value.amount), value.currency)}</Text></View>;
        };
        const EnergyComponent: React.FC<{ energy: number, theme: Theme<AmpecoTheme> }> = ({ energy, theme }) => {
            return <View style={styles.energy}>
                <View style={styles.energyIconAndValue}>
                    <StandaloneIcon
                        source={images.energy} />
                    <Text
                        style={StyleSheet.flatten([styles.energyValue, styles.valueText, theme.Fonts.condensedLight, theme.History.textStyle])}>{formatNumber(Math.round(energy / 100) / 10, 2)}</Text></View>
                <Text
                    style={StyleSheet.flatten([styles.energyUnits, theme.Fonts.condensedLight, theme.History.textStyle])}>kWh</Text>
            </View>;
        };
        const AmountComponent: React.FC<{ amount: number, currency: Currency, theme: Theme<AmpecoTheme> }> = (
            value,
        ) => {
            return <View style={styles.line}>
                <Text style={StyleSheet.flatten([styles.valueText, value.theme.Fonts.condensedLight, value.theme.History.textStyle])}>
                    {format(Math.abs(value.amount), value.currency)}
                </Text>
            </View>;
        };

        const content = () => {
            return (
                <ThemeConsumer<AmpecoTheme>>{({ theme }) => {
                    return (
                        <View style={[styles.wrapper, children ? styles.wrapperWithChildren : null]}>
                            <View
                                style={StyleSheet.flatten([styles.container])}>
                                <View
                                    style={[styles.leftContainer]}>
                                    <Text style={[styles.rightUpper, styles.labelText, theme.History.textStyle]}>
                                        {moment(timestamp).format(LocaleStore.sharedInstance().displayDateFormat)}
                                    </Text>
                                    {leftColumn === 'value' && value &&
                                        <ValueComponent theme={theme} amount={value.totalAmount} currency={value.currency} />}
                                    {leftColumn === 'energy' && energy !== undefined &&
                                        <EnergyComponent theme={theme} energy={energy} />}
                                    {leftColumn === 'amount' ? <AmountComponent theme={theme} amount={value.totalAmount} currency={value.currency} /> : null}

                                </View>
                                <View style={styles.rightContainer}>
                                    <View style={[styles.rightUpper, theme.Fonts.light]}>
                                        {leftColumn === 'amount' ? <Text
                                            style={StyleSheet.flatten([
                                                styles.labelText, theme.History.textStyle,
                                            ])}>
                                            {upperText}
                                        </Text> : null}
                                        {duration !== null && duration !== undefined &&
                                            <View style={styles.rightUpperElement}>
                                                <StandaloneIcon source={images.timerIcon} style={styles.icon} />
                                                <Text
                                                    style={StyleSheet.flatten([
                                                        styles.labelText, theme.History.textStyle,
                                                    ])}>
                                                    {humanizeDuration(duration, true)}
                                                </Text></View>}
                                        {(!value?.hasThirdPartyElectricityCostIntegrationEnabled && ((paymentStatus === 'paid' && value?.currency) || (value?.isPersonal && value.electricityCost !== undefined && value.electricityCostCurrencyId))) && <Text
                                            style={StyleSheet.flatten([
                                                styles.rightUpperElement,
                                                styles.labelText,
                                                theme.History.textStyle,
                                            ])}>
                                            {leftColumn !== ('value' && 'amount') && value && value.totalAmount > 0 && format(Math.abs(value.totalAmount), value.currency)}
                                            {leftColumn !== ('value' && 'amount') && value && !value.totalAmount && value.isPersonal && value.electricityCost !== undefined && value.electricityCost > 0
                                                && value.electricityCostCurrencyId && format(Math.abs(value.electricityCost), Currency.where(value.electricityCostCurrencyId))}
                                        </Text>}

                                        {value?.hasThirdPartyElectricityCostIntegrationEnabled && !value?.reimbursementEligibility && <Text style={StyleSheet.flatten([
                                            styles.rightUpperElement,
                                            styles.labelText,
                                            theme.History.textStyle,
                                        ])}>{__('sessions-history.personal')}</Text>}
                                    </View>
                                    <View style={styles.rightNote}>
                                        {paymentIcon ? <Image source={paymentIcon} style={styles.paymentMethodIcon} resizeMode={'contain'} /> : null}
                                        {noteIcon &&
                                            <StandaloneIcon
                                                style={styles.rightNoteImage}
                                                source={noteIcon} />}<Text style={[styles.rightNoteText, theme.History.textStyle]}>{note}</Text>
                                    </View>
                                </View>
                                {showDisclosure && <Image source={theme.History.disclosureIcon} />}
                                {receiptUrl ? <Button
                                    containerStyle={[styles.downloadReceipt]}
                                    type="clear"
                                    icon={<StandaloneIcon
                                        source={theme.HistoryLine.downloadIcon}
                                        style={theme.HistoryLine.downloadIconStyle}
                                    />}
                                    onPress={() => {
                                        Common.openURL(receiptUrl);
                                    }}
                                /> : null}
                            </View>
                            {children}
                        </View>
                    );
                }}</ThemeConsumer>
            );
        };
        if (onPress) {
            return <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
                {content()}
            </TouchableOpacity>;
        } else {
            return content();
        }
    };

const styles = StyleSheet.create({
    wrapper: {},
    container: {
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center',
    },
    wrapperWithChildren: {
        marginBottom: 10,
    },
    leftContainer: {
        height: '100%',
        flex: 1,
    },
    rightContainer: {
        flex: 2.2,
        marginLeft: 10,
    },
    rightUpper: {
        flexDirection: 'row',
        marginVertical: 4,
        justifyContent: 'space-between',
        textAlign: 'left',
    },
    energy: {
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    energyIconAndValue: {
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    line: {
        flexDirection: 'row',
    },
    energyValue: {
        marginHorizontal: 4,
    },
    energyUnits: { fontSize: 10 },
    rightUpperElement: {
        alignItems: 'center',
        marginRight: 10,
        flexDirection: 'row',
    },
    labelText: {
        fontSize: 11,
    },
    rightUpperElementLeft: {
        alignItems: 'flex-start',
    },
    rightNote: {
        flexDirection: 'row',
        marginVertical: 4,
        alignItems: 'flex-start',
    },
    rightNoteImage: {
        marginRight: 5,
    },
    paymentMethodIcon: {
        marginRight: 5,
        width: 23,
        height: 16,
    },
    rightNoteText: {
        fontSize: 14,
        textAlign: 'left',
    },

    valueText: {
        height: Platform.OS === 'android' ? 24 : -1,
        fontSize: 20,
    },

    icon: {
        marginRight: 5,
    },
    downloadReceipt: {
        width: 50,
        marginVertical: 0,
    },
});

export default HistoryLine;
