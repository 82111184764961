import React from 'react';
import {Text, ThemeConsumer} from 'react-native-elements';

const PricingHeader: React.FC<{padTop?: boolean, padBottom?: boolean}> = ({padTop, padBottom, children}) => (<ThemeConsumer>{({theme}) => {
    return <Text style={[
        theme.Pricing?.titleStyle,
        {marginBottom: padBottom ? 16 : 0, marginTop: padTop ? 16 : 0}]}>
        {children}
    </Text>;
}}</ThemeConsumer>);

export default PricingHeader;