
import {action, observable} from 'mobx';
import {EVSE, Reservation} from '@ampeco/charge-models';
import moment, {now} from 'moment';

let instance: ReservationStore;

export default class ReservationStore {

    static sharedInstance(): ReservationStore {
        if (instance === undefined) {
            instance = new ReservationStore();
            // Restore filters from local storage
        }

        return instance;
    }

    @observable activeReservation: Reservation | null = null;
    @observable activeReservationSeconds?: number;
    @observable reservationPolicy?: {
        enabled: boolean;
        reservation_minutes: number;
    };
    @observable reservationRestriction?: string | null = null;

    @observable reservationButtonEnabled: boolean = true;

    timer?: NodeJS.Timeout;

    private startTimer() {
        if (!this.activeReservation) {
            return;
        }
        this.activeReservationSeconds = this.getRemainingSeconds();
        this.timer = setInterval(() => {
            const seconds = this.getRemainingSeconds();
            if (seconds <= 0) {
                // we are not using the updateReservation in order to avoid loops
                this.activeReservation = null;
            }
            if (!this.activeReservation) {
                this.stopTimer();
                return;
            }
            this.activeReservationSeconds = seconds;
        }, 1000);
    }
    private getRemainingSeconds() {
        if (!this.activeReservation) {
            return 0;
        }
        const reservedAt = moment(this.activeReservation.reservedAt);
        const expire = reservedAt.add(this.activeReservation.periodMinutes, 'minutes');
        const currentTime = now();
        const diff = expire.diff(currentTime);
        return Math.round(diff / 1000);
    }
    private stopTimer() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    evseIsAvailable(evse: EVSE): boolean {
        return evse.isAvailable
            || (
                this.activeReservation
                && this.activeReservation.id == evse.reservationId
            )
            || false;
    }

    canReserveEvse(evse: EVSE): boolean {
        return (
            this.reservationIsEnabled()
            && evse.canReserve
            && (
                evse.isAvailable
                || (
                    this.activeReservation
                    && this.activeReservation.id == evse.reservationId
                )
            )
        ) || false;
    }

    evseIsReservedByCurrentUser(evse: EVSE): boolean {
        if (!this.activeReservation || !evse.reservationId) {
            return false;
        }

        return this.activeReservation.id == evse.reservationId;
    }

    updateReservation(reservation: Reservation|null) {
        this.stopTimer();
        this.activeReservation = reservation;
        if (this.activeReservation) {
            this.startTimer();
        }
    }

    reservationIsEnabled() {
        if (!this.reservationPolicy) {
            return false;
        }
        if (!this.reservationPolicy.enabled) {
            return false;
        }

        return this.reservationPolicy.reservation_minutes !== 0;
    }

    reservationMinutes() {
        if (!this.reservationPolicy) {
            return 0;
        }
        if (!this.reservationPolicy.enabled) {
            return 0;
        }
        return this.reservationPolicy.reservation_minutes;
    }

    @action.bound
    updateReservationRestriction(message: string | null) {
        this.reservationRestriction = message;
    }

    @action.bound
    enableReservationButton() {
        this.reservationButtonEnabled = true;
    }
}
